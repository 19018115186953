












import { Component, Prop, Vue } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import Loader from "@/components/graphic/Loader.vue";
import axios from "axios";

@Component({
  components: { Loader },
})
export default class ApiVersion extends Vue {
  private apiVersion = "";
  private loading = false;
  private error = false;
  async created() {
    this.loading = true;
    await axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/config/apiVersion",
        {
          //headers: authHeader(),
        }
      )
      .then((response) => {
        this.apiVersion = response.data;
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => (this.loading = false));
  }
}
