






























































import ChangePassword from "../models/ChangePassword";
import { Component, Vue } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import ResourceExpired from "@/components/graphic/ResourceExpired.vue";
import LoginPanel from "@/components/graphic/LoginPanel.vue";
import ButtonLogout from "@/components/inputs/ButtonLogout.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    ButtonInput,
    Loader,
    ResourceExpired,
    LoginPanel,
    ButtonLogout,
  },
})
export default class PageChangePassword extends Vue {
  // data()

  private changeEmail = new ChangePassword(
    this.$route.params.email,
    this.$route.params.hash,
    this.$route.query.expires,
    this.$route.query.signature
  );
  private loading = true;
  private expired = false;
  private changedPassword = false;
  private errorsFromServer: Array<string> = [];
  private user = this.$store.state.auth.user; //localStorage.getItem('user');//this.$store.state.auth.user;

  mounted() {
    this.loading = true;
    this.$store.dispatch("auth/changeEmail", this.changeEmail).then(
      () => {
        this.loading = false;
        this.changedPassword = true;
      },
      (error) => {
        this.loading = false;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors || [];
      }
    );
  }
}
