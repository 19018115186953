export default class ChangePassword {
  email: string
  hash: string
  expires: string
  signature: string
  constructor(email, hash, expires, signature) {
    this.email = email
    this.hash = hash
    this.expires = expires
    this.signature = signature
  }
}