








import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Loader extends Vue {
  @Prop({ default: "dot-pulse" }) private loaderClass!: string;
  @Prop({ default: false }) private tiny;
}
