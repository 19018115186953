

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import PageItems from "@/components/PageItems.vue";
import Notes from "@/components/custom/Notes.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import LastModified from "@/components/custom/LastModified.vue";
import ItemsList from "@/components/custom/ItemsList.vue";
import SelectIngredients from "@/components/custom/SelectIngredients.vue";
import PieChart from "@/components/graphic/PieChart.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import Pin from "@/components/graphic/Pin.vue";
import BarChart from "@/components/graphic/BarChart.vue";
import Loader from "@/components/graphic/Loader.vue";
import Modal from "@/components/inputs/Modal.vue";
import PDFViewer from "@/components/graphic/PDFViewer.vue";
import SubNavBar from "@/components/graphic/SubNavBar.vue";
import IngredientDropzone from "@/components/custom/IngredientDropzone.vue";
import EnableFeature from "@/components/custom/EnableFeature.vue";
import Label from "@/models/Label";
import { Helper } from "@/utils/Helper";
import { dragscroll } from "vue-dragscroll";
import axios from "axios";

const DisplaySheet = {
  mainPageSheet: 0,
  subPageSheet: 1,
  mainPageLabel: 2,
  subPageLabel: 3,
};

@Component({
  components: {
    PageItems,
    Notes,
    TextInput,
    SelectInput,
    ButtonInput,
    LastModified,
    ItemsList,
    SelectIngredients,
    PieChart,
    PopoverInput,
    Pin,
    BarChart,
    Loader,
    Modal,
    PDFViewer,
    SubNavBar,
    IngredientDropzone,
    EnableFeature,
  },
  directives: {
    dragscroll,
  },
})
export default class PageFinished extends Vue {
  private Helper = Helper;
  private user = this.$store.state.auth.user;
  private loading = false;
  private licenseStatus = {
    "sheet-pack": -1,
    "label-pack": -1,
    "price-pack": -1,
    "media-pack": -1,
  };
  private openModalSheet = null;
  private openModalLabel = null;
  private openModalPrice = null;
  private openModalLaunch = null;
  private sheetLabels = [];
  private sheetValues = [];
  private labelData = null;
  private batchNumber = "";
  private errorsFromServer: Array<string> = [];
  private displaySheetForm = DisplaySheet.mainPageSheet;
  private subNavbarSheetItem = DisplaySheet.mainPageSheet;
  private subNavbarSheetItems = [
    {
      description: "i18n_page_finished_nav_main",
      value: DisplaySheet.mainPageSheet,
      index: 0,
    },
    {
      description: "i18n_page_finished_nav_sheet",
      value: DisplaySheet.subPageSheet,
      index: 0,
    },
  ];
  private displayLabelForm = DisplaySheet.mainPageLabel;
  private subNavbarLabelItem = DisplaySheet.mainPageLabel;
  private subNavbarLabelItems = [
    {
      description: "i18n_page_finished_nav_main",
      value: DisplaySheet.mainPageLabel,
      index: 0,
    },
    {
      description: "i18n_page_finished_nav_label",
      value: DisplaySheet.subPageLabel,
      index: 0,
    },
  ];

  private final = 0;
  private launch = 0;

  currentTeam() {
    return this.user.currentTeam || null;
  }

  currencyString() {
    const currency = this.currentCurrency();
    if (currency == "$") return "dollar";
    else if (currency == "£") return "pound";
    else return "euro";
  }

  currentCurrency() {
    return (
      this.$store.state.auth.notifications["teams"][this.user.currentTeam]
        .currency || "&euro;"
    );
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  calcIngredientPrice(quantity, price) {
    return (price * quantity) / 1000;
  }

  updateImage(item, avatar) {
    item["image"] = avatar;
  }

  async savePrice(itemId, priceData) {
    this.loading = true;

    const myPriceData = [];
    for (let i = 0; i < priceData.length; i++) {
      if (priceData[i].price)
        myPriceData.push({ id: priceData[i].id, value: priceData[i].price });
    }
    this.openModalPrice = null;
    await axios
      .put(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/priceData/" +
          itemId,
        {
          headers: authHeader(),
          priceData: myPriceData,
        }
      )
      .catch((error) => {
        this.$notify({
          group: "notificationsTable",
          title: this.$t("i18n_component_table_price_error").toString(),
          type: "error",
          duration: 20000,
        });
        console.log("-----error-------");
        console.log(error);
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
      })
      .then((response) => {
        this.$notify({
          group: "notificationsTable",
          title: this.$t("i18n_component_table_price_success").toString(),
          duration: 20000,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  hidePopover(popoverId) {
    let popoverEl = null;
    if (Array.isArray(this.$refs[popoverId]))
      //in v-for
      popoverEl = this.$refs[popoverId][0];
    else popoverEl = this.$refs[popoverId];
    popoverEl.hidePopover();
  }

  totalQuantity(priceData) {
    let totalQuantity: any = 0;
    if (!priceData) return 0;
    for (let i = 0; i < priceData.length; i++) {
      totalQuantity = Number(totalQuantity) + Number(priceData[i].quantity);
    }
    return totalQuantity;
  }

  closePriceEdit(priceData) {
    this.updateFinalPrice(priceData);
  }

  updateFinalPrice(priceData) {
    this.final = this.finalPrice(priceData);
  }

  finalPrice(priceData) {
    let totalPrice: any = 0;
    if (!priceData) return 0;
    for (let i = 0; i < priceData.length; i++) {
      totalPrice =
        Number(totalPrice) +
        Number(
          this.calcIngredientPrice(priceData[i].quantity, priceData[i].price)
        );
    }
    return totalPrice;
  }

  async toggleDisplayPerc(id, item) {
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/sheet/" +
          id +
          "/displayPercentage",
        {
          headers: authHeader(),
          displayId: item.id,
          displayPerc: !item.displayPerc,
        }
      )
      .then((response) => {
        item.displayPerc = !item.displayPerc;
      })
      .catch((error) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_finished_displayperc_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }

  get DisplaySheet(): typeof DisplaySheet {
    return DisplaySheet;
  }

  goToSheetSubpage(page) {
    this.displaySheetForm = page;
    this.subNavbarSheetItem = page;
  }

  goToLabelSubpage(page) {
    this.displayLabelForm = page;
    this.subNavbarLabelItem = page;
  }

  pieChartLabels(item) {
    const arr = [];
    if (item.items) {
      for (let i = 0; i < item.items.length; i++) {
        arr.push(item.items[i].name);
      }
    }
    return arr;
  }

  pieChartQuantity(item) {
    const arr = [];
    if (item.items) {
      for (let i = 0; i < item.items.length; i++) {
        arr.push(item.items[i].quantity);
      }
    }
    return arr; //[10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10];
  }

  async getSheetData(item) {
    let ret = [];
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/sheet/" +
          item.id +
          "/sheetData",
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data) ret = response.data.sheetData;
      });
    return ret;
  }

  async getSheet(item) {
    this.getSheetData(item).then((res) => {
      item.sheetData = res;
      this.sheetLabels = [];
      this.sheetValues = [];
      for (let i = 0; i < item.sheetData.length; i++) {
        const sheetData = item.sheetData[i];
        this.sheetLabels.push(sheetData.name);
        this.sheetValues.push(sheetData.percentage);
      }
    });
  }

  viewSheetCommand(item) {
    this.openModalSheet = item;
    this.getSheet(this.openModalSheet);
  }

  async getLabelData(item) {
    const ret = { id: 0, kj: 0, kcal: 0, elements: null, qty: [], notes: [] };
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/labelData/" +
          item.id,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        const labelData = response.data.labelData;
        if (response.data) {
          ret["id"] = response.data.labelData["id"];
          ret["kj"] = response.data.labelData["kj"];
          ret["kcal"] = response.data.labelData["kcal"];
          ret["elements"] = response.data.labelData["elements"];
          ret["qty"] = response.data.labelData["qty"];
          ret["notes"] = response.data.labelData["notes"];
        }
      });
    return ret;
  }

  async getLabel(item) {
    this.getLabelData(item).then((res) => {
      item.labelData = res;
      this.sheetLabels = [];
      this.sheetValues;
      /*for (let i = 0; i < item.labelData.length; i++) {
        const sheetData = item.sheetData[i];
        this.sheetLabels.push(sheetData.name);
        this.sheetValues.push(sheetData.percentage);
      }*/
    });
  }

  choosenLabel(label) {
    if (label.length) {
      if ((this.$refs.labelViewer as PDFViewer).data == null) {
        this.labelData = {
          name: this.openModalLabel.name,
          id: this.openModalLabel.id,
          kj: this.openModalLabel.labelData["kj"],
          kcal: this.openModalLabel.labelData["kcal"],
          elements: this.openModalLabel.labelData["elements"],
          qty: this.openModalLabel.labelData["qty"],
          notes: this.openModalLabel.labelData["notes"],
          batch: this.batchNumber,
          template: null,
        };
      }
      Label.makeTemplate(label[0]);
      this.labelData.template = label[0];
      (this.$refs.labelViewer as PDFViewer).refresh();
    }
  }

  refreshLabel(batchNumber) {
    this.labelData["batch"] = batchNumber;
    (this.$refs.labelViewer as PDFViewer).refresh();
  }

  viewLabelCommand(item) {
    this.openModalLabel = item;
    this.getLabel(this.openModalLabel);
  }

  async getPriceData(item) {
    let ret = [];
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/priceData/" +
          item.id,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data) ret = response.data.priceData;
      });
    return ret;
  }

  async getPrice(item) {
    this.getPriceData(item).then((res) => {
      item.priceData = res;
      this.updateFinalPrice(item.priceData);
    });
  }

  viewPriceCommand(item) {
    this.openModalPrice = item;
    this.getPrice(this.openModalPrice);
  }

  async getLaunchData(item) {
    let ret = [];
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/launchData/" +
          item.id,
        {
          launchUnits: this.launch,
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data) ret = response.data.launchData;
      });
    return ret;
  }

  async getLaunch(item) {
    this.getLaunchData(item).then((res) => {
      item.launchData = res;
      //this.updateFinalPrice(item.priceData);
    });
  }

  viewLaunchCommand(item) {
    this.openModalLaunch = item;
    this.getLaunch(this.openModalLaunch);
  }

  @Watch("launch") change() {
    this.updateFinalLaunch();
  }

  updateFinalLaunch() {
    this.getLaunch(this.openModalLaunch);
  }

  async getLicensePackStatus(labelPack): Promise<any | null> {
    let ret: any | null = null;
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/licensing/" +
          this.currentUser().id +
          "/licenseStatus",
        {
          orderPurchaseDescription: [labelPack, "full-pack"],
          currentTeam: this.currentTeam(),
          headers: authHeader(),
        }
      )
      .then((response) => {
        ret = response.data.licenseStatus;
      })
      .catch((error) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_app_license_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
    return ret;
  }

  updatePieChart() {
    (this.$refs.pieChart as PieChart).drawChart();
  }

  updateBarChart() {
    (this.$refs.barChart as BarChart).drawChart();
  }

  async created() {
    Promise.all([
      this.getLicensePackStatus("sheet-pack"),
      this.getLicensePackStatus("label-pack"),
      this.getLicensePackStatus("price-pack"),
      this.getLicensePackStatus("media-pack"),
    ]).then((promises) => {
      if (promises[0] != null) this.licenseStatus["sheet-pack"] = promises[0];
      if (promises[1] != null) this.licenseStatus["label-pack"] = promises[1];
      if (promises[2] != null) this.licenseStatus["price-pack"] = promises[2];
      if (promises[3] != null) this.licenseStatus["media-pack"] = promises[3];
    });
  }
}
