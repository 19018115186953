export default class UserInfo {
  userId: string
  username: string
  email: string
  imageUrl: string
  constructor(userId, username, email, imageUrl) {
    this.userId = userId
    this.username = username
    this.email = email
    this.imageUrl = imageUrl
  }

  public isValid(): boolean {
    if (this.userId != "" && this.userId != null && this.username != "" && this.email != "" && this.imageUrl != "")
      return true;
    return false;
  }
}