
























import { Component, Prop, Vue } from "vue-property-decorator";
import Password from "vue-password-strength-meter";

@Component({
  components: {
    Password,
  },
})
export default class UserPassword extends Vue {
  @Prop({ default: false }) private password;
  private passwordScore = null;

  ShowScore(score) {
    this.passwordScore = score;
  }

  /*async created() {
  }*/
}
