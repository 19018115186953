

























































import axios from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";

@Component({
  components: {
    ButtonInput,
    Loader,
  },
})
export default class Modal extends Vue {
  @Prop({ default: true }) private tiny;
  @Prop({ default: false }) private isShown!: boolean;
  @Prop({ default: false }) private autoClose;
  @Prop({ default: "" }) private submitLabel;
  @Prop({ default: true }) private displayFooter;
  @Prop({ default: false }) private isDisabled;
  @Prop({ default: false }) private isLoading;

  closeModal() {
    this.$emit("close");
  }

  saveModal() {
    this.$emit("save");
    if (this.autoClose) this.$emit("close");
  }
}
