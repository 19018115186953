var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vc-date-picker',{attrs:{"popover":{
      positionFixed: true,
      placement: 'bottom-start',
      visibility: 'focus',
    },"min-date":_vm.minDate,"max-date":_vm.maxDate,"popover-positionFixed":true,"popover-visibility":_vm.visibility,"is-required":"","color":_vm.Helper.currentThemeColor(this.$store.state.auth.user['theme']),"is-dark":this.$store.state.auth.user['night_mode'] != 0,"locale":_vm.currentLanguage()},on:{"dayClick":_vm.dayClick,"input":_vm.dayClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var inputValue = ref.inputValue;
    var togglePopover = ref.togglePopover;
return [_c('text-input',{key:'invoiceDate',attrs:{"inputType":'date',"setValue":inputValue,"setDisabled":_vm.visibility != 'hidden',"startingValue":null,"isRequired":true,"icon":'fa fa-calendar',"label":_vm.label,"placeholder":_vm.placeholder,"requiredMessage":_vm.$t('i18n_page_payments_invoice_date_required'),"errors":_vm.errorIdentifier},on:{"focus-in":function($event){return togglePopover()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }