<template>
  <div>
    <div class="st_page-title st_text-align-center">404 NotFound</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {},
};
</script>

<style scoped></style>
