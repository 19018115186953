





















import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import LoadDefaultDataRecipes from "@/components/custom/LoadDefaultDataRecipes.vue";
import { Helper } from "@/utils/Helper";
import { authHeader } from "@/services/auth-header";

@Component({
  components: {
    Loader,
    LoadDefaultDataRecipes,
  },
})
export default class CheckForIngredientsUpdates extends Vue {
  private elementsCount = 0;
  private ingredientsCount = 0;
  private loading = true;
  private errorsFromServer: Array<string> = [];

  async getItemsCount(type): Promise<any | null> {
    let count = 0;
    console.log("GETITEMS" + type);
    if (Helper.currentTeam()) {
      await axios
        .post(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/data/" +
            Helper.currentTeam() +
            "/" +
            "ingredients/count",
          {
            type: type,
            search: "",
            headers: authHeader(),
          }
        )
        .then((response) => {
          if (response.data) {
            count = response.data.count;
          }
        })
        .catch((error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        });
    }
    return count;
  }

  addedDefaultItems() {
    //this.reloadPage();
  }

  async created() {
    Promise.all([
      this.getItemsCount("elemento"),
      this.getItemsCount("ingrediente"),
    ]).then((promises) => {
      if (promises[0] != null) this.elementsCount = promises[0];
      if (promises[1] != null) this.ingredientsCount = promises[1];
      this.loading = false;
    });
  }
}
