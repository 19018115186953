


























import ButtonInput from "@/components/inputs/ButtonInput.vue";
import { Prop, Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    ButtonInput,
  },
})
export default class ErrorMessage extends Vue {
  @Prop({ default: "" }) private errors;
  @Prop({ default: false }) private hidden;
  @Prop({ default: "" }) private message;
  @Prop({ default: 0 }) private margin;
  @Prop({ default: "error-message-absolute" }) private formMessageClass;

  Hide() {
    this.$emit("error-hide", null);
  }
}
