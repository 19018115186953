
































import { Prop, Vue, Component } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: { ButtonInput },
})
export default class HeaderBarShortcut extends Vue {
  private openNotification = false;
  private Helper: Helper = Helper;
  @Prop({ default: "xs" }) private size;
  @Prop({ default: true }) private autoHide;

  toggleNotification() {
    this.openNotification = !this.openNotification;
  }

  hide() {
    this.close();
  }

  close() {
    if (this.openNotification) this.$emit("hidden");
    this.openNotification = false;
  }
}
