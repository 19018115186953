


















































import axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import Link from "@/components/graphic/Link.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    ButtonInput,
    Loader,
    Link,
  },
})
export default class SelectLanguage extends Vue {
  private locales =
    (process.env.VUE_APP_I18N_SUPPORTED_LOCALE || "").split(",") || [];
  private loading = "";
  private Helper: Helper = Helper;

  @Prop({ default: false }) private tiny;
  @Prop({ default: false }) private fromLogin;

  get SupportedLanguages() {
    return this.locales;
  }

  changeLanguage(locale) {
    this.loading = locale;
    if (this.fromLogin) {
      localStorage.setItem("sweetlab_language_from_login", locale);
      localStorage.removeItem("sweetlab_language");
      this.$root.$i18n.locale = locale;
      this.loading = "";
    } else {
      axios
        .post(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/users/" +
            this.$store.state.auth.user.id +
            "/language/" +
            locale
        )
        .then((response) => {
          this.loading = "";
          const user = JSON.parse(localStorage.getItem("user") || "");
          user["language"] = locale;
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.removeItem("sweetlab_language");
          localStorage.setItem("sweetlab_language", locale);
          this.$store.state.auth.user.language = locale;
          this.$root.$i18n.locale = locale;
        })
        .catch((error) => {
          this.loading = "";
          this.$notify({
            group: "notifications",
            title: this.$t("i18n_component_language_update_error").toString(),
            text: error,
            type: "error",
            duration: 20000,
          });
        });
    }
  }

  isCurrentLanguage(lang) {
    return lang === this.currentLanguage();
  }

  currentLanguage() {
    if (
      this.fromLogin &&
      localStorage.getItem("sweetlab_language_from_login")
    ) {
      return localStorage.getItem("sweetlab_language_from_login");
    } else if (localStorage.getItem("sweetlab_language")) {
      return localStorage.getItem("sweetlab_language");
    } else {
      return this.$store.state.auth.user.language || "it";
    }
  }

  mounted() {
    this.$root.$i18n.locale = this.currentLanguage();
  }
}
