var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st_flex-column"},[_c('page-items',{attrs:{"type":'semilavorato',"title":_vm.$t('i18n_page_semifinished_title'),"singleTitle":_vm.$t('i18n_page_semifinished_single_title')},scopedSlots:_vm._u([{key:"edit-item",fn:function(slotProps){return [_c('text-input',{key:'itemName',attrs:{"inputType":'text',"startingValue":slotProps.scope.openModalModify.name,"requiredMessage":_vm.$t('i18n_input_required'),"icon":'fa fa-pencil-alt',"label":_vm.$t('i18n_page_items_name'),"placeholder":_vm.$t('i18n_page_items_modify_name'),"errors":slotProps.scope.errorsFromServer
            ? slotProps.scope.errorsFromServer['itemName']
            : null},model:{value:(slotProps.scope.openModalModify.name),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify, "name", $$v)},expression:"slotProps.scope.openModalModify.name"}}),_c('select-ingredients',{attrs:{"title":_vm.$t('i18n_page_items_modify_ingredients'),"placeholder":_vm.$t('i18n_page_items_choose_ingredients', {
            item: _vm.$t('i18n_component_ingredient_detail_type_ingrediente'),
          }),"item":slotProps.scope.openModalModify,"slotProps":slotProps,"types":['ingrediente', 'semilavorato-industriale', 'preparato-base'],"labels":[
          _vm.$t('i18n_page_elements_title'),
          _vm.$t('i18n_page_ingredients_title') ]}}),_c('div')]}},{key:"row-header",fn:function(){return [(_vm.currentUser().role == 'Admin')?[_c('div',{staticClass:"st_th st_th-50-max"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_num"))+" ")]),_c('div',{staticClass:"st_th st_th-50-max"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_id"))+" ")])]:_vm._e(),_c('div',{staticClass:"st_th st_th-200 st_th-sticky-50"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_name"))+" ")]),_c('div',{staticClass:"st_th st_th-150 st_flex-align-center"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_last_modified"))+" ")]),_c('div',{staticClass:"st_th st_th-150 st_flex-align-center"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_is_used"))+" ")])]},proxy:true},{key:"row-item",fn:function(slotProps){return [(_vm.currentUser().role == 'Admin')?[_c('div',{staticClass:"st_td st_td-50-max"},[_c('span',[_vm._v(_vm._s(slotProps.scope.index + 1))])]),_c('div',{staticClass:"st_td st_td-50-max"},[_c('span',[_vm._v(_vm._s(slotProps.scope.rowItem.id))])])]:_vm._e(),_c('div',{staticClass:"st_td st_td-200 st_td-sticky-50",class:slotProps.scope.sticky ? 'st_sticky-rx' : ''},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            placement: 'right',
            content: slotProps.scope.rowItem.name,
            defaultHideOnTargetClick: false,
            autoHide: false,
          }),expression:"{\n            placement: 'right',\n            content: slotProps.scope.rowItem.name,\n            defaultHideOnTargetClick: false,\n            autoHide: false,\n          }"}]},[_vm._v(_vm._s(slotProps.scope.rowItem.name))])]),_c('div',{staticClass:"st_td st_td-150 st_flex-align-center"},[_c('span',[_c('last-modified',{key:'last-modified-' + slotProps.scope.rowItem.id,attrs:{"item":slotProps.scope.rowItem.id,"teamId":_vm.currentTeam()}})],1)]),_c('div',{staticClass:"st_td st_td-150 st_flex-align-center"},[_c('span',[_c('is-used-in',{ref:"isUsed",attrs:{"itemName":slotProps.scope.rowItem.name,"itemId":slotProps.scope.rowItem.id,"teamId":_vm.currentTeam()},on:{"update:is-used":function($event){slotProps.scope.rowItem.isUsedIn = $event}}})],1)])]}},{key:"row-commands",fn:function(slotProps){return [_c('button-input',{attrs:{"tooltip":_vm.$t('i18n_btn_edit'),"icon":'fa fa-edit',"buttonClass":'cu_btn-circle cu_btn-theme-transparent-circle-bordered st_margin-left-10px'},on:{"btn-click":function($event){return slotProps.scope.modifyCommand(slotProps.scope.rowItem)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }