var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tab-panel',{attrs:{"size":'md'},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("i18n_component_latest_news_title")))]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"cu_news-container"},[(
          _vm.loading ||
          !_vm.latestNews ||
          (_vm.latestNews != null && _vm.latestNews.length == 0)
        )?[_c('div',{staticClass:"cu_news-no-data-image",style:(_vm.latestNews != null && _vm.latestNews.length == 0
              ? 'background-image: url(' + _vm.Helper.newsNoDataImage() + ')'
              : '')},[_c('div',{staticClass:"cu_news-no-data-title"},[(_vm.loading)?[_c('loader')]:[_c('span',[_vm._v(_vm._s(_vm.$t("i18n_component_latest_news_no_results")))])]],2)])]:[_c('div',{staticClass:"splide"},[_c('div',{staticClass:"splide__track"},[_c('ul',{staticClass:"splide__list"},[_vm._l((_vm.latestNews),function(item,index){return [_c('li',{key:'latest-adv-' + index,staticClass:"splide__slide"},[_c('div',{staticClass:"cu_news-image",style:('background-image: url(' +
                      _vm.Helper.newsImage(item['image']) +
                      ')')},[_c('div',{staticClass:"cu_news-title"},[_c('div',{staticClass:"cu_news-title-time",style:('color: ' + item['textColor'])},[_c('div',[_vm._v(_vm._s(item["startDate"]))])]),_c('div',{staticClass:"cu_news-title-text",style:('color: ' + item['textColor'])},[_c('div',{domProps:{"innerHTML":_vm._s(
                            item['title' + _vm.currentLanguage().toUpperCase()]
                          )}})])])]),_c('div',{staticClass:"cu_news-body"},[_c('div',{staticClass:"st_show-on-hover",domProps:{"innerHTML":_vm._s(item['body' + _vm.currentLanguage().toUpperCase()])}})])])]})],2)])])]],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }