












import { Component, Vue, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import IngredientInfo from "@/models/IngredientInfo";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
  },
})
export default class IngredientDetail extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "" }) private userClass;
  @Prop({ default: "" }) private ingredientId;
  @Prop({ default: "" }) private teamId;
  @Prop({ default: null }) private tooltip;
  private ingredientInfo = null;
  private loading = false;
  private error = false;

  async getIngredientInfo(ingredientId: any): Promise<any | null> {
    let infos: any | null = null;
    if (ingredientId == null) return null;
    const ret = [];
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.teamId +
          "/ingredientInfo",
        {
          headers: authHeader(),
          ingredientId: this.ingredientId,
        }
      )
      .then((response) => {
        infos = response.data.ingredientInfo;
        for (let i = 0; i < infos.length; i++) {
          const info = infos[i];
          ret.push(new IngredientInfo(info["id"], info["name"], info["type"]));
        }
      })
      .catch((error) => {
        this.error = true;
      })
      .finally(() => (this.loading = false));
    return ret;
  }

  async mounted() {
    this.ingredientInfo = await this.getIngredientInfo(this.ingredientId);
  }
}
