
import { Helper } from "@/utils/Helper";
import axios from "axios";
import { authHeader } from "@/services/auth-header";
import internal from "stream";

export default class Ingredient {
    teamId: string
    type: string
    id: string
    name: string
    description: string
    kj: any
    kcal: any
    allergen: any
    price: any
    image: any
    loading: boolean
    error: boolean
    discovered: boolean
    hidden: boolean
    items: any
    notes: any
    elements: any
    isUsedIn: any
    sheetData: any
    labelData: any
    priceData: any
    launchData: any
    constructor(teamId, type, id, discovered) {
        this.teamId = teamId
        this.type = type
        this.id = id
        this.loading = true
        this.error = false
        this.discovered = discovered
        this.name = ""
        this.description = ""
        this.kj = 0
        this.kcal = 0
        this.allergen = 1
        this.price = 0
        this.image = null;
        this.hidden = false
        this.items = null
        this.elements = null
        this.notes = []
        this.isUsedIn = null
        this.sheetData = null;
        this.labelData = null;
        this.priceData = null;
        this.launchData = null;

        if (this.id != "")
            axios
                .post(process.env.VUE_APP_HTTP_PROTOCOL +
                    "://" +
                    process.env.VUE_APP_API_ADDRESS +
                    "/api/data/" +
                    this.teamId +
                    "/ingredient/" +
                    this.id,
                    {
                        headers: authHeader(),
                    }
                )
                .then((response) => {
                    const data = response.data;
                    this.name = data.name;
                    this.description = data.description;
                    this.kj = data.kj;
                    this.kcal = data.kcal;
                    this.allergen = data.allergen;
                    this.price = data.price;
                    this.image = data.image;
                    this.notes = data.notes;
                })
                .catch((error) => {
                    this.error = true;
                })
                .finally(() => (this.loading = false));
        else {
            if (type != "elemento") {
                this.items = null;
                this.elements = null
                this.notes = [];
            }
            this.loading = false;
        }
    }

    qtyElements() {
        let qty = 0;
        if (this.elements) {
            for (let i = 0; i < this.elements.length; i++) {
                if (this.elements[i]["quantity"])
                    qty += Helper.toFloat(this.elements[i]["quantity"]);
            }
        }
        return qty;
    }

    noQty() {
        if (this.items && this.type != "semilavorato-industriale") {
            for (let i = 0; i < this.items.length; i++) {
                if (!this.items[i]["quantity"] || Helper.toFloat(this.items[i]["quantity"]) <= 0) {
                    return true;
                }
            }
        }
        if (this.elements) {
            for (let i = 0; i < this.elements.length; i++) {
                if (
                    !this.elements[i]["quantity"] || Helper.toFloat(this.elements[i]["quantity"]) <= 0
                ) { //(this.elements[i]["subIngredient"] &&(!this.elements[i]["subIngredient"]["quantity"] || parseFloat(this.elements[i]["subIngredient"]["quantity"].replace(',', '.')) <= 0))
                    return true;
                }
            }
        }
        return false;
    }

    attrs() {
        return {
            itemId: this.id,
            type: this.type,
            itemName: this.name,
            itemDescription: this.description,
            itemKj: this.kj,
            itemKcal: this.kcal,
            itemAllergen: this.allergen,
            itemPrice: this.price,
            itemType: this.type,
            itemIngredients: this.items,
            itemElements: this.elements,
            itemNotes: this.notes,
            headers: authHeader(),
        }
    }

    isValid() {
        if (this.noQty()) {
            return false;
        }
        if (this.qtyElements() != 100 && this.qtyElements() != 0)
            return false;
        return true;
    }
}