



































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { Helper } from "@/utils/Helper";
import Loader from "@/components/graphic/Loader.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import PasswordStrength from "@/components/inputs/PasswordStrength.vue";

@Component({
  components: {
    Loader,
    ButtonInput,
    TextInput,
    CheckboxInput,
    PasswordStrength,
  },
})
export default class UserPassword extends Vue {
  private value = "";
  private loading = false;
  @Prop({ default: false }) private startingValue;
  private isNameValidated = true;
  private errorsFromServer: Array<string> = [];
  private isPasswordValidated = true;
  private isNewPasswordValidated = true;
  private isConfirmPasswordValidated = true;
  private showPassword = false;
  private currentPassword = "";
  private newPassword = "";
  private newConfirmPassword = "";
  private newPasswordScore = null;

  get validPassword(): boolean {
    if (this.currentPassword && this.currentPassword.length) return true;
    return false;
  }

  get validNewPassword(): boolean {
    if (this.newPassword && this.newPassword.length) return true;
    return false;
  }

  get validConfirmPassword(): boolean {
    if (this.newPassword == this.newConfirmPassword) return true;
    return false;
  }

  showScore(score) {
    this.newPasswordScore = score;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  updatePassword() {
    this.errorsFromServer = [];
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/users/" +
          this.$store.state.auth.user.id +
          "/userPassword",
        {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          newConfirmPassword: this.newConfirmPassword,
        }
      )
      .then((response) => {
        this.loading = false;
        const user = JSON.parse(localStorage.getItem("user") || "");
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_userpassword_success").toString(),
          duration: 20000,
        });
        this.$emit(
          "update",
          this.currentPassword,
          this.newPassword,
          this.newConfirmPassword
        );
      })
      .catch((error) => {
        this.loading = false;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_userpassword_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }

  async created() {
    if (this.startingValue) {
      this.value = this.startingValue;
    }
  }
}
