




































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-this-alias */
import { Prop, Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { Helper } from "@/utils/Helper";
import ModalInput from "@/components/inputs/ModalInput.vue";
import Modal from "@/components/inputs/Modal.vue";
import Panel from "@/components/inputs/Panel.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import ButtonPayment from "@/components/inputs/ButtonPayment.vue";
import StepperBar from "@/components/graphic/StepperBar.vue";
import UserBilling from "@/components/custom/UserBilling.vue";
import Collapsible from "@/components/graphic/Collapsible.vue";
import TabPanel from "@/components/graphic/TabPanel.vue";
import Loader from "@/components/graphic/Loader.vue";
import BankInstructions from "@/components/custom/BankInstructions.vue";
import { authHeader } from "@/services/auth-header";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
declare const window: any;

@Component({
  components: {
    ModalInput,
    Modal,
    Panel,
    ButtonPayment,
    ButtonInput,
    Splide,
    SplideSlide,
    StepperBar,
    UserBilling,
    Collapsible,
    TabPanel,
    Loader,
    BankInstructions,
  },
})
export default class EnableFeature extends Vue {
  @Prop({ default: true }) private tabbed;
  @Prop({ default: "" }) private pack;
  @Prop({ default: "" }) private label;
  @Prop({ default: "" }) private icon;
  @Prop({ default: "modalLicense" }) private portal;

  private openModal = 0;
  private billingCompleted = false;
  private requireBilling = false;
  private doPayment = false;
  private payment = "";
  private paymentOrder = null;
  private paymentPrice = -1;
  private loading = false;
  private script: HTMLScriptElement = document.createElement("script");
  private Helper: Helper = Helper;

  get options() {
    return {
      type: "loop",
      padding: {
        right: "0rem",
        left: "0rem",
      },
      arrows: "slider",
    };
  }

  currentTeam() {
    return this.$store.state.auth.user.currentTeam || null;
  }

  ownedTeams() {
    const teamsIds = Object.keys(this.$store.state.auth.notifications["teams"]);
    const arrOwnedTeams = [];
    for (let i = 0; i < teamsIds.length; i++) {
      const keyTeam = teamsIds[i];

      const owned =
        this.$store.state.auth.notifications["teams"][keyTeam]["owned"];
      const currency =
        this.$store.state.auth.notifications["teams"][keyTeam]["currency"];

      if (owned) {
        arrOwnedTeams.push(keyTeam);
      }
    }
    return arrOwnedTeams;
  }

  inOwnedTeam() {
    return Helper.inArray(this.ownedTeams(), this.currentTeam().toString());
  }

  get currentComponent() {
    return this.tabbed ? "modal" : "panel";
  }

  get currentComponentTop() {
    return this.tabbed ? "portal" : "div";
  }

  get currentComponentItems() {
    return this.tabbed ? "splide" : "div";
  }

  get currentComponentItem() {
    return this.tabbed ? "splide-slide" : "div";
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  billingRequired(order, price) {
    this.requireBilling = true;
    this.paymentOrder = order;
    this.paymentPrice = price;
  }

  nextStepPayment() {
    this.requireBilling = false;
    this.proceedToPayment(this.paymentOrder, this.paymentPrice);
  }

  quitPayment() {
    this.requireBilling = false;
    this.doPayment = false;
    this.loading = false;
    this.paymentOrder = null;
    this.billingCompleted =
      Helper.progress(this.currentUser()["billing"])["count"] ==
      Helper.progress(this.currentUser()["billing"])["tot"];
    const paypalDom = window.document.getElementById("paypal-button-container");
    if (paypalDom)
      window.document.getElementById("paypal-button-container").innerHTML = "";
  }

  proceedToPayment(order, price) {
    this.paymentOrder = order;
    this.paymentPrice = price;
    this.doPayment = true;
    this.$forceUpdate();
  }

  quitEnableFeature() {
    this.openModal = 0;
    this.paymentOrder = null;
    this.doPayment = false;
  }

  hidePopover() {
    (this.$refs.popoverRefEnableFeature as any).hide();
  }

  openEnableFeature() {
    this.openModal = 1;
    this.hidePopover();
  }

  subTotalPrice() {
    let total = 0;
    for (
      let i = 0;
      i < Object.keys(this.$store.state.auth.shoppingCart).length;
      i++
    ) {
      const packName = Object.keys(this.$store.state.auth.shoppingCart)[i];
      const pack = this.$store.state.auth.shoppingCart[packName];
      total += pack["price"] * pack["qty"];
    }
    return total;
  }

  taxes(amount) {
    return Helper.taxes(amount);
  }

  totalPrice(subTotal) {
    const taxes = this.taxes(subTotal);
    return subTotal + taxes;
  }

  activatePaypal() {
    window.document.getElementById("paypal-button-container").innerHTML = "";
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          this.loading = true;
          return actions.order.create({
            purchase_units: [this.paymentOrder],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          const subTotal = this.paymentPrice; //order.purchase_units[0].amount.value;
          const subTotalStr = Number(this.paymentPrice).toFixed(2);
          const taxesStr = Number(this.taxes(subTotal)).toFixed(2).toString();
          const totalStr = Number(this.totalPrice(subTotal))
            .toFixed(2)
            .toString();
          axios
            .post(
              Vue.prototype.$apiHttpProtocol +
                "://" +
                Vue.prototype.$apiAddress +
                "/api/licensing/" +
                this.currentUser().id +
                "/savePayment",
              {
                orderId: order.id,
                orderCreationTime: order.create_time,
                orderPayerEmail: order.payer.email_address,
                orderPayerName: order.payer.name.given_name,
                orderPayerSurname: order.payer.name.surname,
                orderPayerId: order.payer.payer_id,
                orderPurchaseDescription:
                  '[{"pack": "' +
                  order.purchase_units[0].description +
                  '", "qty": 1, "price": ' +
                  subTotal +
                  "}]",
                orderPurchaseTaxes: taxesStr,
                orderPurchaseSubTotal: subTotalStr,
                orderPurchaseTotal: totalStr,
                orderPurchaseAmount: order.purchase_units[0].amount.value,
                orderPurchaseCurrency:
                  order.purchase_units[0].amount.currency_code,
                orderPayeeEmail: order.purchase_units[0].payee.email_address,
                orderPayeeMerchantId: order.purchase_units[0].payee.merchant_id,
                orderStatus: order.status,
                headers: authHeader(),
              }
            )
            .then((response) => {
              window.document.getElementById(
                "paypal-button-container"
              ).innerHTML = "";
              this.paymentOrder = null;
              this.paymentPrice = -1;
              this.doPayment = false;
              this.$notify({
                group: "notifications",
                title: this.$t("i18n_app_payment_pay_success").toString(),
                duration: 20000,
              });
            })
            .catch((error) => {
              this.$notify({
                group: "notifications",
                title: this.$t("i18n_app_payment_pay_error").toString(),
                text: error,
                type: "error",
                duration: 20000,
              });
              //this.errorsFromServer = error.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
        },
        onCancel: (data) => {
          this.loading = false;
        },
        onError: (err) => {
          this.$notify({
            group: "notifications",
            title: this.$t("i18n_app_payment_license_status_error").toString(),
            text: err,
            type: "error",
            duration: 20000,
          });
        },
      })
      .render("#paypal-button-container");
  }

  mounted() {
    this.billingCompleted =
      Helper.progress(this.currentUser()["billing"])["count"] ==
      Helper.progress(this.currentUser()["billing"])["tot"];
    const ClientID =
      "Afdisr7iQ4AkVJjgae4oXi2ey5COvp04FFfIUaI9jEaUBV6OarGM-yZw6OItoxrBrUd8awQvJVN5BJsd";
    this.script.src = `https://www.paypal.com/sdk/js?currency=EUR&client-id=${ClientID}`;
    document.body.appendChild(this.script);
    if (!this.tabbed) this.openModal = 1;
  }
}
