






















































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import { dragscroll } from "vue-dragscroll";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import MinusPlusCheckboxInput from "@/components/inputs/MinusPlusCheckboxInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import ToggleCommandsPanel from "@/components/custom/ToggleCommandsPanel.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import Ingredient from "@/models/Ingredient";
import Payment from "@/models/Payment";
import User from "@/models/UserClient";
import Label from "@/models/Label";
import News from "@/models/NewsClient";
import Modal from "@/components/inputs/Modal.vue";
import { Helper } from "@/utils/Helper";
import { EventBus } from "@/models/EventBus";

@Component({
  components: {
    TextInput,
    ButtonInput,
    SearchInput,
    CheckboxInput,
    MinusPlusCheckboxInput,
    Loader,
    Modal,
    ToggleCommandsPanel,
    SelectInput,
  },
  directives: {
    dragscroll,
  },
})
export default class PageItems extends Vue {
  @Prop({ default: "table" }) pageStyle;
  @Prop({ default: "elemento" }) private type;
  @Prop({ default: "" }) private title;
  @Prop({ default: "" }) private singleTitle;
  @Prop({ default: "ingredient" }) private itemType;
  @Prop({ default: "data" }) private preUrl;
  @Prop({ default: false }) private skipIsUsedCheck;
  @Prop({ default: true }) teamPage;
  @Prop({ default: true }) canAddItem;
  @Prop({ default: null }) itemId;
  @Prop({ default: true }) canCheck;
  private Helper: Helper = Helper;
  private loading = false;
  private noMoreData = false;
  private pageQty = 10;
  private openModalDelete = null;
  private openModalModify = null;
  private itemsCount = -1;
  private searchIds = [];
  private toggleIds = [];
  private toggleIdsUndo = [];
  private page = 0;
  private onFocus = 0;
  private tiny = false;
  private sticky = false;
  private search = "";
  private messageFromServer = "";
  private errorsFromServer: Array<string> = [];
  private user = this.$store.state.auth.user;
  private timer = null;
  private loadItems = 0;
  private deletedItems = [];
  private isMinus = false;
  private registry = null;

  @Watch("loadItems") change() {
    if (!this.loading && !this.noMoreData) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getItems();
      }, 10);
    }
  }

  get rootUrl() {
    return this.preUrl.length ? this.preUrl + "/" : "";
  }

  currentTeam() {
    return this.user.currentTeam || null;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  get showCommands() {
    return this.toggleIds.length > 0;
  }

  get selectedItems() {
    return this.toggleIds.length;
  }

  searchToggle(id) {
    const toggleIndex = this.toggleIds.indexOf(id);
    return toggleIndex >= 0;
  }

  checkAll() {
    this.filteredItems.forEach((item) => {
      if (
        (item.isUsedIn && item.isUsedIn.length == 0) ||
        this.skipIsUsedCheck
      ) {
        this.toggleIds.push(item.id);
      }
    });
  }

  uncheckAll() {
    this.toggleIds = [];
  }

  uncheckAllFromPanel() {
    this.isMinus = false;
    this.uncheckAll();
  }

  toggleAllCommand() {
    if (this.isMinus) this.uncheckAll();
    else this.checkAll();
  }

  toggleItemCommand(id, value) {
    const toggleIndex = this.toggleIds.indexOf(id);
    if (toggleIndex == -1 && value) this.toggleIds.push(id);
    else if (toggleIndex >= 0 && !value) this.toggleIds.splice(toggleIndex, 1);
    if (this.selectedItems) this.isMinus = true;
    else this.isMinus = false;
  }

  handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
    const scroller = this.$el.querySelector("#dataScroller");
    if (scroller.scrollLeft > 0) this.sticky = true;
    else this.sticky = false;
    if (scroller.scrollTop > 300) this.tiny = true;
    else this.tiny = false;
    if (scrollTop + clientHeight >= scrollHeight) {
      this.loadItems += 1;
    }
  }

  isOverflowingY() {
    const element = this.$refs.dataScroller;
    if (element)
      return (
        (element as HTMLElement).offsetHeight <
        (element as HTMLElement).scrollHeight
      );
    else return false;
  }

  newItem() {
    if (this.currentTeam()) {
      this.errorsFromServer = [];
      this.loading = true;
      axios
        .post(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/" +
            this.rootUrl +
            (this.teamPage ? this.currentTeam() + "/" : "") +
            this.itemType,
          this.openModalModify.attrs()
        )
        .then((response) => {
          this.$notify({
            group: "notificationsTable",
            title: this.$t("i18n_component_table_new_success").toString(),
            duration: 20000,
          });
          this.openModalModify.id = response.data.itemId;

          this.closeModalModify();
          this.refreshItems();
        })
        .catch((error) => {
          this.$notify({
            group: "notificationsTable",
            title: this.$t("i18n_component_table_new_error").toString(),
            type: "error",
            duration: 20000,
          });
          console.log("-----error-------");
          console.log(error);
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  get subIngredients() {
    if (this.openModalModify) return this.openModalModify.items;
    return [];
  }

  modifyItem() {
    if (this.currentTeam()) {
      this.errorsFromServer = [];
      this.loading = true;
      axios
        .put(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/" +
            this.rootUrl +
            (this.teamPage ? this.currentTeam() + "/" : "") +
            this.itemType,
          this.openModalModify.attrs()
        )
        .then(() => {
          this.$notify({
            group: "notificationsTable",
            title: this.$t("i18n_component_table_modify_success").toString(),
            duration: 20000,
          });
          this.openModalModify.hidden = true;
          this.closeModalModify();
          this.refreshItems();
        })
        .catch((error) => {
          this.$notify({
            group: "notificationsTable",
            title: this.$t("i18n_component_table_modify_error").toString(),
            type: "error",
            duration: 20000,
          });
          console.log("-----error-------");
          console.log(error);
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  undoModifyItem() {
    this.closeModalModify();
    this.$store.commit("UNDO_ITEM");
  }

  undoNewItem() {
    this.openModalModify = null;
  }

  async getSubElements(item): Promise<any | null> {
    let ret: any | null = null;
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/" +
          this.rootUrl +
          (this.teamPage ? this.currentTeam() + "/" : "") +
          "subElements/" +
          item.id,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        ret = response.data.subElements;
      });
    return ret;
  }

  async getSubIngredients(item): Promise<any | null> {
    let ret: any | null = null;
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/" +
          this.rootUrl +
          (this.teamPage ? this.currentTeam() + "/" : "") +
          "subIngredients/" +
          item.id,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        ret = response.data.subIngredients;
      });
    return ret;
  }

  async modifyCommand(item) {
    this.openModalModify = item;
    if (
      this.itemType != "user" &&
      this.itemType != "advertisements/new" &&
      this.itemType != "label"
    ) {
      Promise.all([
        this.getSubIngredients(item),
        this.getSubElements(item),
      ]).then((promises) => {
        const items = promises[0];
        const elements = promises[1];
        item.items = items;
        item.elements = elements;
        this.$store.commit("BACKUP_ITEM", item);
        this.$emit("modify-item", item);
      });
    } else {
      this.$store.commit("BACKUP_ITEM", item);
      this.$emit("modify-item", item);
    }
  }

  newCommand() {
    if (this.itemType == "ingredient")
      this.openModalModify = new Ingredient(
        this.currentTeam(),
        this.type,
        "",
        false
      );
    else if (this.itemType == "advertisements/new")
      this.openModalModify = new News("", false);
    else this.openModalModify = new Label(this.currentTeam(), "", false);
    this.openModalModify.items = [];
    this.openModalModify.elements = [];
    this.$emit("new-item", this.openModalModify, this.errorsFromServer);
  }

  deleteItems() {
    if (this.currentTeam()) {
      this.loading = true;
      axios
        .delete(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/" +
            this.rootUrl +
            (this.teamPage ? this.currentTeam() + "/" : "") +
            this.itemType +
            "s",
          {
            data: {
              items: this.toggleIds,
            },
            headers: authHeader(),
          }
        )
        .then(() => {
          this.itemsCount = -1;
          this.toggledItems.forEach(async (toggledItem) => {
            Helper.removeObject(this.$store.state.items, toggledItem.id);
          });
          this.getItemsCount();
          this.toggleIdsUndo = this.toggleIds;
          this.$notify({
            group: "notificationsTable",
            clean: true,
          });
          this.$notify({
            group: "notificationsTable",
            title: this.$t("i18n_component_table_delete_success").toString(),
            duration: 20000,
          });
          this.closeModalDelete();
          this.refreshItems();
        })
        .catch((error) => {
          console.log("-----error-------");
          console.log(error);
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  undoDeleteItems() {
    if (this.currentTeam()) {
      this.loading = true;
      axios
        .patch(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/" +
            this.rootUrl +
            (this.teamPage ? this.currentTeam() + "/" : "") +
            this.itemType +
            "s",
          {
            items: this.toggleIdsUndo,
            headers: authHeader(),
          }
        )
        .then(() => {
          this.toggleIdsUndo = [];
          this.$notify({
            group: "notificationsTable",
            title: this.$t("i18n_component_table_undo_success").toString(),
            duration: 20000,
          });
          this.reloadPage();
        })
        .catch((error) => {
          console.log("-----error-------");
          console.log(error);
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  deleteCommand() {
    this.openModalDelete = this.toggleIds;
  }

  closeModalDelete() {
    this.openModalDelete = null;
    this.toggleIds = [];
  }

  closeModalModify() {
    this.openModalModify.items = null;
    this.openModalModify.elements = null;
    this.openModalModify = null;
  }

  reloadPage() {
    this.$store.commit("DEL_ALL_ITEMS");
    this.refreshItems();
  }

  refreshItems() {
    this.loading = false;
    this.page = 1;
    this.itemsCount = -1;
    this.noMoreData = false;
    this.toggleIds = [];
    this.isMinus = false;
    this.openModalDelete = null;
    this.getItemsCount();
    this.loadItems += 1;
  }

  async getItemsCount() {
    if (this.currentTeam()) {
      axios
        .post(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/" +
            this.rootUrl +
            (this.teamPage ? this.currentTeam() + "/" : "") +
            this.itemType +
            "s/count" +
            (this.itemId ? "/" + this.itemId : ""),
          {
            type: this.type,
            search: this.search,
            headers: authHeader(),
          }
        )
        .then((response) => {
          if (response.data) {
            this.itemsCount = response.data.count;
          }
        })
        .catch((error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        });
    }
  }

  async getItems() {
    if (this.currentTeam()) {
      this.loading = true;
      let numUpdates = 0;
      axios
        .post(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/" +
            this.rootUrl +
            (this.teamPage ? this.currentTeam() + "/" : "") +
            this.itemType +
            "s/ids" +
            (this.itemId ? "/" + this.itemId : ""),
          {
            type: this.type,
            search: this.search,
            pageNum: this.page,
            pageQty: this.pageQty,
            headers: authHeader(),
          }
        )
        .then((response) => {
          if (response.data.length) {
            response.data.forEach(async (item, index) => {
              let addPosition = -1;
              let newPosition = 0;
              let newIngredient;
              let addIngredient = false,
                delIngredient = false,
                moveIngredient = false;
              if (this.search != "") {
                this.searchIds.push(item.id);
              }
              const valObj = Helper.searchObjectById(
                this.$store.state.items,
                item.id
              );

              const indexPosition = index + 10 * (this.page - 1);
              if (indexPosition != this.visibleItemsCount) {
                addPosition = indexPosition;
              }

              if (!valObj.length) {
                addIngredient = true;

                if (this.itemType == "ingredient")
                  newIngredient = new Ingredient(
                    this.currentTeam(),
                    this.type,
                    item.id,
                    this.search == "" ? true : false
                  );
                else if (this.itemType == "payment")
                  newIngredient = new Payment(
                    item.id,
                    this.search == "" ? true : false
                  );
                else if (this.itemType == "user")
                  newIngredient = new User(
                    item.id,
                    this.search == "" ? true : false
                  );
                else if (this.itemType == "advertisements/new")
                  newIngredient = new News(
                    item.id,
                    this.search == "" ? true : false
                  );
                else
                  newIngredient = new Label(
                    this.currentTeam(),
                    item.id,
                    this.search == "" ? true : false
                  );
              } else {
                newIngredient = valObj[0];

                if (newIngredient.discovered == false && this.search == "") {
                  newIngredient.discovered = true;
                  delIngredient = true;
                  addIngredient = true;
                }

                if (newIngredient.hidden == true) {
                  const oldPosition = Helper.positionObjectById(
                    this.$store.state.items,
                    newIngredient.id
                  );

                  newPosition = index + 10 * (this.page - 1);

                  if (oldPosition != newPosition) {
                    moveIngredient = true;
                  }
                  newIngredient.hidden = false;
                }
              }

              if (addIngredient || delIngredient || moveIngredient) {
                numUpdates += 1;
              }
              if (delIngredient) this.$store.commit("DEL_ITEM", newIngredient);
              if (addIngredient) {
                this.$store.commit("ADD_ITEM", [newIngredient, addPosition]);
                if (this.isMinus) {
                  this.toggleIds.push(newIngredient.id);
                }
              }
              if (moveIngredient) {
                this.$store.commit("MOVE_ITEM", [newIngredient, newPosition]);
              }
            });
            this.page = this.page + 1;
            if (response.data.length < this.pageQty) this.noMoreData = true;
          } else this.noMoreData = true;
        })
        .catch((error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
          this.noMoreData = true;
          this.$notify({
            group: "notifications",
            title: this.$t("i18n_component_table_loading_error").toString(),
            type: "error",
            duration: 20000,
          });
        })
        .finally(() => {
          this.loading = false;
          if (
            (numUpdates == 0 && this.noMoreData == false) ||
            !this.isOverflowingY()
          ) {
            this.loadItems += 1;
          }
        });
    }
  }

  doSearch(value) {
    this.searchIds = [];
    this.search = value;
    this.refreshItems();
  }

  get allItems() {
    return this.$store.state.items;
  }

  get filteredItems() {
    return this.$store.state.items.filter((item) => {
      return (
        item.hidden == false &&
        ((this.search == "" && item.discovered == true) ||
          this.searchIds.includes(item.id))
      );
    });
  }

  get hasItems() {
    if (this.itemsCount == 0 && this.search == "") return false;
    return true;
  }

  get toggledItems() {
    return this.$store.state.items.filter((item) => {
      return this.toggleIds.includes(item.id);
    });
  }

  get visibleItemsCount() {
    return this.filteredItems.length;
  }

  mounted() {
    this.page =
      this.$store.state.items.length > 0
        ? this.$store.state.items.length / this.pageQty + 1
        : 1;

    this.$store.commit("DEL_ALL_ITEMS");
    this.$store.state.itemsPage = this.type;
    this.reloadPage();
    /*if (this.$store.state.itemsPage != this.type) {
      this.$store.state.itemsPage = this.type;
      //this.reloadPage();
    } else*/
    if (this.page == 1) this.loadItems += 1;

    this.registry = EventBus.getInstance().register(
      "refresh-page",
      (notification: any) => {
        if (this.itemType == notification.data.type) {
          console.log(
            "Received message from Ably: teamId: " +
              notification.data.sectionId +
              " userId:" +
              notification.data.senderId +
              " ids:" +
              notification.data.id +
              " action:" +
              notification.data.action +
              " type:" +
              notification.data.type +
              "/ pageType " +
              this.itemType
          );

          let refreshPage = false;

          if (notification.data.action == "mod") {
            const itemsToModify = Helper.searchObjectByIds(
              this.$store.state.items,
              notification.data.id
            );

            if (itemsToModify.length) refreshPage = true;

            itemsToModify.forEach((itemToModify) => {
              this.$store.commit("DEL_ITEM", itemToModify);
            });
          } else if (notification.data.action == "del") {
            const itemsToDelete = Helper.searchObjectByIds(
              this.$store.state.items,
              notification.data.id
            );

            if (itemsToDelete.length) refreshPage = true;

            itemsToDelete.forEach((itemToDelete) => {
              this.$store.commit("DEL_ITEM", itemToDelete);
            });
          } //New or UNDO DEL...
          if (
            refreshPage ||
            notification.data.action == "new" ||
            notification.data.action == "undo_del"
          )
            this.refreshItems();
        }
      }
    );
  }

  updated() {
    this.$nextTick(function () {
      if (!this.noMoreData && !this.loading) {
        if (!this.isOverflowingY()) this.loadItems += 1;
      }
    });
  }

  async destroyed() {
    this.registry.unregister();
  }
}
