import axios from "axios";
import { Helper } from "@/utils/Helper";
import { authHeader } from "@/services/auth-header";

export default class News {
    id: string
    titleIT: string
    titleEN: string
    bodyIT: string
    bodyEN: string
    image: string
    textColor: string
    startDate: string
    endDate: string
    loading: boolean
    error: boolean
    discovered: boolean
    hidden: boolean
    isOnline: boolean
    isEnabled: boolean
    isActive: boolean

    constructor(id: string, discovered: boolean) {
        this.id = id;
        this.discovered = discovered;
        this.loading = true;
        this.hidden = false
        this.isOnline = false
        this.isEnabled = true;
        this.titleIT = ""
        this.titleEN = ""
        this.bodyIT = ""
        this.bodyEN = ""
        this.image = ""
        this.textColor = "#53535f"
        this.startDate = Helper.calcDateStr(new Date(), 'yyyy-MM-dd')
        const dateNow = new Date()
        dateNow.setDate(dateNow.getDate() + 365);
        this.endDate = Helper.calcDateStr(dateNow, 'yyyy-MM-dd')
        this.isActive = false;

        if (this.id != "")
            axios
                .post(process.env.VUE_APP_HTTP_PROTOCOL +
                    "://" +
                    process.env.VUE_APP_API_ADDRESS +
                    "/api/" +
                    "advertisements/news/" +
                    this.id,
                    {
                        headers: authHeader(),
                    }
                )
                .then((response) => {
                    const data = response.data;
                    this.titleIT = data.title_it;
                    this.titleEN = data.title_en;
                    this.bodyIT = data.body_it;
                    this.bodyEN = data.body_en;
                    this.image = data.image;
                    this.textColor = data.text_color;
                    this.startDate = data.start_date;
                    this.endDate = data.end_date;
                    this.isActive = data.is_active ? true : false;
                })
                .catch((error) => {
                    this.error = true;
                })
                .finally(() => (this.loading = false));
        this.loading = false;
    }

    attrs() {
        return {
            itemId: this.id,
            itemTitleIT: this.titleIT,
            itemBodyIT: this.bodyIT,
            itemTitleEN: this.titleEN,
            itemBodyEN: this.bodyEN,
            itemImage: this.image,
            itemTextColor: this.textColor,
            itemStartDate: this.startDate,
            itemEndDate: this.endDate,
            headers: authHeader(),
        }
    }

    isValid() {
        return true;
    }
}