





























































































































































































































































































































































/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-this-alias */
import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import { Helper } from "@/utils/Helper";
import Loader from "@/components/graphic/Loader.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import UserBilling from "@/components/custom/UserBilling.vue";
import Link from "@/components/graphic/Link.vue";
import { authHeader } from "@/services/auth-header";
declare const window: any;

@Component({
  components: {
    Loader,
    ButtonInput,
    PopoverInput,
    UserBilling,
    Link,
  },
})
export default class ButtonPayment extends Vue {
  private Helper: Helper = Helper;
  private goToPayment = false;
  private loadedPayments = false;
  private currencyType = "EUR";
  private errorsFromServer: Array<string> = [];
  private loading = false;
  private enabledPayments = true;

  private years = 1;
  private licenseStatus = -1;
  private licenseDetail = [];
  private price = -1;
  @Prop({ default: false }) private tiny;
  @Prop({ default: "" }) private icon;
  @Prop({ default: "" }) private licenseTitle;
  @Prop({ default: "" }) private licenseType;
  @Prop({ default: "" }) private licenseInfo;
  @Prop({ default: true }) private displayButtons;

  subTotal() {
    return this.price;
  }

  taxes() {
    return Helper.taxes(this.subTotal());
  }

  total() {
    return this.subTotal() + this.taxes();
  }

  addToCart() {
    this.$store.commit("ADD_TO_CART", [this.licenseType, 1, this.price]);
    //this.$forceUpdate();
  }

  numItemsInCart() {
    if (this.$store.state.auth.shoppingCart[this.licenseType])
      return this.$store.state.auth.shoppingCart[this.licenseType]["qty"];
    return 0;
  }

  nextStepToPayment() {
    if (
      Helper.progress(this.currentUser()["billing"])["count"] !=
      Helper.progress(this.currentUser()["billing"])["tot"]
    )
      this.$emit("billing-required", this.order, this.price);
    else this.$emit("proceed-to-payment", this.order, this.price);
  }

  gotoPaymentDetail() {
    this.goToPayment = true;
    this.loading = true;
  }

  gotoPaymentMaster() {
    this.goToPayment = false;
    this.loadedPayments = false;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  get order() {
    return {
      description: this.licenseType,
      amount: {
        currency_code: this.currencyType,
        value: this.total(),
      },
    };
  }

  setLoaded() {
    /*
    if (this.loading) {
      this.loading = false;
      if (!this.loadedPayments && window.paypal) {
        const self = this;
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [this.order],
              });
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.capture();
              axios
                .post(
                  Vue.prototype.$apiHttpProtocol + "://" +
          Vue.prototype.$apiAddress +
          "/api/licensing/" +
                  this.currentUser().id +
                  "/savePayment",
                  {
                    orderId: order.id,
                    orderCreationTime: order.create_time,
                    orderPayerEmail: order.payer.email_address,
                    orderPayerName: order.payer.name.given_name,
                    orderPayerSurname: order.payer.name.surname,
                    orderPayerId: order.payer.payer_id,
                    orderPurchaseDescription: "[{\"pack\": \"" + order.purchase_units[0].description + "\", \"qty\": 1, \"price\": " + order.purchase_units[0].amount.value + "}]",
                    orderPurchaseAmount: order.purchase_units[0].amount.value,
                    orderPurchaseCurrency:
                      order.purchase_units[0].amount.currency_code,
                    orderPayeeEmail: order.purchase_units[0].payee.email_address,
                    orderPayeeMerchantId:
                      order.purchase_units[0].payee.merchant_id,
                    orderStatus: order.status,
                    headers: authHeader(),
                  }
                )
                .then((response) => {
                  if (this.licenseStatus == 3)
                    this.licenseStatus = 4;
                  else if (this.licenseStatus == 5)
                    this.licenseStatus = 6;
                  else
                    this.licenseStatus = 1;
                })
                .catch((error) => {
                  this.$notify({
                    group: 'notifications',
                    title: this.$t("i18n_app_payment_pay_error").toString(),
                    text: error,
                    type: 'error',
                    duration: 20000
                  });
                  if (!Helper.isUndefined(error.response.data.errors))
                    this.errorsFromServer = error.response.data.errors;
                });
            },
            onError: (err) => {
              this.$notify({
                group: 'notifications',
                title: this.$t("i18n_app_payment_license_status_error").toString(),
                text: err,
                type: 'error',
                duration: 20000
              });
            },
          })
          .render("#paypal-button-container-" + this.licenseType).then(function () {
            self.loadedPayments = true;
          });
        //.render(this.$refs.paypal);
      }
    }*/
  }

  mounted() {
    axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/config/enabledPayments",
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data == "0") this.enabledPayments = false;
      });
    axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/licensing/features/" +
          this.licenseType,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        this.price = response.data.features[0]["price"];
      });

    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/licensing/" +
          this.currentUser().id +
          "/licenseStatus",
        {
          orderPurchaseDescription: [this.licenseType],
          currentTeam: null,
          headers: authHeader(),
        }
      )
      .then((response) => {
        this.licenseStatus = response.data.licenseStatus;
        this.licenseDetail = response.data.licenseDetail;
      })
      .catch((error) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_app_license_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
      });
  }
  updated() {
    if (this.goToPayment) {
      this.setLoaded();
      this.loading = false;
    }
  }

  async created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "ADD_TO_CART" || mutation.type == "DEL_FROM_CART") {
        this.$forceUpdate();
      }
    });
  }
}
