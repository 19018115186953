export default class InviteMember {
  id: string
  email: string
  role: string
  createdAt: string
  constructor(id, email, role, createdAt) {
    this.id = id
    this.email = email
    this.role = role
    this.createdAt = createdAt
  }
}