
































































import axios from "axios";
import Verify from "../models/Verify";
import { Component, Vue } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import ResourceExpired from "@/components/graphic/ResourceExpired.vue";
import LoginPanel from "@/components/graphic/LoginPanel.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    ButtonInput,
    Loader,
    ResourceExpired,
    LoginPanel,
  },
})
export default class PageVerify extends Vue {
  // data()

  private verify = new Verify(
    this.$route.params.id,
    this.$route.params.hash,
    this.$route.query.expires,
    this.$route.query.signature
  );
  private loading = true;
  private expired = false;
  private verified = false;
  private messageFromServer = "";
  private errorsFromServer: Array<string> = [];
  private user = this.$store.state.auth.user; //localStorage.getItem('user');//this.$store.state.auth.user;

  // methods

  verifyEmail() {
    this.errorsFromServer = [];
    this.loading = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/user/verifyUsers/" +
          this.verify.id +
          "/" +
          this.verify.hash +
          "?expires=" +
          this.verify.expires +
          "&signature=" +
          this.verify.signature
      )
      .then((response) => {
        if (response.data) {
          this.loading = false;
        }
      });
  }

  mounted() {
    this.loading = true;
    this.$store.dispatch("auth/verify", this.verify).then(
      () => {
        this.loading = false;
        this.verified = true;
      },
      (error) => {
        this.loading = false;
        this.messageFromServer = error.response.data.message;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors || [];
      }
    );
    /*axios.post(Vue.prototype.$apiHttpProtocol + "://" +
          Vue.prototype.$apiAddress +
          "/api/user/verifyUser/" + this.verifyId + "/" + this.verifyHash + "?expires=" + this.expires + "&signature=" + this.signature).then((response) => {
        if (response.data) {
          this.loading = false
        }
      },
              (error) => {
          this.loading = false
          if (error.response.status === 403) {
              this.expired = true
          }
        }
      );*/
  }
}
