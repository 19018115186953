







































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";

@Component({
  components: {
    Loader,
  },
})
export default class ButtonInput extends Vue {
  private value = false;
  @Prop({ default: true }) private stop;
  @Prop({ default: false }) private startingValue;
  @Prop({ default: false }) private loading!: string;
  @Prop({ default: "cu_btn-size-md cu_btn-theme-normal" })
  private buttonClass!: string;
  @Prop() private label!: string;
  @Prop() private icon!: string;
  @Prop() private stackedIcon!: string;
  @Prop({ default: "" }) private tooltip!: string;
  @Prop() private isDisabled!: boolean;

  @Watch("value") change() {
    if (!this.isDisabled) {
      this.$emit("input", this.value);
    }
  }

  get isLabeledClass(): string {
    const labelClass = "labeled";
    return this.label ? labelClass : "";
  }

  async created() {
    if (this.startingValue) {
      this.value = this.startingValue;
    }
  }

  private btnClicked() {
    if (!this.isDisabled) {
      this.$emit("btn-click", null);
    }
  }
}
