











































import axios from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";

@Component({
  components: {
    ButtonInput,
    CheckboxInput,
  },
})
export default class ToggleCommandsPanel extends Vue {
  @Prop({ default: 0 }) private toggled: number;
  DismissCommands() {
    this.$emit("dismiss");
  }
  deleteCommand() {
    this.$emit("delete");
  }
}
