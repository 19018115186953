

































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PageItems from "@/components/PageItems.vue";
import ColorPicker from "@/components/graphic/ColorPicker.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import NewsDropzone from "@/components/custom/NewsDropzone.vue";
import IsUsedIn from "@/components/custom/IsUsedIn.vue";
import Loader from "@/components/graphic/Loader.vue";
import Modal from "@/components/inputs/Modal.vue";
import PDFViewer from "@/components/graphic/PDFViewer.vue";
import Pin from "@/components/graphic/Pin.vue";
import axios from "axios";
import { authHeader } from "@/services/auth-header";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    PageItems,
    ColorPicker,
    TextInput,
    DatePickerInput,
    ButtonInput,
    UserDetail,
    NewsDropzone,
    IsUsedIn,
    Loader,
    Modal,
    PDFViewer,
    Pin,
  },
})
export default class PageNews extends Vue {
  private user = this.$store.state.auth.user;
  private errorsFromServer: Array<string> = [];
  private Helper: Helper = Helper;
  currentTeam() {
    return this.user.currentTeam || null;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  upColor($event, item, prop) {
    item[prop] = $event;
    //this.upTemplate($event, prop);
  }

  updateImage(item, avatar) {
    item["image"] = avatar;
  }

  startDateClick(item, day) {
    item["startDate"] = day;
  }

  endDateClick(item, day) {
    item["endDate"] = day;
  }
}
