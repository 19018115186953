




























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import Modal from "@/components/inputs/Modal.vue";
import Dropzone from "@/components/custom/Dropzone.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    TextInput,
    ButtonInput,
    Loader,
    Modal,
    Dropzone,
  },
})
export default class ColorPicker extends Vue {
  @Prop({ default: "" }) private title;
  @Prop({ default: "" }) private labelColor1;
  @Prop({ default: "" }) private startingColor1;
  @Prop({ default: "" }) private labelColor2;
  @Prop({ default: null }) private startingColor2;
  @Prop({ default: "" }) private labelColor3;
  @Prop({ default: null }) private startingColor3;
  @Prop() private setValue!: string;
  @Prop({ default: "" }) private label;
  @Prop({ default: "" }) private logo;
  @Prop({ default: false }) private isDisabled;
  private Helper: Helper = Helper;
  private expanded = 0;
  private oldExpanded = 1;
  private hover = 0;

  private color1;
  private color2;
  private color3;

  get numColors() {
    let ret = 1;
    if (this.startingColor2) ret += 1;
    if (this.startingColor3) ret += 1;
    return ret;
  }

  changeColor1(val) {
    this.$forceUpdate();
    this.$emit("change-color-1", val);
    //($event, 'tableHeaderBgColor')
  }

  changeColor2(val) {
    this.$forceUpdate();
    this.$emit("change-color-2", val);
    //($event, 'tableHeaderBgColor')
  }

  changeColor3(val) {
    this.$forceUpdate();
    this.$emit("change-color-3", val);
    //($event, 'tableHeaderBgColor')
  }

  toggleExpanded(val) {
    if (val == 0) {
      if (this.expanded > 0) this.expanded = 0;
      else this.expanded = this.oldExpanded;
    } else {
      this.oldExpanded = val;
      if (this.expanded != val) this.expanded = val;
      else this.expanded = 0;
    }
  }

  async created() {
    this.color1 = this.startingColor1;
    this.color2 = this.startingColor2;
    this.color3 = this.startingColor3;
  }
}
