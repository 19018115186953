












import { Component, Vue, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import LabelInfo from "@/models/LabelInfo";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
  },
})
export default class LabelDetail extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "" }) private userClass;
  @Prop({ default: "" }) private labelId;
  @Prop({ default: "" }) private teamId;
  @Prop({ default: null }) private tooltip;
  private labelInfo = null;
  private loading = false;
  private error = false;

  async getLabelInfo(labelId: any): Promise<any | null> {
    let infos: any | null = null;
    if (labelId == null) return null;
    const ret = [];
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.teamId +
          "/labelInfo",
        {
          headers: authHeader(),
          labelId: this.labelId,
        }
      )
      .then((response) => {
        infos = response.data.labelInfo;
        for (let i = 0; i < infos.length; i++) {
          const info = infos[i];
          ret.push(new LabelInfo(info["id"], info["name"]));
        }
      })
      .catch((error) => {
        this.error = true;
      })
      .finally(() => (this.loading = false));
    return ret;
  }

  async mounted() {
    this.labelInfo = await this.getLabelInfo(this.labelId);
  }
}
