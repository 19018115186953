









import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Pin extends Vue {
  @Prop({ default: 0 }) value;
  @Prop({ default: "md" }) size;
}
