export default class Member {
  id: string
  name: string
  email: string
  imageUrl: string
  role: string
  createdAt: string
  constructor(id, name, email, imageUrl, role, createdAt) {
    this.id = id
    this.name = name
    this.email = email
    this.imageUrl = imageUrl
    this.role = role
    this.createdAt = createdAt
  }
}