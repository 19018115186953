






































import { Component, Vue, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import PDFViewer from "@/components/graphic/PDFViewer.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    PopoverInput,
    PDFViewer,
  },
})
export default class InvoiceDetail extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "" }) private userClass;
  @Prop({ default: "" }) private invoiceId;
  @Prop({ default: "" }) private userId;
  @Prop({ default: null }) private tooltip;
  private invoicesDet = null;
  private invoiceInfo = null;
  private loading = false;
  private error = false;

  async getInvoiceInfo(invoiceId: any): Promise<any | null> {
    const infos: any | null = null;
    if (invoiceId == null) return null;
    const ret = [];
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/licensing/" +
          this.userId +
          "/getInvoice",
        {
          invoiceId: this.invoiceId,
          headers: authHeader(),
        }
      )
      .then((response) => {
        const invoiceIds = response.data.invoiceId;
        this.invoicesDet = invoiceIds;
      })
      .catch((error) => {
        this.error = true;
      })
      .finally(() => (this.loading = false));
    return ret;
  }

  async mounted() {
    this.invoiceInfo = await this.getInvoiceInfo(this.invoiceId);
  }
}
