var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"z-index":"2"},style:(_vm.isDisabled || _vm.loading ? 'cursor:not-allowed' : '')},[_c('div',{style:(_vm.isDisabled || _vm.loading ? 'pointer-events:none' : '')},[(_vm.stop)?[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],class:'cu_btn ' +
          (_vm.isDisabled ? _vm.buttonClass + ' cu_btn-disabled ' : _vm.buttonClass),on:{"click":function($event){$event.stopPropagation();return _vm.btnClicked()}}},[(_vm.loading)?_c('div',[_c('loader')],1):_c('div',{class:!_vm.buttonClass.includes('cu_btn-custom') &&
            !_vm.buttonClass.includes('cu_btn-circle') &&
            !_vm.buttonClass.includes('cu_btn-square') &&
            !_vm.buttonClass.includes('cu_btn-compact')
              ? 'row st_padding-10px-20px'
              : ''},[(_vm.icon)?_c('div',{staticClass:"cu_btn-icon"},[_c('i',{class:_vm.icon}),(_vm.stackedIcon)?_c('i',{class:_vm.stackedIcon}):_vm._e()]):_vm._e(),(_vm.label)?_c('div',{staticClass:"cu_btn-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e()])])]:[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],class:'cu_btn ' +
          (_vm.isDisabled ? _vm.buttonClass + ' cu_btn-disabled ' : _vm.buttonClass),on:{"click":function($event){return _vm.btnClicked()}}},[(_vm.loading)?_c('div',[_c('loader')],1):_c('div',{class:!_vm.buttonClass.includes('cu_btn-custom') &&
            !_vm.buttonClass.includes('cu_btn-circle') &&
            !_vm.buttonClass.includes('cu_btn-square') &&
            !_vm.buttonClass.includes('cu_btn-compact')
              ? 'row st_padding-10px-20px'
              : ''},[(_vm.icon)?_c('div',{staticClass:"cu_btn-icon"},[_c('i',{class:_vm.icon}),(_vm.stackedIcon)?_c('i',{class:_vm.stackedIcon}):_vm._e()]):_vm._e(),(_vm.label)?_c('div',{staticClass:"cu_btn-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e()])])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }