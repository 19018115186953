

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class UserBilling extends Vue {
  @Prop({ default: [] }) private options;
}
