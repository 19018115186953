

































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { Helper } from "@/utils/Helper";
import Loader from "@/components/graphic/Loader.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: {
    Loader,
    ButtonInput,
    TextInput,
  },
})
export default class UserEmail extends Vue {
  private email = "";
  private loading = false;
  @Prop({ default: false }) private startingValue;
  private isEmailValidated = true;
  private errorsFromServer: Array<string> = [];
  @Prop({ default: true }) private displayUndo;

  get ValidEmail(): boolean {
    const regEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (this.email && this.email.length && regEmail.test(this.email))
      return true;
    return false;
  }

  UpdateEmail() {
    this.isEmailValidated = this.ValidEmail;
    if (this.isEmailValidated) {
      this.loading = true;
      axios
        .post(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/users/" +
            this.$store.state.auth.user.id +
            "/askUserEmail",
          { email: this.email }
        )
        .then((response) => {
          this.loading = false;
          const user = JSON.parse(localStorage.getItem("user") || "");
          this.$notify({
            group: "notifications",
            title: this.$t("i18n_component_useremail_success").toString(),
            duration: 20000,
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
          this.$notify({
            group: "notifications",
            title: this.$t("i18n_component_useremail_error").toString(),
            text: error,
            type: "error",
            duration: 20000,
          });
          this.$emit("update", this.email);
        });
    }
  }

  async created() {
    if (this.startingValue) {
      this.email = this.startingValue;
    }
  }
}
