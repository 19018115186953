








































































































































/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import "@/css/style.scss";
import "@/css/flexboxgrid.css";
import "@/css/icomoon.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

import CookieToggler from "@/components/graphic/CookieToggler.vue";
import NavBar from "@/components/graphic/NavBar.vue";
import HeaderBar from "@/components/graphic/HeaderBar.vue";
import PageNotVerified from "@/components/PageNotVerified.vue";
import NotificationMessage from "@/components/graphic/NotificationMessage.vue";
import ConfirmModalPopup from "@/components/inputs/ConfirmModalPopup.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import NotificationManager from "@/models/NotificationManager";
import CookieLaw from "vue-cookie-law";
import i18n from "./i18n-inline-loader";
import { uuid } from "vue-uuid";
import { Helper } from "@/utils/Helper";
import { EventBus } from "@/models/EventBus";

@Component({
  components: {
    CookieToggler,
    NavBar,
    HeaderBar,
    ConfirmModalPopup,
    ButtonInput,
    NotificationMessage,
    PageNotVerified,
    CookieLaw,
  },
})
export default class App extends Vue {
  private Helper: Helper = Helper;
  private UUID = uuid;
  private openCookieToggler = true;
  //private NotificationManager: NotificationManager = new NotificationManager();

  currentTeam() {
    return this.$store.state.auth.user.currentTeam || null;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  closeCookieLaw() {
    (this.$refs.cookieLaw as CookieLaw).accept();
  }

  closeCookieToggler() {
    (this.$refs.cookieToggler as CookieToggler).close();
  }

  enableAllCookies() {
    (this.$refs.cookieToggler as CookieToggler).enableAllCookieCategories();
    this.closeCookieLaw();
  }

  manageCookies() {
    (this.$refs.cookieToggler as CookieToggler).open();
  }

  saveCookieToggler() {
    this.closeCookieLaw();
    this.closeCookieToggler();
  }

  get cookieOpts() {
    const ret = [
      {
        category: "essential",
        name: this.$t("i18n_component_cookie_toggler_essential_name"),
        description: this.$t(
          "i18n_component_cookie_toggler_essential_description"
        ),
        active: true,
        required: true,
      },
      {
        category: "analytics",
        name: this.$t("i18n_component_cookie_toggler_analytics_name"),
        description: this.$t(
          "i18n_component_cookie_toggler_analytics_description",
          { appName: process.env.VUE_APP_NAME }
        ),
        active: false,
        required: false,
      },
    ];
    if (this.currentUser() && this.currentUser().role == "Admin") {
      ret.push({
        category: "test_cookie",
        name: "Test",
        description: "Test",
        active: false,
        required: false,
      });
    }
    return ret;
  }

  get myOwnedTeam() {
    const teams = Object.keys(this.$store.state.auth.notifications["teams"]);
    for (let i = 0; i <= teams.length; i++) {
      const team = this.$store.state.auth.notifications["teams"][teams[i]];
      if (team.owned) {
        return teams[i];
      }
    }
    /*return this.$store.state.auth.notifications["teams"].filter((team) => {
      return team.owned == true;
    });*/
    return null;
  }

  updateCurrentTeam(teamId) {
    this.$store.state.auth.user.currentTeam = teamId;
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(this.$store.state.auth.user));
    /*axios
      .get(Vue.prototype.$apiHttpProtocol + "://" + Vue.prototype.$apiAddress + "/api/teams/" + teamId)
      .then((response) => {
        if (response.data["team"]) {
          const modTeam = response.data["team"];
          this.$store.state.auth.user.currentTeam = modTeam;
          localStorage.removeItem("user");
          localStorage.setItem(
            "user",
            JSON.stringify(this.$store.state.auth.user)
          );
        }
      });*/
  }

  updateTeams() {
    const requestOwnedTeams = axios.post(
      Vue.prototype.$apiHttpProtocol +
        "://" +
        Vue.prototype.$apiAddress +
        "/api/teams/owned"
    );
    const requestAcceptedTeams = axios.post(
      Vue.prototype.$apiHttpProtocol +
        "://" +
        Vue.prototype.$apiAddress +
        "/api/teams/accepted"
    );
    const actTeamsIds = Object.keys(
      this.$store.state.auth.notifications["teams"]
    );
    const newTeamsIds = [];

    axios.all([requestOwnedTeams, requestAcceptedTeams]).then(
      axios.spread((...responses) => {
        if (responses[0].data["teams"]) {
          const teams = responses[0].data["teams"];
          for (let i = 0; i < teams.length; i++) {
            const teamId = teams[i].id;
            const teamName = teams[i].name;
            const teamAvatar = teams[i].team_photo_path;
            const teamCurrency = teams[i].currency;
            const teamHeading1 = teams[i].heading1;
            const teamHeading2 = teams[i].heading2;
            const teamOwned = true;
            this.$store.commit("ADD_TEAM", [
              teamId,
              teamName,
              teamAvatar,
              teamCurrency,
              teamHeading1,
              teamHeading2,
              teamOwned,
            ]);
            newTeamsIds.push(teamId);
          }
        }
        if (responses[1].data["teams"]) {
          const teams = responses[1].data["teams"];
          for (let i = 0; i < teams.length; i++) {
            const teamId = teams[i].id;
            const teamName = teams[i].name;
            const teamAvatar = teams[i].team_photo_path;
            const teamCurrency = teams[i].currency;
            const teamHeading1 = teams[i].heading1;
            const teamHeading2 = teams[i].heading2;
            const teamOwned = false;
            this.$store.commit("ADD_TEAM", [
              teamId,
              teamName,
              teamAvatar,
              teamCurrency,
              teamHeading1,
              teamHeading2,
              teamOwned,
            ]);
            newTeamsIds.push(teamId);
          }
        }
        for (let i = 0; i < actTeamsIds.length; i++) {
          const actTeamId = parseInt(actTeamsIds[i]);
          if (newTeamsIds.indexOf(actTeamId) == -1) {
            this.$store.commit("REMOVE_TEAM", actTeamId);
          }
        }
      })
    );
  }

  async created() {
    this.openCookieToggler = true;
    i18n.locale = localStorage.getItem("sweetlab_language");
    if (
      this.$store.state.auth.user &&
      this.$store.state.auth.user["token"] &&
      !axios.defaults.headers.common["Authorization"]
    ) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.auth.user["token"];
      localStorage.setItem("user", JSON.stringify(this.$store.state.auth.user));
      this.updateCurrentTeam(this.$store.state.auth.user.currentTeam);
      this.updateTeams();

      localStorage.setItem(
        "sweetlab_theme",
        this.$store.state.auth.user["theme"]
      );
      /*Helper.applyTheme(this.$store.state.auth.user["theme"]);
      if (this.$store.state.auth.user["night_mode"]) {
        localStorage.setItem(
          "sweetlab_night_mode",
          this.$store.state.auth.user["night_mode"]
        );
        Helper.applyNightMode(this.$store.state.auth.user["night_mode"]);
      }*/

      const notificationManager = NotificationManager.getInstance();
      const teamNotificationCallback = async (notification) => {
        console.log(
          "here is a notification from user: " +
            notification.data.senderId +
            "receiver" +
            notification.data.receiverId +
            "currentUser: " +
            this.currentUser().id +
            " sectionId: " +
            notification.data.sectionId +
            " teamId: " +
            notification.data.sectionId +
            " currentTeam: " +
            this.currentTeam() +
            " prop: " +
            JSON.stringify(notification.data.prop) +
            " action: " +
            notification.data.action +
            " type: " +
            notification.data.type +
            " dest id:" +
            notification.data.id
        );

        let inTeam = false;
        if (notification.data.sectionId != 0) {
          const myTeams = Object.keys(
            this.$store.state.auth.notifications["teams"]
          );
          if (myTeams.indexOf(notification.data.sectionId) != -1) {
            inTeam = true;
          }
        } else inTeam = true;

        if (
          inTeam ||
          notification.data.senderId == this.currentUser().id ||
          notification.data.receiverId == this.currentUser().id
        ) {
          this.$store.commit("ADD_NOTIFICATION", [
            notification.data.sectionId,
            {
              id: this.UUID.v1(),
              teamId: notification.data.teamId,
              senderId: notification.data.senderId,
              receiverId: notification.data.receiverId,
              type: notification.data.type,
              action: notification.data.action,
              createdAt: Helper.timeStr(),
              epochAt: Helper.epoch(),
              itemId: notification.data.parentId
                ? notification.data.parentId
                : notification.data.id,
              prop: notification.data.prop,
              unread: true,
            },
          ]);

          const notFromMe = notification.data.senderId != this.currentUser().id;
          const teamInUse =
            (notification.data.sectionId &&
              notification.data.sectionId == this.currentTeam()) ||
            (notification.data.prop &&
              notification.data.teamId == this.currentTeam()) ||
            notification.data.sectionId == 0;
          const isForMe =
            notification.data.receiverId == this.currentUser().id ||
            (!notification.data.receiverId && teamInUse);

          console.log(
            "NOTIFICATION: " +
              notification.data.action +
              "/ notFromMe:" +
              notFromMe +
              "/ teamInUse: " +
              teamInUse +
              "/ isForMe" +
              isForMe +
              "/ inTeam" +
              inTeam +
              "/" +
              "(" +
              notification.data.id +
              typeof notification.data.id +
              ":" +
              this.currentUser().id +
              typeof this.currentUser().id +
              ")"
          );
          if (notification.data.type == "team") {
            // Team update

            this.updateTeams();

            if (teamInUse && notFromMe) {
              this.updateCurrentTeam(
                notification.data.sectionId || notification.data.teamId
              );
            }

            if (
              (notification.data.action == "deleted_team" ||
                (notification.data.action == "deleted_member" &&
                  notification.data.receiverId == this.currentUser().id)) &&
              teamInUse &&
              notFromMe
            ) {
              //Quit team!

              const confirmModalPopupApp = this.$refs.confirmModalPopupApp;
              let ok = false;
              ok = await (confirmModalPopupApp as any).show({
                title: this.$t("i18n_attention"),
                message: this.$t("i18n_app_team_error"),
                okButton: this.$t("i18n_btn_understand").toString(),
              });
              if (ok) {
                axios
                  .post(
                    Vue.prototype.$apiHttpProtocol +
                      "://" +
                      Vue.prototype.$apiAddress +
                      "/api/teams/switch/" +
                      this.myOwnedTeam,
                    { withCredentials: true }
                  )
                  .then(() => {
                    this.$store.state.auth.user.currentTeam = this.myOwnedTeam;
                    localStorage.removeItem("user");
                    localStorage.setItem(
                      "user",
                      JSON.stringify(this.$store.state.auth.user)
                    );
                    this.$store.state.items = [];

                    this.$store.commit("REMOVE_TEAM", notification.data.teamId);

                    this.$router.push({ name: "home" });
                  });
              }
            }
          }

          let mustRefreshPage = false;

          if (
            notification.data.action == "deleted_team" ||
            notification.data.action == "deleted_member" ||
            notification.data.action == "invited_team" ||
            notification.data.action == "joined_team" ||
            notification.data.action == "abandoned_team" ||
            teamInUse
          )
            mustRefreshPage = true;

          if (notFromMe && (isForMe || mustRefreshPage)) {
            EventBus.getInstance().dispatch<string>(
              "refresh-page",
              notification
            );
          }
        }
      };

      notificationManager.Subscribe("team", teamNotificationCallback);
    }
  }
}
