









































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SubNavBar extends Vue {
  @Prop({ default: true }) private bgColor;
  @Prop({ default: null }) private current;
  @Prop({ default: [] }) private items;

  private subNavbarItems(index) {
    if (this.items.length)
      return this.items.filter((item) => {
        return item["index"] == index;
      });
    else return [];
  }

  private btnClicked(value) {
    this.$emit("sub-navbar-click", value);
  }
}
