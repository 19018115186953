






























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: {
    Loader,
    TextInput,
    ButtonInput,
  },
})
export default class SearchTag extends Vue {
  @Prop({ default: [] }) tags;
  @Prop({ default: [] }) labels;
  @Prop({ default: [] }) selectedTags;

  get canDeselectAllTags() {
    return this.selectedTags.length == this.tags.length;
  }

  get canSelectAllTags() {
    return this.selectedTags.length < this.tags.length;
  }

  isTagged(tag) {
    const tagIndex = this.selectedTags.indexOf(tag);
    return tagIndex >= 0;
  }

  toggleTag(tag) {
    const tagIndex = this.selectedTags.indexOf(tag);
    if (tagIndex >= 0) this.selectedTags.splice(tagIndex, 1);
    else this.selectedTags.push(tag);
  }

  deselectAllTags() {
    this.tags.forEach((tag) => {
      const tagIndex = this.selectedTags.indexOf(tag);
      if (tagIndex >= 0) this.selectedTags.splice(tagIndex, 1);
    });
  }

  selectAllTags() {
    this.tags.forEach((tag) => {
      const tagIndex = this.selectedTags.indexOf(tag);
      if (tagIndex < 0) this.selectedTags.push(tag);
    });
  }
}
