


























































import { Component, Vue, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import UserInfo from "@/models/UserInfo";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
  },
})
export default class UserDetail extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "" }) private userClass;
  @Prop({ default: "" }) private userId;
  @Prop({ default: null }) private userEmail;
  @Prop({ default: "2.14em" }) private avatarSize;
  private onlineSize = "0.72em";
  private borderSize = "0.1em";
  private isOnline = false;
  @Prop({ default: null }) private tooltip;
  @Prop({ default: true }) private displayTooltip;
  @Prop({ default: true }) private displayOnline;
  @Prop({ default: true }) private displayAvatar;
  private userInfo = null;
  private loading = false;
  private error = false;
  private timer = null;

  get userDetailTooltip() {
    let detailTooltip = "";
    if (this.displayTooltip) {
      detailTooltip =
        this.tooltip != null
          ? this.userDetailName + " | " + this.tooltip
          : this.userDetailName;
    }
    return detailTooltip;
  }

  get userDetailName() {
    return this.userInfo ? this.userInfo.username : "";
  }

  get userDetailInfo() {
    return this.userInfo;
  }

  async getUserInfo(userId: any): Promise<UserInfo | null> {
    let info: UserInfo | null = null;
    if (userId == null) return null;
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/users/" +
          userId +
          "/userInfo",
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        info = new UserInfo(
          userId,
          response.data[0].name,
          response.data[0].email,
          response.data[0].profile_photo_path
        );
      })
      .catch((error) => {
        this.error = true;
      })
      .finally(() => (this.loading = false));
    return info;
  }

  async userOnline(userId: any): Promise<boolean> {
    let info: false;
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/users/" +
          userId +
          "/isOnline",
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        info = response.data;
      })
      .catch((error) => {
        this.error = true;
      });
    return info;
  }

  async displayUserOnline() {
    this.isOnline = await this.userOnline(this.userId);
    this.timer = setTimeout(() => {
      this.displayUserOnline();
    }, 60000);
  }

  async mounted() {
    const userInfoObj = Helper.userInfo(
      this.$store.state.usersInfo,
      this.userId
    );

    if (userInfoObj.length > 0) {
      this.userInfo = userInfoObj[0];
    } else {
      this.userInfo = await this.getUserInfo(this.userId);
      if (this.userInfo && this.userInfo.isValid()) {
        this.$store.commit("ADD_USER_INFO", this.userInfo);
      }
    }

    if (this.displayOnline) this.displayUserOnline();
  }

  async destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
