export default class User {
  username: string
  email: string
  password: string
  confirmPassword: string
  twoFactorSecret: string
  token: string
  rememberMe: boolean
  constructor(username, email, password, twoFactorSecret, token) {
    this.username = username
    this.email = email
    this.password = password
    this.confirmPassword = ''
    this.twoFactorSecret = twoFactorSecret
    this.token = token
    this.rememberMe = false
  }
}
