









































































































import { Component, Vue, Prop } from "vue-property-decorator";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import Modal from "@/components/inputs/Modal.vue";
import Dropzone from "@/components/custom/Dropzone.vue";

@Component({
  components: {
    TextInput,
    ButtonInput,
    Loader,
    Modal,
    Dropzone,
  },
})
export default class IngredientDropzone extends Vue {
  private openModal = false;
  @Prop({ default: "" }) private teamId;
  @Prop({ default: "" }) private ingredientId;
  @Prop({ default: "" }) private ingredientAvatar;
  @Prop({ default: false }) private isDisabled;
  @Prop({ default: false }) private isLoading;

  async uploadedIngredientImage(ingredient) {
    this.$emit("update", ingredient);
    this.openModal = false;
  }
}
