





























































































































































































































import axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import ToggleButton from "@/components/inputs/ToggleButton.vue";
import ConfirmCode from "./ConfirmCode.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: {
    ToggleButton,
    ConfirmCode,
    ButtonInput,
  },
})
export default class TwoFactorAuth extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: false }) private enabled;
  private authenticationStep = 0; //(this.enabled ? 3 : 0);
  private secret = ""; //(this.enabled ? this.showSecret() : "");
  private qrCode = ""; //(this.enabled ? this.showQrCode() : "");
  private recoveryCodes = ""; //(this.enabled ? this.showRecoveryCodes() : "");

  data() {
    return {
      authenticationStep: this.enabled ? 3 : 0,
      secret: this.enabled ? this.showSecret() : "",
      qrCode: this.enabled ? this.showQrCode() : "",
      recoveryCodes: this.enabled ? this.showRecoveryCodes() : "",
    };
  }

  enableTwoFactorAuthentication() {
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/auth/2fa/enable"
      )
      .then(() => {
        return Promise.all([
          this.showSecret(),
          this.showQrCode(),
          this.showRecoveryCodes(),
        ]);
      })
      .then(() => {
        this.authenticationStep = 2;
      });
  }

  confirmTwoFactorAuthentication() {
    this.authenticationStep = 3;
    const user = JSON.parse(localStorage.getItem("user") || "");
    user["two_factor_authentication_enabled"] = true;
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(user));
    this.$store.state.auth.user["two_factor_authentication_enabled"] = true;
    this.$notify({
      group: "notifications",
      title: this.$t("i18n_component_2fa_enable_success").toString(),
      duration: 20000,
    });
  }

  showSecret() {
    return axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/auth/2fa/secret"
      )
      .then((response) => {
        this.secret = response.data;
      });
  }

  showQrCode() {
    return axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/auth/2fa/qrCode"
      )
      .then((response) => {
        this.qrCode = response.data.svg;
      });
  }

  showRecoveryCodes() {
    return axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/auth/2fa/recoveryCodes"
      )
      .then((response) => {
        this.recoveryCodes = response.data;
      });
  }

  disableTwoFactorAuthentication() {
    axios
      .delete(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/auth/2fa/disable"
      )
      .then(() => {
        this.authenticationStep = 0;
        const user = JSON.parse(localStorage.getItem("user") || "");
        user["two_factor_authentication_enabled"] = false;
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(user));
        if (this.$store.state.auth.user["two_factor_authentication_enabled"]) {
          this.$store.state.auth.user["two_factor_authentication_enabled"] =
            false;
          this.qrCode = "";
          this.recoveryCodes = "";

          this.$notify({
            group: "notifications",
            title: this.$t("i18n_component_2fa_disable_success").toString(),
            duration: 20000,
          });
        }
      });
  }

  toggleTwoFactorAuthentication(toggleValue) {
    if (toggleValue) this.authenticationStep = 1;
    else this.disableTwoFactorAuthentication();
  }

  startTwoFactorAuthentication() {
    this.enableTwoFactorAuthentication();
  }

  undoTwoFactorAuthentication() {
    if (this.authenticationStep) this.authenticationStep -= 1;
  }
}
