































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PageItems from "@/components/PageItems.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import GroupRadioInput from "@/components/inputs/GroupRadioInput.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import IsUsedIn from "@/components/custom/IsUsedIn.vue";
import Loader from "@/components/graphic/Loader.vue";
import Modal from "@/components/inputs/Modal.vue";
import PDFViewer from "@/components/graphic/PDFViewer.vue";
import axios from "axios";
import { authHeader } from "@/services/auth-header";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    PageItems,
    TextInput,
    DatePickerInput,
    ButtonInput,
    GroupRadioInput,
    UserDetail,
    IsUsedIn,
    Loader,
    Modal,
    PDFViewer,
  },
})
export default class PagePayments extends Vue {
  private user = this.$store.state.auth.user;
  private errorsFromServer: Array<string> = [];
  private Helper: Helper = Helper;
  currentTeam() {
    return this.user.currentTeam || null;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  isUsedIn(item, isUsedIn) {
    item.isUsedIn = isUsedIn;
  }
}
