



















































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PageItems from "@/components/PageItems.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import IsUsedIn from "@/components/custom/IsUsedIn.vue";
import Loader from "@/components/graphic/Loader.vue";
import Modal from "@/components/inputs/Modal.vue";
import PDFViewer from "@/components/graphic/PDFViewer.vue";
import axios from "axios";
import { authHeader } from "@/services/auth-header";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    PageItems,
    TextInput,
    DatePickerInput,
    ButtonInput,
    PopoverInput,
    UserDetail,
    IsUsedIn,
    Loader,
    Modal,
    PDFViewer,
  },
})
export default class PagePayments extends Vue {
  private user = this.$store.state.auth.user;
  private errorsFromServer: Array<string> = [];
  private openModalEmitInvoice: any = null;
  private openModalPrintInvoice = 0;
  private Helper: Helper = Helper;
  private loading = false;
  currentTeam() {
    return this.user.currentTeam || null;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  isUsedIn(item, isUsedIn) {
    item.isUsedIn = isUsedIn;
  }

  emitLicense(item) {
    this.errorsFromServer = [];
    this.loading = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/licensing/emitLicense/" +
          item.userId,
        {
          paymentId: item.paymentId,
          headers: authHeader(),
        }
      )
      .then((response) => {
        const licenseIds = response.data.licenseId;
        for (let i = 0; i < licenseIds.length; i++) {
          const licenseId = licenseIds[i];
          item.licenseId.push(licenseId);
        }

        if (response.data.licenseId) {
          item.getLicense();
        }
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_payments_emit_license_success").toString(),
          duration: 20000,
        });
      })
      .catch((error) => {
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_payments_emit_license_error").toString(),
          type: "error",
          duration: 20000,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  emitInvoice(item) {
    this.openModalEmitInvoice = {
      item: item,
      taxIdentifier: "",
      invoiceDate: Helper.calcDateStr(new Date()),
    };
  }

  doEmitInvoice(item, invoiceDate, taxIdentifier) {
    this.errorsFromServer = [];
    this.loading = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/licensing/emitInvoice/" +
          item.userId,
        {
          paymentId: item.paymentId,
          licenseId: item.licenseId.toString(),
          taxIdentifier: taxIdentifier,
          requiredTaxIdentifier:
            parseInt(item.orderPurchaseTaxes) > 0 ? true : false,
          invoiceDate: invoiceDate,
          headers: authHeader(),
        }
      )
      .then((response) => {
        this.openModalEmitInvoice = null;
        const invoiceId = response.data.invoiceId;
        item.invoiceId = invoiceId;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_payments_emit_invoice_success").toString(),
          duration: 20000,
        });
      })
      .catch((error) => {
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_payments_emit_invoice_error").toString(),
          type: "error",
          duration: 20000,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  printInvoice(userId, invoiceId) {
    this.openModalPrintInvoice = invoiceId;
  }

  dayClick(day) {
    this.openModalEmitInvoice.invoiceDate = day;
  }
}
