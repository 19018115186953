





















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ColorPicker from "@/components/graphic/ColorPicker.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    ButtonInput,
    CheckboxInput,
    PopoverInput,
    ColorPicker,
    TextInput,
  },
})
export default class LabelBorderEditor extends Vue {
  @Prop({ default: "" }) private name;
  @Prop({ default: null }) private pdfData;
  @Prop({ default: null }) private template;
  @Prop({ default: null }) private stepFactor;
  @Prop({ default: null }) private stepDecimals;

  toggleCheckbox(key) {
    let value = 0;
    if (this.template[key] == 0) value = 1;
    //this.template[key] = value;
    console.log("toggle" + key + "/" + value);
    this.$emit("update", key, value);
  }

  upValue(value, key) {
    console.log("up" + value + "/" + key);
    //this.template[key] = value;
    this.$emit("update", key, value);
  }

  convertToUnit(pageUnit, value) {
    let factor = 1;
    if (pageUnit == "in") factor = 1 / 25.4;
    else if (pageUnit == "cm") factor = 1 / 10;
    else if (pageUnit == "pt") factor = 1 / 0.35;
    return value * factor;
  }
}
