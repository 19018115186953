














import { Component, Prop, Vue } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";

@Component({
  components: {},
})
export default class TabPanel extends Vue {
  @Prop({ default: "md" }) size;
}
