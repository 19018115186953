



















import { Component, Prop, Vue } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";

@Component({
  components: {},
})
export default class WidgetPanel extends Vue {
  @Prop({ default: "md" }) size;
  @Prop({ default: "fa fa-star" }) icon;
}
