






































import { Component, Vue, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    ButtonInput,
    PopoverInput,
  },
})
export default class LastModified extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "" }) private itemName: string;
  @Prop({ default: "" }) private itemId: string;
  @Prop({ default: "" }) private teamId: string;
  //@Prop({ default: null }) private isUsedInDetail: any;
  private isUsedInDetail = null;
  private loading = false;
  private error = false;

  async created() {
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.teamId +
          "/isUsedIn/" +
          this.itemId,
        {
          headers: authHeader(),
        }
      )
      .then(async (response) => {
        const data = response.data;
        this.isUsedInDetail = data.isUsedIn;
        this.$emit("update:is-used", this.isUsedInDetail);
      })
      .catch((error) => {
        this.error = true;
      });
  }
}
