
















































import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Collapsible extends Vue {
  @Prop({ default: "" }) private title;
  @Prop({ default: "fa fa-info" }) private icon;
  private expanded = false;

  toggleCollapsible() {
    this.expanded = !this.expanded;
  }

  updated() {
    if (this.expanded) this.$emit("expanded");
  }
}
