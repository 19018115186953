

























































































import axios from "axios";
import Invitation from "../models/Invitation";
import { Component, Vue } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import ResourceExpired from "@/components/graphic/ResourceExpired.vue";
import { Helper } from "@/utils/Helper";

const DisplayTeamInvitation = {
  progress: 0,
  invited: 1,
  invitationNotFound: 2,
  error: 3,
};

@Component({
  components: {
    ButtonInput,
    Loader,
    ResourceExpired,
  },
})
export default class PageTeamInvitation extends Vue {
  private invitation = new Invitation(
    this.$route.params.id,
    this.$route.query.signature
  );
  private loading = true;
  private expired = false;
  private invited = false;
  private displayTeamInvitationForm = DisplayTeamInvitation.progress;
  private messageFromServer = "";
  private errorsFromServer: Array<string> = [];
  private user = this.$store.state.auth.user; //localStorage.getItem('user');//this.$store.state.auth.user;

  // methods

  get DisplayTeamInvitation(): typeof DisplayTeamInvitation {
    return DisplayTeamInvitation;
  }

  verifyTeam() {
    this.errorsFromServer = [];
    this.loading = true;
    axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/invitation/" +
          this.invitation.id +
          "?signature=" +
          this.invitation.signature
      )
      .then(
        (response) => {
          this.loading = false;
          this.displayTeamInvitationForm = DisplayTeamInvitation.invited;
        },
        (error) => {
          this.loading = false;
          if (error.response.status === 404) {
            this.displayTeamInvitationForm =
              DisplayTeamInvitation.invitationNotFound;
          } else {
            this.displayTeamInvitationForm = DisplayTeamInvitation.error;
            if (!Helper.isUndefined(error.response.data.errors))
              this.errorsFromServer = error.response.data.errors;
          }
        }
      );
  }

  mounted() {
    this.loading = true;
    this.verifyTeam();
    /*this.$store.dispatch("auth/teamVerify", this.verify).then(
      () => {
        this.loading = false;
        this.invited = true;
      },
      (error) => {
        this.loading = false;
        this.messageFromServer = error.response.data.message;
        this.errorsFromServer = error.response.data.errors || [];
      }
    );*/
  }
}
