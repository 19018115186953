




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { authHeader } from "@/services/auth-header";
import PageItems from "@/components/PageItems.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import GroupRadioInput from "@/components/inputs/GroupRadioInput.vue";
import FontSelectInput from "@/components/inputs/FontSelectInput.vue";
import ArgSelectInput from "@/components/inputs/ArgSelectInput.vue";
import PDFViewer from "@/components/graphic/PDFViewer.vue";
import ColorPicker from "@/components/graphic/ColorPicker.vue";
import Dropzone from "@/components/custom/Dropzone.vue";
import Collapsible from "@/components/graphic/Collapsible.vue";
import LabelTextEditor from "@/components/custom/LabelTextEditor.vue";
import LastModified from "@/components/custom/LastModified.vue";
import ItemsList from "@/components/custom/ItemsList.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    PageItems,
    TextInput,
    ButtonInput,
    CheckboxInput,
    GroupRadioInput,
    FontSelectInput,
    ArgSelectInput,
    ColorPicker,
    Dropzone,
    Collapsible,
    LabelTextEditor,
    LastModified,
    ItemsList,
    PDFViewer,
  },
})
export default class PageLabels extends Vue {
  private Helper = Helper;
  private user = this.$store.state.auth.user;
  private thumb = null;
  private currPageUnit = "mm";
  private stepFactor = 1;
  private stepDecimals = 1;
  private pdfData = {
    id: "",
    name: "",
    kj: "",
    kcal: "",
    notes: [],
    qty: [],
    batch: "",
    elements: null,
    template: {},
    lockLogoSize: true,
    highlight: null,
    highlightColor1:
      document.documentElement.style.getPropertyValue("--main-theme-1st"),
    highlightColor2: document.documentElement.style.getPropertyValue(
      "--main-theme-1st-hover"
    ),
  };
  private timer = null;
  private errorsFromServer: Array<string> = [];

  numOfShows(item) {
    let numOfShows = 0;
    let totalShows = 0;
    if (item.headerShow) {
      totalShows += 1;
      numOfShows += 1;
    } else totalShows += 1;
    if (item.noteShow) {
      totalShows += 1;
      numOfShows += 1;
    } else totalShows += 1;
    if (item.qtyShow) {
      totalShows += 1;
      numOfShows += 1;
    } else totalShows += 1;
    if (item.batchShow) {
      totalShows += 1;
      numOfShows += 1;
    } else totalShows += 1;
    if (item.tableShow) {
      totalShows += 1;
      numOfShows += 1;
    } else totalShows += 1;
    if (item.footerShow) {
      totalShows += 1;
      numOfShows += 1;
    } else totalShows += 1;
    return numOfShows + " / " + totalShows;
  }

  toggleCheckbox(item, key) {
    if (item[key] == 0) item[key] = 1;
    else item[key] = 0;
    this.upTemplate(item[key], key);
  }

  updateTextAreaEditor(item, values) {
    console.log("updateTextAreaEditor" + item + "/" + values);
    const key = values[0];
    const value = values[1];
    item[key] = value;
    this.upTemplate(item[key], key);
    console.log("XXX" + key + "/" + value + "/" + item[key]);
  }

  upFontFamily(item, value, key) {
    item[key] = value;
    this.upTemplate(value, key);
  }

  setSteps(value) {
    if (value == "in") {
      this.stepFactor = 0.04;
      this.stepDecimals = 2;
    } else if (value == "cm") {
      this.stepFactor = 0.1;
      this.stepDecimals = 1;
    } else if (value == "mm") {
      this.stepFactor = 1;
      this.stepDecimals = 0;
    } else if (value == "pt") {
      this.stepFactor = 2.83;
      this.stepDecimals = 2;
    } else {
      this.stepFactor = 1;
      this.stepDecimals = 0;
    }
  }

  pageUnitFactor() {
    this.pdfData.template["pageUnit"];
  }

  convertToUnit(pageUnit, value) {
    let factor = 1;
    if (pageUnit == "in") factor = 1 / 25.4;
    else if (pageUnit == "cm") factor = 1 / 10;
    else if (pageUnit == "pt") factor = 1 / 0.35;
    return value * factor;
  }

  upPageUnit(item, value) {
    console.log("upPageUnit" + Object.keys(item));
    console.log(item);
    const keys = Object.keys(item);

    let factor = 1;
    this.setSteps(value);

    const key = "pageUnit";

    if (item[key] == "mm" && value == "in") {
      factor = 1 / 25.4;
    } else if (item[key] == "in" && value == "mm") {
      factor = 25.4;
    }
    if (item[key] == "cm" && value == "in") {
      factor = 10 / 25.4;
    } else if (item[key] == "in" && value == "cm") {
      factor = 25.4 / 10;
    }
    if (item[key] == "mm" && value == "cm") {
      factor = 1 / 10;
    } else if (item[key] == "cm" && value == "mm") {
      factor = 10;
    } else if (item[key] == "pt" && value == "in") {
      this.stepFactor = 0.1;
      factor = 1 / 72;
    } else if (item[key] == "in" && value == "pt") {
      factor = 72;
    } else if (item[key] == "pt" && value == "cm") {
      factor = 0.035;
    } else if (item[key] == "cm" && value == "pt") {
      factor = 1 / 0.035;
    } else if (item[key] == "pt" && value == "mm") {
      factor = 0.35;
    } else if (item[key] == "mm" && value == "pt") {
      factor = 1 / 0.35;
    }

    for (let i = 0; i < keys.length; i++) {
      const prop = keys[i];
      if (
        item[prop] != null &&
        ((Helper.endsWith(prop, "X") && !Helper.endsWith(prop, "numRowsX")) ||
          (Helper.endsWith(prop, "Y") && !Helper.endsWith(prop, "numRowsY")) ||
          Helper.endsWith(prop, "HeaderSize") ||
          Helper.endsWith(prop, "RowSize") ||
          Helper.endsWith(prop, "Width") ||
          Helper.endsWith(prop, "Height") ||
          Helper.endsWith(prop, "Border") ||
          Helper.endsWith(prop, "Radius") ||
          Helper.endsWith(prop, "Weight") ||
          Helper.endsWith(prop, "MarginLeft") ||
          Helper.endsWith(prop, "MarginTop") ||
          Helper.endsWith(prop, "PaddingLeft") ||
          Helper.endsWith(prop, "PaddingTop") ||
          Helper.endsWith(prop, "CircleRadius") ||
          Helper.endsWith(prop, "CellPadding"))
      ) {
        const newPropValue = item[prop] * factor;
        item[prop] = (item[prop] * factor).toFixed(this.stepDecimals);
        this.upTemplate(newPropValue, prop, false);
      }
    }

    item[key] = value;
    this.upTemplate(value, key);
    this.currPageUnit = value;
    console.log("FORCEUPDATE!!!!");
  }

  choosenRecipe(recipe) {
    if (recipe.length) {
      this.pdfData.id = recipe[0].id;
      this.pdfData.name = recipe[0].name;
      this.getIngredient();
    }
  }

  get recipeUrl() {
    return this.pdfData
      ? Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/print/" +
          Helper.currentTeam() +
          "/label"
      : "";
  }

  async getIngredientData() {
    const ret = {
      kj: 0,
      kcal: 0,
      notes: [],
      qty: [],
      batch: "",
      elements: null,
    };
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          Helper.currentTeam() +
          "/labelData/" +
          this.pdfData.id,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        const labelData = response.data.labelData;
        if (response.data) {
          ret["kj"] = response.data.labelData["kj"];
          ret["kcal"] = response.data.labelData["kcal"];
          ret["notes"] = response.data.labelData["notes"];
          ret["qty"] = response.data.labelData["qty"];
          ret["batch"] = response.data.labelData["batch"];
          ret["elements"] = response.data.labelData["elements"];
        }
      });
    return ret;
  }

  async getIngredient() {
    this.getIngredientData().then((res) => {
      this.pdfData.kj = res.kj.toString();
      this.pdfData.kcal = res.kcal.toString();
      this.pdfData.notes = res.notes;
      this.pdfData.qty = res.qty;
      this.pdfData.batch = res.batch;
      let performRefresh = false;
      if (this.pdfData.elements != null) performRefresh = true;

      this.pdfData.elements = res.elements;
      if (performRefresh) (this.$refs.labelViewer as PDFViewer).refresh();
    });
  }

  syncTemplate(obj) {
    this.setSteps(obj.pageUnit);
    Object.entries(obj).forEach(([key, value]) => {
      this.pdfData.template[key] = obj[key];
    });
  }

  upTemplate(value, key, refresh = true) {
    this.pdfData.template[key] = value;

    if (refresh) {
      this.pdfData.highlight = key;
      (this.$refs.labelViewer as PDFViewer).refresh();
      /*if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      if (
        key != "pageUnit" &&
        key != "pageOrientation" &&
        key != "pageSize" &&
        key != "tableHeaderFontColor" &&
        key != "tableHeaderFontSize" &&
        key != "tableRowFontColor" &&
        key != "tableRowFontSize"
      ) {
        this.timer = setTimeout(() => {
          this.pdfData.highlight = null;
          (this.$refs.labelViewer as PDFViewer).refresh();
        }, 1000);
      }*/
    }
  }

  upTemplateLogoWidth(item, refresh, value, key) {
    if (this.pdfData.lockLogoSize) {
      this.pdfData.template["logoHeight"] = Math.ceil(
        (value * item.logoPhotoHeight) / item.logoPhotoWidth
      );
      item.logoHeight = this.pdfData.template["logoHeight"];
      if (!Helper.isUndefined(this.$refs.logoHeight))
        (this.$refs.logoHeight as TextInput).updateValue(
          this.pdfData.template["logoHeight"]
        );
    }
    this.upTemplate(value, key, refresh);
  }

  upTemplateLogoHeight(item, refresh, value, key) {
    if (this.pdfData.lockLogoSize) {
      this.pdfData.template["logoWidth"] = Math.ceil(
        (value * item.logoPhotoWidth) / item.logoPhotoHeight
      );
      item.logoWidth = this.pdfData.template["logoWidth"];
      if (!Helper.isUndefined(this.$refs.logoWidth))
        (this.$refs.logoWidth as TextInput).updateValue(
          this.pdfData.template["logoWidth"]
        );
    }
    this.upTemplate(value, key, refresh);
  }

  toggleLockLogo() {
    this.pdfData.lockLogoSize = !this.pdfData.lockLogoSize;
  }

  upColor($event, item, prop) {
    item[prop] = $event;
    this.upTemplate($event, prop);
  }

  removedLabelImage(item) {
    item.logoPhotoPath = null;
    item.logoPhotoWidth = 0;
    item.logoPhotoHeight = 0;
    (this.$refs.logoWidth as TextInput).updateValue(0);
    this.upTemplate(0, "logoPhotoWidth", false);
    (this.$refs.logoHeight as TextInput).updateValue(0);
    this.upTemplate(0, "logoPhotoHeight", false);
    this.upTemplate(null, "logoPhotoPath");
  }

  uploadedLabelImage(item, logo, w, h) {
    item.logoPhotoPath = logo;
    item.logoPhotoWidth = w;
    item.logoPhotoHeight = h;
    let newWidth = 0;
    let newHeight = 0;
    if (w >= h) {
      newWidth = item.logoWidth;
      newHeight = (item.logoWidth * h) / w;
    } else {
      newWidth = (item.logoHeight * w) / h;
      newHeight = item.logoHeight;
    }

    this.upTemplate(w, "logoPhotoWidth", false);
    this.upTemplate(h, "logoPhotoHeight", false);
    this.upTemplateLogoWidth(item, false, newWidth, "logoWidth");
    this.upTemplateLogoHeight(item, false, newHeight, "logoHeight");
    this.upTemplate(logo, "logoPhotoPath");
  }

  addLabelThumbnail(thumb) {
    this.thumb = thumb;
  }

  fontStyleClass(font) {
    if (font == "B") return "font-weight: bold;";
    else if (font == "I") return "font-style: italic;";
    else if (font == "U") return "text-decoration: underline;";
    else return "";
  }

  defaultLabelName() {
    if (this.user && Helper.currentTeam())
      return this.$store.state.auth.notifications["teams"][Helper.currentTeam()]
        .name;
    else return "";
  }

  labelLogo(logo) {
    let ret = null;
    if (logo) {
      if (logo.startsWith("data:image")) ret = logo;
      else
        ret =
          Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/images/profile-photos/labels/" +
          logo;
    }
    return ret;
  }

  labelThumbWidth(refHeight, w, h) {
    return Math.trunc((refHeight * w) / h);
  }
}
