var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st_flex-row"},[(_vm.notification && _vm.userName)?[_c('div',{staticClass:"st_flex-column"},[_c('div',{staticClass:"cu_personal-notification-avatar"},[_c('i',{class:_vm.notificationIcon(_vm.notification.type)})])]),_c('div',{staticClass:"cu_personal-notification-body",class:{
        'cu_personal-notification-body-unread':
          _vm.displayUnread && _vm.notification.unread,
      }},[_c('div',{staticClass:"st_flex-row st_flex-align-left"},[_c('div',{staticClass:"cu_personal-notification-item",class:{
            'cu_personal-notification-unread-item':
              _vm.displayUnread && _vm.notification.unread,
          },style:('line-height: 1.1em')},[(
              _vm.notification.action == 'invited_team' ||
              _vm.notification.action == 'abandoned_team' ||
              _vm.notification.action == 'deleted_member'
            )?[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  'i18n_component_personal_notifications_' +
                    'action_' +
                    (_vm.notification.senderId == _vm.currentUser().id
                      ? 'sender'
                      : 'receiver') +
                    '_' +
                    _vm.notification.action,
                  {
                    member: _vm.userName,
                  }
                )
              )}})]:[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  'i18n_component_personal_notifications_' +
                    'action_' +
                    (_vm.notification.senderId == _vm.currentUser().id
                      ? 'sender'
                      : 'receiver') +
                    '_' +
                    _vm.notification.action,
                  {
                    member: _vm.userName,
                  }
                )
              )}})],_vm._v(" "+_vm._s(_vm.$tc( "i18n_component_personal_notifications_type_" + _vm.notification.type, _vm.notification.itemId.length ))+" "),[(_vm.notification.type == 'user')?[(
                  _vm.notification.action == 'deleted_user' &&
                  _vm.notification.prop &&
                  _vm.notification.prop[0].userName
                )?[_c('b',[_vm._v(_vm._s(_vm.notification.prop[0].userName))])]:[(_vm.notification.type == 'user')?_c('user-detail',{attrs:{"userId":_vm.notification.action == 'deleted_user'
                      ? _vm.notification.itemId
                      : _vm.notification.senderId,"displayOnline":false,"displayAvatar":false}}):_vm._e()]]:_vm._e(),(_vm.notification.type == 'license')?_c('license-detail',{attrs:{"userId":_vm.currentUser().id,"licenseId":_vm.notification.itemId}}):_vm._e(),(_vm.notification.type == 'invoice')?_c('invoice-detail',{attrs:{"userId":_vm.currentUser().id,"invoiceId":_vm.notification.itemId}}):_vm._e(),(_vm.notification.type == 'team')?[(false)?_c('span',{staticClass:"st_bold"},[_vm._v(_vm._s(_vm.notification.prop.teamName))]):_c('team-detail',{attrs:{"ownerId":_vm.notification.senderId,"teamId":_vm.notification.teamId}})]:_vm._e(),(_vm.notification.type == 'payment')?_vm._l((_vm.notification.itemId),function(item){return _c('span',{key:'payment' + item,staticClass:"st_bold"},[_vm._v(_vm._s(item)+_vm._s(item != _vm.notification.itemId.slice(-1)[0] ? ", " : ""))])}):_vm._e()]],2)]),_c('div',[_c('div',{staticClass:"cu_personal-notification-time"},[_c('time-formatter',{attrs:{"id":_vm.UUID.v1(),"timeEpoch":_vm.notification.epochAt}})],1)])])]:[_c('loader')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }