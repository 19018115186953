export default class News {
    id: string
    titleIT: string
    titleEN: string
    bodyIT: string
    bodyEN: string
    image: string
    textColor: string
    startDate: string

    constructor(titleIT, titleEN, bodyIT, bodyEN, image, textColor, startDate) {
        this.titleIT = titleIT
        this.titleEN = titleEN
        this.bodyIT = bodyIT
        this.bodyEN = bodyEN
        this.image = image
        this.textColor = textColor
        this.startDate = startDate
    }
}