
import Singleton from '@/models/Singleton.ts'
import * as Ably from "ably";

export default class NotificationManager extends Singleton {
    channel: any;
    public myCallback: { (): void };
    public static instance: NotificationManager;

    constructor() {
        super();
        this.Connect();
    }

    public static getInstance(): NotificationManager {
        if (!this.instance) {
            this.instance = new NotificationManager();
        }

        return this.instance;
    }

    public Connect() {
        const ably = new Ably.Realtime("th3rjg.tqIQCQ:PrmSJMSoRWLJFTN0");
        this.channel = ably.channels.get("sweetlab");

        this.channel.history({ limit: 5, direction: 'forwards' }, (err, messagesPage) => {
            messagesPage.items.forEach((item) => {
                console.log("HISTORY " + messagesPage.items);
            });

        });
        ably.connection.on("connected", function () {
            console.log("Connected to Ably");
        });
    }

    public Subscribe(group: string, func: any) {
        this.channel.unsubscribe(group);
        this.channel.subscribe(group, function (notification) {
            func(notification)
        });
    }

}