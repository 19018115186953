






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class RadioInput extends Vue {
  @Prop({ default: false }) private option;
  @Prop({ default: null }) private label;
  @Prop({ default: null }) private icon;
  @Prop({ default: "radio-input" }) private groupName;
  @Prop({ default: false }) private selected;
  @Prop({ default: 20 }) private fontSize;

  changeRadio(option) {
    this.$emit("input", option);
  }
}
