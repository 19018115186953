export function authHeader() {
  const user = JSON.parse(localStorage.getItem('user') || '{}')

  if (user) {
    return {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'Application/json',
      Authorization: 'Bearer ' + user['token']
    }
  } else {
    return {}
  }
}

export function fileAuthHeader() {
  const user = JSON.parse(localStorage.getItem('user') || '{}')

  if (user) {
    return {
      'Access-Control-Allow-Origin': '*',
      'Accept': '*/*',
      Authorization: 'Bearer ' + user['token']
    }
  } else {
    return {}
  }
}
