






























































































import { Component, Prop, Vue } from "vue-property-decorator";
import pdf from "vue-pdf";
import axios from "axios";
import { dragscroll } from "vue-dragscroll";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import { authHeader } from "@/services/auth-header";

const ExpandPDFViewer = {
  none: 0,
  left: 1,
  right: 2,
};

@Component({
  components: {
    ButtonInput,
    pdf,
  },
  directives: {
    dragscroll,
  },
})
export default class PDFViewer extends Vue {
  @Prop({ default: null }) private url;
  @Prop({ default: "" }) private uid;
  @Prop({ default: "sweetlab" }) private pdfName;
  @Prop({ default: null }) public data;
  @Prop({ default: false }) private refreshSrc;
  private pdfsrc?: any = null;
  private zoom = 100;
  private loading = false;
  private hasData = false;
  private lockRefresh = false;
  private expand = ExpandPDFViewer.none;
  private timer = null;

  download() {
    /*axios
      .get(this.url, {
        responseType: "blob",
      })*/
    axios(this.url, {
      method: "POST",
      responseType: "blob",
      headers: authHeader(),
      data: { data: this.data },
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = URL.createObjectURL(blob);
        link.download = this.pdfName + "-" + this.uid;

        link.setAttribute("target", "_blank");
        link.click();
        document.body.appendChild(link);
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_pdfviewer_download_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }

  refresh() {
    if (!this.lockRefresh) {
      this.lockRefresh = true;

      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.loading = true;
        if (this.refreshSrc) this.pdfsrc = null;
        axios(this.url, {
          method: "POST",
          responseType: "blob",
          headers: authHeader(),
          data: { data: this.data },
        })
          .then((response) => {
            const blob = new Blob([response.data]);
            const objectUrl = URL.createObjectURL(blob);
            this.hasData = true;
            this.pdfsrc = objectUrl;
          })
          .catch((error) => {
            this.$notify({
              group: "notifications",
              title: this.$t(
                "i18n_component_pdfviewer_refresh_error"
              ).toString(),
              text: error,
              type: "error",
              duration: 20000,
            });
          })
          .finally(() => {
            this.loading = false;
          });

        this.lockRefresh = false;
      }, 1000);
    }
  }

  empty() {
    this.hasData = false;
  }

  async created() {
    this.refresh();
  }

  toggleSideBarLeft() {
    if (this.expand != ExpandPDFViewer.left) this.expand = ExpandPDFViewer.left;
    else this.expand = ExpandPDFViewer.none;
  }

  toggleSideBarRight() {
    if (this.expand != ExpandPDFViewer.right)
      this.expand = ExpandPDFViewer.right;
    else this.expand = ExpandPDFViewer.none;
  }

  togglePDF() {
    if (this.expand != ExpandPDFViewer.none) this.expand = ExpandPDFViewer.none;
  }
}
