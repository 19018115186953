
























































































import { Component, Vue, Prop } from "vue-property-decorator";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import Modal from "@/components/inputs/Modal.vue";
import Dropzone from "@/components/custom/Dropzone.vue";

@Component({
  components: {
    TextInput,
    ButtonInput,
    Loader,
    Modal,
    Dropzone,
  },
})
export default class UserDropzone extends Vue {
  private openModal = false;
  @Prop({ default: "" }) private user;
  @Prop({ default: false }) private isDisabled;

  async uploadedUserImage(user) {
    this.$emit("update", user);
    this.openModal = false;
  }
}
