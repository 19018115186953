






























































































































































































































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import { Helper } from "@/utils/Helper";
import { EventBus } from "@/models/EventBus";
import Loader from "@/components/graphic/Loader.vue";
import BankInstructions from "@/components/custom/BankInstructions.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import Collapsible from "@/components/graphic/Collapsible.vue";
import TabPanel from "@/components/graphic/TabPanel.vue";
import { uuid } from "vue-uuid";
declare const window: any;

@Component({
  components: {
    Loader,
    BankInstructions,
    TextInput,
    ButtonInput,
    PopoverInput,
    Collapsible,
    TabPanel,
  },
})
export default class ShoppingCart extends Vue {
  private doPayment = false;
  private paymentOrder = null;
  private loading = false;
  private Helper: Helper = Helper;

  shoppingCartItems() {
    return Object.keys(this.$store.state.auth.shoppingCart).length;
  }

  addQtyToCart(key, qty) {
    this.$store.commit("ADD_TO_CART", [key, qty, 0]);
    //this.$forceUpdate();
  }

  removeFromCart(key) {
    this.$store.commit("DEL_FROM_CART", key);
    //this.$forceUpdate();
  }

  subTotalPrice() {
    let total = 0;
    for (
      let i = 0;
      i < Object.keys(this.$store.state.auth.shoppingCart).length;
      i++
    ) {
      const packName = Object.keys(this.$store.state.auth.shoppingCart)[i];
      const pack = this.$store.state.auth.shoppingCart[packName];
      total += pack["price"] * pack["qty"];
    }
    return total;
  }

  taxes() {
    return Helper.taxes(this.subTotalPrice());
  }

  totalPrice() {
    const subTotal = this.subTotalPrice();
    const taxes = this.taxes();
    return subTotal + taxes;
  }

  quitPayment() {
    this.doPayment = false;
  }

  proceedToCheckout() {
    this.doPayment = true;
    this.paymentOrder = {
      description: Object.keys(this.$store.state.auth.shoppingCart).toString(),
      amount: { currency_code: "EUR", value: this.totalPrice() },
    };
    //this.$forceUpdate();
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  orderPurchaseDescription() {
    let msg = "";
    let packMsg = "";
    for (
      let i = 0;
      i < Object.keys(this.$store.state.auth.shoppingCart).length;
      i++
    ) {
      const packName = Object.keys(this.$store.state.auth.shoppingCart)[i];
      const pack = this.$store.state.auth.shoppingCart[packName];
      if (packMsg != "") packMsg += ",";
      packMsg +=
        "{" +
        '"pack":"' +
        packName +
        '", "qty":' +
        pack["qty"] +
        ', "price":' +
        pack["price"] +
        "}";
    }
    msg = "[" + packMsg + "]";
    return msg;
  }

  activatePaypal() {
    const shoppingCartDiv = window.document.getElementById(
      "paypal-button-container-shopping-cart"
    );
    if (typeof shoppingCartDiv != "undefined" && shoppingCartDiv != null) {
      window.document.getElementById(
        "paypal-button-container-shopping-cart"
      ).innerHTML = "";
    }

    if (this.doPayment) {
      if (typeof shoppingCartDiv != "undefined" && shoppingCartDiv != null) {
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [this.paymentOrder],
              });
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.capture();
              this.loading = true;
              axios
                .post(
                  Vue.prototype.$apiHttpProtocol +
                    "://" +
                    Vue.prototype.$apiAddress +
                    "/api/licensing/" +
                    this.currentUser().id +
                    "/savePayment",
                  {
                    orderId: order.id,
                    orderCreationTime: order.create_time,
                    orderPayerEmail: order.payer.email_address,
                    orderPayerName: order.payer.name.given_name,
                    orderPayerSurname: order.payer.name.surname,
                    orderPayerId: order.payer.payer_id,
                    orderPurchaseDescription: this.orderPurchaseDescription(),
                    orderPurchaseTaxes: this.taxes().toFixed(2).toString(),
                    orderPurchaseSubTotal: this.subTotalPrice()
                      .toFixed(2)
                      .toString(),
                    orderPurchaseTotal: this.totalPrice().toFixed(2).toString(),
                    orderPurchaseAmount: order.purchase_units[0].amount.value,
                    orderPurchaseCurrency:
                      order.purchase_units[0].amount.currency_code,
                    orderPayeeEmail:
                      order.purchase_units[0].payee.email_address,
                    orderPayeeMerchantId:
                      order.purchase_units[0].payee.merchant_id,
                    orderStatus: order.status,
                    headers: authHeader(),
                  }
                )
                .then((response) => {
                  /*
                if (this.licenseStatus == 3)
                  this.licenseStatus = 4;
                else if (this.licenseStatus == 5)
                  this.licenseStatus = 6;
                else
                  this.licenseStatus = 1;*/

                  window.document.getElementById(
                    "paypal-button-container-shopping-cart"
                  ).innerHTML = "";
                  this.paymentOrder = null;
                  this.$store.commit("EMPTY_CART");
                  //this.$store.state.auth.shoppingCart = {};
                  EventBus.getInstance().dispatch<string>(
                    "refresh-page-features",
                    null
                  );
                  this.$notify({
                    group: "notifications",
                    title: this.$t("i18n_app_payment_pay_success").toString(),
                    duration: 20000,
                  });
                })
                .catch((error) => {
                  this.$notify({
                    group: "notifications",
                    title: this.$t("i18n_app_payment_pay_error").toString(),
                    text: error,
                    type: "error",
                    duration: 20000,
                  });
                  //this.errorsFromServer = error.response.data.errors;
                })
                .finally(() => {
                  this.loading = false;
                });
            },
            onCancel: (data) => {
              this.loading = false;
            },
            onError: (err) => {
              this.$notify({
                group: "notifications",
                title: this.$t(
                  "i18n_app_payment_license_status_error"
                ).toString(),
                text: err,
                type: "error",
                duration: 20000,
              });
            },
          })
          .render("#paypal-button-container-shopping-cart");
      }
    }
  }
  async created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "ADD_TO_CART" || mutation.type == "DEL_FROM_CART") {
        this.$forceUpdate();
      }
    });
  }
}
