import Vue from 'vue'
import Vuex from 'vuex'
import { app } from "../main";

import { auth } from './auth-module'
import axios from "axios";
import { authHeader } from "@/services/auth-header";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    items: [],
    itemsPage: '',
    backupItem: null,
    usersInfo: [],
    auth: auth.state,
    shoppingCart: {}
  },
  mutations: {
    ADD_TO_CART(state, [pack, qty, price]) {
      if (!state.auth.shoppingCart[pack]) {
        state.auth.shoppingCart[pack] = {
          qty: 1,
          price: price,
        };
      } else state.auth.shoppingCart[pack]["qty"] += qty;
      if (state.auth.shoppingCart[pack]["qty"] == 0)
        delete state.auth.shoppingCart[pack];
      localStorage.setItem(
        "shoppingCart",
        JSON.stringify(state.auth.shoppingCart)
      );
    },
    DEL_FROM_CART(state, pack) {
      if (state.auth.shoppingCart[pack])
        delete state.auth.shoppingCart[pack];

      localStorage.setItem(
        "shoppingCart",
        JSON.stringify(state.auth.shoppingCart)
      );
    },
    EMPTY_CART(state) {
      state.auth.shoppingCart = {}

      localStorage.setItem(
        "shoppingCart",
        JSON.stringify(state.auth.shoppingCart)
      );
    },
    ADD_USER_INFO(state, userInfo) {
      const oldItem = state.usersInfo.find(item => item.userId === userInfo.userId);
      if (oldItem) {
        Object.assign(oldItem, userInfo);
      }
      else
        state.usersInfo.push(userInfo);
    },
    BACKUP_ITEM(state, editedItem) {
      state.backupItem = JSON.parse(JSON.stringify(editedItem));
    },
    UNDO_ITEM(state) {
      const oldItem = state.items.find(item => item.id === state.backupItem.id);
      if (oldItem) {
        if (oldItem.id != "")
          Object.assign(oldItem, state.backupItem);
      }

      state.backupItem = null;
    },
    UPDATE_ITEM(state, editedItem) {
      const oldItem = state.items.find(item => item.id === editedItem.id);
      if (oldItem) {
        Object.assign(oldItem, editedItem)
      }
    },
    ADD_ITEM(state, [item, position]) {
      if (position == -1)
        state.items.push(item);
      else
        state.items.splice(position, 0, item);
    },
    DEL_ITEM(state, item) {
      state.items.splice(state.items.indexOf(item), 1);
    },
    DEL_ALL_ITEMS(state) {
      state.items = [];
    },
    MOVE_ITEM(state, [item, position]) {
      state.items.splice(state.items.indexOf(item), 1);
      state.items.splice(position, 0, item);
    },
    REMOVE_TEAM(state, teamId) {
      Vue.delete(state.auth.notifications["teams"], teamId);
      localStorage.setItem(
        "notifications",
        JSON.stringify(state.auth.notifications)
      );
    },
    async ADD_TEAM(state, [teamId, teamName, teamAvatar, teamCurrency, teamHeading1, teamHeading2, owned]) {
      console.log("ADD_TEAM" + "/" + teamName + "/" + teamHeading1 + "/" + teamHeading2);
      if (!Object.prototype.hasOwnProperty.call(
        state.auth.notifications["teams"],
        teamId
      )) {
        const newTeam = { "name": teamName, "avatar": teamAvatar, "currency": teamCurrency, "heading1": teamHeading1, "heading2": teamHeading2, "owned": owned, "messages": [], "activities": [], "loading": true, "tick": 0 };
        Vue.set(state.auth.notifications["teams"], teamId, newTeam);
      }
      else {
        state.auth.notifications["teams"][teamId]["name"] = teamName;
        state.auth.notifications["teams"][teamId]["avatar"] = teamAvatar;
        state.auth.notifications["teams"][teamId]["currency"] = teamCurrency;
        state.auth.notifications["teams"][teamId]["heading1"] = teamHeading1;
        state.auth.notifications["teams"][teamId]["heading2"] = teamHeading2;
        state.auth.notifications["teams"][teamId]["owned"] = owned;
        localStorage.setItem(
          "notifications",
          JSON.stringify(state.auth.notifications)
        );
      }

      axios
        .post(process.env.VUE_APP_HTTP_PROTOCOL +
          "://" +
          process.env.VUE_APP_API_ADDRESS +
          "/api/data/" +
          teamId +
          "/teamNotifications",
          {
            headers: authHeader(),
            limit: 10,
          }
        )
        .then(async (response) => {
          const data = response.data;
          state.auth.notifications["teams"][teamId]["activities"] = [];
          if (data.notifications.length > 0) {
            for (let i = 0; i < data.notifications.length; i++) {
              const notification = data.notifications[i];
              if (state.auth.notifications["teams"][teamId]["activities"].length > 10)
                state.auth.notifications["teams"][teamId]["activities"].shift();
              state.auth.notifications["teams"][teamId]["activities"].push(notification);
            }
          }
          state.auth.notifications["teams"][teamId].loading = false;
          localStorage.setItem(
            "notifications",
            JSON.stringify(state.auth.notifications)
          );
        });

    },
    async ADD_TEAMS(state, teams) {
      state.auth.notifications["personal"] = { "messages": [], "activities": [], loading: true };
      await axios
        .post(process.env.VUE_APP_HTTP_PROTOCOL +
          "://" +
          process.env.VUE_APP_API_ADDRESS +
          "/api" +
          "/data/personalNotifications",
          {
            headers: authHeader(),
            limit: 10,
          }
        )
        .then(async (response) => {
          const data = response.data;
          if (data.notifications.length > 0) {
            for (let i = 0; i < data.notifications.length; i++) {
              const notification = data.notifications[i];
              if (state.auth.notifications["personal"]["activities"].length > 10)
                state.auth.notifications["personal"]["activities"].shift();
              state.auth.notifications["personal"]["activities"].push(notification);
            }
          }
          state.auth.notifications["personal"].loading = false;
          localStorage.setItem(
            "notifications",
            JSON.stringify(state.auth.notifications)
          );
        });
      state.auth.notifications["teams"] = {}
      for (let i = 0; i < teams.length; i++) {
        const teamId = teams[i].id;
        const teamName = teams[i].name;
        const isTeamOwned = teams[i].owned;
        const teamAvatar = teams[i].avatar;
        const teamCurrency = teams[i].currency;
        const teamHeading1 = teams[i].heading1;
        const teamHeading2 = teams[i].heading2;
        console.log("XXX3");
        app.$store.commit("ADD_TEAM", [teamId, teamName, teamAvatar, teamCurrency, teamHeading1, teamHeading2, isTeamOwned]);
      }
    },
    MARK_READ_PERSONAL_NOTIFICATIONS(state) {
      const teamActivities = state.auth.notifications["personal"]["activities"];
      for (const activityIndex in teamActivities) {
        const activityItem = state.auth.notifications["personal"]["activities"][activityIndex];
        if (activityItem.unread)
          activityItem.unread = false;
      }
    },
    MARK_READ_TEAM_ACTIVITY_NOTIFICATIONS(state, teamId) {
      if (teamId != null) {
        const teamActivities = state.auth.notifications["teams"][
          teamId
        ]["activities"];
        for (const activityIndex in teamActivities) {
          const activityItem = state.auth.notifications["teams"][
            teamId
          ]["activities"][activityIndex];
          if (activityItem.unread)
            activityItem.unread = false;
        }
      }
      else {
        for (const notificationTeam in state.auth.notifications["teams"]) {
          const teamActivities = state.auth.notifications["teams"][
            notificationTeam
          ]["activities"];
          for (const activityIndex in teamActivities) {
            const activityItem = state.auth.notifications["teams"][
              notificationTeam
            ]["activities"][activityIndex];
            if (activityItem.unread)
              activityItem.unread = false;
          }
        }
      }
    },
    ADD_NOTIFICATION(state, [teamId, notification]) {
      let arrNotifications = null;
      if (teamId == 0)
        arrNotifications = state.auth.notifications["personal"];
      else
        arrNotifications = state.auth.notifications["teams"][teamId];
      if (arrNotifications) {
        if (arrNotifications["activities"].length > 10)
          arrNotifications["activities"].pop();
        arrNotifications["activities"].unshift(notification);
        if (arrNotifications.loading) // check in caso di errori precedenti
          arrNotifications.loading = false;
        localStorage.setItem(
          "notifications",
          JSON.stringify(state.auth.notifications)
        );
      }
    },
  },
  actions: {
    async ADD_TEAM(state, [teamId, isOwned]): Promise<void> {
      await app.$store.commit("ADD_TEAM", [teamId, isOwned]);
    }
  },
  modules: {
    auth
  }
})
