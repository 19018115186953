



















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import SelectInput from "@/components/inputs/SelectInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: { SelectInput, TextInput },
})
export default class SelectIngredients extends Vue {
  @Prop({ default: "" }) title;
  @Prop({ default: "" }) placeholder;
  @Prop({ default: null }) item;
  @Prop({ default: null }) slotProps;
  @Prop({ default: null }) types;
  @Prop({ default: null }) labels;
  @Prop({ default: false }) skipQty;
  private Helper = Helper;
}
