



































































































































































































































































































import User from "../models/User";
import { Component, Vue } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import axios from "axios";
import HeaderBarShortcut from "@/components/graphic/HeaderBarShortcut.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import Link from "@/components/graphic/Link.vue";
import SelectLanguage from "@/components/inputs/SelectLanguage.vue";
import LoginPanel from "@/components/graphic/LoginPanel.vue";
import Logo from "@/components/graphic/Logo.vue";

const DisplayLogin = {
  login: 0,
  forgotPassword: 1,
  forgotPasswordDone: 2,
  twoFactorAuthentication: 3,
  needHelp: 4,
};

@Component({
  components: {
    HeaderBarShortcut,
    TextInput,
    CheckboxInput,
    ButtonInput,
    Loader,
    Link,
    SelectLanguage,
    LoginPanel,
    Logo,
  },
})
export default class PageLogin extends Vue {
  // data

  private loading = false;
  private user: User = new User("", "", "", "", "");
  private twoFactorAuthenticationSecret = "";
  private twoFactorAuthenticationToken = "";
  private displayLoginForm = DisplayLogin.login;
  private errorsFromServer: Array<string> = [];
  private showPassword = false;
  private verifyUrl =
    this.$route.query.from && this.$route.query.from.includes("verify")
      ? true
      : false;
  private enabledRegistration = null;
  private unknownErrors = false;

  // methods

  get displayLogin(): typeof DisplayLogin {
    return DisplayLogin;
  }

  get translate() {
    return (arg: string) => {
      return this.$t(arg);
    };
  }

  get validEmail(): boolean {
    const regEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (
      this.user.email &&
      this.user.email.length &&
      regEmail.test(this.user.email)
    )
      return true;
    return false;
  }

  get validPassword(): boolean {
    if (this.user.password && this.user.password.length) return true;
    return false;
  }

  get validQRCode() {
    return this.twoFactorAuthenticationSecret.length == 6;
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  login() {
    this.unknownErrors = false;

    this.loading = true;
    this.$store.dispatch("auth/login", this.user).then(
      () => {
        this.loading = false;
        this.$store.commit(
          "ADD_TEAMS",
          this.$store.state.auth.user["all_teams"]
        );

        localStorage.setItem(
          "sweetlab_theme",
          this.$store.state.auth.user["theme"]
        );
        Helper.applyTheme(this.$store.state.auth.user["theme"]);
        if (this.$store.state.auth.user["night_mode"]) {
          localStorage.setItem(
            "sweetlab_night_mode",
            this.$store.state.auth.user["night_mode"]
          );
          Helper.applyNightMode(this.$store.state.auth.user["night_mode"]);
        }
        if (this.$route.query.from && this.$route.query.from != "")
          this.$router.push(this.$route.query.from.toString());
        else {
          this.$router.push({ name: "home" });
        }
      },
      (error) => {
        this.loading = false;
        if (error.response) {
          if (error.response.status === 400) {
            this.twoFactorAuthenticationToken = error.response.data.authToken;
            this.displayLoginForm = DisplayLogin.twoFactorAuthentication;
          }
          if (error.response.status === 429) {
            // Too many rquests
            this.errorsFromServer["email"] = [
              this.$t("i18n_page_too_many_attempts"),
            ];
          } else {
            if (!Helper.isUndefined(error.response.data.errors)) {
              this.errorsFromServer = error.response.data.errors;
            }
          }
        } else this.unknownErrors = true;
      }
    );
  }

  performTwoFactorAuthentication() {
    this.errorsFromServer = [];
    this.loading = true;
    this.unknownErrors = false;
    this.$store
      .dispatch("auth/twoFactorChallenge", {
        twoFactorAuthenticationSecret: this.twoFactorAuthenticationSecret,
        twoFactorAuthenticationToken: this.twoFactorAuthenticationToken,
      })
      .then(
        () => {
          this.loading = false;
          localStorage.setItem(
            "sweetlab_theme",
            this.$store.state.auth.user["theme"]
          );
          Helper.applyTheme(this.$store.state.auth.user["theme"]);
          if (this.$store.state.auth.user["night_mode"]) {
            localStorage.setItem(
              "sweetlab_night_mode",
              this.$store.state.auth.user["night_mode"]
            );
            Helper.applyNightMode(this.$store.state.auth.user["night_mode"]);
          }
          this.$store.commit(
            "ADD_TEAMS",
            this.$store.state.auth.user["all_teams"]
          );
          this.$router.push({ name: "home" });
        },
        (error) => {
          this.loading = false;
          if (error.response) {
            if (error.response.status === 400) {
              this.displayLoginForm = DisplayLogin.twoFactorAuthentication;
            } else {
              if (!Helper.isUndefined(error.response.data.errors))
                this.errorsFromServer = error.response.data.errors;
            }
          } else this.unknownErrors = true;
        }
      );
  }

  forgotPassword() {
    this.errorsFromServer = [];
    this.loading = true;
    this.unknownErrors = false;
    this.$store
      .dispatch("auth/forgotPassword", { email: this.user.email })
      .then(
        () => {
          this.loading = false;
          this.displayLoginForm = DisplayLogin.forgotPasswordDone;
        },
        (error) => {
          this.loading = false;
          if (error.response && !Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
          else this.unknownErrors = true;
        }
      );
  }

  goToForgotPassword() {
    this.displayLoginForm = DisplayLogin.forgotPassword;
  }

  goToRegister() {
    this.$router.push({ name: "register" });
  }

  goToNeedHelp() {
    this.displayLoginForm = DisplayLogin.needHelp;
  }

  goToLogin() {
    this.displayLoginForm = DisplayLogin.login;
  }

  mounted() {
    axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/config/enabledRegistration"
      )
      .then((response) => {
        if (response.data == "0") this.enabledRegistration = false;
        else this.enabledRegistration = true;
      });
  }
}
