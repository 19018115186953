var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pdfData && _vm.template)?_c('div',[(_vm.template[_vm.name + 'Show'])?_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_' + _vm.name + '_section'),"icon":'fa fa-info'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-center"},[_c('div',{staticClass:"cu_border-editor-thumb"})])]},proxy:true},{key:"content",fn:function(){return [(_vm.template[_vm.name + 'Show'])?[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:_vm.name + 'Shape',attrs:{"id":_vm.name + 'Shape',"title":_vm.$t('i18n_page_labels_' + _vm.name + '_shape'),"groupName":_vm.name + 'Shape',"options":['R'],"icons":['far fa-square'],"startingValue":_vm.template[_vm.name + 'Shape']},on:{"input":function($event){return _vm.$emit('update', [$event, _vm.name + 'Shape'])}},model:{value:(_vm.template[_vm.name + 'Shape']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'Shape', $$v)},expression:"template[name + 'Shape']"}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('label-border-editor',{attrs:{"pdfData":_vm.pdfData,"stepFactor":_vm.stepFactor,"stepDecimals":_vm.stepDecimals,"template":_vm.template,"name":_vm.name},on:{"update":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}}})],1),(_vm.template[_vm.name + 'Shape'] == 'R')?_c('text-input',{key:_vm.name + 'ShapeRadius-' + _vm.template.pageUnit,attrs:{"uid":_vm.name + 'ShapeRadius',"inputType":'number',"startingValue":_vm.template[_vm.name + 'ShapeRadius'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":30,"infoLabel":_vm.template.pageUnit,"icon":'icomoon-border-radius',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_shape_radius'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_shape_radius'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'ShapeRadius']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'ShapeRadius', $$v)},expression:"template[name + 'ShapeRadius']"}}):_vm._e(),_c('text-input',{key:_vm.name + 'Width-' + _vm.template.pageUnit,attrs:{"uid":_vm.name + 'Width',"inputType":'number',"infoLabel":_vm.template.pageUnit,"startingValue":_vm.template[_vm.name + 'Width'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.template['labelWidth'] - 2 * _vm.template[_vm.name + 'PaddingLeft'],"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_width'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_width'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'Width']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'Width', $$v)},expression:"template[name + 'Width']"}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_' + _vm.name + '_colors'),"labelColor1":_vm.$t('i18n_page_labels_' + _vm.name + '_bg_color'),"startingColor1":_vm.template[_vm.name + 'BgColor']},on:{"change-color-1":function($event){return _vm.$emit('update', [_vm.name + 'BgColor', $event])}}})],1)],1)],1),_c('text-input',{key:_vm.name + 'Title',attrs:{"inputType":'text',"startingValue":_vm.template[_vm.name + 'Title'],"requiredMessage":_vm.$t('i18n_input_required'),"icon":'fa fa-font',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_text'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_text'),"errors":_vm.errorsFromServer
              ? _vm.errorsFromServer['itemTemplate.' + _vm.name + 'Title']
              : null},on:{"key-press":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'Title']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'Title', $$v)},expression:"template[name + 'Title']"}}),_c('text-input',{key:_vm.name + 'X-' + _vm.template.pageUnit,attrs:{"uid":_vm.name + 'X',"inputType":'number',"infoLabel":_vm.template.pageUnit,"startingValue":_vm.template[_vm.name + 'X'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.template.labelWidth -
            2 * _vm.template.labelPaddingLeft -
            _vm.template[_vm.name + 'Width'],"icon":'icomoon-axis-x',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_x'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_x'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'X']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'X', $$v)},expression:"template[name + 'X']"}}),_c('text-input',{key:_vm.name + 'Y-' + _vm.template.pageUnit,attrs:{"uid":_vm.name + 'Y',"inputType":'number',"infoLabel":_vm.template.pageUnit,"startingValue":_vm.template[_vm.name + 'Y'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.template.labelHeight - 2 * _vm.template.labelPaddingTop,"icon":'icomoon-axis-y',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_y'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_y'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'Y']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'Y', $$v)},expression:"template[name + 'Y']"}}),_c('text-input',{key:_vm.name + 'PaddingTop-' + _vm.template.pageUnit,attrs:{"uid":_vm.name + 'PaddingTop',"inputType":'number',"infoLabel":_vm.template.pageUnit,"startingValue":_vm.template[_vm.name + 'PaddingTop'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-horizontal',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_padding_top'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_padding_top'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'PaddingTop']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'PaddingTop', $$v)},expression:"template[name + 'PaddingTop']"}}),_c('text-input',{key:_vm.name + 'PaddingLeft-' + _vm.template.pageUnit,attrs:{"uid":_vm.name + 'PaddingLeft',"inputType":'number',"infoLabel":_vm.template.pageUnit,"startingValue":_vm.template[_vm.name + 'PaddingLeft'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-vertical',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_padding_left'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_padding_left'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'PaddingLeft']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'PaddingLeft', $$v)},expression:"template[name + 'PaddingLeft']"}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_" + _vm.name + "_title_subsection"))+" ")]),_c('popover-input',{ref:'popover' + _vm.name + 'TitleEditor',attrs:{"title1":_vm.$t('i18n_page_labels_' + _vm.name + '_title_subsection'),"title2":_vm.$t('i18n_page_labels_' + _vm.name + '_section'),"buttonIcon":'fa fa-edit',"buttonClass":'cu_btn-size-md cu_btn-theme-normal'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:_vm.name + 'TitleAlignment',attrs:{"id":_vm.name + 'TitleAlignment',"title":_vm.$t('i18n_page_labels_' + _vm.name + '_alignment'),"groupName":_vm.name + 'TitleAlignment',"options":['L', 'C', 'R'],"icons":[
                    'fas fa-align-left',
                    'fas fa-align-center',
                    'fas fa-align-right' ],"tiny":true,"startingValue":_vm.template[_vm.name + 'TitleAlignment']},on:{"input":function($event){return _vm.$emit('update', [$event, _vm.name + 'TitleAlignment'])}},model:{value:(_vm.template[_vm.name + 'TitleAlignment']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'TitleAlignment', $$v)},expression:"template[name + 'TitleAlignment']"}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_' + _vm.name + '_colors'),"labelColor1":_vm.$t('i18n_page_labels_' + _vm.name + '_font_color'),"startingColor1":_vm.template[_vm.name + 'TitleFontColor']},on:{"change-color-1":function($event){return _vm.$emit('update', [_vm.name + 'TitleFontColor', $event])}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_" + _vm.name + "_text_subsection"))+" ")]),_c('text-input',{key:_vm.name + 'TitleFontSize',attrs:{"inputType":'number',"startingValue":_vm.template[_vm.name + 'TitleFontSize'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_font_size'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_font_size'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
                  var i = arguments.length, argsArray = Array(i);
                  while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
                  var i = arguments.length, argsArray = Array(i);
                  while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'TitleFontSize']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'TitleFontSize', $$v)},expression:"template[name + 'TitleFontSize']"}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:_vm.name + 'TitleFontStyle',attrs:{"id":_vm.name + 'TitleFontStyle',"title":_vm.$t('i18n_page_labels_' + _vm.name + '_font_style'),"groupName":_vm.name + 'TitleFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                      'fas fa-font',
                      'fas fa-bold',
                      'fas fa-italic',
                      'fas fa-underline' ],"startingValue":_vm.template[_vm.name + 'TitleFontStyle']},on:{"input":function($event){return _vm.$emit('update', [$event, _vm.name + 'TitleFontStyle'])}},model:{value:(_vm.template[_vm.name + 'TitleFontStyle']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'TitleFontStyle', $$v)},expression:"template[name + 'TitleFontStyle']"}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":_vm.template[_vm.name + 'TitleFontFamily'],"title":_vm.$t('i18n_page_labels_' + _vm.name + '_font_family')},on:{"selected-font":function($event){return _vm.$emit('update', [$event, _vm.name + 'TitleFontFamily'])}}})],1),_c('text-input',{key:_vm.name + 'TitleRowHeight-' + _vm.template.pageUnit,attrs:{"uid":_vm.name + 'TitleRowHeight',"inputType":'number',"infoLabel":_vm.template.pageUnit,"startingValue":_vm.template[_vm.name + 'TitleRowHeight'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_size'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_size'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
                    var i = arguments.length, argsArray = Array(i);
                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
                    var i = arguments.length, argsArray = Array(i);
                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'TitleRowHeight']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'TitleRowHeight', $$v)},expression:"template[name + 'TitleRowHeight']"}})],1)]},proxy:true}],null,false,3412369702)})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_" + _vm.name + "_text_subsection"))+" ")]),_c('popover-input',{ref:'popover' + _vm.name + 'TextEditor',attrs:{"title1":_vm.$t('i18n_page_labels_' + _vm.name + '_text_subsection'),"title2":_vm.$t('i18n_page_labels_' + _vm.name + '_section'),"buttonIcon":'fa fa-edit',"buttonClass":'cu_btn-size-md cu_btn-theme-normal'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:_vm.name + 'TextAlignment',attrs:{"id":_vm.name + 'TextAlignment',"title":_vm.$t('i18n_page_labels_' + _vm.name + '_alignment'),"groupName":_vm.name + 'TextAlignment',"options":['L', 'C', 'R'],"icons":[
                    'fas fa-align-left',
                    'fas fa-align-center',
                    'fas fa-align-right' ],"tiny":true,"startingValue":_vm.template[_vm.name + 'TextAlignment']},on:{"input":function($event){return _vm.$emit('update', [$event, _vm.name + 'TextAlignment'])}},model:{value:(_vm.template[_vm.name + 'TextAlignment']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'TextAlignment', $$v)},expression:"template[name + 'TextAlignment']"}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_' + _vm.name + '_colors'),"labelColor1":_vm.$t('i18n_page_labels_' + _vm.name + '_font_color'),"startingColor1":_vm.template[_vm.name + 'TextFontColor']},on:{"change-color-1":function($event){return _vm.$emit('update', [_vm.name + 'TextFontColor', $event])}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_" + _vm.name + "_text_subsection"))+" ")]),_c('text-input',{key:_vm.name + 'TextFontSize',attrs:{"inputType":'number',"startingValue":_vm.template[_vm.name + 'TextFontSize'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_font_size'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_font_size'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
                  var i = arguments.length, argsArray = Array(i);
                  while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
                  var i = arguments.length, argsArray = Array(i);
                  while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'TextFontSize']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'TextFontSize', $$v)},expression:"template[name + 'TextFontSize']"}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:_vm.name + 'TextFontStyle',attrs:{"id":_vm.name + 'TextFontStyle',"title":_vm.$t('i18n_page_labels_' + _vm.name + '_font_style'),"groupName":_vm.name + 'TextFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                      'fas fa-font',
                      'fas fa-bold',
                      'fas fa-italic',
                      'fas fa-underline' ],"startingValue":_vm.template[_vm.name + 'TextFontStyle']},on:{"input":function($event){return _vm.$emit('update', [$event, _vm.name + 'TextFontStyle'])}},model:{value:(_vm.template[_vm.name + 'TextFontStyle']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'TextFontStyle', $$v)},expression:"template[name + 'TextFontStyle']"}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":_vm.template[_vm.name + 'TextFontFamily'],"title":_vm.$t('i18n_page_labels_' + _vm.name + '_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(_vm.template, $event, _vm.name + 'TextFontFamily')}}})],1),_c('text-input',{key:_vm.name + 'TextRowHeight-' + _vm.template.pageUnit,attrs:{"uid":_vm.name + 'TextRowHeight',"inputType":'number',"infoLabel":_vm.template.pageUnit,"startingValue":_vm.template[_vm.name + 'TextRowHeight'],"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_' + _vm.name + '_size'),"placeholder":_vm.$t('i18n_page_labels_' + _vm.name + '_size'),"errors":_vm.errorsFromServer},on:{"key-up":function($event){
                    var i = arguments.length, argsArray = Array(i);
                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))},"step-pressed":function($event){
                    var i = arguments.length, argsArray = Array(i);
                    while ( i-- ) argsArray[i] = arguments[i];
return _vm.$emit('update', [].concat( argsArray ))}},model:{value:(_vm.template[_vm.name + 'TextRowHeight']),callback:function ($$v) {_vm.$set(_vm.template, _vm.name + 'TextRowHeight', $$v)},expression:"template[name + 'TextRowHeight']"}})],1)]},proxy:true}],null,false,4054346637)})],1)]:_vm._e()]},proxy:true}],null,false,27400685)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }