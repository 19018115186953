






























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: {
    Loader,
    ButtonInput,
    TextInput,
  },
})

export default class UserName extends Vue {
  private value = '';
  @Prop({ default: false }) private startingValue;
  private isNameValidated = true;
  private errorsFromServer: Array<string> = [];
  @Prop({ default: true }) private displayUndo;

  UpdateUserName() {
    this.$emit("update", this.value);
  }

  async created() {
    if (this.startingValue) {
      this.value = this.startingValue;
    }
  }
}
