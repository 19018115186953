import { render, staticRenderFns } from "./CheckboxInput.vue?vue&type=template&id=487383f1&scoped=true&"
import script from "./CheckboxInput.vue?vue&type=script&lang=ts&"
export * from "./CheckboxInput.vue?vue&type=script&lang=ts&"
import style0 from "./CheckboxInput.vue?vue&type=style&index=0&id=487383f1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487383f1",
  null
  
)

export default component.exports