import AuthService from '../services/auth-service'
import { Helper } from "@/utils/Helper";

const user = JSON.parse(localStorage.getItem('user') || '{}')
let notifications = { personal: { "messages": [], "activities": [], loading: true }, teams: {} };
try {
  if (localStorage.getItem('notifications') != null && localStorage.getItem('notifications') != "{}")
    notifications = JSON.parse(localStorage.getItem('notifications'))
} catch (e) {
  console.log(e);
}
let shoppingCart = {};
try {
  if (localStorage.getItem('shoppingCart') != null && localStorage.getItem('shoppingCart') != "{}")
    shoppingCart = JSON.parse(localStorage.getItem('shoppingCart'))
} catch (e) {
  console.log(e);
}
const isLogged = (Object.keys(user).length ? true : false)
const initialState = user
  ? { status: { loggedIn: isLogged }, user, notifications, shoppingCart } // user, notifications, shoppingCart aggiunge la chiave automaticamente
  : { status: { loggedIn: isLogged }, user: null, notifications: null, shoppingCart: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    forgotPassword({ commit }, user) {
      return AuthService.forgotPassword(user).then(
        (user) => {
          commit('forgotPasswordSuccess', user)
          return Promise.resolve(user)
        },
        (error) => {
          commit('forgotPasswordFailure')
          return Promise.reject(error)
        }
      )
    },
    resetPassword({ commit }, user) {
      return AuthService.resetPassword(user).then(
        (user) => {
          commit('resetPasswordSuccess', user)
          return Promise.resolve(user)
        },
        (error) => {
          commit('resetPasswordFailure')
          return Promise.reject(error)
        }
      )
    },
    resendVerify() {
      return AuthService.resendVerify().then(
        (response) => {
          return Promise.resolve(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    verify({ commit }, user) {
      return AuthService.verify(user).then(
        (user) => {
          commit('verifySuccess', user)
          return Promise.resolve(user)
        },
        (error) => {
          commit('verifyFailure', user)
          return Promise.reject(error)
        }
      )
    },
    changeEmail({ commit }, newEmail) {
      return AuthService.changeEmail(newEmail).then(
        () => {
          commit('changeEmailSuccess', newEmail)
          return Promise.resolve(user)
        },
        (error) => {
          commit('changeEmailFailure')
          return Promise.reject(error)
        }
      )
    },
    twoFactorChallenge({ commit }, user) {
      return AuthService.twoFactorChallenge(user).then(
        (user) => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        (error) => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
      localStorage.removeItem('user')
    },
    verifySuccess(state) {
      state.user["verified"] = true
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    verifyFailure(state) {
      state.user["verified"] = null
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    changeEmailSuccess(state, emailData) {
      state.user["email"] = emailData.email;
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    changeEmailFailure(state) {
      state.status.loggedIn = false
    },
    forgotPasswordSuccess(state) {
      state.status.loggedIn = false
    },
    forgotPasswordFailure(state) {
      state.status.loggedIn = false
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    }
  }
}
