var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.slotProps && _vm.item && _vm.item.elements)?_c('select-input',{attrs:{"multiple":true,"dataUrl":_vm.$apiHttpProtocol +
      '://' +
      _vm.$apiAddress +
      '/api/data/' +
      _vm.Helper.currentTeam() +
      '/ingredients/list',"startingValue":_vm.item.elements,"selectedItems":_vm.item.elements,"title":_vm.title,"placeholder":_vm.placeholder,"types":_vm.types,"labels":_vm.labels,"optionClass":'cu_page-items-item-type'},scopedSlots:_vm._u([{key:"available-option",fn:function(slotProps){return [_c('div',{staticClass:"st_flex-row st_flex-align-left"},[_c('div',[_vm._v(" "+_vm._s(slotProps.scope.props.option.name)+" ")]),_c('div',{staticStyle:{"min-width":"160px","display":"flex"}},[_c('div',{class:'cu_page-items-item-type' +
              ' ' +
              slotProps.scope.props.option.type},[_vm._v(" "+_vm._s(slotProps.scope.props.option.type)+" ")])])])]}},{key:"selected-option",fn:function(slotPropsOption){return [_c('div',{staticClass:"multiselect__tags-wrap"},[_c('div',{staticClass:"multiselect__tag",staticStyle:{"display":"table","width":"500px","margin":"5px auto","overflow":"visible"}},[_c('div',{staticClass:"st_flex-column"},[_c('div',{staticClass:"st_flex-row st_flex-align-left"},[_c('div',{staticStyle:{"align-self":"center","flex-grow":"1","text-overflow":"ellipsis","min-width":"200px"}},[_vm._v(" "+_vm._s(slotPropsOption.scope.option.name)+" ")]),_c('div',{staticStyle:{"min-width":"160px","display":"flex","flex-direction":"row-reverse"}},[_c('div',{class:'cu_page-items-item-type' +
                    ' ' +
                    slotPropsOption.scope.option.type},[_vm._v(" "+_vm._s(slotPropsOption.scope.option.type)+" ")])]),_c('div',{staticClass:"cu_page-items-item-qty"},[_c('text-input',{key:'itemName' + slotPropsOption.scope.option.id,attrs:{"size":'sm',"inputType":'number',"startingValue":_vm.Helper.toLocaleString(
                      slotPropsOption.scope.option.quantity,
                      1
                    ),"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":0.1,"maxNumber":_vm.qtyToCheck > 0
                      ? _vm.qtyToCheck -
                        _vm.item.qtyElements() +
                        (slotPropsOption.scope.option.quantity
                          ? _vm.Helper.toFloat(
                              slotPropsOption.scope.option.quantity
                            )
                          : 0)
                      : 20000,"icon":'fa fa-sort-amount-up',"label":_vm.$t('i18n_page_items_quantity'),"placeholder":_vm.$t('i18n_page_items_modify_quantity'),"errors":_vm.slotProps.scope.errorsFromServer
                      ? _vm.slotProps.scope.errorsFromServer[
                          'itemElements.' +
                            _vm.item.items.indexOf(slotPropsOption.scope.option) +
                            '.quantity'
                        ]
                      : null,"displayErrorsMessages":false},on:{"enter-pressed":function($event){return _vm.hidePopover(
                      'popoverRef' + slotPropsOption.scope.option.id
                    )}},model:{value:(slotPropsOption.scope.option.quantity),callback:function ($$v) {_vm.$set(slotPropsOption.scope.option, "quantity", $$v)},expression:"slotPropsOption.scope.option.quantity"}})],1),_c('div',{staticStyle:{"position":"relative","display":"flex"}},[(slotPropsOption.scope.option.subIngredient != null)?_c('div',{staticClass:"st_flex-row"},[_c('div',{staticStyle:{"min-width":"160px","display":"flex","flex-direction":"row-reverse"}},[_c('div',{staticClass:"cu_page-items-item-sub"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_items_modify_part"))+" "+_vm._s(slotPropsOption.scope.option.subIngredient.name)+" ")])]),_c('div',{staticClass:"cu_page-items-item-qty"},[_c('text-input',{key:'itemName' +
                        slotPropsOption.scope.option.subIngredient.id,attrs:{"size":'sm',"inputType":'number',"startingValue":slotPropsOption.scope.option.subIngredient.quantity,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":0.1,"maxNumber":slotPropsOption.scope.option.quantity,"icon":'fa fa-sort-amount-up',"label":_vm.$t('i18n_page_items_quantity'),"placeholder":_vm.$t('i18n_page_items_modify_quantity'),"displayErrorsMessages":false},on:{"enter-pressed":function($event){return _vm.hidePopover(
                          'popoverRef2' +
                            slotPropsOption.scope.option.subIngredient.id
                        )}},model:{value:(
                        slotPropsOption.scope.option.subIngredient.quantity
                      ),callback:function ($$v) {_vm.$set(slotPropsOption.scope.option.subIngredient, "quantity", $$v)},expression:"\n                        slotPropsOption.scope.option.subIngredient.quantity\n                      "}})],1)]):_c('div',{staticClass:"cu_page-items-item-qty-empty"},[_vm._v("---")])]),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hiddem":"true","tabindex":"1"},on:{"click":function($event){return slotPropsOption.scopeFunc.remove(
                    slotPropsOption.scope.option
                  )}}})])])])])]}},{key:"footer",fn:function(){return [(
          _vm.item.noQty() ||
          (_vm.item.qtyElements() > 0 &&
            _vm.item.qtyElements() < _vm.qtyToCheck &&
            _vm.qtyToCheck > 0)
        )?_c('div',{staticClass:"st_flex-row st_flex-align-horizontal-center"},[_c('div',{staticClass:"st_flex-grow-1 st_text-align-left"},[_c('div',{staticClass:"st_error-notice"},[(_vm.item.noQty())?_c('div',[_vm._v(" "+_vm._s(_vm.$t("i18n_page_items_quantity_error"))+" ")]):_vm._e(),(_vm.item.qtyElements() > 0 && _vm.item.qtyElements() < _vm.qtyToCheck)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("i18n_page_items_quantity_error_elements", { missing: _vm.Helper.toLocaleString(100 - _vm.item.qtyElements(), 1), }))+" ")]):_vm._e()])])]):_vm._e()]},proxy:true}],null,false,3728203424)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }