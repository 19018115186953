<template>
  <transition name="fade">
    <div class="cu_popup" v-if="isVisible">
      <div class="cu_popup-panel">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalPopup",

  data: () => ({
    isVisible: false,
  }),

  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.cu_popup {
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 999;
}

.cu_popup-panel {
  margin: 15% auto; /* 15% from the top and centered */
  max-width: 80%;
  min-width: 300px;
  width: auto; /* Could be more or less, depending on screen size */
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #acacac;
  overflow: auto;
  -webkit-box-shadow: rgb(0 0 0 / 20%) 0 4px 16px;
  -webkit-box-shadow: rgb(0 0 0 / 20%) 0 4px 16px;
  box-shadow: rgb(0 0 0 / 20%) 0 4px 16px;
  -webkit-transition: top 0.5s ease-in-out;
  -webkit-transition: top 0.5s ease-in-out;
  transition: top 0.5s ease-in-out;
  padding: 20px;
}
</style>