










































































































































































































































































































































import User from "../models/User";
import { Component, Vue } from "vue-property-decorator";
import Logo from "@/components/graphic/Logo.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: { Logo },
})
export default class PageCookiePolicy extends Vue {
  currentUser() {
    return this.$store.state.auth.user;
  }

  currentLanguage() {
    if (localStorage.getItem("sweetlab_language")) {
      return localStorage.getItem("sweetlab_language");
    } else if (
      this.$store.state.auth.user &&
      this.$store.state.auth.user.language
    ) {
      return this.$store.state.auth.user.language || "it";
    } else if (localStorage.getItem("sweetlab_language_from_login")) {
      return localStorage.getItem("sweetlab_language_from_login");
    } else return "it";
  }
}
