



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import GroupRadioInput from "@/components/inputs/GroupRadioInput.vue";
import ModalInput from "@/components/inputs/ModalInput.vue";
import Modal from "@/components/inputs/Modal.vue";
import Loader from "@/components/graphic/Loader.vue";
import TeamDropzone from "@/components/custom/TeamDropzone.vue";
import TeamMembers from "@/components/custom/TeamMembers.vue";
import TeamActivityNotifications from "@/components/custom/TeamActivityNotifications.vue";
import Member from "@/models/Member";
import InviteMember from "@/models/InviteMember";
import EnableFeature from "@/components/custom/EnableFeature.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import { Helper } from "@/utils/Helper";
import { EventBus } from "@/models/EventBus";
import { dragscroll } from "vue-dragscroll";
//import helpers from '@/plugins/helpers';
//import { removeObject, teamAvatar } from '@/plugins/helpers'

const DisplayTeams = {
  listTeams: 0,
  editTeam: 1,
  viewTeam: 2,
  createTeam: 3,
  createdTeam: 4,
  deletedTeam: 5,
  leavedTeam: 6,
};

@Component({
  components: {
    TextInput,
    ButtonInput,
    GroupRadioInput,
    ModalInput,
    Loader,
    TeamDropzone,
    TeamMembers,
    TeamActivityNotifications,
    EnableFeature,
    UserDetail,
    Modal,
  },
  directives: {
    dragscroll,
  },
})
export default class Teams extends Vue {
  // data()

  private Helper: Helper = Helper;
  private licenseStatus = -1;
  private loadingCreateButton = false;
  private loadingMembers = false;
  private loadingInvitations = false;
  private loadingSendInvitation = false;
  private loadingUpdatingTeamName = false;
  private loadingUpdatingTeamHeading1 = false;
  private loadingUpdatingTeamHeading2 = false;
  private loadingButton = 0;
  private displayTeamsForm = DisplayTeams.listTeams;
  private expired = false;
  private verified = false;
  private messageFromServer = "";
  private errorsFromServer: Array<string> = [];
  private ownedTeams2: Array<any> = [];
  private acceptedTeams2: Array<any> = [];
  private members: Array<Member> = [];
  private invitations: Array<InviteMember> = [];
  private teamName = "";
  private user = this.$store.state.auth.user; //localStorage.getItem('user');//this.$store.state.auth.user;
  private isNameValidated = true;
  private editTeam = null; //: Record<string, any> = new Object();
  private teamMember = "";
  private openModal = 0;
  private registry = null;

  // methods

  currentUser() {
    return this.$store.state.auth.user;
  }

  currentTeam() {
    return this.$store.state.auth.user.currentTeam || null;
  }

  get DisplayTeams(): typeof DisplayTeams {
    return DisplayTeams;
  }

  get editingTeam() {
    if (this.editTeam && this.editTeam.id)
      return this.$store.state.auth.notifications["teams"][this.editTeam.id];
    else
      return {
        name: "My Team",
        avatar: null,
        currency: "€",
        owned: true,
      };
  }

  get acceptedTeams() {
    const teamsIds = Object.keys(this.$store.state.auth.notifications["teams"]);
    const arrAcceptedTeams = [];
    for (let i = 0; i < teamsIds.length; i++) {
      const keyTeam = teamsIds[i];

      const owned =
        this.$store.state.auth.notifications["teams"][keyTeam]["owned"];
      const currency =
        this.$store.state.auth.notifications["teams"][keyTeam]["currency"];
      if (!owned) {
        arrAcceptedTeams.push({
          id: keyTeam,
          tick: this.$store.state.auth.notifications["teams"][keyTeam]["tick"],
          name: this.$store.state.auth.notifications["teams"][keyTeam]["name"],
          currency: currency,
          owned: owned,
          avatar:
            this.$store.state.auth.notifications["teams"][keyTeam]["avatar"],
          value: keyTeam,
          index: 0,
        });
      }
    }
    return arrAcceptedTeams;
  }

  get ownedTeams() {
    const teamsIds = Object.keys(this.$store.state.auth.notifications["teams"]);
    const arrOwnedTeams = [];
    for (let i = 0; i < teamsIds.length; i++) {
      const keyTeam = teamsIds[i];

      const owned =
        this.$store.state.auth.notifications["teams"][keyTeam]["owned"];
      const currency =
        this.$store.state.auth.notifications["teams"][keyTeam]["currency"];

      if (owned) {
        arrOwnedTeams.push({
          id: keyTeam,
          tick: this.$store.state.auth.notifications["teams"][keyTeam]["tick"],
          name: this.$store.state.auth.notifications["teams"][keyTeam]["name"],
          currency: currency,
          owned: owned,
          avatar:
            this.$store.state.auth.notifications["teams"][keyTeam]["avatar"],
          value: keyTeam,
          index: 0,
        });
      }
    }
    return arrOwnedTeams;
  }

  switchTeam(teamId) {
    this.loadingButton = teamId;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/switch/" +
          teamId
      )
      .then((response) => {
        this.loadingButton = 0;
        this.$store.state.auth.user.currentTeam = teamId;
        localStorage.removeItem("user");
        localStorage.setItem(
          "user",
          JSON.stringify(this.$store.state.auth.user)
        );
        this.$store.state.items = [];
      });
  }

  deleteTeam(teamId) {
    this.loadingButton = teamId;
    axios
      .delete(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          teamId
      )
      .then((response) => {
        this.loadingButton = 0;
        this.displayTeamsForm = DisplayTeams.deletedTeam;
        this.$store.commit("REMOVE_TEAM", teamId);
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_teams_delete_success").toString(),
          duration: 20000,
        });
      });
  }

  closeModal() {
    this.openModal = 0;
  }

  updateTeamName() {
    const team = this.editingTeam;
    this.loadingUpdatingTeamName = true;
    axios
      .put(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          this.editTeam.id +
          "/name",
        {
          name: team["name"],
        }
      )
      .then(
        (response) => {
          /*this.$set(
            this.$store.state.auth.user.currentTeam,
            "name",
            team["name"]
          );
          this.$store.state.auth.notifications["teams"][this.editTeam.id][
            "name"
          ] = team["name"];*/
          this.$set(
            this.$store.state.auth.notifications["teams"][this.editTeam.id],
            "name",
            team["name"]
          );
          localStorage.setItem(
            "notifications",
            JSON.stringify(this.$store.state.auth.notifications)
          );
          localStorage.removeItem("user");
          localStorage.setItem(
            "user",
            JSON.stringify(this.$store.state.auth.user)
          );
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_update_team_name_success"
            ).toString(),
            text:
              this.$t(
                "i18n_component_teams_update_team_name_success_info"
              ).toString() + team.name,
            duration: 20000,
          });
          this.closeModal();
        },
        (error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_update_team_name_error"
            ).toString(),
            type: "error",
            duration: 20000,
          });
        }
      )
      .finally(() => {
        this.loadingUpdatingTeamName = false;
      });
  }

  updateTeamHeading1() {
    const team = this.editingTeam;
    this.loadingUpdatingTeamHeading1 = true;
    axios
      .put(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          this.editTeam.id +
          "/heading1",
        {
          heading1: team["heading1"],
        }
      )
      .then(
        (response) => {
          this.$set(
            this.$store.state.auth.notifications["teams"][this.editTeam.id],
            "heading1",
            team["heading1"]
          );
          localStorage.setItem(
            "notifications",
            JSON.stringify(this.$store.state.auth.notifications)
          );
          localStorage.removeItem("user");
          localStorage.setItem(
            "user",
            JSON.stringify(this.$store.state.auth.user)
          );
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_update_team_heading1_success"
            ).toString(),
            text:
              this.$t(
                "i18n_component_teams_update_team_heading1_success_info"
              ).toString() + team.heading1,
            duration: 20000,
          });
          this.closeModal();
        },
        (error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_update_team_heading1_error"
            ).toString(),
            type: "error",
            duration: 20000,
          });
        }
      )
      .finally(() => {
        this.loadingUpdatingTeamHeading1 = false;
      });
  }

  updateTeamHeading2() {
    const team = this.editingTeam;
    this.loadingUpdatingTeamHeading2 = true;
    axios
      .put(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          this.editTeam.id +
          "/heading2",
        {
          heading2: team["heading2"],
        }
      )
      .then(
        (response) => {
          this.$set(
            this.$store.state.auth.notifications["teams"][this.editTeam.id],
            "heading2",
            team["heading2"]
          );
          localStorage.setItem(
            "notifications",
            JSON.stringify(this.$store.state.auth.notifications)
          );
          localStorage.removeItem("user");
          localStorage.setItem(
            "user",
            JSON.stringify(this.$store.state.auth.user)
          );
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_update_team_heading2_success"
            ).toString(),
            text:
              this.$t(
                "i18n_component_teams_update_team_heading2_success_info"
              ).toString() + team.heading2,
            duration: 20000,
          });
          this.closeModal();
        },
        (error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_update_team_heading2_error"
            ).toString(),
            type: "error",
            duration: 20000,
          });
        }
      )
      .finally(() => {
        this.loadingUpdatingTeamHeading2 = false;
      });
  }

  updateTeamCurrency() {
    const team = this.editingTeam;
    axios
      .put(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          this.editTeam.id +
          "/currency",
        {
          currency: team.currency,
        }
      )
      .then(
        (response) => {
          /*this.$set(
            this.$store.state.auth.user.currentTeam,
            "currency",
            team.currency
          );
          this.$store.state.auth.notifications["teams"][this.editTeam.id][
            "currency"
          ] = team.currency;*/
          this.$set(
            this.$store.state.auth.notifications["teams"][this.editTeam.id],
            "currency",
            team["currency"]
          );
          localStorage.setItem(
            "notifications",
            JSON.stringify(this.$store.state.auth.notifications)
          );
          localStorage.removeItem("user");
          localStorage.setItem(
            "user",
            JSON.stringify(this.$store.state.auth.user)
          );
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_update_team_currency_success"
            ).toString(),
            text:
              this.$t(
                "i18n_component_teams_update_team_currency_success_info"
              ).toString() + team.currency,
            duration: 20000,
          });
          this.closeModal();
        },
        (error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_update_team_currency_error"
            ).toString(),
            type: "error",
            duration: 20000,
          });
        }
      );
  }

  updateTeamAvatar(avatar) {
    //this.$set(this.$store.state.auth.user.currentTeam, "avatar", avatar);
    //this.editTeam.avatar = avatar;
    /*this.$store.state.auth.notifications["teams"][this.editTeam.id]["avatar"] =
      avatar;*/
    this.$set(
      this.$store.state.auth.notifications["teams"][this.editTeam.id],
      "avatar",
      avatar
    );
    localStorage.setItem(
      "notifications",
      JSON.stringify(this.$store.state.auth.notifications)
    );
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(this.$store.state.auth.user));
  }

  listMembers(teamId) {
    this.loadingMembers = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          teamId +
          "/members"
      )
      .then(
        (response) => {
          this.members = response.data.map((member) => {
            let role = "owner";
            if (member.membership) role = member.membership.role;
            return new Member(
              member.id,
              member.name,
              member.email,
              Helper.userAvatar(member.profile_photo_path),
              role,
              member.created_at
            );
          });
          this.loadingMembers = false;
        },
        (error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      );
  }

  listInvitations(teamId) {
    this.loadingInvitations = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          teamId +
          "/invitations"
      )
      .then(
        (response) => {
          this.invitations = response.data.map((member) => {
            let role = "?";
            if (member.role) role = member.role;
            return new InviteMember(
              member.id,
              member.email,
              role,
              member.created_at
            );
          });
          this.loadingInvitations = false;
        },
        (error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      );
  }

  async getInvitation(teamId, email): Promise<InviteMember | null> {
    let invitation: InviteMember | null = null;
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          teamId +
          "/invitations/" +
          email
      )
      .then(
        (response) => {
          invitation = new InviteMember(
            response.data.id,
            response.data.email,
            response.data.role,
            response.data.created_at
          );
        },
        (error) => {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      );
    return invitation;
  }

  inviteMemberToTeam(teamId, emailMember) {
    this.loadingSendInvitation = true;
    this.errorsFromServer = [];
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          teamId +
          "/member/invite",
        {
          email: emailMember,
          role: "editor",
        }
      )
      .then(
        async (response) => {
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_add_member_success"
            ).toString(),
            text:
              this.$t(
                "i18n_component_teams_add_member_success_info"
              ).toString() + emailMember,
          });
          this.messageFromServer = response.data;
          const invitation = await this.getInvitation(teamId, emailMember);

          if (invitation !== null) {
            this.invitations.push(invitation);
          }
        },
        (error) => {
          this.$notify({
            group: "notifications",
            title: this.$t("i18n_component_teams_add_member_error").toString(),
            type: "error",
          });
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      )
      .finally(() => {
        this.loadingSendInvitation = false;
      });
  }

  leaveTeam(teamId) {
    const memberId = this.currentUser()["id"];
    axios
      .delete(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          teamId +
          "/member/" +
          memberId
      )
      .then(
        (response) => {
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_remove_member_success"
            ).toString(),
          });
          this.messageFromServer = response.data;
          Helper.removeObject(this.acceptedTeams, teamId);
          this.$store.commit("REMOVE_TEAM", teamId);
          this.displayTeamsForm = DisplayTeams.leavedTeam;
        },
        (error) => {
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_remove_member_error"
            ).toString(),
            type: "error",
          });
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      );
  }

  removeMemberFromTeam(teamId, memberEmail, memberId) {
    axios
      .delete(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          teamId +
          "/member/" +
          memberId
      )
      .then(
        (response) => {
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_remove_member_success"
            ).toString(),
            text:
              this.$t(
                "i18n_component_teams_remove_member_success_info"
              ).toString() + memberEmail,
          });
          this.messageFromServer = response.data;
          Helper.removeObject(this.members, memberId);
        },
        (error) => {
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_remove_member_error"
            ).toString(),
            type: "error",
          });
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      );
  }

  removeInvitationFromTeam(teamId, memberEmail, memberId) {
    axios
      .delete(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          teamId +
          "/invitation/" +
          memberId
      )
      .then(
        (response) => {
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_remove_invite_success"
            ).toString(),
            text:
              this.$t(
                "i18n_component_teams_remove_invite_success_info"
              ).toString() + memberEmail,
          });
          this.messageFromServer = response.data;
          Helper.removeObject(this.invitations, memberId);
        },
        (error) => {
          this.$notify({
            group: "notifications",
            title: this.$t(
              "i18n_component_teams_remove_invite_error"
            ).toString(),
            type: "error",
          });
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      );
  }

  createTeam(teamId) {
    const team = this.editingTeam;
    this.loadingCreateButton = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/create",
        {
          teamName: team.name,
        }
      )
      .then(
        (response) => {
          const createdTeam = response.data;
          this.$notify({
            group: "notifications",
            title: this.$t("i18n_component_teams_create_success").toString(),
          });
          //this.ownedTeams.push(createdTeam);
          this.editTeam.id = createdTeam.id;
          this.editTeam.name = createdTeam.name;

          this.loadingCreateButton = false;
          this.displayTeamsForm = DisplayTeams.createdTeam;
          //[teamId, teamName, teamAvatar, teamCurrency, owned]
          this.$store.commit("ADD_TEAM", [
            createdTeam["id"],
            createdTeam["name"],
            null,
            createdTeam["currency"],
            createdTeam["heading1"],
            createdTeam["heading2"],
            true,
          ]);
          //this.listTeams(null);
        },
        (error) => {
          this.$notify({
            group: "notifications",
            title: this.$t("i18n_component_teams_create_error").toString(),
            type: "error",
          });
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      );
  }

  goToTeams() {
    this.displayTeamsForm = DisplayTeams.listTeams;
  }

  goToEditTeam(team) {
    this.editTeam = { id: team.id, name: team.name };
    this.displayTeamsForm = DisplayTeams.editTeam;
    this.listMembers(team.id);
    this.listInvitations(team.id);
  }

  goToViewTeam(team) {
    this.editTeam = { id: team.id, name: team.name };
    this.displayTeamsForm = DisplayTeams.viewTeam;
    this.listMembers(team.id);
  }

  goToNewTeam() {
    this.editTeam = { id: 0, name: "" };
    this.displayTeamsForm = DisplayTeams.createTeam;
  }

  refreshTeam(teamId) {
    if (this.$store.state.auth.notifications["teams"][parseInt(teamId)]) {
      this.$store.state.auth.notifications["teams"][parseInt(teamId)].tick += 1;
    }
  }

  mounted() {
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/licensing/" +
          this.currentUser().id +
          "/licenseStatus",
        {
          orderPurchaseDescription: ["cooperative-pack", "full-pack"],
          currentTeam: null,
          headers: authHeader(),
        }
      )
      .then((response) => {
        this.licenseStatus = response.data.licenseStatus;
      })
      .catch((error) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_app_license_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });

    this.registry = EventBus.getInstance().register(
      "refresh-page",
      (notification: any) => {
        if (notification.data.type == "team") {
          /*console.log(
            "list Received message from Ably: teamId: " +
              notification.data.sectionId +
              " senderId:" +
              notification.data.senderId +
              " receiverId:" +
              notification.data.receiverId +
              " ids:" +
              notification.data.id +
              " action:" +
              notification.data.action +
              " type:" +
              notification.data.type
          );*/
          const teamModified =
            notification.data.prop && notification.data.teamId
              ? notification.data.teamId
              : null;
          if (
            this.displayTeamsForm == DisplayTeams.editTeam ||
            this.displayTeamsForm == DisplayTeams.viewTeam
          ) {
            if (
              notification.data.action == "joined_team" ||
              notification.data.action == "invited_team"
            ) {
              this.listInvitations(this.editTeam.id);
              this.listMembers(this.editTeam.id);
            } else if (notification.data.action == "invited_team") {
              this.listInvitations(this.editTeam.id);
            } else if (
              notification.data.action == "abandoned_team" ||
              notification.data.action == "deleted_member"
            ) {
              this.listMembers(this.editTeam.id);
            }
          } else {
            this.refreshTeam(
              notification.data.teamId || notification.data.sectionId
            );
          }
        }
      }
    );
  }
}
