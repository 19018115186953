
















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";
import TimeFormatter from "@/components/graphic/TimeFormatter.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import IngredientDetail from "@/components/custom/IngredientDetail.vue";
import LabelDetail from "@/components/custom/LabelDetail.vue";
import TabPanel from "@/components/graphic/TabPanel.vue";
import { Helper } from "@/utils/Helper";
import { uuid } from "vue-uuid";

@Component({
  components: {
    Loader,
    ButtonInput,
    UserDetail,
    IngredientDetail,
    LabelDetail,
    TabPanel,
    TimeFormatter,
  },
})
export default class TeamActivityNotifications extends Vue {
  private Helper: Helper = Helper;
  private UUID = uuid;
  @Prop({ default: "md" }) size;
  @Prop({ default: true }) displayTitle;
  @Prop({ default: false }) displayUnread;
  @Prop({ default: true }) displayMyNotifications;
  @Prop({ default: "" }) private teamId: string;
  @Prop({ default: 5 }) private limit;
  private loading = false;
  private error = false;

  get notifications() {
    if (
      this.$store.state.auth.notifications &&
      this.$store.state.auth.notifications["teams"][this.teamId] &&
      this.$store.state.auth.notifications["teams"][this.teamId].loading ==
        false
    ) {
      return this.$store.state.auth.notifications["teams"][this.teamId][
        "activities"
      ].filter((activity) => {
        return (
          this.displayMyNotifications == true ||
          activity.senderId != this.currentUser().id
        );
      });
    }
    return null;
  }

  currentUser() {
    return this.$store.state.auth.user || null;
  }
}
