
















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import Loader from "@/components/graphic/Loader.vue";
import News from "@/models/News";
import { Helper } from "@/utils/Helper";
import TabPanel from "@/components/graphic/TabPanel.vue";
import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import axios from "axios";

@Component({
  components: { Loader, TabPanel },
})
export default class LatestNews extends Vue {
  private apiVersion = "";
  private loading = false;
  private error = false;
  private latestNews = null;
  private Helper = Helper;

  currentLanguage() {
    if (localStorage.getItem("sweetlab_language")) {
      return localStorage.getItem("sweetlab_language");
    } else if (
      this.$store.state.auth.user &&
      this.$store.state.auth.user.language
    ) {
      return this.$store.state.auth.user.language || "it";
    } else if (localStorage.getItem("sweetlab_language_from_login")) {
      return localStorage.getItem("sweetlab_language_from_login");
    }
  }

  async getNews(): Promise<any | null> {
    let infos: any | null = null;
    const ret = [];
    await axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/advertisements/latestNews",
        {
          //headers: authHeader(),
        }
      )
      .then((response) => {
        infos = response.data;
        for (let i = 0; i < infos.length; i++) {
          const item = infos[i];
          ret.push(
            new News(
              item.title_it,
              item.title_en,
              item.body_it,
              item.body_en,
              item.image,
              item.text_color,
              item.start_date
            )
          );
        }
      })
      .catch((error) => {
        this.error = true;
      })
      .finally(() => (this.loading = false));
    return ret;
  }

  async mounted() {
    this.loading = true;
    this.latestNews = await this.getNews();
    if (this.latestNews && this.latestNews.length) {
      setTimeout(() => {
        const splide = new Splide(".splide", {
          type: "loop",
          perPage: 1,
          autoplay: true,
          interval: 20000, // How long to display each slide
          pauseOnHover: false, // must be false
          pauseOnFocus: false, // must be false
          resetProgress: false,
        });

        splide.on("active", function (Slide) {
          const baseSpeed = 40;
          const baseSlideWidth = 100;

          const slideWidth =
            Slide.slide.getBoundingClientRect().width / baseSlideWidth;
          const slideSpeed = baseSpeed * slideWidth;

          splide.options.speed = slideSpeed;
        });
        splide.mount();
      }, 250);
    }
  }
}
