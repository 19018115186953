
















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";
import TimeFormatter from "@/components/graphic/TimeFormatter.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import LicenseDetail from "@/components/custom/LicenseDetail.vue";
import InvoiceDetail from "@/components/custom/InvoiceDetail.vue";
import TeamDetail from "@/components/custom/TeamDetail.vue";
import TabPanel from "@/components/graphic/TabPanel.vue";
import { Helper } from "@/utils/Helper";
import { uuid } from "vue-uuid";
import axios from "axios";
import { authHeader } from "@/services/auth-header";

@Component({
  components: {
    Loader,
    ButtonInput,
    UserDetail,
    LicenseDetail,
    InvoiceDetail,
    TeamDetail,
    TabPanel,
    TimeFormatter,
  },
})
export default class PersonalNotification extends Vue {
  private Helper: Helper = Helper;
  private UUID = uuid;
  @Prop({ default: false }) displayUnread;
  @Prop({ default: null }) notification;
  private userName = null;

  notificationIcon(iconType) {
    if (iconType == "invoice") return "fa fa-scroll";
    else if (iconType == "license") return "fa fa-award";
    else if (iconType == "team") return "fas fa-users";
    else if (iconType == "payment") return "fas fa-coins";
    else if (iconType == "user") return "fas fa-user";
    else return "fa fa-star";
  }

  currentUser() {
    return this.$store.state.auth.user || null;
  }

  async getUserName(userId): Promise<any | null> {
    let name = null;
    if (userId)
      name = axios
        .post(
          Vue.prototype.$apiHttpProtocol +
            "://" +
            Vue.prototype.$apiAddress +
            "/api/users/" +
            userId +
            "/userInfo",
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.userName = response.data[0]["name"];
        })
        .catch((error) => {
          this.userName = "-";
        });
    else this.userName = "-";
  }

  async created() {
    await this.getUserName(this.notification.receiverId);
  }
}
