

















import { Component, Vue, Prop } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
@Component({
  components: {
    ButtonInput,
  },
})
export default class ButtonLogout extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: false }) private tiny;

  logout() {
    this.$router.push({ name: "login" });
    this.$store.dispatch("auth/logout").then(
      () => {
        this.$store.commit("DEL_ALL_ITEMS");
        localStorage.removeItem("user");
        localStorage.removeItem("notifications");
        localStorage.removeItem("shoppingCart");
        localStorage.removeItem("sweetlab_theme");
        localStorage.removeItem("sweetlab_night_mode");
      },
      (error) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_btn_logout").toString(),
          type: "error",
          duration: 20000,
        });
      }
    );
  }
}
