<template>
  <div style="position: relative">
    <label
      :for="id + '_button'"
      :class="[
        {
          active: isActive && !disabled,
          activedisabled: isActive && disabled,
        },
        toggleClass,
      ]"
    >
      <input
        type="checkbox"
        :disabled="disabled"
        :id="id + '_button'"
        :key="id + '_button'"
        v-model="checkedValue"
      />
      <span
        :class="
          'cu_toggle-switch' +
          (labels ? '-label' : '') +
          (disabled ? ' disabled' : '')
        "
      >
        <span v-if="isActive && labels" class="toggle__label">{{
          enableText
        }}</span>
        <span v-if="!isActive && labels" class="toggle__label">{{
          disabledText
        }}</span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    labelEnableText: {
      type: String,
      default: "On",
    },

    labelDisableText: {
      type: String,
      default: "Off",
    },

    toggleClass: {
      type: String,
      default: "cu_toggle-button-xs",
    },

    id: {
      type: String,
      default: "primary",
    },

    defaultState: {
      type: Boolean,
      default: false,
    },

    labels: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentState: this.defaultState,
    };
  },

  watch: {
    defaultState: function defaultState() {
      this.currentState = Boolean(this.defaultState);
    },
  },

  computed: {
    // currentState() {
    //     return this.defaultState;
    // },

    isActive() {
      return this.currentState;
    },

    enableText() {
      return this.labelEnableText;
    },

    disabledText() {
      return this.labelDisableText;
    },

    checkedValue: {
      get() {
        return this.currentState;
      },

      set(newValue) {
        this.currentState = newValue;
        this.$emit("toggled", newValue);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.cu_toggle-button-xs,
.cu_toggle-button-sm {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.cu_toggle-button-xs input[type="checkbox"],
.cu_toggle-button-sm input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.cu_toggle-button-xs .cu_toggle-switch,
.cu_toggle-button-sm .cu_toggle-switch {
  display: inline-block;
  height: 24px;
  border-radius: 100em;
  width: 50px;
  color: var(--main-theme-btn-font);
  font-weight: 900;
  background: var(--main-theme-3rd);
  box-shadow: inset 0 0 1px var(--main-theme-3rd);
  position: relative;
  transition: all 0.25s;
  line-height: 24px;
}

.cu_toggle-button-xs .cu_toggle-switch::after,
.cu_toggle-button-xs .cu_toggle-switch::before,
.cu_toggle-button-sm .cu_toggle-switch::after,
.cu_toggle-button-sm .cu_toggle-switch::before {
  content: "";
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transform: translateX(0);
  transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}

.cu_toggle-button-xs .cu_toggle-switch::after,
.cu_toggle-button-sm .cu_toggle-switch::after {
  background: var(--main-theme-default);
  box-shadow: 0 0 1px var(--main-theme-border);
  &.disabled {
    background: var(--main-theme-3rd);
  }
}
.cu_toggle-button-xs .cu_toggle-switch::before,
.cu_toggle-button-sm .cu_toggle-switch::before {
  background: var(--main-theme-default);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.active .cu_toggle-switch {
  background: var(--main-theme-1st);
  box-shadow: inset 0 0 1px var(--main-theme-1st);
}

.active .cu_toggle-switch::after,
.active .cu_toggle-switch::before {
  transform: translateX(50px - 24px);
}

.active .cu_toggle-switch::after {
  /*left: 23px;*/
  background: var(--main-theme-1st-hover);
  box-shadow: 0 0 1px var(--main-theme-1st-hover);
}

.activedisabled .cu_toggle-switch {
  background: var(--main-theme-4th);
  box-shadow: inset 0 0 1px var(--main-theme-4th);
}

.activedisabled .cu_toggle-switch::after,
.activedisabled .cu_toggle-switch::before {
  transform: translateX(50px - 24px);
}

.activedisabled .cu_toggle-switch::after {
  /*left: 23px;*/
  background: var(--main-theme-3rd);
  box-shadow: 0 0 1px var(--main-theme-3rd);
}

.cu_toggle-button-xs .cu_toggle-switch-label {
  display: inline-block;
  height: 24px;
  border-radius: 100em;
  width: 80px;
  color: var(--main-theme-btn-font);
  font-weight: 900;
  background: var(--main-theme-3rd);
  box-shadow: inset 0 0 1px var(--main-theme-3rd);
  position: relative;
  transition: all 0.25s;
  //padding: 0px 20px 0px 30px;
  line-height: 24px;
  text-align: right;
  padding-left: 0px;
  padding-right: 10px;
}

.cu_toggle-button-sm .cu_toggle-switch-label {
  display: inline-block;
  height: 24px;
  border-radius: 100em;
  width: 80px;
  color: var(--main-theme-btn-font);
  font-weight: 900;
  background: var(--main-theme-3rd);
  box-shadow: inset 0 0 1px var(--main-theme-3rd);
  position: relative;
  transition: all 0.25s;
  //padding: 0px 20px 0px 30px;
  line-height: 24px;
  text-align: right;
  padding-left: 0px;
  padding-right: 10px;
  font-size: 11px;
}

.cu_toggle-button-xs .cu_toggle-switch-label::after,
.cu_toggle-button-xs .cu_toggle-switch-label::before,
.cu_toggle-button-sm .cu_toggle-switch-label::after,
.cu_toggle-button-sm .cu_toggle-switch-label::before {
  content: "";
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transform: translateX(0);
  transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}

.cu_toggle-button-xs .cu_toggle-switch-label::after,
.cu_toggle-button-sm .cu_toggle-switch-label::after {
  background: var(--main-theme-default);
  box-shadow: 0 0 1px var(--main-theme-border);
}
.cu_toggle-button-xs .cu_toggle-switch-label::before,
.cu_toggle-button-sm .cu_toggle-switch-label::before {
  background: var(--main-theme-default);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.active .cu_toggle-switch-label {
  background: var(--main-theme-1st);
  box-shadow: inset 0 0 1px var(--main-theme-1st);
  text-align: left;
  padding-left: 10px;
  padding-right: 0px;
}

.active .cu_toggle-switch-label::after,
.active .cu_toggle-switch-label::before {
  transform: translateX(90px - 24px);
}

.active .cu_toggle-switch-label::after {
  /*left: 23px;*/
  background: var(--main-theme-1st-hover);
  box-shadow: 0 0 1px var(--main-theme-1st-hover);
  &.disabled {
    background: var(--main-theme-3rd);
  }
}
</style>