




import { Component, Prop, Vue } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";

@Component({
  components: {},
})
export default class TimeFormatter extends Vue {
  @Prop({ default: null }) private timeEpoch;
  private Helper: Helper = Helper;
  private elapsedStr = "---";
  private timer = null;
  private dateStr = "---";

  get formatted() {
    return this.elapsedStr;
  }

  epochToDate(ts) {
    const tsMs = ts * 1000;
    const dateObj = new Date(tsMs);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const hours = ("0" + dateObj.getHours()).slice(-2);
    const minutes = ("0" + dateObj.getMinutes()).slice(-2);
    const seconds = ("0" + dateObj.getSeconds()).slice(-2);
    return (
      day +
      "/" +
      month +
      "/" +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  }

  calcMinutesElapsed() {
    const now = Helper.epoch();
    const minutes = Math.floor((now - this.timeEpoch) / 60);
    return minutes;
  }

  calcHoursElapsed() {
    const now = Helper.epoch();
    const hours = Math.floor((now - this.timeEpoch) / 3600);
    return hours;
  }

  async displayElapsed() {
    const minutes = this.calcMinutesElapsed();
    let waitElapsed = 0;

    if (minutes <= 0) {
      this.elapsedStr = this.$t("i18n_component_time_formatter_now").toString();
      waitElapsed = 60000;
    } else if (minutes > 0 && minutes < 60) {
      this.elapsedStr =
        this.$tc("i18n_component_time_formatter_min", minutes).toString() +
        " " +
        this.$t("i18n_component_time_formatter_ago").toString();
      waitElapsed = 60000;
    } else if (minutes >= 60 && minutes < 60 * 24) {
      const hours = this.calcHoursElapsed();
      this.elapsedStr =
        this.$tc("i18n_component_time_formatter_hrs", hours).toString() +
        " " +
        this.$t("i18n_component_time_formatter_ago").toString();
      waitElapsed = 3600000;
    } else this.elapsedStr = this.dateStr;

    if (waitElapsed) {
      this.timer = setTimeout(() => {
        this.displayElapsed();
      }, waitElapsed);
    }
  }

  async created() {
    this.dateStr = this.epochToDate(this.timeEpoch);
    this.displayElapsed();
  }

  async destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
