//import { Vue } from 'vue-property-decorator';
export default class Singleton {
    public static instance: Singleton;

    /*constructor() {
        //super()
        console.log("Singleton");
    }*/

    public static getInstance(): Singleton {
        if (!Singleton.instance) {
            Singleton.instance = new Singleton();
        }

        return Singleton.instance;
    }
}