



















import { Component, Vue, Prop } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";

@Component({
  components: {
    ButtonInput,
    Loader,
  },
})
export default class Panel extends Vue {
  @Prop({ default: true }) private tiny;
  @Prop({ default: false }) private isShown!: boolean;
}
