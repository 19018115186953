





































import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: { ButtonInput },
})
export default class PopoverInput extends Vue {
  @Prop({ default: 1 }) private type;
  @Prop({ default: true }) private showPopover;
  @Prop({ default: false }) private title1;
  @Prop({ default: false }) private title2;
  @Prop({ default: "popoverInput" }) private popoverRef;
  @Prop({ default: "" }) private buttonTooltip;
  @Prop({ default: null }) private buttonIcon;
  @Prop({ default: null }) private buttonLabel;
  @Prop({ default: null }) private buttonClass;
  private doPopover = false;

  hidePopover() {
    (this.$refs[this.popoverRef] as any).hide();
  }

  showPopoverEvent() {
    this.doPopover = true;
  }
}
