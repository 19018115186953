












import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import ToggleButton from "@/components/inputs/ToggleButton.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    ToggleButton,
  },
})
export default class SelectNightMode extends Vue {
  private Helper: Helper = Helper;
  private loading = false;

  currentUser() {
    return this.$store.state.auth.user || null;
  }

  toggleNightMode(toggleValue) {
    this.loading = true;
    const mode = Helper.booleanToInt(toggleValue);
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/users/" +
          this.currentUser().id +
          "/nightMode/" +
          mode
      )
      .then((response) => {
        this.loading = false;
        const user = JSON.parse(localStorage.getItem("user") || "");
        user["night_mode"] = mode;
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(user));
        this.$store.state.auth.user["night_mode"] = mode;
        localStorage.setItem("sweetlab_night_mode", mode.toString());
        Helper.applyNightMode(mode);
      })
      .catch((error) => {
        this.loading = false;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_night_mode_update_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }
}
