

































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PageItems from "@/components/PageItems.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import LastModified from "@/components/custom/LastModified.vue";
import IsUsedIn from "@/components/custom/IsUsedIn.vue";
import SelectIngredients from "@/components/custom/SelectIngredients.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    PageItems,
    TextInput,
    ButtonInput,
    PopoverInput,
    SelectInput,
    LastModified,
    IsUsedIn,
    SelectIngredients,
  },
})
export default class PageSemifinishedIndustry extends Vue {
  private Helper = Helper;
  private user = this.$store.state.auth.user;
  private errorsFromServer: Array<string> = [];
  currentTeam() {
    return this.user.currentTeam || null;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  hidePopover(popoverId) {
    let popoverEl = null;
    if (Array.isArray(this.$refs[popoverId]))
      //in v-for
      popoverEl = this.$refs[popoverId][0];
    else popoverEl = this.$refs[popoverId];
    popoverEl.hidePopover();
  }
}
