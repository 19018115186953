
import axios from "axios";
import { authHeader } from "@/services/auth-header";
import UserInfo from './UserInfo'

export default class Payment {
    type: string
    id: string
    name: string
    licenseId: Array<string>
    loading: boolean
    error: boolean
    discovered: boolean
    hidden: boolean
    licensesDet: Array<any>
    paymentId: string
    orderCreationTime: string
    userId: string
    orderId: string
    invoiceId: string
    orderPayeeEmail: string
    orderPayeeMerchantId: string
    orderPayerEmail: string
    orderPayerId: string
    orderPayerName: string
    orderPayerUser?: UserInfo | null
    orderPurchaseCurrency: string
    orderPurchaseSubTotal: string
    orderPurchaseTaxes: string
    orderPurchaseTotal: string
    orderPurchaseDescription: string
    orderStatus: string
    constructor(id, discovered) {
        this.type = "payment"
        this.id = id
        this.loading = true
        this.error = false
        this.discovered = discovered
        this.name = ""
        this.licenseId = []
        this.hidden = false
        this.licensesDet = [];
        this.paymentId = "";
        this.orderCreationTime = "";
        this.userId = "";
        this.orderId = "";
        this.invoiceId = "";
        this.orderPayeeEmail = "";
        this.orderPayeeMerchantId = "";
        this.orderPayerEmail = "";
        this.orderPayerId = "";
        this.orderPayerName = "";
        this.orderPurchaseCurrency = "";
        this.orderPurchaseSubTotal = "";
        this.orderPurchaseTaxes = "";
        this.orderPurchaseTotal = "";
        this.orderPurchaseDescription = "";
        this.orderStatus = "";
        if (this.id != "")
            axios
                .post(process.env.VUE_APP_HTTP_PROTOCOL +
                    "://" +
                    process.env.VUE_APP_API_ADDRESS +
                    "/api/licensing/" +
                    "payment/" +
                    this.id,
                    {
                        headers: authHeader(),
                    }
                )
                .then((response) => {
                    const data = response.data;
                    this.licensesDet = [];
                    this.name = "Pmt N." + data.payment_id;
                    this.paymentId = data.payment_id;
                    this.orderCreationTime = data.order_creation_time;
                    this.userId = data.user_id;
                    this.orderId = data.order_id;
                    this.invoiceId = data.invoice_id;
                    if (data.license_id != null)
                        this.licenseId = data.license_id;
                    this.orderPayeeEmail = data.order_payee_email;
                    this.orderPayeeMerchantId = data.order_payee_merchant_id;
                    this.orderPayerEmail = data.order_payer_email;
                    this.orderPayerId = data.order_payer_id;
                    this.orderPayerName = data.order_payer_name;
                    this.orderPurchaseCurrency = data.order_purchase_currency;
                    this.orderPurchaseSubTotal = data.order_purchase_subtotal;
                    this.orderPurchaseTaxes = data.order_purchase_tax;
                    this.orderPurchaseTotal = data.order_purchase_total;
                    this.orderPurchaseDescription = JSON.parse(data.order_purchase_description);
                    this.orderStatus = data.order_status;
                    if (data.license_id)
                        this.getLicense()
                })
                .catch((error) => {
                    this.error = true;
                })
                .finally(() => (this.loading = false));
        this.loading = false;
    }

    attrs() {
        return {
            itemId: this.id,
            itemName: this.name,
            itemTemplate: {
            },
            headers: authHeader(),
        }
    }

    isValid() {
        return true;
    }

    public hasValidLicense(): boolean {
        return this.licenseId.length > 0;
    }

    public getLicense() {
        axios
            .post(process.env.VUE_APP_HTTP_PROTOCOL + "://" +
                process.env.VUE_APP_API_ADDRESS +
                "/api/licensing/" + this.userId + "/getLicense", {
                licenseId: this.licenseId,
                headers: authHeader(),
            })
            .then((response) => {
                const licenseIds = response.data.licenseId;
                this.licensesDet = licenseIds;
            })
            .catch((error) => {
                this.error = true;
            });
    }
}