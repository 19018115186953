import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import VueCookie from 'vue-cookie'
import PagePersonal from '../components/PagePersonal.vue'
import PageMain from '../components/PageMain.vue'
import PageHome from '../components/PageHome.vue'
import PageLogin from '../components/PageLogin.vue'
import PageTerms from '../components/PageTerms.vue'
import PagePrivacyPolicy from '../components/PagePrivacyPolicy.vue'
import PageCookiePolicy from '../components/PageCookiePolicy.vue'
import PageRegister from '../components/PageRegister.vue'
import PageVerify from '../components/PageVerify.vue'
import PageChangeEmail from '../components/PageChangeEmail.vue'
import PageResetPassword from '../components/PageResetPassword.vue'
import PageNotFound from '../components/PageNotFound.vue'
import PageProfile from '../components/PageProfile.vue'
import PageTeams from '../components/PageTeams.vue'
import PageElements from '../components/PageElements.vue'
import PageIngredients from '../components/PageIngredients.vue'
import PageSemifinishedIndustry from '../components/PageSemifinishedIndustry.vue'
import PagePreparations from '../components/PagePreparations.vue'
import PageSemifinished from '../components/PageSemifinished.vue'
import PageFinished from '../components/PageFinished.vue'
import PageTeamInvitation from '../components/PageTeamInvitation.vue'
import PageFeatures from '../components/PageFeatures.vue'
import PagePayments from '../components/PagePayments.vue'
import PageLabels from '../components/PageLabels.vue'
import PageNews from '../components/PageNews.vue'
import PageUsers from '../components/PageUsers.vue'

Vue.use(VueCookie)
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'notfound',
    component: PageNotFound,
    meta: {
      menu: false
    }
  },
  {
    path: '/',
    name: 'main',
    component: PageMain,
    meta: {
      menu: false
    }
  },
  {
    path: '/home2',
    name: 'home2',
    component: PagePersonal,
    meta: {
      menu: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: PageHome,
    meta: {
      menu: true
    }
  },
  {
    path: '/#',
    name: '#',
    component: PageHome,
    meta: {
      menu: true
    }
  },
  {
    path: '/elements',
    name: 'elements',
    component: PageElements,
    meta: {
      menu: true
    }
  },
  {
    path: '/ingredients',
    name: 'ingredients',
    component: PageIngredients,
    meta: {
      menu: true
    }
  },
  {
    path: '/semifinished-industry',
    name: 'semifinished-industry',
    component: PageSemifinishedIndustry,
    meta: {
      menu: true
    }
  },
  {
    path: '/preparations',
    name: 'preparations',
    component: PagePreparations,
    meta: {
      menu: true
    }
  },
  {
    path: '/semifinished',
    name: 'semifinished',
    component: PageSemifinished,
    meta: {
      menu: true
    }
  },
  {
    path: '/finished',
    name: 'finished',
    component: PageFinished,
    meta: {
      menu: true
    }
  },
  {
    path: '/post/:id',
    name: 'post',
    component: PageIngredients,
    props: true,
    meta: {
      menu: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: PageRegister,
    meta: {
      menu: false
    }
  },
  {
    path: '/verify/:id/:hash',
    name: 'verify',
    component: PageVerify,
    meta: {
      menu: false
    }
  },
  {
    path: '/changeEmail/:email',
    name: 'changeEmail',
    component: PageChangeEmail,
    meta: {
      menu: false
    }
  },
  {
    path: '/team-invitations/:id',
    name: 'team-invitation',
    component: PageTeamInvitation,
    meta: {
      menu: false
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: PageResetPassword,
    meta: {
      menu: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: PageLogin,
    meta: {
      menu: false
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: PageTerms,
    meta: {
      menu: false
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PagePrivacyPolicy,
    meta: {
      menu: false
    }
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: PageCookiePolicy,
    meta: {
      menu: false
    }
  },
  {
    path: '/features',
    name: 'features',
    component: PageFeatures,
    meta: {
      menu: true
    }
  },
  {
    path: '/payments',
    name: 'payments',
    component: PagePayments,
    meta: {
      menu: true
    }
  },
  {
    path: '/labels',
    name: 'labels',
    component: PageLabels,
    meta: {
      menu: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: PageUsers,
    meta: {
      menu: true
    }
  },
  {
    path: '/teams',
    name: 'teams',
    component: PageTeams,
    meta: {
      menu: true
    }
  },
  {
    path: '/news',
    name: 'news',
    component: PageNews,
    meta: {
      menu: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: PageProfile,
    meta: {
      menu: true
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      menu: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
