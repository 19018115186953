
























import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import { Helper } from "@/utils/Helper";
import Member from "@/models/Member";

@Component({
  components: {
    Loader,
    UserDetail,
  },
})
export default class TeamMembers extends Vue {
  @Prop({ default: null }) private teamId;
  @Prop({ default: true }) private displayOnline;
  @Prop({ default: true }) private displayTooltip;
  @Prop({ default: 30 }) private avatarSize;
  @Prop({ default: -7.5 }) private avatarMargin;
  @Prop({ default: "md" }) private size;
  private loadingTeamMembers = false;
  private members: Array<Member> = [];
  private errorsFromServer: Array<string> = [];

  listMembers() {
    this.loadingTeamMembers = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/teams/" +
          this.teamId +
          "/members"
      )
      .then(
        (response) => {
          this.members = response.data.map((member) => {
            let role = "owner";
            if (member.membership) role = member.membership.role;
            return new Member(
              member.id,
              member.name,
              member.email,
              Helper.userAvatar(member.profile_photo_path),
              role,
              member.created_at
            );
          });
          this.loadingTeamMembers = false;
        },
        (error) => {
          this.loadingTeamMembers = false;
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
      );
  }

  async created() {
    this.loadingTeamMembers = true;
    this.listMembers();
  }
}
