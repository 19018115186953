var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_c('label',{class:[
      {
        active: _vm.isActive && !_vm.disabled,
        activedisabled: _vm.isActive && _vm.disabled,
      },
      _vm.toggleClass ],attrs:{"for":_vm.id + '_button'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedValue),expression:"checkedValue"}],key:_vm.id + '_button',attrs:{"type":"checkbox","disabled":_vm.disabled,"id":_vm.id + '_button'},domProps:{"checked":Array.isArray(_vm.checkedValue)?_vm._i(_vm.checkedValue,null)>-1:(_vm.checkedValue)},on:{"change":function($event){var $$a=_vm.checkedValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedValue=$$c}}}}),_c('span',{class:'cu_toggle-switch' +
        (_vm.labels ? '-label' : '') +
        (_vm.disabled ? ' disabled' : '')},[(_vm.isActive && _vm.labels)?_c('span',{staticClass:"toggle__label"},[_vm._v(_vm._s(_vm.enableText))]):_vm._e(),(!_vm.isActive && _vm.labels)?_c('span',{staticClass:"toggle__label"},[_vm._v(_vm._s(_vm.disabledText))]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }