










































import { Component, Vue } from "vue-property-decorator";
import "@/css/style.scss";
import NotificationMessage from "@/components/graphic/NotificationMessage.vue";
import ErrorMessage from "@/components/graphic/ErrorMessage.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import ButtonLogout from "@/components/inputs/ButtonLogout.vue";
import LoginPanel from "@/components/graphic/LoginPanel.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    ButtonInput,
    NotificationMessage,
    ErrorMessage,
    ButtonLogout,
    LoginPanel,
  },
})
export default class PageNotVerified extends Vue {
  // data()

  private loading = false;
  private currentUser = this.$store.state.auth.user;
  private loggedIn = this.$store.state.auth.status.loggedIn;
  private messageFromServer = "";
  private errorsFromServer: Array<string> = [];

  hideNotificationMessage() {
    this.messageFromServer = "";
  }

  hideErrorMessage() {
    this.errorsFromServer = [];
  }

  resendVerifyEmail() {
    this.errorsFromServer = [];
    this.loading = true;
    this.$store.dispatch("auth/resendVerify").then(
      () => {
        this.loading = false;
        this.messageFromServer = "ok";
      },
      (error) => {
        this.loading = false;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
      }
    );
  }

  logout() {
    this.$store.dispatch("auth/logout").then(
      () => {
        this.$router.push({ name: "login" });
      },
      (error) => {
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
      }
    );
  }
}
