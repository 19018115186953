

































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CheckboxInput extends Vue {
  @Prop({ default: false }) private checkValue;
  @Prop({ default: false }) private startingValue;
  @Prop({ default: "" }) private tooltip;
  @Prop({ default: "md" })
  private size: string;
  @Prop({ default: "check" })
  private type: string;
  @Prop() private label!: string;
  @Prop() private icon!: string;
  @Prop() private isDisabled!: boolean;
  private value = false;

  @Watch("value") change() {
    if (!this.isDisabled) {
      this.$emit("input", this.checkBoxValue);
    }
  }

  @Watch("checkValue") check() {
    if (!this.isDisabled && this.checkValue != this.value) {
      this.checkBoxValue = this.checkValue;
    }
  }

  get checkBoxValue(): boolean {
    return this.value;
  }

  set checkBoxValue(value: boolean) {
    this.value = value;
  }

  get isLabeledClass(): string {
    const labelClass = "labeled";
    return this.label ? labelClass : "";
  }

  async created() {
    if (this.startingValue) {
      this.value = this.startingValue;
    }
  }
}
