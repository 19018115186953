var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":'modalNewsDropzone'}},[_c('modal',{attrs:{"isShown":_vm.openModal},on:{"close":function($event){_vm.openModal = false},"save":function($event){return _vm.$refs.newsDropzone.saveUpload()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("i18n_component_newsdropzone_avatar"))+" n. "+_vm._s(_vm.newsId)+" ")]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-right"},[_c('div',{staticClass:"st_width-100"},[(_vm.isLoading)?[_c('loader')]:[_c('Dropzone',{ref:"newsDropzone",attrs:{"title":_vm.$t('i18n_component_newsdropzone_avatar_info'),"icon":'fa fa-pencil',"addUrl":_vm.$apiHttpProtocol +
                  '://' +
                  _vm.$apiAddress +
                  '/api/advertisements/news/' +
                  _vm.newsId +
                  '/uploadImage',"removeUrl":_vm.$apiHttpProtocol +
                  '://' +
                  _vm.$apiAddress +
                  '/api/advertisements/news/' +
                  _vm.newsId +
                  '/removeImage',"uploadedUrl":_vm.$apiHttpProtocol +
                  '://' +
                  _vm.$apiAddress +
                  '/api/advertisements/news/' +
                  _vm.newsId +
                  '/uploadedImage',"startingValue":_vm.newsAvatar
                    ? _vm.$apiHttpProtocol +
                      '://' +
                      _vm.$apiAddress +
                      '/images/profile-photos/news/' +
                      _vm.newsAvatar
                    : null},on:{"uploaded":_vm.uploadedNewsImage}})]],2)])]},proxy:true}])})],1),_c('button-input',{attrs:{"tooltip":_vm.$t('i18n_page_news_image'),"icon":'fa fa-picture-o',"buttonClass":'cu_btn-circle cu_btn-theme-transparent-circle-bordered st_margin-left-10px'},on:{"btn-click":function($event){_vm.openModal = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }