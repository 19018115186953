










import { Prop, Vue, Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Link extends Vue {
  // props
  @Prop({ default: false }) private inline;
  @Prop({ default: "link" }) private label;
  @Prop({ default: "" }) private address;
  @Prop({ default: "" }) private icon;
  @Prop({ default: "cu_link" }) private linkClass;
}
