















































































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Helper } from "@/utils/Helper";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import UserDropzone from "@/components/custom/UserDropzone.vue";
import UserEmail from "@/components/custom/UserEmail.vue";
import UserName from "@/components/custom/UserName.vue";
import UserPassword from "@/components/custom/UserPassword.vue";
import BillingSummary from "@/components/custom/BillingSummary.vue";
import UserBilling from "@/components/custom/UserBilling.vue";
import TwoFactorAuth from "@/components/custom/TwoFactorAuth.vue";
import SubNavBar from "@/components/graphic/SubNavBar.vue";
import SelectTheme from "@/components/inputs/SelectTheme.vue";
import SelectLanguage from "@/components/inputs/SelectLanguage.vue";
import SelectNightMode from "@/components/inputs/SelectNightMode.vue";
import ToggleButton from "@/components/inputs/ToggleButton.vue";
import PageResetPassword from "@/components/PageResetPassword.vue";
import ButtonLogout from "@/components/inputs/ButtonLogout.vue";

const DisplayProfile = {
  mainPage: 0,
  subPageUser: 1,
  subPageEmail: 2,
  subPagePassword: 3,
  subPage2FA: 4,
  subPageLanguage: 5,
  subPageNightMode: 6,
  subPageTheme: 7,
  subPageBilling: 8,
};

@Component({
  components: {
    ButtonInput,
    TextInput,
    Loader,
    TwoFactorAuth,
    SubNavBar,
    UserDropzone,
    SelectTheme,
    SelectLanguage,
    SelectNightMode,
    ToggleButton,
    UserEmail,
    UserName,
    UserPassword,
    UserBilling,
    BillingSummary,
    PageResetPassword,
    ButtonLogout,
  },
})
export default class PageProfile extends Vue {
  private Helper: Helper = Helper;
  private loading = true;
  private expired = false;
  private displayProfileForm = DisplayProfile.mainPage;
  private verified = false;
  private messageFromServer = "";
  private errorsFromServer: Array<string> = [];
  private editName = "";
  private user = this.$store.state.auth.user; //localStorage.getItem('user');//this.$store.state.auth.user;
  private subNavbarItems = [
    {
      description: "i18n_page_profile_overview",
      value: DisplayProfile.mainPage,
      index: 0,
    },
    {
      description: "i18n_page_profile_username",
      value: DisplayProfile.subPageUser,
      index: 0,
    },
    {
      description: "i18n_page_profile_useremail",
      value: DisplayProfile.subPageEmail,
      index: 0,
    },
    {
      description: "i18n_page_profile_userpassword",
      value: DisplayProfile.subPagePassword,
      index: 0,
    },
    {
      description: "i18n_page_profile_2fa",
      value: DisplayProfile.subPage2FA,
      index: 0,
    },
    {
      description: "i18n_page_profile_language",
      value: DisplayProfile.subPageLanguage,
      index: 0,
    },
    {
      description: "i18n_page_profile_billing",
      value: DisplayProfile.subPageBilling,
      index: 0,
    },
  ];
  private subNavbarItem = DisplayProfile.mainPage;

  // methods

  get DisplayProfile(): typeof DisplayProfile {
    return DisplayProfile;
  }

  goToProfileSubpage(page) {
    this.displayProfileForm = page;
    this.subNavbarItem = page;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  currentTeamName() {
    return (
      this.$store.state.auth.notifications["teams"][
        this.$store.state.auth.user.currentTeam
      ].name || null
    );
  }

  enabledTwoFactor() {
    return this.currentUser()["two_factor_authentication_enabled"];
  }

  updateUserAvatar(avatar) {
    const user = JSON.parse(localStorage.getItem("user") || "");
    user["profile_photo_path"] = avatar;
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(user));
    this.$set(this.$store.state.auth.user, "profile_photo_path", avatar);
  }

  currentLanguage() {
    if (localStorage.getItem("sweetlab_language")) {
      return localStorage.getItem("sweetlab_language");
    } else if (
      this.$store.state.auth.user &&
      this.$store.state.auth.user.language
    ) {
      return this.$store.state.auth.user.language || "it";
    } else if (localStorage.getItem("sweetlab_language_from_login")) {
      return localStorage.getItem("sweetlab_language_from_login");
    }
  }

  updateUserName(userName) {
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/users/" +
          this.currentUser().id +
          "/userName",
        { name: userName }
      )
      .then(() => {
        this.loading = false;
        const user = JSON.parse(localStorage.getItem("user") || "");
        user["username"] = userName;
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(user));
        this.$store.state.auth.user.username = userName;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_profile_username_success").toString(),
          duration: 20000,
        });
      })
      .catch((error) => {
        this.loading = false;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_profile_username_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }

  updateUserEmail() {
    console.log("updateUserEmail done");
  }

  updateUserPassword() {
    console.log("updateUserPassword done");
  }
}
