









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import TextInput from "@/components/inputs/TextInput.vue";

@Component({
  components: {
    TextInput,
  },
})
export default class DatePickerInput extends Vue {
  @Prop()
  private day!: Date;
  @Prop({ default: "" }) label;
  @Prop({ default: "" }) placeholder;
  @Prop({ default: "dd/MM/yyyy" }) format;
  @Prop({ default: null }) errorIdentifier;
  @Prop({ default: "1970-01-01" }) minDate;
  @Prop({ default: "2099-01-01" }) maxDate;
  private visibility = "hidden";
  private value = new Date();
  private Helper: Helper = Helper;

  get date(): Date {
    return this.day;
  }

  set date(val) {
    this.value = val;
  }

  dayClick(day) {
    console.log(
      "daiClick????" + day + "/" + Helper.calcDateStr(day, this.format)
    );
    this.$emit("day-click", Helper.calcDateStr(day, this.format));
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  currentLanguage() {
    if (localStorage.getItem("sweetlab_language")) {
      return localStorage.getItem("sweetlab_language");
    } else if (
      this.$store.state.auth.user &&
      this.$store.state.auth.user.language
    ) {
      return this.$store.state.auth.user.language || "it";
    } else if (localStorage.getItem("sweetlab_language_from_login")) {
      return localStorage.getItem("sweetlab_language_from_login");
    }
  }
}
