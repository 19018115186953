
import axios from "axios";
import { authHeader } from "@/services/auth-header";

export default class UserClient {
    type: string
    id: string
    name: string
    email: string
    role: string
    licenseId: Array<string>
    loading: boolean
    error: boolean
    discovered: boolean
    hidden: boolean
    isOnline: boolean
    isEnabled: boolean
    constructor(id, discovered) {
        this.type = "user"
        this.id = id
        this.loading = true
        this.error = false
        this.discovered = discovered
        this.name = ""
        this.email = ""
        this.role = ""
        this.licenseId = []
        this.hidden = false
        this.isOnline = false
        this.isEnabled = true;
        if (this.id != "")
            axios
                .post(process.env.VUE_APP_HTTP_PROTOCOL +
                    "://" +
                    process.env.VUE_APP_API_ADDRESS +
                    "/api/" +
                    "user/" +
                    this.id,
                    {
                        headers: authHeader(),
                    }
                )
                .then((response) => {
                    const data = response.data;
                    this.name = data.name;
                    this.email = data.email;
                    this.role = data.role;
                    this.isOnline = data.isOnline;
                    this.isEnabled = data.isEnabled;
                })
                .catch((error) => {
                    this.error = true;
                })
                .finally(() => (this.loading = false));
        this.loading = false;
    }

    attrs() {
        return {
            itemId: this.id,
            itemName: this.name,
            itemEmail: this.email,
            itemRole: this.role,
            itemEnabled: this.isEnabled,
            headers: authHeader(),
        }
    }

    isValid() {
        return true;
    }

    public hasValidLicense(): boolean {
        return this.licenseId.length > 0;
    }
}