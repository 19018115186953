





































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: { ButtonInput },
})
export default class TextInput extends Vue {
  @Prop({ default: "input-default" }) private inputClass!: string;
  @Prop({ default: "md" }) private size!: string;
  @Prop({ default: 1 }) private numDecimals: any;
  @Prop({ default: 0 }) private minNumber: number;
  @Prop({ default: 20000 }) private maxNumber: number;
  @Prop({ default: 1.0 }) private stepNumber: any;
  @Prop({ default: false }) private displayMax;
  @Prop({ default: true }) private steppers;
  @Prop({ default: true }) private setMinMax;
  @Prop({ default: null }) private infoLabel: string;
  @Prop({ default: null }) private uid: string;
  @Prop() private label!: string;
  @Prop() private placeholder!: string;
  @Prop() private icon!: string;
  @Prop() private startingValue!: string;
  @Prop() private setValue!: string;
  @Prop({ default: false }) private setDisabled!: boolean;
  @Prop() private textSizeClass!: string;
  @Prop()
  private requiredMessage!: string;
  @Prop({ default: true }) private isRequired: boolean;
  @Prop() private isDisabled!: boolean;
  @Prop({ default: "text" }) private inputType!: string;
  @Prop({ default: true }) private isValidInput!: boolean;
  @Prop() private noBorderRadiusRight!: boolean;
  @Prop({ default: 0 }) private maxLen: number;
  @Prop({ default: 0 }) private requiredLen: number;
  @Prop({ default: () => [] }) private errors: Array<string>;
  @Prop({ default: true }) private displayErrorsMessages: boolean;
  private inputFocus = false;
  private value = this.inputType == "number" ? "0" : "";
  private previousValue = this.inputType == "number" ? 0 : "";
  private oldValue = this.inputType == "number" ? 0 : "";
  private Helper = Helper;

  @Watch("value") change() {
    if (!this.isDisabled) {
      this.$emit("input", this.value);
    }
  }

  get isDisabledClass(): string {
    const disabledClass = "input-disabled";
    return this.isDisabled ? disabledClass : "";
  }

  get inputClassValue(): string {
    if (!this.isValid || (this.errors && this.errors.length))
      return this.inputClass + " " + this.size + " " + "input-error";
    return this.inputFocus
      ? this.inputClass +
          (this.size != "md" ? " " + this.size : "") +
          " " +
          "input-focus"
      : this.inputClass + (this.size != "md" ? " " + this.size : "");
  }

  get iconClass(): string {
    if (!this.isValid || (this.errors && this.errors.length))
      return "input-icon-error";
    return this.inputFocus ? "input-icon-focus" : "input-icon";
  }

  get placeholderClass(): string {
    if (!this.isValid || (this.errors && this.errors.length))
      return "input-placeholder-error";
    return this.inputFocus ? "input-placeholder-focus" : "";
  }

  get textSizeClassValue(): string {
    return this.textSizeClass ? this.textSizeClass : "";
  }

  get isValidText() {
    if (
      (this.currentValue() === "" && this.isRequired) ||
      (this.currentValue().length > this.maxLen && this.maxLen > 0) ||
      (this.requiredLen && this.currentValue().length != this.requiredLen)
    ) {
      return false;
    }
    return true;
  }

  get isValid() {
    //if (this.isValidInput) return true;
    if (this.errors && this.errors.length) return false;
    return true;
  }

  get isEmpty(): boolean {
    if (this.isRequired && this.currentValue() === "") {
      return false;
    }
    return true;
  }

  keyPressStr(ev) {
    const evt = ev ? ev : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (this.inputType == "number") {
      let dot = ".";
      if (Helper.currentLanguage() == "it") {
        dot = ",";
      }
      const dotPosition = this.value.toString().indexOf(dot);
      const canSetDot = this.value.toString().length > 0 && dotPosition == -1;
      //if ((charCode >= 48 && charCode <= 57) || (charCode == 46 && canSetDot)) {
      if (
        (charCode < 48 && charCode != 13 && charCode != 44 && charCode != 46) ||
        (charCode == dot.charCodeAt(0) && !canSetDot) ||
        charCode > 57
      ) {
        evt.preventDefault();
        console.log(
          "AAAAAAAAA" +
            charCode +
            "/" +
            canSetDot +
            "/" +
            dot +
            "/" +
            dot.charCodeAt(0)
        );
        return "return false";
      }
    } else if (this.inputType == "date") {
      evt.preventDefault();
      return "return false";
    } else {
      const myUid = this.uid ? this.uid : this.$vnode.key;
      this.$emit("key-press", this.value, myUid);
      return "return true";
    }
  }

  keyUpStr() {
    if (this.value != this.previousValue) {
      this.previousValue = this.value;
      const myUid = this.uid ? this.uid : this.$vnode.key;
      this.$emit("key-up", this.value, myUid);
    }
  }

  get getAvailableQuantity(): any {
    let available =
      Helper.toFloat(this.maxNumber) -
      parseFloat(
        this.previousValue ? Helper.toFloat(this.previousValue).toString() : "0"
      );
    if (available < 0) available = 0;
    return Helper.toLocaleString(available.toFixed(this.numDecimals));
  }

  checkNumberValue() {
    if (this.inputType == "number") {
      if (this.value == "" && this.setMinMax) {
        this.value = Helper.toFloat(this.minNumber).toString();
      }
      let dot = ".";
      if (Helper.currentLanguage() == "it") dot = ",";
      const dotPosition = this.value.toString().indexOf(dot);
      if (dotPosition == 0)
        this.value = Helper.toLocaleString(
          (0 + Number(this.value)).toFixed(this.numDecimals)
        );
    }
  }

  keyEnterStr() {
    this.checkNumberValue();
    this.$emit("enter-pressed");
  }

  addStepNumber() {
    if (this.numDecimals > 0)
      this.value = Helper.toLocaleString(
        (Helper.toFloat(this.value) + this.stepNumber).toFixed(
          this.numDecimals
        ),
        this.numDecimals
      );
    else
      this.value = Helper.toLocaleString(
        (Helper.toFloat(this.value) + Helper.toFloat(this.stepNumber)).toFixed(
          this.numDecimals
        ),
        this.numDecimals
      );
    if (
      Helper.toFloat(this.value) > Helper.toFloat(this.maxNumber) &&
      this.setMinMax
    )
      this.value = Helper.toLocaleString(
        Helper.toFloat(this.maxNumber).toFixed(this.numDecimals)
      );
    if (
      Helper.toFloat(this.value) < Helper.toFloat(this.minNumber) &&
      this.setMinMax
    )
      this.value = Helper.toLocaleString(
        Helper.toFloat(this.minNumber).toFixed(this.numDecimals)
      );
    this.previousValue = this.value;
    const myUid = this.uid ? this.uid : this.$vnode.key;
    this.$emit("step-pressed", this.value, myUid);
  }

  subStepNumber() {
    this.value = Helper.toLocaleString(
      (Helper.toFloat(this.value) - this.stepNumber).toFixed(this.numDecimals)
    );
    if (
      Helper.toFloat(this.value) > Helper.toFloat(this.maxNumber) &&
      this.setMinMax
    )
      this.value = Helper.toLocaleString(
        Helper.toFloat(this.maxNumber).toFixed(this.numDecimals)
      );
    if (
      Helper.toFloat(this.value) < Helper.toFloat(this.minNumber) &&
      this.setMinMax
    )
      this.value = Helper.toLocaleString(
        Helper.toFloat(this.minNumber).toFixed(this.numDecimals)
      );
    this.previousValue = this.value;
    const myUid = this.uid ? this.uid : this.$vnode.key;
    this.$emit("step-pressed", this.value, myUid);
  }

  focusIn(): void {
    this.inputFocus = true;
    this.$emit("focus-in");
  }

  focusOut(): void {
    this.inputFocus = false;
    if (parseInt(this.value) > Helper.toFloat(this.maxNumber) && this.setMinMax)
      this.value = Helper.toLocaleString(this.maxNumber.toString());
    if (parseInt(this.value) < Helper.toFloat(this.minNumber) && this.setMinMax)
      this.value = Helper.toLocaleString(this.minNumber.toString());
    this.$emit("focus-out");
  }

  currentValue() {
    if (this.setValue != null) return this.setValue;
    else return this.value;
  }

  updateValue(val) {
    this.value = val;
  }

  created() {
    this.inputType == "number" && this.isRequired == true ? 0 : "";
    if (this.startingValue) {
      if (this.inputType == "number") {
        if (this.startingValue.length || this.isRequired) {
          this.value = Helper.toLocaleString(this.startingValue);
        } else this.value = this.startingValue;
      } else this.value = this.startingValue;
      this.previousValue = this.value;
      this.oldValue = this.value;
    }
  }
}
