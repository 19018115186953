









































import { Component, Vue, Prop } from "vue-property-decorator";
import SelectInput from "@/components/inputs/SelectInput.vue";

@Component({
  components: {
    SelectInput,
  },
})
export default class FontSelectInput extends Vue {
  @Prop({ default: null }) private title;
  @Prop({ default: null }) private startingFont;
  @Prop({ default: () => [] }) private selectedFonts;
  private availableFonts = [
    { id: 0, name: "arial" },
    { id: 1, name: "courier" },
    { id: 2, name: "times" },
  ];

  selectedFont() {
    this.$emit("selected-font", this.selectedFonts[0]["name"]);
  }

  get startingSelectedFonts() {
    const arr = [];
    for (let i = 0; i < this.availableFonts.length; i++) {
      const fontData = this.availableFonts[i];
      if (fontData["name"] == this.startingFont) arr.push(fontData);
    }
    return arr;
  }

  async created() {
    for (let i = 0; i < this.availableFonts.length; i++) {
      const fontData = this.availableFonts[i];
      if (fontData["name"] == this.startingFont)
        this.selectedFonts.push(fontData);
    }
  }
}
