











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import { Helper } from "@/utils/Helper";
import Link from "@/components/graphic/Link.vue";
import SelectLanguage from "@/components/inputs/SelectLanguage.vue";
import Logo from "@/components/graphic/Logo.vue";
import ButtonLogout from "@/components/inputs/ButtonLogout.vue";

@Component({
  components: {
    ButtonInput,
    Loader,
    Logo,
    Link,
    SelectLanguage,
    ButtonLogout,
  },
})
export default class PageMain extends Vue {
  private loading = false;
  private user = this.$store.state.auth.user;
  private numDeletedIngredients = -1;
  private tiny = true;
  private isHovering = false;
  private enabledRegistration = null;
  private Helper = Helper;

  isLogged() {
    if (localStorage.getItem("user")) return true;
    else return false;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  goToLogin() {
    this.$router.push({ name: "login" });
  }

  goToRegister() {
    this.$router.push({ name: "register" });
  }

  goToHome() {
    this.$router.push({ name: "home" });
  }

  currentLanguage() {
    if (localStorage.getItem("sweetlab_language")) {
      return localStorage.getItem("sweetlab_language");
    } else if (
      this.$store.state.auth.user &&
      this.$store.state.auth.user.language
    ) {
      return this.$store.state.auth.user.language || "it";
    } else if (localStorage.getItem("sweetlab_language_from_login")) {
      return localStorage.getItem("sweetlab_language_from_login");
    } else return "it";
  }

  mounted() {
    if (Helper.currentUser()) {
      this.$router.push({ name: "home" });
    }
    axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/config/enabledRegistration"
      )
      .then((response) => {
        if (response.data == "0") this.enabledRegistration = false;
        else this.enabledRegistration = true;
      });
  }

  created() {
    setTimeout(() => {
      const splide = new Splide(".splide", {
        type: "loop",
        perPage: 1,
        autoplay: true,
        interval: 20000, // How long to display each slide
        pauseOnHover: false, // must be false
        pauseOnFocus: false, // must be false
        resetProgress: false,
      });

      splide.on("active", function (Slide) {
        const baseSpeed = 40;
        const baseSlideWidth = 100;

        const slideWidth =
          Slide.slide.getBoundingClientRect().width / baseSlideWidth;
        const slideSpeed = baseSpeed * slideWidth;

        splide.options.speed = slideSpeed;
      });
      splide.mount();
    }, 250);
    if (window.scrollY > 350) this.tiny = true;
    else if (window.scrollY < 300) this.tiny = false;
    window.addEventListener("scroll", this.handleScroll);
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    ///console.log("handleScroll: " + window.scrollY);
    if (window.scrollY > 350) this.tiny = true;
    else if (window.scrollY < 300) this.tiny = false;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
