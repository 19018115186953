<template>
  <modal-popup ref="ModalPopup">
    <h2 style="margin-top: 0">{{ title }}</h2>
    <i class="fa fa-exclamation-triangle fa-3x"></i>
    <p>{{ message }}</p>
    <div class="st_flex-row st_flex-align-center">
      <button-input
        v-if="cancelButton"
        :label="cancelButton"
        :buttonClass="'cu_btn-size-md cu_btn-theme-transparent'"
        v-on:btn-click="cancel"
      />
      <button-input
        :label="okButton"
        :buttonClass="'cu_btn-size-md cu_btn-theme-normal'"
        v-on:btn-click="confirm"
      />
    </div>
  </modal-popup>
</template>

<!--<script lang='ts'>
import { Component, Prop, Vue } from "vue-property-decorator";
import ModalPopup from "@/components/inputs/ModalPopup.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: {
    ModalPopup,
    ButtonInput,
  },
})
export default class ConfirmModalPopup extends Vue {
  @Prop({ default: "" }) private title;
  @Prop({ default: "" }) private message;
  @Prop({ default: "ok" }) private okButton;
  @Prop({ default: null }) private cancelButton;
  private resolvePromise = undefined;
  private rejectPromise = undefined;

  show() {
    /*this.title = opts.title;
    this.message = opts.message;
    this.okButton = opts.okButton;
    if (opts.cancelButton) {
      set this.cancelButton = opts.cancelButton;
    }*/
    this.$refs.ModalPopup.open();
    return new Promise((resolve, reject) => {
      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  }

  confirm() {
    this.$refs.ModalPopup.close();
    this.resolvePromise(true);
  }

  cancel() {
    this.$refs.ModalPopup.close();
    this.resolvePromise(false);
  }
}
</script>-->

<script>
import ModalPopup from "./ModalPopup.vue";
import ButtonInput from "./ButtonInput.vue";

export default {
  name: "ConfirmModalPopup",

  components: { ModalPopup, ButtonInput },

  data: () => ({
    title: undefined,
    message: undefined,
    okButton: "PROCEED",
    cancelButton: null,

    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      this.$refs.ModalPopup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.$refs.ModalPopup.close();
      this.resolvePromise(true);
    },

    cancel() {
      this.$refs.ModalPopup.close();
      this.resolvePromise(false);
    },
  },
};
</script>

<style scoped lang='scss'>
</style>