





















import { Component, Vue, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import UserInfo from "@/models/UserInfo";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    UserDetail,
  },
})
export default class LastModified extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "" }) private item: string;
  @Prop({ default: "ingredient" }) private itemType: string;
  @Prop({ default: "" }) private teamId: string;
  private lastModifiedElapsed = -1;
  private lastModifiedDate = null;
  private lastModifiedUserId = null;
  private userInfo = new UserInfo("", "", "", "");
  private lastAuthor = "";
  private loading = false;
  private error = false;
  private timer = null;
  private minutesElapsed = "-";

  calcMinutesElapsed() {
    const now = Helper.epoch();
    const minutes = Math.floor((now - this.lastModifiedElapsed) / 60);
    /*if (minutes <= 0) return this.$t("i18n_component_last_modified_now");
    else if (minutes > 0 && minutes <= 3600) return minutes + " min";
    else return "> 1h";*/
    return minutes;
  }

  get isNew() {
    const now = Helper.epoch();
    const secondsElapsed = Math.floor(now - this.lastModifiedElapsed);
    return secondsElapsed >= 0 && secondsElapsed < 3600 ? true : false;
  }

  async UserInfo(userId): Promise<UserInfo | null> {
    let info: UserInfo | null = null;
    await axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/users/" +
          userId +
          "/userInfo",
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        info = new UserInfo(
          userId,
          response.data[0].name,
          response.data[0].email,
          response.data[0].profile_photo_path
        );
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => (this.loading = false));
    return info;
  }

  async displayElapsed() {
    const minutes = this.calcMinutesElapsed();
    let waitElapsed = 0;
    if (minutes <= 10) waitElapsed = 60000;
    else if (minutes > 10 && minutes < 3600) waitElapsed = 600000;

    if (minutes <= 0)
      this.minutesElapsed = this.$t(
        "i18n_component_last_modified_now"
      ).toString();
    else if (minutes > 0 && minutes <= 3600)
      this.minutesElapsed = minutes + " min";
    else this.minutesElapsed = "> 1h";

    if (waitElapsed) {
      this.timer = setTimeout(() => {
        this.displayElapsed();
      }, waitElapsed);
    }
  }

  async created() {
    this.userInfo = null;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.teamId +
          "/lastModified/" +
          this.itemType +
          "/" +
          this.item,
        {
          headers: authHeader(),
        }
      )
      .then(async (response) => {
        const data = response.data;
        if (data.user_id) {
          this.lastModifiedElapsed = data.elapsed;
          this.lastModifiedDate = data.modified_date;
          this.lastModifiedUserId = data.user_id;
          this.displayElapsed();
        } else this.error = true;

        const userInfoObj = Helper.userInfo(
          this.$store.state.usersInfo,
          this.lastModifiedUserId
        );

        if (userInfoObj.length > 0) {
          this.userInfo = userInfoObj[0];
        } else {
          const defaultUserInfo = new UserInfo(
            this.lastModifiedUserId,
            "",
            "",
            ""
          );
          this.$store.commit("ADD_USER_INFO", defaultUserInfo);
          this.userInfo = await this.UserInfo(this.lastModifiedUserId);
          if (this.userInfo && this.userInfo.isValid()) {
            this.$store.commit("ADD_USER_INFO", this.userInfo);
          }
        }
      })
      .catch(() => {
        this.error = true;
      });
  }

  async destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
