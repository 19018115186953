


















import ButtonInput from "@/components/inputs/ButtonInput.vue";
import { Prop, Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    ButtonInput,
  },
})
export default class NotificationMessage extends Vue {
  @Prop({ default: false }) private hidden;
  @Prop({ default: "" }) private message;
  @Prop({ default: 0 }) private margin;
  @Prop({ default: "notification-message-fixed" }) private notificationMessageClass;

  Hide() {
    this.hidden = true;
    this.$emit("message-hide", null);
  }
}
