

























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { fileAuthHeader } from "@/services/auth-header";

@Component({
  components: {
    Loader,
    ButtonInput,
    vueDropzone,
  },
})
export default class Dropzone extends Vue {
  private myDropzone: any;
  private value!: string | null;
  private progress = false;
  private loadingProgress = 0;
  private loading = true;
  private isDestroying = false;
  @Prop({ default: "" }) private title;
  @Prop({ default: null }) private thumb;
  @Prop({ default: "fa fa-camera" }) private icon;
  @Prop({ default: "fa fa-camera" }) private uploaded;
  @Prop({ default: false }) private startingValue;
  @Prop({ default: "" }) private addUrl;
  @Prop({ default: "" }) private removeUrl;
  @Prop({ default: "" }) private uploadedUrl;
  @Prop({ default: 1 }) private maxFiles;
  @Prop({ default: 0.2 }) private maxFileSize;
  @Prop({ default: false }) private uploadMultiple;
  @Prop({ default: ".png, .jpg" }) private acceptedFiles;

  saveUpload() {
    const files = this.myDropzone.dropzone.files;
    if (!files.length) {
      axios.post(this.removeUrl).then(() => {
        this.value = null;
        this.$emit("uploaded", null);
      });
    } else this.myDropzone.processQueue();
  }

  async created() {
    if (this.startingValue) {
      this.value = this.startingValue;
    }
  }

  startUpload(file) {
    const manuallyAdded = typeof file["manuallyAdded"] !== "undefined"; // Starting thumbnail
    if (!manuallyAdded) {
      file.previewElement.querySelector("img").style.cssText =
        "width: 200px; height: 200px; object-fit: contain;";
      const files = this.myDropzone.dropzone.files;
      if (files.length > this.maxFiles) {
        const exceeded = files.length - this.maxFiles;
        for (let i = 0; i < exceeded; i++) {
          const fileToRemove = this.myDropzone.dropzone.files[i];
          this.myDropzone.dropzone.removeFile(fileToRemove);
        }
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      let blobImage = null;
      let wImage = 0;
      let hImage = 0;
      const image = new Image();
      reader.onload = function (event) {
        blobImage = event.target.result;

        (image as HTMLImageElement).src = reader.result.toString();
        //   event.target.result - is dataURL data
      };
      reader.onloadend = () => {
        if (blobImage && wImage && hImage) {
          this.$emit("start-upload", blobImage, wImage, hImage);
        }
      };
      image.onload = () => {
        wImage = image.width;
        hImage = image.height;
        if (blobImage && wImage && hImage) {
          this.$emit("start-upload", blobImage, wImage, hImage);
        }
      };
    }
  }

  endUpload(file) {
    this.progress = false;
    axios.post(this.uploadedUrl).then((response) => {
      this.value = response.data["uploaded"];
      this.$emit("uploaded", this.value);
    });
  }

  beforeDestroy() {
    this.isDestroying = true;
  }

  destroyed() {
    this.isDestroying = false;
    //this.uploaded(this.value);
    this.$emit("close", this.value);
  }

  removeUpload(file, error, xhr) {
    //alert("removeUpload " + this.isDestroying);
    //const files = this.myDropzone.dropzone.files;
    if (this.isDestroying) return;
    this.$emit("removed", this.value);
    /*axios
      .post(this.removeUrl).then(() => {
        this.value = null;
        //this.$emit("uploaded", null);
      })*/
  }

  progressUpload(totalProgress, totalBytes, totalBytesSent) {
    this.progress = true;
    this.loadingProgress = Math.floor(totalProgress);
  }

  addThumbnail(file, dataUrl) {
    this.$emit("thumbnail", dataUrl);
  }

  get dropzoneOptions() {
    return {
      url: this.addUrl,
      maxFileSize: this.maxFileSize,
      uploadMultiple: this.uploadMultiple,
      maxFiles: this.maxFiles,
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictRemoveFile: this.$t("i18n_component_dropzone_remove"),
      dictDefaultMessage:
        "<i class='fa fa-cloud-upload'></i>&nbsp;" +
        this.$t("i18n_component_dropzone_info").toString() +
        "<br><span style='font-size:12px;'>" +
        this.$t("i18n_component_dropzone_max_size").toString() +
        this.maxFileSize +
        " MB</span>",
      dictInvalidFileType:
        this.$t("i18n_component_dropzone_accepted_files").toString() +
        this.acceptedFiles,
      acceptedFiles: this.acceptedFiles,
      headers: fileAuthHeader(),
    };
  }

  mounted(): void {
    this.loading = false;
    const url = this.startingValue;
    this.myDropzone = this.$refs.myVueDropzone;
    if (url != null) {
      let extension = null;
      if (!url.startsWith("data")) {
        extension = url.substr(url.lastIndexOf(".") + 1);
        const file = {
          size: 100,
          name: "Icon",
          type: "image/" + extension,
          previewElement: null,
        };
        this.myDropzone.manuallyAddFile(file, url);
        file.previewElement.querySelector("img").style.cssText =
          "width: 200px; height: 200px; object-fit: contain;";
      } else {
        extension = "jpeg";

        const file = {
          size: 100,
          name: "Icon",
          type: "image/" + extension,
          previewElement: null,
        };
        this.myDropzone.manuallyAddFile(file, this.thumb);

        // the code above is used for making a fix for dropzone in collapsible component: perform thumbnail refresh after compoennt recreation in expand event

        file.previewElement.querySelector("img").src = this.startingValue;
        file.previewElement.classList.remove("dz-file-preview");
        file.previewElement.classList.add("dz-image-preview");
        file.previewElement.querySelector("img").style.cssText =
          "width: 200px; height: 200px; object-fit: contain;";
      }
    }
  }
}
