



















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    SelectInput,
  },
})
export default class ItemsList extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "" }) private teamId: string;
  @Prop({ default: "" }) private url: string;
  @Prop({ default: "md" }) private size: string;
  @Prop({ default: "" }) private title: string;
  private loading = false;
  private error = false;
  private labels = [];

  @Watch("labels") change() {
    this.$emit("item-choosen", this.labels);
  }

  //async created() {}
}
