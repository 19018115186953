




















































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { Helper } from "@/utils/Helper";
import PageLoader from "@/components/graphic/PageLoader.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import GroupRadioInput from "@/components/inputs/GroupRadioInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: {
    PageLoader,
    ButtonInput,
    TextInput,
    GroupRadioInput,
  },
})
export default class UserBilling extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: false }) private startingValue;
  @Prop({ default: "" }) private billingName;
  private billing = [];
  private isVATValidated = true;
  private errorsFromServer: Array<string> = [];
  private loading = false;

  currentUser() {
    return this.$store.state.auth.user;
  }

  /*async VATValidation(): Promise<boolean> {

    try {
      const validationInfo: ViesValidationResponse = await validateVat(CountryCodes.Germany, this.billing['billing_fiscal_code']);
      return validationInfo.valid;
    } catch (e) {
      console.log(e);
      return false;
    }
  }*/

  UpdateBilling() {
    this.loading = true;
    this.errorsFromServer = [];
    //this.VATValidation().then((result): void => {
    //  this.isVATValidated = result;

    //if (this.isVATValidated) {
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/users/" +
          this.currentUser().id +
          "/userBilling",
        this.billing
      )
      .then((response) => {
        this.loading = false;
        const user = JSON.parse(localStorage.getItem("user") || "");
        user["billing"] = this.billing;
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(user));
        this.$store.state.auth.user.billing = this.billing;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_userbilling_save_success").toString(),
          duration: 20000,
        });
        if (
          Helper.progress(this.currentUser()["billing"])["count"] ==
          Helper.progress(this.currentUser()["billing"])["tot"]
        ) {
          this.$emit("billing-completed");
        }
      })
      .catch((error) => {
        this.loading = false;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors || [];
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_userbilling_save_error").toString(),
          type: "error",
          duration: 20000,
        });
      });
    /*}
    else {
      this.loading = false;
    }*/
    //});
  }

  async created() {
    if (this.startingValue) {
      this.billing = this.startingValue; //JSON.parse(JSON.stringify(this.startingValue));
    }
  }
}
