export default class LabelInfo {
    id: string
    name: string
    constructor(id, name) {
        this.id = id
        this.name = name
    }

    public isValid(): boolean {
        if (this.id && this.id != "" && this.name && this.name != "")
            return true;
        return false;
    }
}