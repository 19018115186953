var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.selectClass},[(_vm.options)?[_c('div',{class:_vm.size + ' ' + _vm.styleClass,staticStyle:{"position":"relative"}},[_c('div',{staticClass:"cu_select-placeholder",class:{
          'cu_select-placeholder-alt':
            _vm.open ||
            (_vm.mySelectedItems && _vm.mySelectedItems.length > 0) ||
            _vm.preselectFirst,
        }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.multiple)?_c('multiselect',{attrs:{"close-on-select":false,"clear-on-select":false,"label":"name","track-by":"id","placeholder":_vm.open ? _vm.placeholderValue : _vm.title,"options":_vm.optionsValues,"searchable":true,"allow-empty":_vm.allowEmpty,"block-keys":['Delete'],"group-values":_vm.groupValues,"group-label":_vm.groupLabel,"group-select":_vm.groupValues != '' && _vm.groupLabel != '' ? true : false,"preselect-first":_vm.preselectFirst,"openDirection":'bottom',"selectedLabel":this.$t('i18n_component_select_option_selected_info'),"selectLabel":this.$t('i18n_component_select_option_add_info'),"deselect-label":_vm.canDelete ? this.$t('i18n_component_select_option_remove_info') : ''},on:{"select":_vm.selectedOption,"remove":_vm.removedOption,"open":function($event){_vm.open = true},"close":function($event){_vm.open = false}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
        var option = ref.option;
        var remove = ref.remove;
return [_vm._t("selected-option",null,{"scope":{ option: option },"scopeFunc":{ remove: remove }})]}},{key:"option",fn:function(props){return [_vm._t("available-option",null,{"scope":{ props: props }})]}}],null,true),model:{value:(_vm.mySelectedItems),callback:function ($$v) {_vm.mySelectedItems=$$v},expression:"mySelectedItems"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(_vm._s(_vm.$t("i18n_component_select_no_result")))])]):_c('multiselect',{attrs:{"options":_vm.optionsValues,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"hideSelected":true,"block-keys":['Delete'],"group-values":_vm.groupValues,"group-label":_vm.groupLabel,"group-select":_vm.groupValues != '' && _vm.groupLabel != '' ? true : false,"placeholder":_vm.open ? _vm.placeholderValue : _vm.title,"label":"name","track-by":"id","preselect-first":_vm.preselectFirst,"openDirection":'bottom',"selectedLabel":this.$t('i18n_component_select_option_selected_info'),"selectLabel":this.$t('i18n_component_select_option_add_info'),"deselect-label":_vm.canDelete ? this.$t('i18n_component_select_option_remove_info') : ''},on:{"select":_vm.selectedOption,"remove":_vm.removedOption,"open":function($event){_vm.open = true},"close":function($event){_vm.open = false}},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
        var option = ref.option;
        var remove = ref.remove;
return [_vm._t("selected-option",null,{"scope":{ option: option },"scopeFunc":{ remove: remove }})]}},{key:"option",fn:function(props){return [_vm._t("available-option",null,{"scope":{ props: props }})]}}],null,true),model:{value:(_vm.mySelectedItems),callback:function ($$v) {_vm.mySelectedItems=$$v},expression:"mySelectedItems"}},[(_vm.selectionTagOptions.length > 1 && _vm.displayTag)?_c('template',{slot:"beforeList"},[_c('div',{staticClass:"cu_select-tags",staticStyle:{"position":"sticky","top":"0px","left":"0px","padding":"10px 20px","z-index":"53","align-self":"center"}},[_c('search-tag',{attrs:{"selectedTags":_vm.tags,"tags":_vm.selectionTagOptions,"labels":_vm.selectionLabels}})],1)]):_vm._e(),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(_vm._s(_vm.$t("i18n_component_select_no_result")))])],2)],1),_vm._t("footer")]:_c('loader')],2)}
var staticRenderFns = []

export { render, staticRenderFns }