





























































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Helper } from "@/utils/Helper";
import User from "../models/User";
import ToggleButton from "@/components/inputs/ToggleButton.vue";
import HeaderBarShortcut from "@/components/graphic/HeaderBarShortcut.vue";
import LoginPanel from "@/components/graphic/LoginPanel.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import ApiVersion from "@/components/graphic/ApiVersion.vue";
import Loader from "@/components/graphic/Loader.vue";
import Logo from "@/components/graphic/Logo.vue";

const DisplayRegister = {
  register: 0,
  registerSuccessfull: 1,
};

@Component({
  components: {
    ToggleButton,
    HeaderBarShortcut,
    ButtonInput,
    LoginPanel,
    TextInput,
    ApiVersion,
    Loader,
    Logo,
  },
})
export default class PageRegister extends Vue {
  private user: User = new User("", "", "", "", "");
  private errorsApi = [];
  private errorsFromServer: Array<string> = [];
  private loading = false;
  private displayRegisterForm = DisplayRegister.register;
  private showPassword = false;
  private unknownErrors = false;
  private acceptedPolicies = false;
  private enabledRegistration = null;

  get DisplayRegister(): typeof DisplayRegister {
    return DisplayRegister;
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleAcceptedPolicies(): void {
    this.acceptedPolicies = !this.acceptedPolicies;
  }

  goToLogin() {
    this.$router.push({ name: "login" });
  }

  register() {
    this.loading = true;
    this.errorsFromServer = [];
    this.unknownErrors = false;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/auth/register",
        this.user
      )
      .then(() => {
        this.displayRegisterForm = DisplayRegister.registerSuccessfull;
      })
      .catch((error) => {
        if (error.response) {
          if (!Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
        }
        if (
          !this.errorsFromServer ||
          !Object.keys(this.errorsFromServer).length
        ) {
          this.unknownErrors = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  mounted() {
    axios
      .get(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/config/enabledRegistration"
      )
      .then((response) => {
        if (response.data == "0") this.enabledRegistration = false;
        else this.enabledRegistration = true;
      });
  }
}
