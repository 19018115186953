


































import { Component, Vue, Prop } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";
import TimeFormatter from "@/components/graphic/TimeFormatter.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PersonalNotification from "@/components/custom/PersonalNotification.vue";
import UserDetail from "@/components/custom/UserDetail.vue";
import LicenseDetail from "@/components/custom/LicenseDetail.vue";
import InvoiceDetail from "@/components/custom/InvoiceDetail.vue";
import TeamDetail from "@/components/custom/TeamDetail.vue";
import TabPanel from "@/components/graphic/TabPanel.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    ButtonInput,
    PersonalNotification,
    UserDetail,
    LicenseDetail,
    InvoiceDetail,
    TeamDetail,
    TabPanel,
    TimeFormatter,
  },
})
export default class PersonalNotifications extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "md" }) size;
  @Prop({ default: true }) displayTitle;
  @Prop({ default: false }) displayUnread;
  @Prop({ default: true }) displayMyNotifications;
  @Prop({ default: 5 }) private limit: any;
  private loading = true;
  private error = false;

  get notifications() {
    if (
      this.$store.state.auth.notifications &&
      this.$store.state.auth.notifications["personal"] &&
      this.$store.state.auth.notifications["personal"].loading == false
    )
      return this.$store.state.auth.notifications["personal"][
        "activities"
      ].filter((activity) => {
        return (
          (this.displayMyNotifications &&
            activity.senderId == this.currentUser().id) ||
          activity.receiverId == this.currentUser().id
        );
      });
    return null;
  }

  notificationIcon(iconType) {
    if (iconType == "invoice") return "fa fa-scroll";
    else if (iconType == "license") return "fa fa-award";
    else if (iconType == "team") return "fas fa-users";
    else return "fa fa-star";
  }

  currentUser() {
    return this.$store.state.auth.user || null;
  }
}
