














































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PersonalNotifications from "@/components/custom/PersonalNotifications.vue";
import TeamMembers from "@/components/custom/TeamMembers.vue";
import WidgetPanel from "@/components/custom/WidgetPanel.vue";
import TabPanel from "@/components/graphic/TabPanel.vue";
import LatestNews from "@/components/graphic/LatestNews.vue";
import Loader from "@/components/graphic/Loader.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    ButtonInput,
    Loader,
    WidgetPanel,
    TabPanel,
    PersonalNotifications,
    TeamMembers,
    LatestNews,
  },
})
export default class PagePersonal extends Vue {
  private Helper = Helper;
  private loading = false;
  private user = this.$store.state.auth.user;
  private numDeletedIngredients = -1;

  currentUser() {
    return this.$store.state.auth.user;
  }

  currentTeam() {
    return this.user.currentTeam || null;
  }

  numOfDeletedItems() {
    this.loading = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/deletedItems",
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        this.numDeletedIngredients = response.data.numOfDeletedItems;
      })
      .catch(() => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_home_num_deleted_items_error").toString(),
          type: "error",
          duration: 20000,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  removeDeletedItems() {
    this.loading = true;
    axios
      .delete(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/deletedItems",
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        this.numDeletedIngredients = 0;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_home_deleted_items_success").toString(),
          duration: 20000,
        });
      })
      .catch(() => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_page_home_deleted_items_error").toString(),
          type: "error",
          duration: 20000,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async created() {
    if (this.currentUser().role == "Admin") this.numOfDeletedItems();
  }
}
