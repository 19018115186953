









































import { Component, Vue, Prop } from "vue-property-decorator";
import SelectInput from "@/components/inputs/SelectInput.vue";

@Component({
  components: {
    SelectInput,
  },
})
export default class ArgSelectInput extends Vue {
  @Prop({ default: null }) private title;
  @Prop({ default: null }) private startingArg;
  @Prop({ default: () => [] }) private selectedArgs;
  @Prop({ default: () => [] }) private availableArgs;

  selectedArg() {
    this.$emit("selected-arg", this.selectedArgs[0]["name"]);
  }

  get startingSelectedArgs() {
    const arr = [];
    for (let i = 0; i < this.availableArgs.length; i++) {
      const argData = this.availableArgs[i];
      if (argData["name"] == this.startingArg) arr.push(argData);
    }
    return arr;
  }

  async created() {
    for (let i = 0; i < this.availableArgs.length; i++) {
      const argData = this.availableArgs[i];
      if (argData["name"] == this.startingArg) this.selectedArgs.push(argData);
    }
  }
}
