



































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import Logo from "@/components/graphic/Logo.vue";
import HeaderBarShortcut from "@/components/graphic/HeaderBarShortcut.vue";
import GroupRadioInput from "@/components/inputs/GroupRadioInput.vue";
import PersonalNotifications from "@/components/custom/PersonalNotifications.vue";
import TeamActivityNotifications from "@/components/custom/TeamActivityNotifications.vue";
import ShoppingCart from "@/components/custom/ShoppingCart.vue";
import TeamSelector from "@/components/custom/TeamSelector.vue";
import SelectTheme from "@/components/inputs/SelectTheme.vue";
import SelectLanguage from "@/components/inputs/SelectLanguage.vue";
import SelectNightMode from "@/components/inputs/SelectNightMode.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import ButtonLogout from "@/components/inputs/ButtonLogout.vue";
import SubNavBar from "@/components/graphic/SubNavBar.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    Logo,
    HeaderBarShortcut,
    GroupRadioInput,
    PersonalNotifications,
    TeamActivityNotifications,
    ShoppingCart,
    TeamSelector,
    SelectTheme,
    SelectLanguage,
    SelectNightMode,
    ButtonInput,
    ButtonLogout,
    SubNavBar,
  },
})
export default class HeaderBar extends Vue {
  private Helper: Helper = Helper;
  private ownedTeams2: Array<any> = [];
  private acceptedTeams2: Array<any> = [];
  private subNavbarTeamItemOwned = null;
  private subNavbarTeamItemAccepted = null;
  private subNavbarTeamsOwned = null;
  private subNavbarTeamsAccepted = null;
  private teamNotificationSelection = "owned";
  private loading = false;

  get acceptedTeams() {
    const teamsIds = Object.keys(this.$store.state.auth.notifications["teams"]);
    const arrOwnedTeams = [];
    for (let i = 0; i < teamsIds.length; i++) {
      const keyTeam = teamsIds[i];

      const owned =
        this.$store.state.auth.notifications["teams"][keyTeam]["owned"];
      if (!owned) {
        if (!this.subNavbarTeamItemAccepted)
          this.subNavbarTeamItemAccepted = keyTeam;
        arrOwnedTeams.push({
          id: keyTeam,
          name: this.$store.state.auth.notifications["teams"][keyTeam]["name"],
          owned: owned,
          avatar:
            this.$store.state.auth.notifications["teams"][keyTeam]["avatar"],
          value: keyTeam,
          index: 0,
        });
      }
    }
    return arrOwnedTeams;
  }

  get ownedTeams() {
    const teamsIds = Object.keys(this.$store.state.auth.notifications["teams"]);
    const arrOwnedTeams = [];
    for (let i = 0; i < teamsIds.length; i++) {
      const keyTeam = teamsIds[i];

      const owned =
        this.$store.state.auth.notifications["teams"][keyTeam]["owned"];

      if (owned) {
        if (!this.subNavbarTeamItemOwned) this.subNavbarTeamItemOwned = keyTeam;
        arrOwnedTeams.push({
          id: keyTeam,
          name: this.$store.state.auth.notifications["teams"][keyTeam]["name"],
          owned: owned,
          avatar:
            this.$store.state.auth.notifications["teams"][keyTeam]["avatar"],
          value: keyTeam,
          index: 0,
        });
      }
    }
    return arrOwnedTeams;
  }

  currentUser() {
    return this.$store.state.auth.user || null;
  }

  currentTeam() {
    return this.$store.state.auth.user.currentTeam || null;
  }

  shoppingCartItems() {
    let numOfItems = 0;
    for (
      let i = 0;
      i < Object.keys(this.$store.state.auth.shoppingCart).length;
      i++
    ) {
      const keyShoppingCart = Object.keys(this.$store.state.auth.shoppingCart)[
        i
      ];
      numOfItems += this.$store.state.auth.shoppingCart[keyShoppingCart]["qty"];
    }
    return numOfItems;
  }

  hidePersonalNotifications() {
    this.$store.commit("MARK_READ_PERSONAL_NOTIFICATIONS");
    localStorage.setItem(
      "notifications",
      JSON.stringify(this.$store.state.auth.notifications)
    );
  }

  hideTeamNotifications(notification) {
    this.$store.commit("MARK_READ_TEAM_ACTIVITY_NOTIFICATIONS", notification);
    localStorage.setItem(
      "notifications",
      JSON.stringify(this.$store.state.auth.notifications)
    );
  }

  unreadTeamNotifications(teamId = null) {
    let numOfUnread = 0;
    let unreadNotificationsArr = [];
    if (teamId != null) {
      if (this.$store.state.auth.notifications["teams"][teamId])
        unreadNotificationsArr = this.$store.state.auth.notifications["teams"][
          teamId
        ]["activities"].filter((activity) => {
          return (
            (activity.senderId != this.currentUser().id ||
              activity.itemId == this.currentUser().id ||
              (activity.prop &&
                activity.prop.email &&
                activity.prop.email == this.currentUser().email)) &&
            activity.unread == true
          );
        });
      numOfUnread = unreadNotificationsArr.length;
    } else {
      for (const notificationTeam in this.$store.state.auth.notifications[
        "teams"
      ]) {
        unreadNotificationsArr = this.$store.state.auth.notifications["teams"][
          notificationTeam
        ]["activities"].filter((activity) => {
          return (
            (activity.senderId != this.currentUser().id ||
              activity.itemId == this.currentUser().id ||
              (activity.prop &&
                activity.prop.email &&
                activity.prop.email == this.currentUser().email)) &&
            activity.unread == true
          );
        });
        numOfUnread += unreadNotificationsArr.length;
      }
    }
    return numOfUnread;
  }

  unreadPersonalNotifications() {
    let numOfUnread = 0;
    let unreadNotificationsArr = [];
    if (
      this.$store.state.auth.notifications &&
      this.$store.state.auth.notifications["personal"] &&
      !Helper.isUndefined(
        this.$store.state.auth.notifications["personal"]["activities"]
      )
    )
      unreadNotificationsArr = this.$store.state.auth.notifications["personal"][
        "activities"
      ].filter((activity) => {
        return (
          (activity.receiverId == this.currentUser().id ||
            (activity.prop &&
              activity.prop.email &&
              activity.prop.email == this.currentUser().email)) &&
          activity.unread == true
        );
      });
    numOfUnread = unreadNotificationsArr.length;
    return numOfUnread;
  }

  get unreadOwnedTeamsNotifications() {
    let numOfUnread = 0;
    for (const teamIndex in this.ownedTeams) {
      let unreadNotificationsArr = [];
      if (
        Object.prototype.hasOwnProperty.call(
          this.$store.state.auth.notifications["teams"],
          this.ownedTeams[teamIndex]["id"]
        )
      ) {
        unreadNotificationsArr = this.$store.state.auth.notifications["teams"][
          this.ownedTeams[teamIndex]["id"]
        ]["activities"].filter((activity) => {
          return (
            (activity.senderId != this.currentUser().id ||
              activity.itemId == this.currentUser().id ||
              (activity.prop &&
                activity.prop.email &&
                activity.prop.email == this.currentUser().email)) &&
            activity.unread == true
          );
        });
      }
      numOfUnread += unreadNotificationsArr.length;
    }
    return numOfUnread;
  }

  get unreadAcceptedTeamsNotifications() {
    let numOfUnread = 0;
    for (const teamIndex in this.acceptedTeams) {
      let unreadNotificationsArr = [];
      if (
        Object.prototype.hasOwnProperty.call(
          this.$store.state.auth.notifications["teams"],
          this.acceptedTeams[teamIndex]["id"]
        )
      ) {
        unreadNotificationsArr = this.$store.state.auth.notifications["teams"][
          this.acceptedTeams[teamIndex]["id"]
        ]["activities"].filter((activity) => {
          return (
            (activity.senderId != this.currentUser().id ||
              activity.itemId == this.currentUser().id ||
              (activity.prop &&
                activity.prop.email &&
                activity.prop.email == this.currentUser().email)) &&
            activity.unread == true
          );
        });
      }
      numOfUnread += unreadNotificationsArr.length;
    }
    return numOfUnread;
  }

  goToPage(page) {
    this.$router.push({ name: page });
  }

  goToNotificationsTeams(page) {
    this.teamNotificationSelection = page;
  }

  goToNotificationsTeamsOwned(page) {
    this.subNavbarTeamItemOwned = page;
  }

  goToNotificationsTeamsAccepted(page) {
    this.subNavbarTeamItemAccepted = page;
  }

  async created() {
    this.subNavbarTeamsOwned = this.ownedTeams;
    this.subNavbarTeamsAccepted = this.acceptedTeams;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "ADD_TO_CART" || mutation.type == "DEL_FROM_CART") {
        if (this.$refs.shoppingCart)
          (this.$refs.shoppingCart as any).$forceUpdate();
      }
    });
  }
}
