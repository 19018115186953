





























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import SearchTag from "@/components/inputs/SearchTag.vue";
import Multiselect from "vue-multiselect";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    SearchTag,
    Multiselect,
  },
})
export default class SelectInput extends Vue {
  private Helper: Helper = Helper;
  private selectionTags = [];
  private open = false;
  private tags = [];
  private options = null;
  @Prop({ default: "st_margin-bottom-20px" }) private selectClass;
  @Prop({ default: "md" }) private size;
  @Prop({ default: "normal" }) private styleClass;
  @Prop({ default: null }) private startOptions;
  @Prop({ default: null }) private dataUrl;
  @Prop({ default: () => [] }) private startingValue;
  @Prop({ default: false }) private multiple;
  @Prop({ default: true }) private allowEmpty;
  @Prop({ default: false }) private preselectFirst;
  @Prop({ default: true }) private canDelete;
  @Prop({ default: "" }) private optionClass;
  @Prop({ default: true }) private displayTag;
  @Prop({ default: "" }) private title;
  @Prop({ default: "" }) private placeholder;
  @Prop({ default: () => [] }) private labels;
  @Prop({ default: () => [] }) private types;
  @Prop({ default: "" }) private subType;
  @Prop({ default: () => [] }) private subTypeSelected;
  @Prop({ default: () => [] }) private selectedItems;
  @Prop({ default: "items" }) private groupValues;
  @Prop({ default: "type" }) private groupLabel;
  private mySelectedItems = null;
  private placeholderValue = "";

  get availableOptions() {
    if (this.options)
      return this.options.filter((opt) => {
        return !this.selectedItems.includes(opt.id);
      });
    else return null;
  }

  get optionsValues() {
    return this.options.filter((opt) => {
      return this.tags.indexOf(opt.type) >= 0 || this.tags.length == 0;
    });
  }

  get selectionTagOptions() {
    return this.selectionTags;
  }

  get selectionLabels() {
    return this.labels;
  }

  nameWithType({ name, type }) {
    return `${name} — <div style='border: 1px solid red;'>${type}</div>`;
  }

  subIngredients(types) {
    if (this.dataUrl)
      axios
        .post(this.dataUrl, {
          types: types,
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            this.options = response.data;
            this.options.forEach((opt) => {
              if (this.displayTag) this.selectionTags.push(opt.type);
            });
          }
        })
        .finally(() => {
          this.$emit("select-start");
        })
        .catch((error) => {
          this.options = [];
        });
  }

  selectedOption(opt) {
    if (!this.multiple) {
      Helper.flush(this.selectedItems);
    }
    this.selectedItems.push(opt);
    this.$emit("selected-option");
  }

  removeOption(opt) {
    Helper.removeObject(this.selectedItems, opt.id);
    this.$emit("removed-option");
  }

  removedOption(opt) {
    this.removeOption(opt);
  }

  async mounted() {
    if (this.title != "" && this.placeholder == "")
      this.placeholderValue = this.title;
    else this.placeholderValue = this.placeholder;

    if (this.startOptions) this.options = this.startOptions;
    if (this.startingValue) {
      this.mySelectedItems = [];
      this.startingValue.forEach((opt) => {
        this.mySelectedItems.push(opt);
      });
    }
    this.tags = this.subTypeSelected;
    this.subIngredients(this.types);
  }
}
