
























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";
import ConfirmModalPopup from "@/components/inputs/ConfirmModalPopup.vue";

@Component({
  components: {
    Loader,
    ConfirmModalPopup,
  },
})
export default class ModalInput extends Vue {
  @Prop({ default: false }) private startingValue;

  @Prop({ default: false }) private loading!: string;

  @Prop({ default: "" })
  private buttonClass!: string;

  @Prop() private label!: string;

  @Prop({ default: null }) private confirmLabel!: string;

  @Prop({ default: "" }) private disabledLabel!: string;

  @Prop() private value!: string;

  @Prop() private icon!: string;

  @Prop() private isDisabled!: boolean;

  @Prop({ default: false }) private askForConfirmation!: boolean;

  @Watch("value") change() {
    if (!this.isDisabled) {
      this.$emit("input", this.value);
    }
  }

  get isLabeledClass(): string {
    const labelClass = "labeled";
    return this.label ? labelClass : "";
  }

  async created() {
    if (this.startingValue) {
      this.value = this.startingValue;
    }
  }

  private async InputClicked() {
    if (!this.isDisabled) {
      const ConfirmModalPopup: any = this.$refs.ConfirmModalPopup;
      let ok = false;
      if (this.askForConfirmation && this.confirmLabel.length) {
        ok = await ConfirmModalPopup.show({
          title: this.label,
          message: this.confirmLabel,
          okButton: this.$t("i18n_btn_proceed").toString(),
          cancelButton: this.$t("i18n_btn_undo").toString(),
        });
      } else {
        ok = true;
      }
      if (ok) {
        this.$emit("input-click", null);
      }
    }
  }
}
