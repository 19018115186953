



































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/inputs/Modal.vue";
import ToggleButton from "@/components/inputs/ToggleButton.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import parsers from "../../js/cookieToggler/scripts";

@Component({
  components: { Modal, ToggleButton, ButtonInput, PopoverInput },
})
export default class CookieToggler extends Vue {
  private activeCookieCategories = [];
  private isShown = false;
  @Prop({ default: [] }) private opts;

  get isUpdated() {
    const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    const currentEnabledCookies = this.activeCookieCategories;
    const savedEnabledCookies = JSON.parse(
      localStorage.getItem("sweetlab_cookies_enabled") || "[]"
    );
    if (equals(currentEnabledCookies, savedEnabledCookies)) return false;
    else return true;
  }

  currentUser() {
    return this.$store.state.auth.user;
  }

  updateCookieCategories() {
    this.activeCookieCategories = [];
    for (let i = 0; i < this.opts.length; i++) {
      const opt = this.opts[i];
      if (opt.active) {
        this.activeCookieCategories.push(opt.category);
      }
    }
  }

  enableAllCookieCategories() {
    this.activeCookieCategories = [];
    for (let i = 0; i < this.opts.length; i++) {
      const opt = this.opts[i];
      this.activeCookieCategories.push(opt.category);
    }
    this.updateCookies();
  }

  getActiveCookieCategories() {
    return this.activeCookieCategories;
  }

  open() {
    this.isShown = true;
  }

  close() {
    this.isShown = false;
  }

  quitCookieToggler() {
    this.$emit("quit");
  }

  saveCookieToggler() {
    this.updateCookieCategories();
    this.updateCookies();
    this.$emit("save");
  }

  toggleCookie(option, value) {
    option.active = value;
    this.updateCookieCategories();
  }

  updateCookies() {
    localStorage.setItem(
      "sweetlab_cookies_enabled",
      JSON.stringify(this.activeCookieCategories)
    );
    parsers.update(this.getActiveCookieCategories());
  }

  mounted() {
    parsers.init();
    this.activeCookieCategories = JSON.parse(
      localStorage.getItem("sweetlab_cookies_enabled") || "[]"
    );
    for (let i = 0; i < this.opts.length; i++) {
      const opt = this.opts[i];
      if (!opt.required) {
        if (this.activeCookieCategories.indexOf(opt.category) != -1) {
          opt.active = true;
        } else opt.active = false;
      }
    }
    parsers.enable(this.getActiveCookieCategories());
  }
}
