








import { Prop, Vue, Component } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";

@Component({
  components: { Loader },
})
export default class PageLoader extends Vue {
}
