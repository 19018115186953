import axios from 'axios'
import { authHeader } from './auth-header'
import i18n from '../i18n-inline-loader'

const API_URL = process.env.VUE_APP_HTTP_PROTOCOL + '://' +
  process.env.VUE_APP_API_ADDRESS;

//axios.defaults.withCredentials = true

class AuthService {
  async updateLocaleFromLogin(userId): Promise<string> {
    const loginLanguage = localStorage.getItem("sweetlab_language_from_login") || 'it'
    await axios
      .post(
        API_URL + '/api/users/' +
        userId +
        "/language/" +
        localStorage.getItem("sweetlab_language_from_login")
      )
      .then(() => {
        localStorage.setItem("sweetlab_language", loginLanguage);
        localStorage.removeItem("sweetlab_language_from_login");
        return loginLanguage;
      });
    return loginLanguage;
  }

  loginLanguage() {
    if (localStorage.getItem("sweetlab_language_from_login")) {
      return localStorage.getItem("sweetlab_language_from_login");
    }
    else if (localStorage.getItem("sweetlab_language")) {
      return localStorage.getItem("sweetlab_language");
    }
    else {
      return "it";
    }
  }

  login(user) {
    return axios
      .post(API_URL + '/api/auth/login', {
        email: user.email,
        password: user.password,
        language: this.loginLanguage(),
        headers: authHeader(),
      })
      .then(async (response) => {
        if (response.data) {
          const auth = response.data;
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + auth['token'];
          if (localStorage.getItem("sweetlab_language_from_login")) {

            // Language selected from login: update locale

            auth["language"] = await this.updateLocaleFromLogin(auth["id"]);
          }

          localStorage.setItem('user', JSON.stringify(auth));
          localStorage.removeItem("sweetlab_language");
          localStorage.setItem("sweetlab_language", auth["language"]);
          i18n.locale = auth["language"];
          return response.data
        }
      })
  }

  forgotPassword(user) {
    return axios
      .post(API_URL + '/api/auth/forgotPassword', {
        email: user.email,
        language: this.loginLanguage()
      })
      .then((response) => {
        if (response.data) {
          return response.data
        }
      })
  }

  resetPassword(user) {
    return axios
      .post(API_URL + '/api/auth/resetPassword', {
        token: user.token,
        email: user.email,
        password: user.password,
        confirmPassword: user.confirmPassword,
        language: this.loginLanguage()
      })
      .then((response) => {
        if (response.data) {
          return response.data
        }
      })
  }

  resendVerify() {
    return axios
      .post(API_URL + '/api/auth/resendVerifyEmail', { headers: authHeader() })
      .then((response) => {
        if (response.data) {
          return response.data
        }
      })
  }

  verify(user) {
    return axios
      .post(API_URL + '/api/auth/verify/' + user.id + "/" + user.hash + "?expires=" + user.expires + "&signature=" + user.signature, { headers: authHeader() })
      .then((response) => {
        if (response.data) {
          return response.data
        }
      })
  }

  changeEmail(emailData) {
    return axios
      .post(API_URL +
        "/api/auth/changeEmail" + "/" + emailData.email + "?expires=" + emailData.expires + "&signature=" + emailData.signature, { headers: authHeader(), email: emailData.email }) //email nella richiesta per il controllo di validazione email
      .then(() => {
        return emailData.email;
      })
  }

  twoFactorChallenge(user) {
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + user.twoFactorAuthenticationToken
    return axios
      .post(API_URL + '/api/auth/2fa/challenge', {
        twoFactorAuthenticationSecret: user.twoFactorAuthenticationSecret,
        headers: authHeader()
      })
      .then((response) => {
        if (response.data) {
          const auth = response.data;
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + auth['token'];
          if (localStorage.getItem("sweetlab_language_from_login")) {
            axios
              .post(
                API_URL +
                "/api/users/" +
                auth["id"] +
                "/language/" +
                localStorage.getItem("sweetlab_language_from_login")
              )
              .then(() => {
                const newLanguage = localStorage.getItem("sweetlab_language_from_login") || 'it';
                localStorage.setItem("sweetlab_language", newLanguage);
                auth["language"] = localStorage.getItem("sweetlab_language");
                localStorage.removeItem("sweetlab_language_from_login");
              });
          }

          localStorage.setItem('user', JSON.stringify(auth))
          localStorage.removeItem("sweetlab_language");
          localStorage.setItem("sweetlab_language", auth["language"]);
          return auth
        }
      })
  }

  logout() {
    return axios
      .post(API_URL + '/api/auth/logout', { headers: authHeader() })
      .then((response) => {
        localStorage.removeItem('user');
        localStorage.removeItem('notifications');
        localStorage.removeItem('shoppingCart');
        return response.data
      })
  }

  register(user) {
    return axios.post(API_URL + '/api/auth/register', {
      username: user.username,
      email: user.email,
      password: user.password,
      language: this.loginLanguage()
    })
  }
}

export default new AuthService()
