






import { Component, Vue } from "vue-property-decorator";
import Teams from "@/components/custom/Teams.vue";

@Component({
  components: {
    Teams,
  },
})
export default class PageTeams extends Vue {}
