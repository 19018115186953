
































import { Component, Vue, Prop } from "vue-property-decorator";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: {
    ButtonInput,
  },
})
export default class LoadDefaultDataRecipes extends Vue {
  @Prop({ default: null }) private type;
  private loading = false;
  private user = this.$store.state.auth.user;
  private loadedData = false;

  currentTeam() {
    return this.user.currentTeam || null;
  }

  loadDefaultData() {
    this.loading = true;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.currentTeam() +
          "/insertDefaultData",
        {
          type: this.type,
          headers: authHeader(),
        }
      )
      .then(() => {
        this.$notify({
          group: "notificationsTable",
          title: this.$t("i18n_component_load_default_success").toString(),
          duration: 20000,
        });
        this.$emit("loaded");
      })
      .catch(() => {
        this.$notify({
          group: "notificationsTable",
          title: this.$t("i18n_component_load_default_error").toString(),
          type: "error",
          duration: 20000,
        });
      })
      .finally(() => {
        this.loading = false;
        this.loadedData = true;
      });
  }
}
