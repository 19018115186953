export default class IngredientInfo {
    id: string
    name: string
    type: string
    constructor(id, name, type) {
        this.id = id
        this.name = name
        this.type = type
    }

    public isValid(): boolean {
        if (this.id && this.id != "" && this.name && this.name != "" && this.type && this.type != "")
            return true;
        return false;
    }
}