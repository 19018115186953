import { render, staticRenderFns } from "./HeaderBarShortcut.vue?vue&type=template&id=256dfdfb&scoped=true&"
import script from "./HeaderBarShortcut.vue?vue&type=script&lang=ts&"
export * from "./HeaderBarShortcut.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderBarShortcut.vue?vue&type=style&index=0&id=256dfdfb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "256dfdfb",
  null
  
)

export default component.exports