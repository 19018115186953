














































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    ButtonInput,
    TextInput,
  },
})
export default class ConfirmCode extends Vue {
  private code = "";
  private isCodeValidated = true;
  private errorsFromServer: Array<string> = [];
  @Prop({ default: true }) private displayUndo;

  ConfirmCode() {
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/auth/2fa/confirm",
        {
          twoFactorAuthenticationSecret: this.code,
        }
      )
      .then(() => {
        this.$emit("confirmed");
      })
      .catch((error) => {
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
      });
  }
}
