

























































import { Component, Prop, Vue } from "vue-property-decorator";
import Logo from "@/components/graphic/Logo.vue";
import Loader from "@/components/graphic/Loader.vue";
import ApiVersion from "@/components/graphic/ApiVersion.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Logo,
    Loader,
    ApiVersion,
  },
})
export default class LoginPanel extends Vue {
  private Helper: Helper = Helper;
}
