
























import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    ButtonInput,
    Loader,
  },
})
export default class SelectTheme extends Vue {
  private loading = -1;
  private errors = "";
  private Helper: Helper = Helper;
  get supportedThemes() {
    return Helper.themes();
  }

  changeTheme(theme) {
    this.loading = theme;
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/users/" +
          this.currentUser().id +
          "/theme/" +
          theme
      )
      .then(() => {
        this.loading = -1;
        const user = JSON.parse(localStorage.getItem("user") || "");
        user["theme"] = theme;
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(user));
        this.$store.state.auth.user.theme = theme;
        localStorage.setItem("sweetlab_theme", theme);
        Helper.applyTheme(theme);
      })
      .catch((error) => {
        this.loading = -1;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_theme_update_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }
  isCurrentTheme(lang) {
    return lang["index"] === this.currentTheme();
  }
  currentTheme() {
    if (this.currentUser()) return this.currentUser().theme;
    else return "0";
  }
  currentUser() {
    return this.$store.state.auth.user || null;
  }
}
