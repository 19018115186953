var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st_flex-column"},[_c('page-items',{attrs:{"type":'etichetta',"itemType":'label',"skipIsUsedCheck":true,"title":_vm.$t('i18n_page_labels_title'),"singleTitle":_vm.$t('i18n_page_labels_single_title')},on:{"new-item":_vm.syncTemplate,"modify-item":_vm.syncTemplate},scopedSlots:_vm._u([{key:"edit-item",fn:function(slotProps){return [_c('text-input',{key:'itemName',attrs:{"inputType":'text',"startingValue":slotProps.scope.openModalModify.name,"requiredMessage":_vm.$t('i18n_input_required'),"icon":'fa fa-pencil-alt',"label":_vm.$t('i18n_page_items_name'),"placeholder":_vm.$t('i18n_page_items_modify_name'),"errors":slotProps.scope.errorsFromServer['itemName']},model:{value:(slotProps.scope.openModalModify.name),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify, "name", $$v)},expression:"slotProps.scope.openModalModify.name"}}),[_c('PDFViewer',{ref:"labelViewer",attrs:{"uid":slotProps.scope.openModalModify.id,"pdfName":'label',"url":_vm.recipeUrl,"refreshSrc":false,"data":_vm.pdfData},scopedSlots:_vm._u([{key:"sidebar-left",fn:function(){return [_c('button-input',{staticStyle:{"width":"50px"},attrs:{"buttonClass":'cu_btn-size-nn cu_btn-theme-transparent-rounded-slideleft',"icon":'fa fa-lg fa-chevron-right'},on:{"btn-click":function($event){return _vm.$refs.labelViewer.toggleSideBarLeft()}}}),_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_structure_section'),"icon":'fa fa-th'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"cu_page-labels-structure-thumb"},[_vm._v(" "+_vm._s(slotProps.scope.openModalModify.labelTemplate.numRowsX)+" x "+_vm._s(slotProps.scope.openModalModify.labelTemplate.numRowsY)+" ")])]},proxy:true},{key:"content",fn:function(){return [_c('arg-select-input',{attrs:{"availableArgs":[
                    { id: 0, name: 'P', description: 'Portrait' },
                    { id: 1, name: 'L', description: 'Landscape' } ],"startingArg":slotProps.scope.openModalModify.labelTemplate
                      .pageOrientation,"title":_vm.$t('i18n_page_labels_page_orientation')},on:{"selected-arg":function($event){return _vm.upFontFamily(
                      slotProps.scope.openModalModify.labelTemplate,
                      $event,
                      'pageOrientation'
                    )}}}),_c('arg-select-input',{attrs:{"availableArgs":[
                    { id: 0, name: 'mm', description: 'mm' },
                    { id: 1, name: 'in', description: 'in' },
                    { id: 2, name: 'pt', description: 'pt' },
                    { id: 3, name: 'cm', description: 'cm' } ],"startingArg":slotProps.scope.openModalModify.labelTemplate.pageUnit,"title":_vm.$t('i18n_page_labels_page_unit')},on:{"selected-arg":function($event){return _vm.upPageUnit(
                      slotProps.scope.openModalModify.labelTemplate,
                      $event
                    )}}}),_c('arg-select-input',{attrs:{"availableArgs":[
                    { id: 0, name: 'A3', description: 'A3' },
                    { id: 1, name: 'A4', description: 'A4' },
                    { id: 2, name: 'A5', description: 'A5' },
                    { id: 3, name: 'Letter', description: 'Letter' },
                    { id: 4, name: 'Legal', description: 'Legal' } ],"startingArg":slotProps.scope.openModalModify.labelTemplate.pageSize,"title":_vm.$t('i18n_page_labels_page_size')},on:{"selected-arg":function($event){return _vm.upFontFamily(
                      slotProps.scope.openModalModify.labelTemplate,
                      $event,
                      'pageSize'
                    )}}}),_c('text-input',{key:'numRowsX',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate.numRowsX,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":2,"maxNumber":30,"icon":'fa fa-ellipsis-h',"label":_vm.$t('i18n_page_labels_num_rows_x'),"placeholder":_vm.$t('i18n_page_labels_num_rows_x'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.numRowsX
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "numRowsX", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.numRowsX\n                  "}}),_c('text-input',{key:'numRowsY',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate.numRowsY,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"icon":'fa fa-ellipsis-v',"label":_vm.$t('i18n_page_labels_num_rows_y'),"placeholder":_vm.$t('i18n_page_labels_num_rows_y'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.numRowsY
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "numRowsY", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.numRowsY\n                  "}}),_c('text-input',{key:'labelMarginTop-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'labelMarginTop',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                      .labelMarginTop,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 200),"icon":'icomoon-margin-top-between',"label":_vm.$t('i18n_page_labels_label_margin_top'),"placeholder":_vm.$t('i18n_page_labels_label_margin_top'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate
                      .labelMarginTop
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                      , "labelMarginTop", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate\n                      .labelMarginTop\n                  "}}),_c('text-input',{key:'labelMarginLeft-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'labelMarginLeft',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                      .labelMarginLeft,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 200),"icon":'icomoon-margin-left-between',"label":_vm.$t('i18n_page_labels_label_margin_left'),"placeholder":_vm.$t('i18n_page_labels_label_margin_left'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate
                      .labelMarginLeft
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                      , "labelMarginLeft", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate\n                      .labelMarginLeft\n                  "}}),_c('text-input',{key:'startMarginTop-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'startMarginTop',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                      .startMarginTop,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 100),"icon":'icomoon-margin-top',"label":_vm.$t('i18n_page_labels_start_margin_top'),"placeholder":_vm.$t('i18n_page_labels_start_margin_top'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate
                      .startMarginTop
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                      , "startMarginTop", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate\n                      .startMarginTop\n                  "}}),_c('text-input',{key:'startMarginLeft-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'startMarginLeft',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                      .startMarginLeft,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 100),"icon":'icomoon-margin-left',"label":_vm.$t('i18n_page_labels_start_margin_left'),"placeholder":_vm.$t('i18n_page_labels_start_margin_left'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate
                      .startMarginLeft
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                      , "startMarginLeft", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate\n                      .startMarginLeft\n                  "}})]},proxy:true}],null,true)}),_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_label_section'),"icon":'fa fa-tag'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-center"},[_c('div',{staticClass:"cu_page-labels-label-thumb"},[_vm._v(" "+_vm._s(slotProps.scope.openModalModify.labelTemplate.labelWidth)+" x "+_vm._s(slotProps.scope.openModalModify.labelTemplate .labelHeight)+" ")]),_c('div',{staticClass:"st_margin-0px-2px"},[_c('div',{staticClass:"st_square-20px",style:('background-color: ' +
                        slotProps.scope.openModalModify.labelTemplate
                          .labelBgColor +
                        ';' +
                        (slotProps.scope.openModalModify.labelTemplate
                          .labelShape == 'R'
                          ? 'border-radius: ' +
                            (slotProps.scope.openModalModify
                              .labelShapeRadius *
                              20) /
                              slotProps.scope.openModalModify.labelTemplate
                                .labelHeight +
                            'px;' +
                            (slotProps.scope.openModalModify.labelTemplate
                              .labelBorder == 1
                              ? 'border: 1px solid #000;'
                              : '')
                          : ''))})])])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'labelShape',attrs:{"id":'labelShape',"title":_vm.$t('i18n_page_labels_label_shape'),"groupName":'labelShape',"options":['R'],"icons":['far fa-square'],"startingValue":slotProps.scope.openModalModify.labelTemplate.labelShape},on:{"input":function($event){return _vm.upTemplate($event, 'labelShape')}},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.labelShape
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "labelShape", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.labelShape\n                    "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_label_border'),"checkValue":slotProps.scope.openModalModify.labelTemplate
                            .labelBorder,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .labelBorder},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                            slotProps.scope.openModalModify.labelTemplate,
                            'labelBorder'
                          )}}})],1),(
                        slotProps.scope.openModalModify.labelTemplate
                          .labelShape == 'R'
                      )?_c('text-input',{key:'labelShapeRadius-' +
                        slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'labelShapeRadius',"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .labelShapeRadius,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":30,"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"icon":'icomoon-border-radius',"label":_vm.$t('i18n_page_labels_label_shape_radius'),"placeholder":_vm.$t('i18n_page_labels_label_shape_radius'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .labelShapeRadius
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "labelShapeRadius", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .labelShapeRadius\n                      "}}):_vm._e(),_c('text-input',{key:'labelWidth-' +
                        slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'labelWidth',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .labelWidth,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 200),"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_label_width'),"placeholder":_vm.$t('i18n_page_labels_label_width'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .labelWidth
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "labelWidth", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .labelWidth\n                      "}}),_c('text-input',{key:'labelHeight-' +
                        slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'labelHeight',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .labelHeight,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 300),"icon":'icomoon-height',"label":_vm.$t('i18n_page_labels_label_height'),"placeholder":_vm.$t('i18n_page_labels_label_height'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .labelHeight
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "labelHeight", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .labelHeight\n                      "}})],1)],1),_c('text-input',{key:'labelPaddingTop-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'labelPaddingTop',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                      .labelPaddingTop,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-horizontal',"label":_vm.$t('i18n_page_labels_label_padding_top'),"placeholder":_vm.$t('i18n_page_labels_label_padding_top'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate
                      .labelPaddingTop
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                      , "labelPaddingTop", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate\n                      .labelPaddingTop\n                  "}}),_c('text-input',{key:'labelPaddingLeft-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'labelPaddingLeft',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                      .labelPaddingLeft,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-vertical',"label":_vm.$t('i18n_page_labels_label_padding_left'),"placeholder":_vm.$t('i18n_page_labels_label_padding_left'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate
                      .labelPaddingLeft
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                      , "labelPaddingLeft", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate\n                      .labelPaddingLeft\n                  "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_label_colors'),"labelColor1":_vm.$t('i18n_page_labels_label_bg_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                        .labelBgColor},on:{"change-color-1":function($event){return _vm.upColor(
                        $event,
                        slotProps.scope.openModalModify.labelTemplate,
                        'labelBgColor'
                      )}}})],1)]},proxy:true}],null,true)}),_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_check_section'),"icon":'fa fa-check'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"cu_page-labels-sections-thumb"},[_vm._v(" "+_vm._s(_vm.numOfShows(slotProps.scope.openModalModify.labelTemplate))+" ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_header_section'),"checkValue":slotProps.scope.openModalModify.labelTemplate.headerShow,"startingValue":slotProps.scope.openModalModify.labelTemplate.headerShow},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                        slotProps.scope.openModalModify.labelTemplate,
                        'headerShow'
                      )}}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_table_section'),"checkValue":slotProps.scope.openModalModify.labelTemplate.tableShow,"startingValue":slotProps.scope.openModalModify.labelTemplate.tableShow},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                        slotProps.scope.openModalModify.labelTemplate,
                        'tableShow'
                      )}}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_note_section'),"checkValue":slotProps.scope.openModalModify.labelTemplate.noteShow,"startingValue":slotProps.scope.openModalModify.labelTemplate.noteShow},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                        slotProps.scope.openModalModify.labelTemplate,
                        'noteShow'
                      )}}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_qty_section'),"checkValue":slotProps.scope.openModalModify.labelTemplate.qtyShow,"startingValue":slotProps.scope.openModalModify.labelTemplate.qtyShow},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                        slotProps.scope.openModalModify.labelTemplate,
                        'qtyShow'
                      )}}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_batch_section'),"checkValue":slotProps.scope.openModalModify.labelTemplate.batchShow,"startingValue":slotProps.scope.openModalModify.labelTemplate.batchShow},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                        slotProps.scope.openModalModify.labelTemplate,
                        'batchShow'
                      )}}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_footer_section'),"checkValue":slotProps.scope.openModalModify.labelTemplate.footerShow,"startingValue":slotProps.scope.openModalModify.labelTemplate.footerShow},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                        slotProps.scope.openModalModify.labelTemplate,
                        'footerShow'
                      )}}})],1)]},proxy:true}],null,true)})]},proxy:true},{key:"sidebar-right",fn:function(){return [_c('button-input',{staticStyle:{"width":"50px"},attrs:{"buttonClass":'cu_btn-size-nn cu_btn-theme-transparent-rounded-slideright',"icon":'fa fa-lg fa-chevron-left'},on:{"btn-click":function($event){return _vm.$refs.labelViewer.toggleSideBarRight()}}}),_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_logo_section'),"icon":'fa fa-image'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{class:{
                    'st_image-cropper-20px':
                      slotProps.scope.openModalModify.labelTemplate
                        .logoShape == 'C',
                  },style:((slotProps.scope.openModalModify.labelTemplate
                      .logoShape == 'C'
                      ? 'background-image: url(\'' +
                        _vm.labelLogo(
                          slotProps.scope.openModalModify.labelTemplate
                            .logoPhotoPath
                        ) +
                        '\');'
                      : '') +
                    (slotProps.scope.openModalModify.labelTemplate
                      .logoBorder == 1 &&
                    slotProps.scope.openModalModify.labelTemplate.logoShape ==
                      'C'
                      ? 'border: 1px solid #000;'
                      : ''))},[(
                      slotProps.scope.openModalModify.labelTemplate
                        .logoShape == 'R'
                    )?_c('img',{staticClass:"cu_page-labels-logo-thumb",style:('width:' +
                      _vm.labelThumbWidth(
                        20,
                        slotProps.scope.openModalModify.labelTemplate
                          .logoWidth,
                        slotProps.scope.openModalModify.labelTemplate
                          .logoHeight
                      ) +
                      'px;' +
                      (slotProps.scope.openModalModify.labelTemplate
                        .logoShape == 'R'
                        ? 'border-radius: ' +
                          (slotProps.scope.openModalModify.labelTemplate
                            .logoShapeRadius *
                            20) /
                            slotProps.scope.openModalModify.labelTemplate
                              .logoHeight +
                          'px;' +
                          (slotProps.scope.openModalModify.labelTemplate
                            .logoBorder == 1
                            ? 'border: 1px solid #000;'
                            : '')
                        : '')),attrs:{"src":_vm.labelLogo(
                        slotProps.scope.openModalModify.labelTemplate
                          .logoPhotoPath
                      )}}):_vm._e()])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_logo_image"))+" ")]),_c('Dropzone',{ref:"labelDropzone",attrs:{"title":_vm.$t('i18n_page_labels_logo_image_info'),"icon":'fa fa-pencil',"thumb":_vm.thumb,"addUrl":_vm.$apiHttpProtocol +
                      '://' +
                      _vm.$apiAddress +
                      '/api/data/' +
                      _vm.Helper.currentTeam() +
                      '/label/' +
                      slotProps.scope.openModalModify.labelTemplate.id +
                      '/uploadLogo',"uploadedUrl":_vm.$apiHttpProtocol +
                      '://' +
                      _vm.$apiAddress +
                      '/api/data/' +
                      _vm.Helper.currentTeam() +
                      '/label/' +
                      slotProps.scope.openModalModify.labelTemplate.id +
                      '/uploadedLogo',"startingValue":_vm.labelLogo(
                        slotProps.scope.openModalModify.labelTemplate
                          .logoPhotoPath
                      )},on:{"start-upload":function () {
                                  var args = [], len = arguments.length;
                                  while ( len-- ) args[ len ] = arguments[ len ];

                                  return _vm.uploadedLabelImage.apply(
                          void 0, [ slotProps.scope.openModalModify.labelTemplate ].concat( args )
                        );
                      },"removed":function($event){return _vm.removedLabelImage(
                        slotProps.scope.openModalModify.labelTemplate
                      )},"thumbnail":_vm.addLabelThumbnail}})],1),[_c('text-input',{key:'logoX-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"isDisabled":slotProps.scope.openModalModify.labelTemplate
                        .logoPhotoPath == '' ||
                      slotProps.scope.openModalModify.labelTemplate
                        .logoPhotoPath == null,"uid":'logoX',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.logoX,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                        .labelWidth -
                      2 *
                        slotProps.scope.openModalModify.labelTemplate
                          .labelPaddingLeft -
                      slotProps.scope.openModalModify.labelTemplate.logoWidth,"icon":'icomoon-axis-x',"label":_vm.$t('i18n_page_labels_logo_x'),"placeholder":_vm.$t('i18n_page_labels_logo_x'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.logoX
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "logoX", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.logoX\n                    "}}),_c('text-input',{key:'logoY-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"isDisabled":slotProps.scope.openModalModify.labelTemplate
                        .logoPhotoPath == '' ||
                      slotProps.scope.openModalModify.labelTemplate
                        .logoPhotoPath == null,"uid":'logoY',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.logoY,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                        .labelHeight -
                      2 *
                        slotProps.scope.openModalModify.labelTemplate
                          .labelPaddingTop -
                      slotProps.scope.openModalModify.labelTemplate.logoHeight,"icon":'icomoon-axis-y',"label":_vm.$t('i18n_page_labels_logo_y'),"placeholder":_vm.$t('i18n_page_labels_logo_y'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.logoY
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "logoY", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.logoY\n                    "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'logoShape',attrs:{"id":'logoShape',"title":_vm.$t('i18n_page_labels_logo_shape'),"groupName":'logoShape',"options":['R', 'C'],"icons":['far fa-square', 'far fa-circle'],"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .logoShape},on:{"input":function($event){return _vm.upTemplate($event, 'logoShape')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .logoShape
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "logoShape", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .logoShape\n                      "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_logo_border'),"checkValue":slotProps.scope.openModalModify.labelTemplate
                              .logoBorder,"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .logoBorder,"isDisabled":slotProps.scope.openModalModify.labelTemplate
                              .logoPhotoPath == '' ||
                            slotProps.scope.openModalModify.labelTemplate
                              .logoPhotoPath == null},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                              slotProps.scope.openModalModify.labelTemplate,
                              'logoBorder'
                            )}}})],1),(
                          slotProps.scope.openModalModify.labelTemplate
                            .logoShape == 'R'
                        )?[_c('text-input',{key:'logoShapeRadius',attrs:{"isDisabled":slotProps.scope.openModalModify.labelTemplate
                              .logoPhotoPath == '' ||
                            slotProps.scope.openModalModify.labelTemplate
                              .logoPhotoPath == null,"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .logoShapeRadius,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":30,"infoLabel":slotProps.scope.openModalModify.labelTemplate
                              .pageUnit,"icon":'icomoon-border-radius',"label":_vm.$t('i18n_page_labels_logo_shape_radius'),"placeholder":_vm.$t('i18n_page_labels_logo_shape_radius'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                            slotProps.scope.openModalModify.labelTemplate
                              .logoShapeRadius
                          ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                              , "logoShapeRadius", $$v)},expression:"\n                            slotProps.scope.openModalModify.labelTemplate\n                              .logoShapeRadius\n                          "}}),_c('div',{staticStyle:{"position":"relative"}},[_c('text-input',{key:'logoWidth-' +
                              slotProps.scope.openModalModify.labelTemplate
                                .pageUnit,ref:"logoWidth",attrs:{"isDisabled":slotProps.scope.openModalModify.labelTemplate
                                .logoPhotoPath == '' ||
                              slotProps.scope.openModalModify.labelTemplate
                                .logoPhotoPath == null,"uid":'logoWidth',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate
                                .pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                                .logoWidth,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":1,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                                .labelWidth,"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_logo_width'),"placeholder":_vm.$t('i18n_page_labels_logo_width'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":function () {
                                          var args = [], len = arguments.length;
                                          while ( len-- ) args[ len ] = arguments[ len ];

                                          return _vm.upTemplateLogoWidth.apply(
                                  void 0, [ slotProps.scope.openModalModify
                                    .labelTemplate,
                                  true ].concat( args )
                                );
                      },"step-pressed":function () {
                                          var args = [], len = arguments.length;
                                          while ( len-- ) args[ len ] = arguments[ len ];

                                          return _vm.upTemplateLogoWidth.apply(
                                  void 0, [ slotProps.scope.openModalModify
                                    .labelTemplate,
                                  true ].concat( args )
                                );
                      }},model:{value:(
                              slotProps.scope.openModalModify.labelTemplate
                                .logoWidth
                            ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                                , "logoWidth", $$v)},expression:"\n                              slotProps.scope.openModalModify.labelTemplate\n                                .logoWidth\n                            "}}),_c('button-input',{staticStyle:{"position":"absolute","left":"0px","top":"56px"},attrs:{"icon":'fa fa-' +
                              (_vm.pdfData.lockLogoSize ? 'lock' : 'lock-open'),"isDisabled":slotProps.scope.openModalModify.labelTemplate
                                .logoPhotoPath == '' ||
                              slotProps.scope.openModalModify.labelTemplate
                                .logoPhotoPath == null,"buttonClass":'cu_btn-square-xs cu_btn-theme-normal'},on:{"btn-click":_vm.toggleLockLogo}}),_c('text-input',{key:'logoHeight-' +
                              slotProps.scope.openModalModify.labelTemplate
                                .pageUnit,ref:"logoHeight",attrs:{"value":slotProps.scope.openModalModify.labelTemplate
                                .logoHeight,"isDisabled":slotProps.scope.openModalModify.labelTemplate
                                .logoPhotoPath == '' ||
                              slotProps.scope.openModalModify.labelTemplate
                                .logoPhotoPath == null,"uid":'logoHeight',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate
                                .pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                                .logoHeight,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":1,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                                .labelHeight,"icon":'icomoon-height',"label":_vm.$t('i18n_page_labels_logo_height'),"placeholder":_vm.$t('i18n_page_labels_logo_height'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":function () {
                                          var args = [], len = arguments.length;
                                          while ( len-- ) args[ len ] = arguments[ len ];

                                          return _vm.upTemplateLogoHeight.apply(
                                  void 0, [ slotProps.scope.openModalModify
                                    .labelTemplate,
                                  true ].concat( args )
                                );
                      },"step-pressed":function () {
                                          var args = [], len = arguments.length;
                                          while ( len-- ) args[ len ] = arguments[ len ];

                                          return _vm.upTemplateLogoHeight.apply(
                                  void 0, [ slotProps.scope.openModalModify
                                    .labelTemplate,
                                  true ].concat( args )
                                );
                      }},model:{value:(
                              slotProps.scope.openModalModify.labelTemplate
                                .logoHeight
                            ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                                , "logoHeight", $$v)},expression:"\n                              slotProps.scope.openModalModify.labelTemplate\n                                .logoHeight\n                            "}})],1)]:(
                          slotProps.scope.openModalModify.labelTemplate
                            .logoShape == 'C'
                        )?_c('text-input',{key:'logoCircleRadius',attrs:{"isDisabled":slotProps.scope.openModalModify.labelTemplate
                            .logoPhotoPath == '' ||
                          slotProps.scope.openModalModify.labelTemplate
                            .logoPhotoPath == null,"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .logoCircleRadius,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":0,"maxNumber":30,"icon":'icomoon-border-radius',"label":_vm.$t('i18n_page_labels_logo_circle_radius'),"placeholder":_vm.$t('i18n_page_labels_logo_circle_radius'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .logoCircleRadius
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "logoCircleRadius", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .logoCircleRadius\n                        "}}):_vm._e()],2)],1)]]},proxy:true}],null,true)}),_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_header_section'),"icon":'fa fa-image'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{style:('max-width: 150px; height: 20px; line-height: 20px; overflow: hidden; text-overflow: ellipsis; font-size: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .headerFontSize +
                    'px; color: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .headerFontColor +
                    '; font-family: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .headerFontFamily +
                    ';' +
                    _vm.fontStyleClass(
                      slotProps.scope.openModalModify.labelTemplate
                        .headerFontStyle
                    ))},[_vm._v(_vm._s(slotProps.scope.openModalModify.labelTemplate.headerText))])]},proxy:true},{key:"content",fn:function(){return [_c('text-input',{key:'headerText',attrs:{"inputType":'text',"startingValue":slotProps.scope.openModalModify.labelTemplate.headerText,"requiredMessage":_vm.$t('i18n_input_required'),"icon":'fa fa-font',"label":_vm.$t('i18n_page_labels_header_text'),"placeholder":_vm.$t('i18n_page_labels_header_text'),"errors":slotProps.scope.errorsFromServer
                      ? slotProps.scope.errorsFromServer[
                          'itemTemplate.headerText'
                        ]
                      : null},on:{"key-press":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.headerText
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "headerText", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.headerText\n                  "}}),_c('text-input',{key:'headerWidth-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'headerWidth',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.headerWidth,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate.labelWidth -
                    2 *
                      slotProps.scope.openModalModify.labelTemplate
                        .labelPaddingLeft,"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_header_width'),"placeholder":_vm.$t('i18n_page_labels_header_width'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.headerWidth
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "headerWidth", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.headerWidth\n                  "}}),_c('text-input',{key:'headerX-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'headerX',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.headerX,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 200),"icon":'icomoon-axis-x',"label":_vm.$t('i18n_page_labels_logo_x'),"placeholder":_vm.$t('i18n_page_labels_logo_x'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.headerX
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "headerX", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.headerX\n                  "}}),_c('text-input',{key:'headerY-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'headerY',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.headerY,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 200),"icon":'icomoon-axis-y',"label":_vm.$t('i18n_page_labels_header_y'),"placeholder":_vm.$t('i18n_page_labels_header_y'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.headerY
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "headerY", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.headerY\n                  "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-header"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_header_text_subsection"))+" ")]),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'headerAlignment',attrs:{"id":'headerAlignment',"title":_vm.$t('i18n_page_labels_header_alignment'),"groupName":'headerAlignment',"options":['L', 'C', 'R'],"icons":[
                        'fas fa-align-left',
                        'fas fa-align-center',
                        'fas fa-align-right' ],"tiny":true,"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .headerAlignment},on:{"input":function($event){return _vm.upTemplate($event, 'headerAlignment')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .headerAlignment
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "headerAlignment", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .headerAlignment\n                      "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_header_font_colors'),"labelColor1":_vm.$t('i18n_page_labels_header_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                          .headerFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                          $event,
                          slotProps.scope.openModalModify.labelTemplate,
                          'headerFontColor'
                        )}}})],1),_c('text-input',{key:'headerFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .headerFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_header_font_size'),"placeholder":_vm.$t('i18n_page_labels_header_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .headerFontSize
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "headerFontSize", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .headerFontSize\n                    "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'headerFontStyle',attrs:{"id":'headerFontStyle',"title":_vm.$t('i18n_page_labels_header_font_style'),"groupName":'headerFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                        'fas fa-font',
                        'fas fa-bold',
                        'fas fa-italic',
                        'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .headerFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'headerFontStyle')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .headerFontStyle
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "headerFontStyle", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .headerFontStyle\n                      "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify.labelTemplate
                          .headerFontFamily,"title":_vm.$t('i18n_page_labels_header_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                          slotProps.scope.openModalModify.labelTemplate,
                          $event,
                          'headerFontFamily'
                        )}}})],1)],1)]},proxy:true}],null,true)}),_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_title_section'),"icon":'fa fa-image'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{style:('max-width: 150px; height: 20px; line-height: 20px; overflow: hidden; text-overflow: ellipsis; font-size: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .titleFontSize +
                    'px; color: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .titleFontColor +
                    '; font-family: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .titleFontFamily +
                    ';' +
                    _vm.fontStyleClass(
                      slotProps.scope.openModalModify.labelTemplate
                        .titleFontStyle
                    ))},[_vm._v(_vm._s(_vm.pdfData.name))])]},proxy:true},{key:"content",fn:function(){return [_c('text-input',{key:'titleWidth-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'titleWidth',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.titleWidth,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate.labelWidth -
                    2 *
                      slotProps.scope.openModalModify.labelTemplate
                        .labelPaddingLeft,"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_title_width'),"placeholder":_vm.$t('i18n_page_labels_title_width'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.titleWidth
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "titleWidth", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.titleWidth\n                  "}}),_c('text-input',{key:'titleX-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'titleX',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.titleX,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 200),"icon":'icomoon-axis-x',"label":_vm.$t('i18n_page_labels_logo_x'),"placeholder":_vm.$t('i18n_page_labels_logo_x'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.titleX
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "titleX", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.titleX\n                  "}}),_c('text-input',{key:'titleY-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'titleY',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.titleY,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 200),"icon":'icomoon-axis-y',"label":_vm.$t('i18n_page_labels_title_y'),"placeholder":_vm.$t('i18n_page_labels_title_y'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.titleY
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "titleY", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.titleY\n                  "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_title_text_subsection"))+" ")]),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'titleAlignment',attrs:{"id":'titleAlignment',"title":_vm.$t('i18n_page_labels_title_alignment'),"groupName":'titleAlignment',"options":['L', 'C', 'R'],"icons":[
                        'fas fa-align-left',
                        'fas fa-align-center',
                        'fas fa-align-right' ],"tiny":true,"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .titleAlignment},on:{"input":function($event){return _vm.upTemplate($event, 'titleAlignment')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .titleAlignment
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "titleAlignment", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .titleAlignment\n                      "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_title_font_colors'),"labelColor1":_vm.$t('i18n_page_labels_title_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                          .titleFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                          $event,
                          slotProps.scope.openModalModify.labelTemplate,
                          'titleFontColor'
                        )}}})],1),_c('text-input',{key:'titleFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .titleFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_title_font_size'),"placeholder":_vm.$t('i18n_page_labels_title_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .titleFontSize
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "titleFontSize", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .titleFontSize\n                    "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'titleFontStyle',attrs:{"id":'titleFontStyle',"title":_vm.$t('i18n_page_labels_title_font_style'),"groupName":'titleFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                        'fas fa-font',
                        'fas fa-bold',
                        'fas fa-italic',
                        'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .titleFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'titleFontStyle')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .titleFontStyle
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "titleFontStyle", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .titleFontStyle\n                      "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify.labelTemplate
                          .titleFontFamily,"title":_vm.$t('i18n_page_labels_title_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                          slotProps.scope.openModalModify.labelTemplate,
                          $event,
                          'titleFontFamily'
                        )}}})],1)],1)]},proxy:true}],null,true)}),(slotProps.scope.openModalModify.labelTemplate.tableShow)?_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_table_section'),"icon":'fa fa-table'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-center"},[_c('div',{staticClass:"st_margin-0px-2px"},[_c('div',{staticClass:"st_square-20px",style:('position: relative; background-color: ' +
                        slotProps.scope.openModalModify.labelTemplate
                          .tableHeaderBgColor)},[_c('div',{staticClass:"st_square-7px",style:('position:absolute; right: 0px; bottom: 0px; background-color: ' +
                          slotProps.scope.openModalModify.labelTemplate
                            .tableHeaderFontColor)})])]),_c('div',{staticClass:"st_margin-0px-2px"},[_c('div',{staticClass:"st_square-20px",style:('position: relative; background-color: ' +
                        slotProps.scope.openModalModify.labelTemplate
                          .tableRowOddBgColor)},[_c('div',{staticClass:"st_square-7px",style:('position:absolute; right: 0px; bottom: 0px; background-color: ' +
                          slotProps.scope.openModalModify.labelTemplate
                            .tableRowFontColor)})])]),_c('div',{staticClass:"st_margin-0px-2px"},[_c('div',{staticClass:"st_square-20px",style:('position: relative; background-color: ' +
                        slotProps.scope.openModalModify.labelTemplate
                          .tableRowEvenBgColor)},[_c('div',{staticClass:"st_square-7px",style:('position:absolute; right: 0px; bottom: 0px; background-color: ' +
                          slotProps.scope.openModalModify.labelTemplate
                            .tableRowFontColor)})])])])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_table_border'),"checkValue":slotProps.scope.openModalModify.labelTemplate
                        .tableBorder,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .tableBorder},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                        slotProps.scope.openModalModify.labelTemplate,
                        'tableBorder'
                      )}}})],1),_c('text-input',{key:'tableWidth-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'tableWidth',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.tableWidth,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate.labelWidth -
                    2 *
                      slotProps.scope.openModalModify.labelTemplate
                        .labelPaddingLeft,"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_table_width'),"placeholder":_vm.$t('i18n_page_labels_table_width'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.tableWidth
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "tableWidth", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.tableWidth\n                  "}}),_c('text-input',{key:'tableX-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'tableX',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.tableX,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate.labelWidth -
                    2 *
                      slotProps.scope.openModalModify.labelTemplate
                        .labelPaddingLeft -
                    slotProps.scope.openModalModify.labelTemplate.tableWidth,"icon":'icomoon-axis-x',"label":_vm.$t('i18n_page_labels_table_x'),"placeholder":_vm.$t('i18n_page_labels_table_x'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.tableX
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "tableX", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.tableX\n                  "}}),_c('text-input',{key:'tableY-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'tableY',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.tableY,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                      .labelHeight -
                    2 *
                      slotProps.scope.openModalModify.labelTemplate
                        .labelPaddingTop,"icon":'icomoon-axis-y',"label":_vm.$t('i18n_page_labels_table_y'),"placeholder":_vm.$t('i18n_page_labels_table_y'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.tableY
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "tableY", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.tableY\n                  "}}),_c('text-input',{key:'tableCellPadding-' +
                    slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'tableCellPadding',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                      .tableCellPadding,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-vertical',"label":_vm.$t('i18n_page_labels_table_cell_padding'),"placeholder":_vm.$t('i18n_page_labels_table_cell_padding'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate
                      .tableCellPadding
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                      , "tableCellPadding", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate\n                      .tableCellPadding\n                  "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_table_header"))+" ")]),_c('text-input',{key:'tableHeaderSize-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'tableHeaderSize',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .tableHeaderSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_table_header_size'),"placeholder":_vm.$t('i18n_page_labels_table_header_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .tableHeaderSize
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "tableHeaderSize", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .tableHeaderSize\n                    "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_table_header_colors'),"labelColor1":_vm.$t('i18n_page_labels_table_header_bg_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                          .tableHeaderBgColor,"labelColor2":_vm.$t('i18n_page_labels_table_header_font_color'),"startingColor2":slotProps.scope.openModalModify.labelTemplate
                          .tableHeaderFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                          $event,
                          slotProps.scope.openModalModify.labelTemplate,
                          'tableHeaderBgColor'
                        )},"change-color-2":function($event){return _vm.upColor(
                          $event,
                          slotProps.scope.openModalModify.labelTemplate,
                          'tableHeaderFontColor'
                        )}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_table_header_text_subsection"))+" ")]),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('text-input',{key:'tableHeaderFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .tableHeaderFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_table_header_font_size'),"placeholder":_vm.$t('i18n_page_labels_table_header_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .tableHeaderFontSize
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "tableHeaderFontSize", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .tableHeaderFontSize\n                        "}}),_c('group-radio-input',{key:'tableHeaderFontStyle',attrs:{"id":'tableHeaderFontStyle',"title":_vm.$t('i18n_page_labels_table_header_font_style'),"groupName":'tableHeaderFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                          'fas fa-font',
                          'fas fa-bold',
                          'fas fa-italic',
                          'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .tableHeaderFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'tableHeaderFontStyle')}},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .tableHeaderFontStyle
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "tableHeaderFontStyle", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .tableHeaderFontStyle\n                        "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify
                            .tableHeaderFontFamily,"title":_vm.$t('i18n_page_labels_table_header_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                            slotProps.scope.openModalModify.labelTemplate,
                            $event,
                            'tableHeaderFontFamily'
                          )}}})],1)])],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_table_rows"))+" ")]),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('text-input',{key:'tableRowSize-' +
                        slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'tableRowSize',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .tableRowSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_table_row_size'),"placeholder":_vm.$t('i18n_page_labels_table_row_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .tableRowSize
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "tableRowSize", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .tableRowSize\n                      "}}),_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_table_row_colors'),"labelColor1":_vm.$t('i18n_page_labels_table_row_odd_bg_color'),"labelColor2":_vm.$t('i18n_page_labels_table_row_even_bg_color'),"labelColor3":_vm.$t('i18n_page_labels_table_row_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                          .tableRowOddBgColor,"startingColor2":slotProps.scope.openModalModify.labelTemplate
                          .tableRowEvenBgColor,"startingColor3":slotProps.scope.openModalModify.labelTemplate
                          .tableRowFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                          $event,
                          slotProps.scope.openModalModify.labelTemplate,
                          'tableRowOddBgColor'
                        )},"change-color-2":function($event){return _vm.upColor(
                          $event,
                          slotProps.scope.openModalModify.labelTemplate,
                          'tableRowEvenBgColor'
                        )},"change-color-3":function($event){return _vm.upColor(
                          $event,
                          slotProps.scope.openModalModify.labelTemplate,
                          'tableRowFontColor'
                        )}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_table_row_text_subsection"))+" ")]),_c('text-input',{key:'tableRowFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .tableRowFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_table_row_font_size'),"placeholder":_vm.$t('i18n_page_labels_table_row_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .tableRowFontSize
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "tableRowFontSize", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .tableRowFontSize\n                      "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'tableRowFontStyle',attrs:{"id":'tableRowFontStyle',"title":_vm.$t('i18n_page_labels_table_row_font_style'),"groupName":'tableRowFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                          'fas fa-font',
                          'fas fa-bold',
                          'fas fa-italic',
                          'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .tableRowFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'tableRowFontStyle')}},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .tableRowFontStyle
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "tableRowFontStyle", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .tableRowFontStyle\n                        "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify.labelTemplate
                            .tableRowFontFamily,"title":_vm.$t('i18n_page_labels_table_row_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                            slotProps.scope.openModalModify.labelTemplate,
                            $event,
                            'tableRowFontFamily'
                          )}}})],1)],1)])]},proxy:true}],null,true)}):_vm._e(),(slotProps.scope.openModalModify.labelTemplate.noteShow)?_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_note_section'),"icon":'fa fa-info'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-center"},[_c('div',{staticClass:"cu_page-labels-note-thumb"})])]},proxy:true},{key:"content",fn:function(){return [(
                    slotProps.scope.openModalModify.labelTemplate.noteShow
                  )?[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'noteShape',attrs:{"id":'noteShape',"title":_vm.$t('i18n_page_labels_note_shape'),"groupName":'noteShape',"options":['R'],"icons":['far fa-square'],"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .noteShape},on:{"input":function($event){return _vm.upTemplate($event, 'noteShape')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .noteShape
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "noteShape", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .noteShape\n                      "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_note_border'),"checkValue":slotProps.scope.openModalModify.labelTemplate
                              .noteBorder,"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .noteBorder},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                              slotProps.scope.openModalModify.labelTemplate,
                              'noteBorder'
                            )}}})],1),(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteShape == 'R'
                        )?_c('text-input',{key:'noteShapeRadius-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'noteShapeRadius',"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .noteShapeRadius,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":30,"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"icon":'icomoon-border-radius',"label":_vm.$t('i18n_page_labels_note_shape_radius'),"placeholder":_vm.$t('i18n_page_labels_note_shape_radius'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteShapeRadius
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "noteShapeRadius", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .noteShapeRadius\n                        "}}):_vm._e(),_c('text-input',{key:'noteWidth-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'noteWidth',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .noteWidth,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                            .noteWidth -
                          2 *
                            slotProps.scope.openModalModify.labelTemplate
                              .notePaddingLeft,"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_note_width'),"placeholder":_vm.$t('i18n_page_labels_note_width'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteWidth
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "noteWidth", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .noteWidth\n                        "}})],1)],1),_c('text-input',{key:'noteTitle',attrs:{"inputType":'text',"startingValue":slotProps.scope.openModalModify.labelTemplate.noteTitle,"requiredMessage":_vm.$t('i18n_input_required'),"icon":'fa fa-font',"label":_vm.$t('i18n_page_labels_note_text'),"placeholder":_vm.$t('i18n_page_labels_note_text'),"errors":slotProps.scope.errorsFromServer
                        ? slotProps.scope.errorsFromServer[
                            'itemTemplate.noteTitle'
                          ]
                        : null},on:{"key-press":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.noteTitle
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "noteTitle", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.noteTitle\n                    "}}),_c('text-input',{key:'noteX-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'noteX',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.noteX,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                        .labelWidth -
                      2 *
                        slotProps.scope.openModalModify.labelTemplate
                          .labelPaddingLeft -
                      slotProps.scope.openModalModify.labelTemplate.noteWidth,"icon":'icomoon-axis-x',"label":_vm.$t('i18n_page_labels_note_x'),"placeholder":_vm.$t('i18n_page_labels_note_x'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.noteX
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "noteX", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.noteX\n                    "}}),_c('text-input',{key:'noteY-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'noteY',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.noteY,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                        .labelHeight -
                      2 *
                        slotProps.scope.openModalModify.labelTemplate
                          .labelPaddingTop,"icon":'icomoon-axis-y',"label":_vm.$t('i18n_page_labels_note_y'),"placeholder":_vm.$t('i18n_page_labels_note_y'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.noteY
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "noteY", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.noteY\n                    "}}),_c('text-input',{key:'notePaddingTop-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'notePaddingTop',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .notePaddingTop,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-horizontal',"label":_vm.$t('i18n_page_labels_note_padding_top'),"placeholder":_vm.$t('i18n_page_labels_note_padding_top'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .notePaddingTop
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "notePaddingTop", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .notePaddingTop\n                    "}}),_c('text-input',{key:'notePaddingLeft-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'notePaddingLeft',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .notePaddingLeft,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-vertical',"label":_vm.$t('i18n_page_labels_note_padding_left'),"placeholder":_vm.$t('i18n_page_labels_note_padding_left'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .notePaddingLeft
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "notePaddingLeft", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .notePaddingLeft\n                    "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_note_title_subsection"))+" ")]),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'noteTitleAlignment',attrs:{"id":'noteTitleAlignment',"title":_vm.$t('i18n_page_labels_note_alignment'),"groupName":'noteTitleAlignment',"options":['L', 'C', 'R'],"icons":[
                          'fas fa-align-left',
                          'fas fa-align-center',
                          'fas fa-align-right' ],"tiny":true,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .noteTitleAlignment},on:{"input":function($event){return _vm.upTemplate($event, 'noteTitleAlignment')}},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteTitleAlignment
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "noteTitleAlignment", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .noteTitleAlignment\n                        "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_note_colors'),"labelColor1":_vm.$t('i18n_page_labels_note_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                            .noteTitleFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                            $event,
                            slotProps.scope.openModalModify.labelTemplate,
                            'noteTitleFontColor'
                          )}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_note_text_subsection"))+" ")]),_c('text-input',{key:'noteTitleFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .noteTitleFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_note_font_size'),"placeholder":_vm.$t('i18n_page_labels_note_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteTitleFontSize
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "noteTitleFontSize", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .noteTitleFontSize\n                        "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'noteTitleFontStyle',attrs:{"id":'noteTitleFontStyle',"title":_vm.$t('i18n_page_labels_note_font_style'),"groupName":'noteTitleFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                            'fas fa-font',
                            'fas fa-bold',
                            'fas fa-italic',
                            'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .noteTitleFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'noteTitleFontStyle')}},model:{value:(
                            slotProps.scope.openModalModify.labelTemplate
                              .noteTitleFontStyle
                          ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                              , "noteTitleFontStyle", $$v)},expression:"\n                            slotProps.scope.openModalModify.labelTemplate\n                              .noteTitleFontStyle\n                          "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify
                              .noteTitleFontFamily,"title":_vm.$t('i18n_page_labels_note_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                              slotProps.scope.openModalModify.labelTemplate,
                              $event,
                              'noteTitleFontFamily'
                            )}}})],1),_c('text-input',{key:'noteTitleRowHeight-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'noteTitleRowHeight',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .noteTitleRowHeight,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_note_size'),"placeholder":_vm.$t('i18n_page_labels_note_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteTitleRowHeight
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "noteTitleRowHeight", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .noteTitleRowHeight\n                        "}})],1)]),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_note_text_subsection"))+" ")]),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'noteTextAlignment',attrs:{"id":'noteTextAlignment',"title":_vm.$t('i18n_page_labels_note_alignment'),"groupName":'noteTextAlignment',"options":['L', 'C', 'R'],"icons":[
                          'fas fa-align-left',
                          'fas fa-align-center',
                          'fas fa-align-right' ],"tiny":true,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .noteTextAlignment},on:{"input":function($event){return _vm.upTemplate($event, 'noteTextAlignment')}},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteTextAlignment
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "noteTextAlignment", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .noteTextAlignment\n                        "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_note_colors'),"labelColor1":_vm.$t('i18n_page_labels_note_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                            .noteTextFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                            $event,
                            slotProps.scope.openModalModify.labelTemplate,
                            'noteTextFontColor'
                          )}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_note_text_subsection"))+" ")]),_c('text-input',{key:'noteTextFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .noteTextFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_note_font_size'),"placeholder":_vm.$t('i18n_page_labels_note_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteTextFontSize
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "noteTextFontSize", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .noteTextFontSize\n                        "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'noteTextFontStyle',attrs:{"id":'noteTextFontStyle',"title":_vm.$t('i18n_page_labels_note_font_style'),"groupName":'noteTextFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                            'fas fa-font',
                            'fas fa-bold',
                            'fas fa-italic',
                            'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .noteTextFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'noteTextFontStyle')}},model:{value:(
                            slotProps.scope.openModalModify.labelTemplate
                              .noteTextFontStyle
                          ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                              , "noteTextFontStyle", $$v)},expression:"\n                            slotProps.scope.openModalModify.labelTemplate\n                              .noteTextFontStyle\n                          "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify.labelTemplate
                              .noteTextFontFamily,"title":_vm.$t('i18n_page_labels_note_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                              slotProps.scope.openModalModify.labelTemplate,
                              $event,
                              'noteTextFontFamily'
                            )}}})],1),_c('text-input',{key:'noteTextRowHeight-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'noteTextRowHeight',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .noteTextRowHeight,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_note_size'),"placeholder":_vm.$t('i18n_page_labels_note_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .noteTextRowHeight
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "noteTextRowHeight", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .noteTextRowHeight\n                        "}})],1)])]:_vm._e()]},proxy:true}],null,true)}):_vm._e(),(slotProps.scope.openModalModify.labelTemplate.qtyShow)?_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_qty_section'),"icon":'fa fa-th-list'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-center"},[_c('div',{staticClass:"cu_page-labels-qty-thumb"})])]},proxy:true},{key:"content",fn:function(){return [(slotProps.scope.openModalModify.labelTemplate.qtyShow)?[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'qtyShape',attrs:{"id":'qtyShape',"title":_vm.$t('i18n_page_labels_qty_shape'),"groupName":'qtyShape',"options":['R'],"icons":['far fa-square'],"startingValue":slotProps.scope.openModalModify.labelTemplate.qtyShape},on:{"input":function($event){return _vm.upTemplate($event, 'qtyShape')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate.qtyShape
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "qtyShape", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate.qtyShape\n                      "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_qty_border'),"checkValue":slotProps.scope.openModalModify.labelTemplate
                              .qtyBorder,"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .qtyBorder},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                              slotProps.scope.openModalModify.labelTemplate,
                              'qtyBorder'
                            )}}})],1),(
                          slotProps.scope.openModalModify.labelTemplate
                            .qtyShape == 'R'
                        )?_c('text-input',{key:'qtyShapeRadius-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'qtyShapeRadius',"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .qtyShapeRadius,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":30,"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"icon":'icomoon-border-radius',"label":_vm.$t('i18n_page_labels_qty_shape_radius'),"placeholder":_vm.$t('i18n_page_labels_qty_shape_radius'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .qtyShapeRadius
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "qtyShapeRadius", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .qtyShapeRadius\n                        "}}):_vm._e(),_c('text-input',{key:'qtyWidth-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'qtyWidth',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .qtyWidth,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                            .qtyWidth -
                          2 *
                            slotProps.scope.openModalModify.labelTemplate
                              .qtyPaddingLeft,"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_qty_width'),"placeholder":_vm.$t('i18n_page_labels_qty_width'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .qtyWidth
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "qtyWidth", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .qtyWidth\n                        "}})],1)],1),_c('text-input',{key:'qtyX-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'qtyX',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.qtyX,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                        .labelWidth -
                      2 *
                        slotProps.scope.openModalModify.labelTemplate
                          .labelPaddingLeft -
                      slotProps.scope.openModalModify.labelTemplate.qtyWidth,"icon":'icomoon-axis-x',"label":_vm.$t('i18n_page_labels_qty_x'),"placeholder":_vm.$t('i18n_page_labels_qty_x'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.qtyX
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "qtyX", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.qtyX\n                    "}}),_c('text-input',{key:'qtyY-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'qtyY',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.qtyY,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                        .labelHeight -
                      2 *
                        slotProps.scope.openModalModify.labelTemplate
                          .labelPaddingTop,"icon":'icomoon-axis-y',"label":_vm.$t('i18n_page_labels_qty_y'),"placeholder":_vm.$t('i18n_page_labels_qty_y'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.qtyY
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "qtyY", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.qtyY\n                    "}}),_c('text-input',{key:'qtyPaddingTop-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'qtyPaddingTop',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .qtyPaddingTop,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-horizontal',"label":_vm.$t('i18n_page_labels_qty_padding_top'),"placeholder":_vm.$t('i18n_page_labels_qty_padding_top'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .qtyPaddingTop
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "qtyPaddingTop", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .qtyPaddingTop\n                    "}}),_c('text-input',{key:'qtyPaddingLeft-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'qtyPaddingLeft',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .qtyPaddingLeft,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-vertical',"label":_vm.$t('i18n_page_labels_qty_padding_left'),"placeholder":_vm.$t('i18n_page_labels_qty_padding_left'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .qtyPaddingLeft
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "qtyPaddingLeft", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .qtyPaddingLeft\n                    "}}),_c('text-input',{key:'qtyTitle',attrs:{"inputType":'text',"startingValue":slotProps.scope.openModalModify.labelTemplate.qtyTitle,"requiredMessage":_vm.$t('i18n_input_required'),"icon":'fa fa-font',"label":_vm.$t('i18n_page_labels_qty_title'),"placeholder":_vm.$t('i18n_page_labels_qty_title'),"errors":slotProps.scope.errorsFromServer
                        ? slotProps.scope.errorsFromServer[
                            'itemTemplate.qtyTitle'
                          ]
                        : null},on:{"key-press":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.qtyTitle
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "qtyTitle", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.qtyTitle\n                    "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_qty_text_subsection"))+" ")]),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'qtyAlignment',attrs:{"id":'qtyAlignment',"title":_vm.$t('i18n_page_labels_qty_alignment'),"groupName":'qtyAlignment',"options":['L', 'C', 'R'],"icons":[
                          'fas fa-align-left',
                          'fas fa-align-center',
                          'fas fa-align-right' ],"tiny":true,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .qtyAlignment},on:{"input":function($event){return _vm.upTemplate($event, 'qtyAlignment')}},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .qtyAlignment
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "qtyAlignment", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .qtyAlignment\n                        "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_qty_colors'),"labelColor1":_vm.$t('i18n_page_labels_qty_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                            .qtyFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                            $event,
                            slotProps.scope.openModalModify.labelTemplate,
                            'qtyFontColor'
                          )}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_qty_text_subsection"))+" ")]),_c('text-input',{key:'qtyFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .qtyFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_qty_font_size'),"placeholder":_vm.$t('i18n_page_labels_qty_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .qtyFontSize
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "qtyFontSize", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .qtyFontSize\n                        "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'qtyFontStyle',attrs:{"id":'qtyFontStyle',"title":_vm.$t('i18n_page_labels_qty_font_style'),"groupName":'qtyFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                            'fas fa-font',
                            'fas fa-bold',
                            'fas fa-italic',
                            'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .qtyFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'qtyFontStyle')}},model:{value:(
                            slotProps.scope.openModalModify.labelTemplate
                              .qtyFontStyle
                          ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                              , "qtyFontStyle", $$v)},expression:"\n                            slotProps.scope.openModalModify.labelTemplate\n                              .qtyFontStyle\n                          "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify.labelTemplate
                              .qtyFontFamily,"title":_vm.$t('i18n_page_labels_qty_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                              slotProps.scope.openModalModify.labelTemplate,
                              $event,
                              'qtyFontFamily'
                            )}}})],1)],1)]),_c('text-input',{key:'qtyRowHeight-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'qtyRowHeight',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .qtyRowHeight,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_qty_size'),"placeholder":_vm.$t('i18n_page_labels_qty_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .qtyRowHeight
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "qtyRowHeight", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .qtyRowHeight\n                    "}})]:_vm._e()]},proxy:true}],null,true)}):_vm._e(),(slotProps.scope.openModalModify.labelTemplate.batchShow)?_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_batch_section'),"icon":'fa fa-hashtag'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-center"},[_c('div',{staticClass:"cu_page-labels-batch-thumb"})])]},proxy:true},{key:"content",fn:function(){return [(
                    slotProps.scope.openModalModify.labelTemplate.batchShow
                  )?[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'batchShape',attrs:{"id":'batchShape',"title":_vm.$t('i18n_page_labels_batch_shape'),"groupName":'batchShape',"options":['R'],"icons":['far fa-square'],"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .batchShape},on:{"input":function($event){return _vm.upTemplate($event, 'batchShape')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .batchShape
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "batchShape", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .batchShape\n                      "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"st_margin-bottom-10px"},[_c('checkbox-input',{attrs:{"label":_vm.$t('i18n_page_labels_batch_border'),"checkValue":slotProps.scope.openModalModify.labelTemplate
                              .batchBorder,"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .batchBorder},nativeOn:{"input":function($event){return _vm.toggleCheckbox(
                              slotProps.scope.openModalModify.labelTemplate,
                              'batchBorder'
                            )}}})],1),(
                          slotProps.scope.openModalModify.labelTemplate
                            .batchShape == 'R'
                        )?_c('text-input',{key:'batchShapeRadius-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'batchShapeRadius',"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .batchShapeRadius,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":30,"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"icon":'icomoon-border-radius',"label":_vm.$t('i18n_page_labels_batch_shape_radius'),"placeholder":_vm.$t('i18n_page_labels_batch_shape_radius'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .batchShapeRadius
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "batchShapeRadius", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .batchShapeRadius\n                        "}}):_vm._e(),_c('text-input',{key:'batchWidth-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'batchWidth',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .batchWidth,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                            .batchWidth -
                          2 *
                            slotProps.scope.openModalModify.labelTemplate
                              .batchPaddingLeft,"icon":'icomoon-width',"label":_vm.$t('i18n_page_labels_batch_width'),"placeholder":_vm.$t('i18n_page_labels_batch_width'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .batchWidth
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "batchWidth", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .batchWidth\n                        "}})],1)],1),_c('text-input',{key:'batchX-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'batchX',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.batchX,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                        .labelWidth -
                      2 *
                        slotProps.scope.openModalModify.labelTemplate
                          .labelPaddingLeft -
                      slotProps.scope.openModalModify.labelTemplate.batchWidth,"icon":'icomoon-axis-x',"label":_vm.$t('i18n_page_labels_batch_x'),"placeholder":_vm.$t('i18n_page_labels_batch_x'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.batchX
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "batchX", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.batchX\n                    "}}),_c('text-input',{key:'batchY-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'batchY',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate.batchY,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":slotProps.scope.openModalModify.labelTemplate
                        .labelHeight -
                      2 *
                        slotProps.scope.openModalModify.labelTemplate
                          .labelPaddingTop,"icon":'icomoon-axis-y',"label":_vm.$t('i18n_page_labels_batch_y'),"placeholder":_vm.$t('i18n_page_labels_batch_y'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate.batchY
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "batchY", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate.batchY\n                    "}}),_c('text-input',{key:'batchPaddingTop-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'batchPaddingTop',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .batchPaddingTop,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-horizontal',"label":_vm.$t('i18n_page_labels_batch_padding_top'),"placeholder":_vm.$t('i18n_page_labels_batch_padding_top'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .batchPaddingTop
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "batchPaddingTop", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .batchPaddingTop\n                    "}}),_c('text-input',{key:'batchPaddingLeft-' +
                      slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'batchPaddingLeft',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .batchPaddingLeft,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'icomoon-padding-vertical',"label":_vm.$t('i18n_page_labels_batch_padding_left'),"placeholder":_vm.$t('i18n_page_labels_batch_padding_left'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .batchPaddingLeft
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "batchPaddingLeft", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .batchPaddingLeft\n                    "}}),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_batch_title_subsection"))+" ")]),_c('text-input',{key:'batchTitle',attrs:{"inputType":'text',"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .batchTitle,"requiredMessage":_vm.$t('i18n_input_required'),"icon":'fa fa-font',"label":_vm.$t('i18n_page_labels_batch_text'),"placeholder":_vm.$t('i18n_page_labels_batch_text'),"errors":slotProps.scope.errorsFromServer
                          ? slotProps.scope.errorsFromServer[
                              'itemTemplate.batchTitle'
                            ]
                          : null},on:{"key-press":_vm.upTemplate},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .batchTitle
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "batchTitle", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .batchTitle\n                      "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'batchTitleAlignment',attrs:{"id":'batchTitleAlignment',"title":_vm.$t('i18n_page_labels_batch_alignment'),"groupName":'batchTitleAlignment',"options":['L', 'C', 'R'],"icons":[
                          'fas fa-align-left',
                          'fas fa-align-center',
                          'fas fa-align-right' ],"tiny":true,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .batchTitleAlignment},on:{"input":function($event){return _vm.upTemplate($event, 'batchTitleAlignment')}},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .batchTitleAlignment
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "batchTitleAlignment", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .batchTitleAlignment\n                        "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_batch_colors'),"labelColor1":_vm.$t('i18n_page_labels_batch_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                            .batchTitleFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                            $event,
                            slotProps.scope.openModalModify.labelTemplate,
                            'batchTitleFontColor'
                          )}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_batch_text_subsection"))+" ")]),_c('text-input',{key:'batchTitleFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .batchTitleFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_batch_font_size'),"placeholder":_vm.$t('i18n_page_labels_batch_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .batchTitleFontSize
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "batchTitleFontSize", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .batchTitleFontSize\n                        "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'batchTitleFontStyle',attrs:{"id":'batchTitleFontStyle',"title":_vm.$t('i18n_page_labels_batch_font_style'),"groupName":'batchTitleFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                            'fas fa-font',
                            'fas fa-bold',
                            'fas fa-italic',
                            'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify
                              .batchTitleFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'batchTitleFontStyle')}},model:{value:(
                            slotProps.scope.openModalModify
                              .batchTitleFontStyle
                          ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify
                              , "batchTitleFontStyle", $$v)},expression:"\n                            slotProps.scope.openModalModify\n                              .batchTitleFontStyle\n                          "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify
                              .batchTitleFontFamily,"title":_vm.$t('i18n_page_labels_batch_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                              slotProps.scope.openModalModify.labelTemplate,
                              $event,
                              'batchTitleFontFamily'
                            )}}})],1)],1),_c('text-input',{key:'batchTitleRowHeight-' +
                        slotProps.scope.openModalModify.labelTemplate.pageUnit,attrs:{"uid":'batchTitleRowHeight',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate.pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .batchTitleRowHeight,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_batch_size'),"placeholder":_vm.$t('i18n_page_labels_batch_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .batchTitleRowHeight
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "batchTitleRowHeight", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .batchTitleRowHeight\n                      "}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_batch_text_subsection"))+" ")]),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'batchTextAlignment',attrs:{"id":'batchTextAlignment',"title":_vm.$t('i18n_page_labels_batch_alignment'),"groupName":'batchTextAlignment',"options":['L', 'C', 'R'],"icons":[
                          'fas fa-align-left',
                          'fas fa-align-center',
                          'fas fa-align-right' ],"tiny":true,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .batchTextAlignment},on:{"input":function($event){return _vm.upTemplate($event, 'batchTextAlignment')}},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .batchTextAlignment
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "batchTextAlignment", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .batchTextAlignment\n                        "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_batch_colors'),"labelColor1":_vm.$t('i18n_page_labels_batch_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                            .batchTextFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                            $event,
                            slotProps.scope.openModalModify.labelTemplate,
                            'batchTextFontColor'
                          )}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_batch_text_subsection"))+" ")]),_c('text-input',{key:'batchTextFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .batchTextFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":1,"maxNumber":30,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_batch_font_size'),"placeholder":_vm.$t('i18n_page_labels_batch_font_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .batchTextFontSize
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "batchTextFontSize", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .batchTextFontSize\n                        "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'batchTextFontStyle',attrs:{"id":'batchTextFontStyle',"title":_vm.$t('i18n_page_labels_batch_font_style'),"groupName":'batchTextFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                            'fas fa-font',
                            'fas fa-bold',
                            'fas fa-italic',
                            'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                              .batchTextFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'batchTextFontStyle')}},model:{value:(
                            slotProps.scope.openModalModify.labelTemplate
                              .batchTextFontStyle
                          ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                              , "batchTextFontStyle", $$v)},expression:"\n                            slotProps.scope.openModalModify.labelTemplate\n                              .batchTextFontStyle\n                          "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify
                              .batchTextFontFamily,"title":_vm.$t('i18n_page_labels_batch_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                              slotProps.scope.openModalModify.labelTemplate,
                              $event,
                              'batchTextFontFamily'
                            )}}})],1),_c('text-input',{key:'batchTextRowHeight-' +
                          slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,attrs:{"uid":'batchTextRowHeight',"inputType":'number',"infoLabel":slotProps.scope.openModalModify.labelTemplate
                            .pageUnit,"startingValue":slotProps.scope.openModalModify.labelTemplate
                            .batchTextRowHeight,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":_vm.stepFactor,"numDecimals":_vm.stepDecimals,"minNumber":0,"maxNumber":_vm.convertToUnit(_vm.pdfData.template['pageUnit'], 30),"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_batch_size'),"placeholder":_vm.$t('i18n_page_labels_batch_size'),"errors":slotProps.scope.errorsFromServer},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                          slotProps.scope.openModalModify.labelTemplate
                            .batchTextRowHeight
                        ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                            , "batchTextRowHeight", $$v)},expression:"\n                          slotProps.scope.openModalModify.labelTemplate\n                            .batchTextRowHeight\n                        "}})],1)])]:_vm._e()]},proxy:true}],null,true)}):_vm._e(),_c('collapsible',{attrs:{"title":_vm.$t('i18n_page_labels_footer_section'),"icon":'icomoon-footer'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{style:('max-width: 150px; height: 20px; line-height: 20px; overflow: hidden; text-overflow: ellipsis; font-size: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .footerFontSize +
                    'px; color: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .footerFontColor +
                    '; font-family: ' +
                    slotProps.scope.openModalModify.labelTemplate
                      .footerFontFamily +
                    ';' +
                    _vm.fontStyleClass(
                      slotProps.scope.openModalModify.labelTemplate
                        .footerFontStyle
                    ))},[_vm._v(_vm._s(slotProps.scope.openModalModify.labelTemplate.footerText))])]},proxy:true},{key:"content",fn:function(){return [_c('text-input',{key:'footerText',attrs:{"inputType":'text',"startingValue":slotProps.scope.openModalModify.labelTemplate.footerText,"requiredMessage":_vm.$t('i18n_input_required'),"icon":'fa fa-font',"label":_vm.$t('i18n_page_labels_footer_text'),"placeholder":_vm.$t('i18n_page_labels_footer_text'),"errors":slotProps.scope.errorsFromServer
                      ? slotProps.scope.errorsFromServer[
                          'itemTemplate.footerText'
                        ]
                      : null},on:{"key-press":_vm.upTemplate},model:{value:(
                    slotProps.scope.openModalModify.labelTemplate.footerText
                  ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate, "footerText", $$v)},expression:"\n                    slotProps.scope.openModalModify.labelTemplate.footerText\n                  "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('color-picker',{attrs:{"title":_vm.$t('i18n_page_labels_footer_colors'),"labelColor1":_vm.$t('i18n_page_labels_footer_font_color'),"startingColor1":slotProps.scope.openModalModify.labelTemplate
                        .footerFontColor},on:{"change-color-1":function($event){return _vm.upColor(
                        $event,
                        slotProps.scope.openModalModify.labelTemplate,
                        'footerFontColor'
                      )}}})],1),_c('div',{staticClass:"cu_page-labels-panel"},[_c('div',{staticClass:"cu_page-labels-panel-title"},[_vm._v(" "+_vm._s(_vm.$t("i18n_page_labels_footer_text_subsection"))+" ")]),_c('text-input',{key:'footerFontSize',attrs:{"inputType":'number',"startingValue":slotProps.scope.openModalModify.labelTemplate
                        .footerFontSize,"requiredMessage":_vm.$t('i18n_input_required'),"stepNumber":1,"minNumber":4,"maxNumber":20,"infoLabel":'pt',"icon":'fa fa-text-height',"label":_vm.$t('i18n_page_labels_footer_size'),"placeholder":_vm.$t('i18n_page_labels_footer_size'),"errors":slotProps.scope.errorsFromServer
                        ? slotProps.scope.errorsFromServer[
                            'itemTemplate.footerFontSize'
                          ]
                        : null},on:{"key-up":_vm.upTemplate,"step-pressed":_vm.upTemplate},model:{value:(
                      slotProps.scope.openModalModify.labelTemplate
                        .footerFontSize
                    ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                        , "footerFontSize", $$v)},expression:"\n                      slotProps.scope.openModalModify.labelTemplate\n                        .footerFontSize\n                    "}}),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('group-radio-input',{key:'tableRowFontStyle',attrs:{"id":'tableRowFontStyle',"title":_vm.$t('i18n_page_labels_footer_font_style'),"groupName":'footerFontStyle',"options":['N', 'B', 'I', 'U'],"fontSize":15,"tiny":true,"icons":[
                        'fas fa-font',
                        'fas fa-bold',
                        'fas fa-italic',
                        'fas fa-underline' ],"startingValue":slotProps.scope.openModalModify.labelTemplate
                          .footerFontStyle},on:{"input":function($event){return _vm.upTemplate($event, 'footerFontStyle')}},model:{value:(
                        slotProps.scope.openModalModify.labelTemplate
                          .footerFontStyle
                      ),callback:function ($$v) {_vm.$set(slotProps.scope.openModalModify.labelTemplate
                          , "footerFontStyle", $$v)},expression:"\n                        slotProps.scope.openModalModify.labelTemplate\n                          .footerFontStyle\n                      "}})],1),_c('div',{staticClass:"st_margin-bottom-10px"},[_c('font-select-input',{attrs:{"startingFont":slotProps.scope.openModalModify.labelTemplate
                          .footerFontFamily,"title":_vm.$t('i18n_page_labels_footer_font_family')},on:{"selected-font":function($event){return _vm.upFontFamily(
                          slotProps.scope.openModalModify.labelTemplate,
                          $event,
                          'footerFontFamily'
                        )}}})],1)],1)]},proxy:true}],null,true)})]},proxy:true},{key:"footer",fn:function(){return [_c('items-list',{attrs:{"title":_vm.$t('i18n_page_labels_recipes_list_title'),"teamId":_vm.Helper.currentTeam(),"url":'recipes/list',"size":'md'},on:{"item-choosen":_vm.choosenRecipe}})]},proxy:true}],null,true)})]]}},{key:"row-header",fn:function(){return [(_vm.Helper.currentUser().role == 'Admin')?[_c('div',{staticClass:"st_th st_th-50-max"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_num"))+" ")]),_c('div',{staticClass:"st_th st_th-50-max"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_id"))+" ")])]:_vm._e(),_c('div',{staticClass:"st_th st_th-200 st_th-sticky-50"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_name"))+" ")]),_c('div',{staticClass:"st_th st_th-150 st_flex-align-center"},[_vm._v(" "+_vm._s(_vm.$t("i18n_component_table_th_last_modified"))+" ")])]},proxy:true},{key:"row-item",fn:function(slotProps){return [(_vm.Helper.currentUser().role == 'Admin')?[_c('div',{staticClass:"st_td st_td-50-max"},[_c('span',[_vm._v(_vm._s(slotProps.scope.index + 1))])]),_c('div',{staticClass:"st_td st_td-50-max"},[_c('span',[_vm._v(_vm._s(slotProps.scope.rowItem.id))])])]:_vm._e(),_c('div',{staticClass:"st_td st_td-200 st_td-sticky-50",class:slotProps.scope.sticky ? 'st_sticky-rx' : ''},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            placement: 'right',
            content: slotProps.scope.rowItem.name,
            defaultHideOnTargetClick: false,
            autoHide: false,
          }),expression:"{\n            placement: 'right',\n            content: slotProps.scope.rowItem.name,\n            defaultHideOnTargetClick: false,\n            autoHide: false,\n          }"}]},[_vm._v(_vm._s(slotProps.scope.rowItem.name))])]),_c('div',{staticClass:"st_td st_td-150 st_flex-align-center"},[_c('span',[_c('last-modified',{key:'last-modified-' + slotProps.scope.rowItem.id,attrs:{"item":slotProps.scope.rowItem.id,"itemType":'label',"teamId":_vm.Helper.currentTeam()}})],1)])]}},{key:"row-commands",fn:function(slotProps){return [_c('button-input',{attrs:{"tooltip":_vm.$t('i18n_btn_edit'),"icon":'fa fa-edit',"buttonClass":'cu_btn-circle cu_btn-theme-transparent-circle-bordered st_margin-left-10px'},on:{"btn-click":function($event){return slotProps.scope.modifyCommand(slotProps.scope.rowItem)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }