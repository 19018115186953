var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading || !_vm.userDetailInfo || !_vm.userId)?_c('div',[_c('loader')],1):(_vm.error)?_c('div',[_vm._v("---")]):_c('span',[(_vm.displayAvatar && _vm.userDetailInfo)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      placement: 'right',
      content: _vm.userDetailTooltip,
      defaultHideOnTargetClick: false,
      autoHide: false,
    }),expression:"{\n      placement: 'right',\n      content: userDetailTooltip,\n      defaultHideOnTargetClick: false,\n      autoHide: false,\n    }"}],style:('position: relative; width: ' +
      _vm.avatarSize +
      'px; height: ' +
      _vm.avatarSize +
      'px')},[_c('div',[_c('div',{staticClass:"st_avatar",class:_vm.userClass,style:('background-image: url(' +
          _vm.Helper.userAvatar(_vm.userDetailInfo.imageUrl) +
          ');' +
          'width: ' +
          _vm.avatarSize +
          '; height: ' +
          _vm.avatarSize +
          ';')}),(_vm.displayOnline)?_c('div',{class:'cu_user-detail-online-' + _vm.isOnline,style:('border-width: ' +
          _vm.borderSize +
          '; width: ' +
          _vm.onlineSize +
          '; height: ' +
          _vm.onlineSize +
          ';')}):_vm._e()])]):_c('span',{staticClass:"st_bold"},[_vm._v(" "+_vm._s(this.userDetailName || this.userEmail)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }