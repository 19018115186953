













import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/models/EventBus";
import ButtonPayment from "@/components/inputs/ButtonPayment.vue";
import EnableFeature from "@/components/custom/EnableFeature.vue";

@Component({
  components: {
    ButtonPayment,
    EnableFeature,
  },
})
export default class PageFeatures extends Vue {
  private loading = true;
  private messageFromServer = "";
  private errorsFromServer: Array<string> = [];
  private registry = null;
  private numUpdates = 1;
  private user = this.$store.state.auth.user; //localStorage.getItem('user');//this.$store.state.auth.user;
  private script: HTMLScriptElement = document.createElement("script");

  mounted() {
    const ClientID =
      "Afdisr7iQ4AkVJjgae4oXi2ey5COvp04FFfIUaI9jEaUBV6OarGM-yZw6OItoxrBrUd8awQvJVN5BJsd";
    this.script.src = `https://www.paypal.com/sdk/js?currency=EUR&client-id=${ClientID}`;
    document.body.appendChild(this.script);

    this.registry = EventBus.getInstance().register(
      "refresh-page-features",
      (notification: any) => {
        this.numUpdates += 1;
      }
    );
  }

  async destroyed() {
    this.registry.unregister();
  }
}
