






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Logo extends Vue {
  @Prop({ default: "md" }) size;
}
