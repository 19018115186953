var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('cookie-toggler',{ref:"cookieToggler",attrs:{"opts":_vm.cookieOpts},on:{"save":_vm.saveCookieToggler,"quit":_vm.closeCookieToggler}}),_c('confirm-modal-popup',{ref:"confirmModalPopupApp"}),(
      (this.$store.state.auth.status.loggedIn &&
        this.$store.state.auth.user['verified'] != false &&
        this.$store.state.auth.user['verified'] != null) ||
      !this.$store.state.auth.status.loggedIn ||
      this.$router.currentRoute.name == 'verify' ||
      this.$router.currentRoute.name == 'changeUser' ||
      this.$router.currentRoute.name == 'login'
    )?[_c('div',{staticClass:"st_flex-row"},[(this.$route.meta.menu)?_c('div',[_c('nav-bar',{attrs:{"path":this.$route.name}}),_c('header-bar'),_c('notifications',{staticStyle:{"position":"fixed","bottom":"10px","left":"60px"},attrs:{"group":"notifications","position":"bottom left","classes":"st_notification"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('div',{class:'st_notification ' + props.item.type + ' st_shadow'},[_c('div',{staticClass:"st_flex-row",staticStyle:{"margin-bottom":"10px"}},[_c('div',[_c('a',{staticClass:"title"},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[(props.item.type == 'error')?_c('span',{staticClass:"fa fa-2x fa-warning",staticStyle:{"margin-right":"10px"}}):_vm._e()]),_c('span',{staticStyle:{"font-weight":"bold","font-size":"14px"}},[_vm._v(_vm._s(props.item.title))])]),_c('div',{domProps:{"innerHTML":_vm._s(props.item.text)}})]),_c('div',{class:'st_notification-icon ' + props.item.type,on:{"click":props.close}},[_c('a',{staticClass:"close"},[_c('i',{staticClass:"fa fa-md fa-close"})])])])])]}}],null,false,2258849583)})],1):_vm._e(),(this.$router.currentRoute.name == 'main')?[_c('router-view')]:[_c('div',{staticClass:"st_body"},[_c('div',{staticClass:"st_body-content"},[_c('div',{class:this.$router.currentRoute.name != 'resetPassword' &&
                this.$router.currentRoute.name != 'verify' &&
                this.$router.currentRoute.name != 'changeEmail' &&
                this.$router.currentRoute.name != 'login' &&
                this.$router.currentRoute.name != 'register' &&
                this.$router.currentRoute.name != 'privacy' &&
                this.$router.currentRoute.name != 'terms' &&
                this.$router.currentRoute.name != 'cookies'
                  ? 'st_panel'
                  : ''},[_c('router-view')],1)])])],_c('cookie-law',{ref:"cookieLaw",attrs:{"theme":"custom","buttonText":_vm.$t('i18n_btn_accept_cookies')}},[_c('div',{attrs:{"slot":"message"},slot:"message"}),_c('div',{staticClass:"st_width-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-9",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t("i18n_cookie_message", { name: _vm.$appName, }))+" "),_c('span',[_c('router-link',{key:'/privacy',attrs:{"to":'/cookies'}},[_vm._v(_vm._s(_vm.$t("i18n_app_cookie_policy_name")))])],1)]),_c('div',{staticClass:"col-xs-12 col-sm-3 st_flex-row st_flex-align-center"},[_c('div',[_c('button-input',{attrs:{"label":_vm.$t('i18n_btn_manage_cookies'),"buttonClass":'cu_btn-size-sm cu_btn-theme-undo st_margin-10px'},on:{"btn-click":function($event){return _vm.manageCookies()}}})],1),_c('div',[_c('button-input',{attrs:{"label":_vm.$t('i18n_btn_accept_cookies'),"buttonClass":'cu_btn-size-sm cu_btn-theme-normal st_margin-10px'},on:{"btn-click":function($event){return _vm.enableAllCookies()}}})],1)])])])])],2)]:[_c('PageNotVerified')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }