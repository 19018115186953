










































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import { authHeader } from "@/services/auth-header";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import Modal from "@/components/inputs/Modal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";

@Component({
  components: { Loader, Modal, TextInput, ButtonInput, PopoverInput },
})
export default class Notes extends Vue {
  @Prop({ default: () => [] }) items;
  @Prop({ default: "" }) private teamId: string;
  private Helper = Helper;
  private loading = false;
  private error = false;
  private openModal = 0;
  private notes = [];
  private selectedNotes = [];
  private note = "";
  private loadingNote = false;
  private errorsFromServer: Array<string> = [];
  private goToNewNote = false;
  private goToToolsNote = null;
  private goToEditNote = null;
  private goToDelNote = null;

  openNewNote() {
    this.openModal = 1;
  }

  get availableNotes() {
    return this.notes.filter((note) => {
      return this.items.indexOf(note["id"]) == -1;
    });
  }

  get usedNotes() {
    return this.notes.filter((note) => {
      return this.items.indexOf(note["id"]) != -1;
    });
  }

  delNote(noteId) {
    axios
      .delete(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.teamId +
          "/note",
        {
          data: {
            noteId: noteId,
          },
          headers: authHeader(),
        }
      )
      .then(async (response) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_notes_delete_success").toString(),
          duration: 20000,
        });
        this.refreshNotes();
        this.goToToolsNote = null;
        this.goToDelNote = null;
      })
      .catch((error) => {
        this.error = true;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_notes_delete_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }

  getNote(noteId, attrib = null) {
    const notes = this.notes.filter((note) => {
      return note["id"] == noteId;
    });

    if (notes.length) {
      if (attrib == null) return notes[0];
      else return notes[0][attrib];
    } else return null;
  }

  selectNote(noteId) {
    this.items.push(noteId);
  }

  deselectNote(noteId) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i] === noteId) {
        this.items.splice(i, 1);
      }
    }
  }

  isUsedNote(noteId) {
    let foundNote = false;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i] === noteId) {
        foundNote = true;
      }
    }
    return foundNote;
  }

  toggleNote(noteId) {
    let foundNote = false;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i] === noteId) {
        this.items.splice(i, 1);
        foundNote = true;
      }
    }

    if (!foundNote) {
      this.items.push(noteId);
    }
  }

  async refreshNotes(): Promise<any | null> {
    this.loading = true;
    this.notes = [];
    return axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.teamId +
          "/notes/list",
        {
          headers: authHeader(),
        }
      )
      .then(async (response) => {
        const data = response.data;
        this.notes = response.data;
        /*if (data.user_id) {
        } else this.error = true;*/
        this.loading = false;
        return this.notes;
      })
      .catch(() => {
        this.error = true;
        return null;
      })
      .finally(() => {
        return null;
      });
  }

  async mounted() {
    const notes = await this.refreshNotes();
    for (let i = 0; i < this.items.length; i++) {
      const savedNote = this.items[i];
      let foundNote = false;
      for (let j = 0; j < notes.length; j++) {
        if (notes[j]["id"] == savedNote) {
          foundNote = true;
        }
      }
      if (foundNote == false) {
        this.items.splice(i, 1);
        i--;
      }
    }
  }

  newNote() {
    this.errorsFromServer = [];
    axios
      .post(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.teamId +
          "/note",
        {
          noteDescription: this.note,
          headers: authHeader(),
        }
      )
      .then(async (response) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_notes_new_success").toString(),
          duration: 20000,
        });
        this.refreshNotes();
      })
      .catch((error) => {
        this.error = true;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_notes_new_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }

  editNote(note) {
    this.errorsFromServer = [];
    console.log("editNote" + note);
    axios
      .put(
        Vue.prototype.$apiHttpProtocol +
          "://" +
          Vue.prototype.$apiAddress +
          "/api/data/" +
          this.teamId +
          "/note",
        {
          noteId: note["id"],
          noteDescription: note["description"],
          headers: authHeader(),
        }
      )
      .then(async (response) => {
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_notes_edit_success").toString(),
          duration: 20000,
        });
        this.refreshNotes();
      })
      .catch((error) => {
        this.error = true;
        if (!Helper.isUndefined(error.response.data.errors))
          this.errorsFromServer = error.response.data.errors;
        this.$notify({
          group: "notifications",
          title: this.$t("i18n_component_notes_edit_error").toString(),
          text: error,
          type: "error",
          duration: 20000,
        });
      });
  }
}
