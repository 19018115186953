




























import { Component, Vue, Prop } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";

@Component({
  components: {},
})
export default class BillingSummary extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: true }) displayInfo;

  currentUser() {
    return this.$store.state.auth.user;
  }
}
