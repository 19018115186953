



























































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import Loader from "@/components/graphic/Loader.vue";
import PopoverInput from "@/components/inputs/PopoverInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ColorPicker from "@/components/graphic/ColorPicker.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import LabelBorderEditor from "@/components/custom/LabelBorderEditor.vue";
import FontSelectInput from "@/components/inputs/FontSelectInput.vue";
import GroupRadioInput from "@/components/inputs/GroupRadioInput.vue";
import Collapsible from "@/components/graphic/Collapsible.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
    ButtonInput,
    CheckboxInput,
    PopoverInput,
    ColorPicker,
    TextInput,
    LabelBorderEditor,
    FontSelectInput,
    GroupRadioInput,
    Collapsible,
  },
})
export default class LabelTextEditor extends Vue {
  @Prop({ default: "" }) private name;
  @Prop({ default: null }) private pdfData;
  @Prop({ default: null }) private template;
  @Prop({ default: null }) private stepFactor;
  @Prop({ default: null }) private stepDecimals;

  private errorsFromServer: Array<string> = [];

  convertToUnit(pageUnit, value) {
    let factor = 1;
    if (pageUnit == "in") factor = 1 / 25.4;
    else if (pageUnit == "cm") factor = 1 / 10;
    else if (pageUnit == "pt") factor = 1 / 0.35;
    return value * factor;
  }
}
