









import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MinusPlusCheckboxInput extends Vue {
  private isMinus = false;
  @Prop({ default: false }) private checkValue;

  public get isMinusValue(): boolean {
    return this.isMinus;
  }

  public set isMinusValue(value: boolean) {
    this.isMinus = value;
  }

  @Watch("checkValue") check() {
    if (this.checkValue != this.isMinusValue) {
      this.isMinusValue = this.checkValue;
    }
  }

  changeMinus() {
    this.isMinusValue = !this.isMinus;
    this.$emit("input", this.isMinusValue);
  }
}
