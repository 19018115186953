






















































































import { Component, Vue, Prop } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";
import { Helper } from "@/utils/Helper";

@Component({
  components: {
    Loader,
  },
})
export default class BankInstructions extends Vue {
  private Helper: Helper = Helper;
  @Prop({ default: "n.d." }) private total;
}
