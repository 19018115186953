var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal-target',{attrs:{"name":"modalUserDropzone"}}),_c('portal',{attrs:{"to":"modalUserDropzone"}},[_c('modal',{attrs:{"isShown":_vm.openModal},on:{"close":function($event){_vm.openModal = false},"save":function($event){return _vm.$refs.userDropzone.saveUpload()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("i18n_component_userdropzone_avatar"))+" ")]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-right"},[_c('div',{staticClass:"st_width-100"},[_c('Dropzone',{ref:"userDropzone",attrs:{"title":_vm.$t('i18n_component_userdropzone_avatar_info'),"icon":'fa fa-pencil',"addUrl":_vm.$apiHttpProtocol +
                '://' +
                _vm.$apiAddress +
                '/api/users/' +
                _vm.user.id +
                '/uploadAvatar',"removeUrl":_vm.$apiHttpProtocol +
                '://' +
                _vm.$apiAddress +
                '/api/users/' +
                _vm.user.id +
                '/removeAvatar',"uploadedUrl":_vm.$apiHttpProtocol +
                '://' +
                _vm.$apiAddress +
                '/api/users/' +
                _vm.user.id +
                '/uploadedAvatar',"startingValue":_vm.user.profile_photo_path
                  ? _vm.$apiHttpProtocol +
                    '://' +
                    _vm.$apiAddress +
                    '/images/profile-photos/users/' +
                    _vm.user.profile_photo_path
                  : null},on:{"uploaded":_vm.uploadedUserImage}})],1)])]},proxy:true}])})],1),_c('div',{staticClass:"cu_user-image",staticStyle:{"overflow":"visible","position":"relative"},style:('background-image: url(' +
      (_vm.user.profile_photo_path
        ? _vm.$apiHttpProtocol +
          '://' +
          _vm.$apiAddress +
          '/images/profile-photos/users/' +
          _vm.user.profile_photo_path
        : _vm.$apiHttpProtocol +
          '://' +
          _vm.$apiAddress +
          '/images/default-user.png') +
      ')')},[(!_vm.isDisabled)?_c('div',{staticClass:"cu_user-edit st_shadow-icon",on:{"click":function($event){_vm.openModal = true}}},[_c('i',{staticClass:"fa fa-pencil"})]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }