
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import { Pie } from "vue-chartjs";

@Component({
  //    the following two settings don't make any visible difference neither compile nor run time
  //    extends: Line,
  //    mixins: [Line]
})
export default class PieChart extends Mixins(Pie) {
  @Prop({ default: [] }) private labels;
  @Prop({ default: "" }) private label;
  @Prop({ default: [] }) private data;
  @Prop({ default: "%" }) private labelUnit;
  @Prop({ default: "top" }) private legendPosition;
  private Helper = Helper;
  private timer = null;

  private bgColors = Helper.dynChartColors(
    document.documentElement.style.getPropertyValue("--main-theme-2nd"),
    this.data.length
  ); /*[
    "#4c0026",
    "#660033",
    "#800040",
    "#99004d",
    "#b3005a",
    "#cc0066",
    "#e60073",
    "#ff0080",
    "#ff1a8d",
    "#ff3399",
    "#ff4da6",
    "#ff66b3",
    "#ff80c0",
    "#ff99cc",
    "#ffb3d9",
    "#ffe6f2",
    "#ffffff",
  ];*/ /*
  [
    "#c13018",
    "#f36f13",
    "#ebcb38",
    "#a2b969",
    "#0d95bc",
    "#063951",
    ]
    */

  currentUser() {
    return this.$store.state.auth.user;
  }

  currentTheme() {
    if (this.currentUser()) return this.currentUser().theme;
    else return "0";
  }

  drawChart() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.doDrawChart();
    }, 10);
  }

  doDrawChart() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: this.label,
            data: this.data,
            backgroundColor: Helper.dynChartColors(
              document.documentElement.style.getPropertyValue(
                "--main-theme-2nd"
              ),
              this.data.length
            ),
          },
        ],
      },
      {
        legend: {
          position: this.legendPosition,
          onClick: null,
          labels: {
            boxWidth: 7.5,
            usePointStyle: true, // circle points
            fontSize: 8,
          },
        },
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              const i = tooltipItems["index"];
              const label = data["labels"][i];
              const qty = data["datasets"][0]["data"][i].toString();
              return (
                label +
                ": " +
                Helper.toLocaleString(parseFloat(qty), 2) +
                this.labelUnit
              );
            },
          },
        },
      }
    );
  }

  mounted() {
    this.doDrawChart();
  }
}
