
import axios from "axios";
import i18n from "../i18n-inline-loader";
import { authHeader } from "@/services/auth-header";
import { Helper } from "@/utils/Helper";
import { threadId } from "worker_threads";

export default class Label {
    teamId: string
    type: string
    id: string
    name: string
    loading: boolean
    error: boolean
    discovered: boolean
    hidden: boolean
    items: any
    elements: any
    isUsedIn: any
    labelTemplate: any

    public static makeTemplate(templateData) {
        const newTemplate = Label.getDefaultTemplateValues();

        if (templateData != null) {
            const oldTemplate = templateData.labelTemplate;
            const templateKeys = Object.keys(newTemplate);
            for (let i = 0; i < templateKeys.length; i++) {
                const key = templateKeys[i];
                if (oldTemplate[key])
                    newTemplate[key] = oldTemplate[key];
            }
        }
        templateData.labelTemplate = newTemplate;
    }

    public static getDefaultTemplateValues() {
        const template = {}
        template['pageOrientation'] = 'P'
        template['pageUnit'] = 'mm'
        template['pageSize'] = 'A4'
        template['qtyShow'] = 0
        template['headerShow'] = 1
        template['tableShow'] = 1
        template['noteShow'] = 1
        template['batchShow'] = 1
        template['footerShow'] = 0
        template['labelBorder'] = 0
        template['labelWidth'] = 105
        template['labelHeight'] = 57
        template['startMarginTop'] = 6;
        template['startMarginLeft'] = 0;
        template['labelPaddingTop'] = 5;
        template['labelPaddingLeft'] = 5;
        template['labelMarginTop'] = 0;
        template['labelMarginLeft'] = 0;
        template['labelBgColor'] = "#ffffff";
        template['labelShape'] = "R"
        template['labelShapeRadius'] = 0
        template['logoBorder'] = 0
        template['logoWidth'] = 40
        template['logoHeight'] = 40
        template['logoX'] = 0
        template['logoY'] = 0
        template['logoShape'] = "R"
        template['logoShapeRadius'] = 0
        template['logoCircleRadius'] = 20
        template['numRowsX'] = 2
        template['numRowsY'] = 5
        template['titleWidth'] = 95
        template['titleX'] = 0
        template['titleY'] = 2
        template['titleAlignment'] = 'C'
        template['titleFontSize'] = 8
        template['titleFontColor'] = '#3c436b'
        template['titleFontStyle'] = 'B'
        template['titleFontFamily'] = 'arial'
        template['headerText'] = Helper.defaultAuthor()
        template['headerWidth'] = 95
        template['headerX'] = 0
        template['headerY'] = 0
        template['headerAlignment'] = 'C'
        template['headerFontSize'] = 5
        template['headerFontColor'] = '#3c436b'
        template['headerFontStyle'] = 'N'
        template['headerFontFamily'] = 'arial'
        template['tableWidth'] = 50
        template['tableX'] = 0
        template['tableY'] = 5
        template['tableBorder'] = 1
        template['tableHeaderSize'] = 4
        template['tableHeaderFontSize'] = 7
        template['tableHeaderBgColor'] = "rgba(255, 255, 255, 0)"
        template['tableHeaderFontStyle'] = 'B'
        template['tableHeaderFontFamily'] = 'arial'
        template['tableHeaderFontColor'] = '#3c436b'
        template['tableRowSize'] = 3
        template['tableRowFontSize'] = 5
        template['tableRowFontStyle'] = 'N'
        template['tableRowFontFamily'] = 'arial'
        template['tableRowFontColor'] = '#3c436b'
        template['tableRowOddBgColor'] = "#ffffff"
        template['tableRowEvenBgColor'] = "#ccd0e8"
        template['tableCellPadding'] = 2
        template['logoPhotoPath'] = ""
        template['logoPhotoWidth'] = 0
        template['logoPhotoHeight'] = 0
        template['noteTitle'] = "Modalità di conservazione"
        template['noteShape'] = "R"
        template['noteShapeRadius'] = 0
        template['noteBgColor'] = "#ff0000"
        template['noteBorder'] = 1
        template['noteBorderColor'] = "#000000"
        template['noteBorderWeight'] = 0.2
        template['noteWidth'] = 35
        template['noteX'] = 60
        template['noteY'] = 13
        template['notePaddingTop'] = 2
        template['notePaddingLeft'] = 2
        template['noteTitleRowHeight'] = 3
        template['noteTitleFontSize'] = 6
        template['noteTitleFontFamily'] = 'arial'
        template['noteTitleFontStyle'] = 'B'
        template['noteTitleAlignment'] = 'C'
        template['noteTitleFontColor'] = '#3c436b'
        template['noteTextRowHeight'] = 2
        template['noteTextFontSize'] = 5
        template['noteTextFontFamily'] = 'arial'
        template['noteTextFontStyle'] = 'N'
        template['noteTextAlignment'] = 'C'
        template['noteTextFontColor'] = '#3c436b'
        template['batchTitle'] = i18n.messages[i18n.locale]['i18n_page_labels_batch_default_title']
        template['batchShape'] = "R"
        template['batchShapeRadius'] = 0
        template['batchBorder'] = 1
        template['batchWidth'] = 35
        template['batchX'] = 60
        template['batchY'] = 28
        template['batchPaddingTop'] = 2
        template['batchPaddingLeft'] = 2
        template['batchTitleRowHeight'] = 3
        template['batchTitleFontSize'] = 6
        template['batchTitleFontFamily'] = 'arial'
        template['batchTitleFontStyle'] = 'B'
        template['batchTitleAlignment'] = 'C'
        template['batchTitleFontColor'] = '#3c436b'
        template['batchTextRowHeight'] = 2
        template['batchTextFontSize'] = 5
        template['batchTextFontFamily'] = 'arial'
        template['batchTextFontStyle'] = 'N'
        template['batchTextAlignment'] = 'C'
        template['batchTextFontColor'] = '#3c436b'
        template['qtyTitle'] = i18n.messages[i18n.locale]['i18n_page_labels_qty_default_title']
        template['qtyShape'] = "R"
        template['qtyShapeRadius'] = 0
        template['qtyBorder'] = 0
        template['qtyWidth'] = 95
        template['qtyRowHeight'] = 2
        template['qtyX'] = 0
        template['qtyY'] = 4
        template['qtyPaddingTop'] = 0
        template['qtyPaddingLeft'] = 0
        template['qtyFontSize'] = 5
        template['qtyFontFamily'] = 'arial'
        template['qtyFontStyle'] = 'N'
        template['qtyAlignment'] = 'C'
        template['qtyFontColor'] = '#3c436b'
        template['footerText'] = Helper.defaultAuthor()
        template['footerFontSize'] = 6
        template['footerFontColor'] = "#3c436b"
        template['footerFontStyle'] = 'I'
        template['footerFontFamily'] = 'arial'
        return template
    }

    constructor(teamId, id, discovered) {
        this.teamId = teamId
        this.type = "label"
        this.id = id
        this.loading = true
        this.error = false
        this.discovered = discovered
        this.name = ""
        this.hidden = false
        this.items = null
        this.elements = null
        this.isUsedIn = null
        this.labelTemplate = Label.getDefaultTemplateValues()


        if (this.id != "")
            axios
                .post(process.env.VUE_APP_HTTP_PROTOCOL +
                    "://" +
                    process.env.VUE_APP_API_ADDRESS +
                    "/api/data/" +
                    this.teamId +
                    "/label/" +
                    this.id,
                    {
                        headers: authHeader(),
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    const data = response.data;
                    const template = data.template;
                    this.name = data.name;

                    if (template != null) {
                        const templateKeys = Object.keys(this.labelTemplate);
                        for (let i = 0; i < templateKeys.length; i++) {
                            const key = templateKeys[i];
                            if (template[key])
                                this.labelTemplate[key] = template[key];
                        }
                    }
                })
                .catch((error) => {
                    this.error = true;
                })
                .finally(() => (this.loading = false));
        this.loading = false;
    }

    getTemplateAttrs() {
        const attrs = {};
        const templateKeys = Object.keys(this.labelTemplate);
        for (let i = 0; i < templateKeys.length; i++) {
            const key = templateKeys[i];
            attrs[key] = this.labelTemplate[key];
        }
        return attrs;
    }

    attrs() {
        return {
            itemId: this.id,
            itemName: this.name,
            itemTemplate: this.getTemplateAttrs(),
            headers: authHeader(),
        }
    }

    isValid() {
        return true;
    }
}
