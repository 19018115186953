

























































































import User from "../models/User";
import { Component, Vue } from "vue-property-decorator";
import TextInput from "@/components/inputs/TextInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
import Loader from "@/components/graphic/Loader.vue";
import { Helper } from "@/utils/Helper";

const DisplayReset = {
  resetPassword: 0,
  resetPasswordDone: 1,
};

@Component({
  components: {
    TextInput,
    CheckboxInput,
    ButtonInput,
    Loader,
  },
})
export default class PageResetPassword extends Vue {
  // data()

  private user: User = new User(
    "",
    this.$route.query.email,
    "",
    "",
    this.$route.query.token
  );
  private loading = false;
  private displayResetForm = DisplayReset.resetPassword;
  private messageFromServer = "ciao miao bau";
  private errorsFromServer: Array<string> = [];
  private showPassword = false;
  private unknownErrors = false;

  // methods

  get hasUnknownErrors() {
    if (Object.keys(this.errorsFromServer).length) return true;
    else return false;
  }

  get DisplayReset(): typeof DisplayReset {
    return DisplayReset;
  }

  get validEmail(): boolean {
    const regEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (
      this.user.email &&
      this.user.email.length &&
      regEmail.test(this.user.email)
    )
      return true;
    return false;
  }

  get validPassword(): boolean {
    if (this.user.password && this.user.password.length) return true;
    return false;
  }

  get validConfirmPassword(): boolean {
    if (this.user.confirmPassword == this.user.password) return true;
    return false;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  goToLogin() {
    this.$router.push({ name: "login" });
  }

  resetPassword() {
    this.errorsFromServer = [];
    this.unknownErrors = false;
    this.loading = true;
    this.$store
      .dispatch("auth/resetPassword", {
        token: this.user.token,
        email: this.user.email,
        password: this.user.password,
        confirmPassword: this.user.confirmPassword,
      })
      .then(
        (response) => {
          this.loading = false;
          this.messageFromServer = response.message;
          this.displayResetForm = DisplayReset.resetPasswordDone;
        },
        (error) => {
          this.loading = false;
          if (error.response && !Helper.isUndefined(error.response.data.errors))
            this.errorsFromServer = error.response.data.errors;
          else this.unknownErrors = true;
        }
      );
  }
}
