export default class Verify {
  id: string
  hash: string
  expires: string
  signature: string
  constructor(id, hash, expires, signature) {
    this.id = id
    this.hash = hash
    this.expires = expires
    this.signature = signature
  }
}