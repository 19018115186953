

























import { Component, Prop, Vue } from "vue-property-decorator";
import RadioInput from "./RadioInput.vue";

@Component({
  components: {
    RadioInput,
  },
})
export default class GroupRadioInput extends Vue {
  @Prop({ default: null }) private title;
  @Prop()
  private options!: Array<string>;
  @Prop({ default: null }) private labels: Array<string>;
  @Prop({ default: null }) private icons: Array<string>;
  @Prop() private startingValue!: string;
  @Prop({ default: "" }) private groupName;
  @Prop({ default: false }) private tiny;
  @Prop({ default: false }) private extended;
  @Prop({ default: 20 }) private fontSize;
  private value = "";

  getOptionIndex(input) {
    return this.options.indexOf(input);
  }

  async created() {
    this.value = this.startingValue;
  }
}
