






















































































import { Component, Vue } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import ButtonInput from "@/components/inputs/ButtonInput.vue";
//import TeamActivityNotifications from "@/components/custom/TeamActivityNotifications.vue";
import TeamMembers from "@/components/custom/TeamMembers.vue";
import CheckForIngredientsUpdates from "@/components/custom/CheckForIngredientsUpdates.vue";
import ProductsOverview from "@/components/custom/ProductsOverview.vue";
import Loader from "@/components/graphic/Loader.vue";
import TabPanel from "@/components/graphic/TabPanel.vue";
import NavBar from "@/components/graphic/NavBar.vue";

@Component({
  components: {
    ButtonInput,
    Loader,
    //TeamActivityNotifications,
    TeamMembers,
    CheckForIngredientsUpdates,
    ProductsOverview,
    TabPanel,
    NavBar,
  },
})
export default class PageHome extends Vue {
  private Helper: Helper = Helper;
  private loading = false;
  private user = this.$store.state.auth.user;
  private numDeletedIngredients = -1;

  currentUser() {
    return this.$store.state.auth.user;
  }

  currentTeam() {
    return this.user.currentTeam || null;
  }
}
