
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Helper } from "@/utils/Helper";
import { Bar } from "vue-chartjs";

@Component({
  //    the following two settings don't make any visible difference neither compile nor run time
  //    extends: Line,
  //    mixins: [Line]
})
export default class BarChart extends Mixins(Bar) {
  @Prop({ default: [] }) private labels;
  @Prop({ default: "" }) private label;
  @Prop({ default: [] }) private data;
  @Prop({ default: "%" }) private labelUnit;
  private Helper = Helper;
  private timer = null;

  drawChart() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.doDrawChart();
    }, 10);
  }

  doDrawChart() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: this.label,
            data: this.data,
            backgroundColor: Helper.dynChartColors(
              document.documentElement.style.getPropertyValue(
                "--main-theme-2nd"
              ),
              this.data.length
            ),
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              return (
                Helper.toLocaleString(
                  parseFloat(tooltipItems.yLabel.toString()),
                  2
                ) + this.labelUnit
              );
            },
          },
        },
      }
    );
  }

  mounted() {
    this.doDrawChart();
  }
}
