import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n-inline-loader'
import Notifications from 'vue-notification'
import VModal from 'vue-js-modal'
import PortalVue from 'portal-vue'
import moment from 'moment'
import VTooltip from 'v-tooltip'
import VPopover from 'v-tooltip'
//import VueUUID from 'vue-uuid'
import VCalendar from 'v-calendar';
import { longClickDirective } from 'vue-long-click'
import { ColorPicker, ColorPanel } from 'one-colorpicker'
import { auth } from './store/auth-module'
import VueClickOutsideComponent from "vue-click-outside-component";
import { Helper } from "@/utils/Helper";

Vue.use(VueClickOutsideComponent);

//import Utils from '@/plugins/Utils'

/* eslint-disable @typescript-eslint/camelcase */

Vue.use(Notifications)
Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.use(PortalVue)
Vue.use(VTooltip)
Vue.use(VPopover)
//Vue.use(UUID);
//Vue.component('v-select', vSelect)
//Vue.use(VCalendar)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});
Vue.use(ColorPanel)
Vue.use(ColorPicker)

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
})

const longClickInstance = longClickDirective({ delay: 400, interval: 50 })
Vue.directive('longclick', longClickInstance);

interface UIHTMLElementClicketyClick extends HTMLElement {
  clickOutsideEvent?(event: MouseEvent): void;
}


Vue.directive('click-outside', {
  bind: function (el: UIHTMLElementClicketyClick, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains((event as any).target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el: UIHTMLElementClicketyClick) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.prototype.$appName = process.env.VUE_APP_NAME;
Vue.prototype.$apiAddress = process.env.VUE_APP_API_ADDRESS;
Vue.prototype.$apiHttpProtocol = process.env.VUE_APP_HTTP_PROTOCOL;
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  console.log("ROUTE1");
  const publicPages = ['/login', '/resetPassword', '/register', '/privacy', '/terms', '/cookies']
  //const matchingPublicRoutes = to.matched.filter(c => publicPages.some(v => c.path.startsWith(v))).length
  let authRequired = true;
  for (let i = 0; i < publicPages.length; i++) {
    const publicPage = publicPages[i];
    if (to.path == '/' || to.path.startsWith(publicPage)) {
      authRequired = false;
      break;
    }
  }
  //const authRequired = !publicPages.includes(to.path) && !matchingPublicRoutes
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next({ name: 'login', query: { from: to.fullPath } })
  }
  else {
    next({ params: { showLogin: "1" } })
  }
})

router.afterEach((to, from) => {
  console.log("ROUTE2" + to.path);
  if (to.path == "/" || to.path == "/login" || to.path == "/register") {
    console.log("THEME DEFAULT!");
    Helper.applyTheme("0");
    Helper.applyNightMode(false);
  }
  else {
    console.log("THEME USER!");
    let theme = "0";
    if (localStorage.getItem("sweetlab_theme"))
      theme = localStorage.getItem("sweetlab_theme");

    let nightMode = false;
    if (localStorage.getItem("sweetlab_night_mode") == "1") nightMode = true;

    Helper.applyTheme(theme);
    Helper.applyNightMode(nightMode);
  }
})

export const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

