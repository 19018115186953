
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Loader from "@/components/graphic/Loader.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ButtonInput from "@/components/inputs/ButtonInput.vue";

@Component({
  components: {
    Loader,
    TextInput,
    ButtonInput,
  },
})
export default class SearchInput extends Vue {
  @Prop({ default: "" }) searchLabel: string;
  @Prop() private isDisabled!: boolean;
  @Prop({ default: 500 }) delay: number;

  private time = null;

  emitSearch(value) {
    if (this.time) {
      clearTimeout(this.time);
    }
    this.time = setTimeout(() => this.$emit("search-input", value), this.delay);
  }
}
