var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal-target',{attrs:{"name":"modalTeamDropzone"}}),_c('portal',{attrs:{"to":"modalTeamDropzone"}},[_c('modal',{attrs:{"isShown":_vm.openModal},on:{"close":function($event){_vm.openModal = false},"save":function($event){return _vm.$refs.teamDropzone.saveUpload()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("i18n_component_teamdropzone_avatar"))+" ")]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"st_flex-row st_flex-align-right"},[_c('div',{staticClass:"st_width-100"},[(_vm.isLoading)?[_c('loader')]:[_c('Dropzone',{ref:"teamDropzone",attrs:{"title":_vm.$t('i18n_component_teamdropzone_avatar_info'),"icon":'fa fa-pencil',"addUrl":_vm.$apiHttpProtocol +
                  '://' +
                  _vm.$apiAddress +
                  '/api/teams/' +
                  _vm.teamId +
                  '/uploadAvatar',"removeUrl":_vm.$apiHttpProtocol +
                  '://' +
                  _vm.$apiAddress +
                  '/api/teams/' +
                  _vm.teamId +
                  '/removeAvatar',"uploadedUrl":_vm.$apiHttpProtocol +
                  '://' +
                  _vm.$apiAddress +
                  '/api/teams/' +
                  _vm.teamId +
                  '/uploadedAvatar',"startingValue":_vm.teamAvatar
                    ? _vm.$apiHttpProtocol +
                      '://' +
                      _vm.$apiAddress +
                      '/images/profile-photos/teams/' +
                      _vm.teamAvatar
                    : null},on:{"uploaded":_vm.uploadedTeamImage}})]],2)])]},proxy:true}])})],1),_c('div',{staticClass:"cu_team-image",staticStyle:{"overflow":"visible","position":"relative"},style:('background-color: #fff; background-image: url(' +
      (_vm.teamAvatar
        ? _vm.$apiHttpProtocol +
          '://' +
          _vm.$apiAddress +
          '/images/profile-photos/teams/' +
          _vm.teamAvatar
        : _vm.$apiHttpProtocol +
          '://' +
          _vm.$apiAddress +
          '/images/default-team.png') +
      ')')},[(!_vm.isDisabled)?_c('div',{staticClass:"cu_team-edit st_shadow-icon",on:{"click":function($event){_vm.openModal = true}}},[_c('i',{staticClass:"fa fa-pencil"})]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }