var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"st_flex-column cu_color-picker-preview"},[_c('div',{staticClass:"cu_color-picker-preview-header",class:{ active: _vm.expanded == 1 },on:{"click":function($event){return _vm.toggleExpanded(0)}}},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('div',{staticClass:"cu_color-picker-info"},[_c('i',{class:_vm.expanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'})])]),_c('div',{staticClass:"cu_color-picker-preview-body",class:{ active: _vm.expanded == 1 }},[_c('div',{staticClass:"st_flex-row st_margin-10px-0px"},[_c('div',{class:'cu_color-' +
            _vm.numColors +
            '-1 ' +
            (_vm.expanded == 1 || _vm.hover == 1
              ? 'cu_color-picker-active'
              : 'cu_color-picker-default'),style:('background-color: ' + _vm.color1),on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpanded(1)}}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.color1),expression:"color1"}],staticStyle:{"align-self":"center","margin":"0 auto"},on:{"mouseover":function($event){_vm.hover = 1},"mouseleave":function($event){_vm.hover = 0}}},[_c('span',{style:('color: ' + (_vm.Helper.isLight(_vm.color1) ? '#000' : '#fff'))},[_vm._v(_vm._s(_vm.labelColor1))])])]),(_vm.startingColor2)?_c('div',{class:'cu_color-' +
            _vm.numColors +
            '-2 ' +
            (_vm.expanded == 2 || _vm.hover == 2
              ? 'cu_color-picker-active'
              : 'cu_color-picker-default'),style:('background-color: ' + _vm.color2),on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpanded(2)}}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.color2),expression:"color2"}],staticStyle:{"align-self":"center","margin":"0 auto"},on:{"mouseover":function($event){_vm.hover = 2},"mouseleave":function($event){_vm.hover = 0}}},[_c('span',{style:('color: ' + (_vm.Helper.isLight(_vm.color2) ? '#000' : '#fff'))},[_vm._v(_vm._s(_vm.labelColor2))])])]):_vm._e(),(_vm.startingColor3)?_c('div',{class:'cu_color-' +
            _vm.numColors +
            '-3 ' +
            (_vm.expanded == 3 || _vm.hover == 3
              ? 'cu_color-picker-active'
              : 'cu_color-picker-default'),style:('background-color: ' + _vm.color3),on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpanded(3)}}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.color3),expression:"color3"}],staticStyle:{"align-self":"center","margin":"0 auto"},on:{"mouseover":function($event){_vm.hover = 3},"mouseleave":function($event){_vm.hover = 0}}},[_c('span',{style:('color: ' + (_vm.Helper.isLight(_vm.color3) ? '#000' : '#fff'))},[_vm._v(_vm._s(_vm.labelColor3))])])]):_vm._e()]),(_vm.expanded)?_c('div',[(_vm.expanded == 1)?_c('div',{staticClass:"cu_color-picker cu_color-picker-preview-palette"},[_c('color-panel',{on:{"change":_vm.changeColor1},model:{value:(_vm.color1),callback:function ($$v) {_vm.color1=$$v},expression:"color1"}})],1):(_vm.expanded == 2)?_c('div',{staticClass:"cu_color-picker cu_color-picker-preview-palette"},[_c('color-panel',{on:{"change":_vm.changeColor2},model:{value:(_vm.color2),callback:function ($$v) {_vm.color2=$$v},expression:"color2"}})],1):_c('div',{staticClass:"cu_color-picker cu_color-picker-preview-palette"},[_c('color-panel',{on:{"change":_vm.changeColor3},model:{value:(_vm.color3),callback:function ($$v) {_vm.color3=$$v},expression:"color3"}})],1)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }