import store from "@/store";

export class Helper {

  public static flush(arr) {
    arr.splice(0);
  }

  public static removeObject(arr, searchValue) {
    const listOfItems = arr.map((sel) => sel.id);
    const itemIndex = listOfItems.indexOf(searchValue);
    arr.splice(itemIndex, 1);
  }

  public static searchObjectById(arr, searchId) {
    const obj = arr.filter(function (el) {
      if (el && el.id == searchId) return el;
    });
    return obj
  }

  public static searchObjectByIds(arr, searchIds) {
    const obj = arr.filter(function (el) {
      if (el && searchIds.indexOf(el.id) >= 0) return el;
    });
    return obj
  }

  public static positionObjectById(arr, searchId) {
    const listOfItems = arr.map((sel) => sel.id);
    const itemIndex = listOfItems.indexOf(searchId);
    return itemIndex;
  }

  public static inArray(arr, val) {
    if (arr.indexOf(val) != -1)
      return true;
    else
      return false;
  }

  public static isUndefined(val) {
    if (typeof val === "undefined")
      return true;
    else
      return false;
  }

  public static toLocaleString(val, precision = 1) {

    let language = undefined;
    if (Helper.currentLanguage() == 'en')
      language = "en-GB";
    else
      language = "it-IT";
    let localeVal = "";
    if (typeof val == "number") {
      localeVal = val.toLocaleString(language, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      });
      console.log("toLocalString" + language + "/" + typeof val + "/" + val + "/" + localeVal);
    }
    else {
      localeVal = val;
      if (language == "it-IT")
        localeVal = localeVal.replace('.', ',');
    }

    if (language == "it-IT")
      localeVal = localeVal.replace('.', '');
    else
      localeVal = localeVal.replace(',', '');

    // remove zeros if not needed
    if (localeVal.endsWith('.000') || localeVal.endsWith(',000'))
      localeVal = localeVal.slice(0, -4);
    if (localeVal.endsWith('.00') || localeVal.endsWith(',00'))
      localeVal = localeVal.slice(0, -3);
    if (localeVal.endsWith('.0') || localeVal.endsWith(',0'))
      localeVal = localeVal.slice(0, -2);
    return localeVal;
  }

  public static toFloat(val) {
    return parseFloat(String(val).replace(',', '.'));
  }

  public static intToBoolean(val) {
    if (val == 0)
      return false;
    else
      return true;
  }

  public static booleanToInt(val) {
    if (val == false)
      return 0;
    else
      return 1;
  }

  public static lowerCase(val) {
    return val.toLowerCase().trim();
  }

  public static endsWith(str, suffix) {
    return str.toString().indexOf(suffix, str.toString().length - suffix.length) !== -1;
  }

  public static eval(expr) {
    return eval(expr);
  }

  public static epoch() {
    return Math.floor(Date.now() / 1000);
  }

  public static timeStr() {
    const dateOb = new Date();
    const day = ("0" + dateOb.getDate()).slice(-2);
    const month = ("0" + (dateOb.getMonth() + 1)).slice(-2);
    const year = dateOb.getFullYear();

    const hours = dateOb.getHours();
    const minutes = dateOb.getMinutes();
    const seconds = dateOb.getSeconds();
    return day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
  }

  public static today() {
    const dateOb = new Date();
    const day = ("0" + dateOb.getDate()).slice(-2);
    const month = ("0" + (dateOb.getMonth() + 1)).slice(-2);
    const year = dateOb.getFullYear();

    const hours = dateOb.getHours();
    const minutes = dateOb.getMinutes();
    const seconds = dateOb.getSeconds();
    return day + "/" + month + "/" + year;
  }

  public static progress(values) {
    let count = 0;
    let tot = 0;
    for (const key of Object.keys(values)) {
      const value = values[key];
      if (value != null && (value.length || Number.isInteger(value))) {
        count += 1;
      }
      tot += 1;
    }
    return { "count": count, "tot": tot, "val": count * 100 / tot };
  }

  public static currentTeam() {
    return store.state.auth.user.currentTeam || null;
  }

  public static currentUser() {
    return store.state.auth.user;
  }

  public static currentLanguage() {
    if (localStorage.getItem("sweetlab_language")) {
      return localStorage.getItem("sweetlab_language");
    } else if (
      store.state.auth.user &&
      store.state.auth.user.language
    ) {
      return store.state.auth.user.language || "it";
    } else if (localStorage.getItem("sweetlab_language_from_login")) {
      return localStorage.getItem("sweetlab_language_from_login");
    }
  }

  public static defaultAuthor(defaultAuthorText = "") {
    let ret = defaultAuthorText;
    if (defaultAuthorText == "" && Helper.currentTeam()) {
      const checkTeam = store.state.auth.notifications["teams"][Helper.currentTeam()];
      ret = checkTeam["name"];
      if (checkTeam["heading1"] && checkTeam["heading1"] != "")
        ret += " - " + checkTeam["heading1"];
      if (checkTeam["heading2"] && checkTeam["heading2"] != "")
        ret += " - " + checkTeam["heading2"];
    }
    return ret;
  }

  public static userInfo(usersInfoArr, userId) {
    return usersInfoArr.filter((info) => {
      return (
        info.userId == userId
      );
    });
  }

  public static teamAvatar(path) {
    return path
      ? process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/profile-photos/teams/" + path
      : process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/default-team.png";
  }
  public static userAvatar(path) {
    return path
      ? process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/profile-photos/users/" + path
      : process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/default-user.png";
  }

  public static newsImage(path) {
    return path
      ? process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/profile-photos/news/" + path
      : process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/default-news.png";
  }

  public static ingredientImage(path) {
    return path
      ? process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/profile-photos/ingredients/" + path
      : process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/default-ingredient.png";
  }

  public static newsNoDataImage() {
    return process.env.VUE_APP_HTTP_PROTOCOL + "://" + process.env.VUE_APP_API_ADDRESS + "/images/default-news.png";
  }

  public static language(locale) {
    if (locale == "it")
      return "Italiano";
    else
      return "English";
  }

  public static themes() {
    return [
      { index: "0", color: "purple" },
      { index: "1", color: "pink" },
      { index: "2", color: "red" },
      { index: "3", color: "orange" },
      { index: "4", color: "yellow" },
      { index: "5", color: "green" },
      { index: "6", color: "teal" },
      { index: "7", color: "indigo" },
      { index: "8", color: "blue" },
      { index: "9", color: "gray" },
    ]
  }

  public static currentThemeColor($theme) {
    const availableThemes = Helper.themes();
    for (let $i = 0; $i < availableThemes.length; $i++) {
      if (availableThemes[$i]["index"] == $theme) return availableThemes[$i]["color"];
    }
    return availableThemes[0];
  }


  public static applyNightMode(mode) {
    const nightModeColorBody = "#2a2a2f";
    const nightModeColorNav = "#313138";
    if (mode) {
      document.documentElement.style.setProperty("--main-theme-body", nightModeColorBody);
      document.documentElement.style.setProperty("--main-theme-font", "#efeff1");
      document.documentElement.style.setProperty("--main-theme-font-reverse", "#53535f");
      document.documentElement.style.setProperty("--main-theme-btn-font-reverse", "#efeff1");
      document.documentElement.style.setProperty("--main-theme-bg-input", "#2b343b");
      document.documentElement.style.setProperty("--main-theme-panel", nightModeColorBody);
      document.documentElement.style.setProperty("--main-theme-row", nightModeColorNav);
      document.documentElement.style.setProperty("--main-theme-table-body", "#2b343b");
      document.documentElement.style.setProperty("--main-theme-code-area", "#2b343b");
      document.documentElement.style.setProperty("--main-theme-border", "#0e0e10");
      document.documentElement.style.setProperty("--main-theme-border-night-mode", "#575763");
      document.documentElement.style.setProperty("--main-theme-default", nightModeColorNav);
      document.documentElement.style.setProperty("--main-theme-default-2", nightModeColorNav);
      document.documentElement.style.setProperty("--main-theme-default-3", nightModeColorBody);
      document.documentElement.style.setProperty("--main-theme-4th", "#1a202c");
      document.documentElement.style.setProperty("--main-theme-5th", nightModeColorNav);
      document.documentElement.style.setProperty("--main-theme-color-7", "#0e0e10");
    }
    else {
      document.documentElement.style.setProperty("--main-theme-body", "var(--main-theme-color-11)");
      document.documentElement.style.setProperty("--main-theme-font", "#53535f");
      document.documentElement.style.setProperty("--main-theme-font-reverse", "#fff");
      document.documentElement.style.setProperty("--main-theme-btn-font-reverse", "#53535f");

      document.documentElement.style.setProperty("--main-theme-bg-input", "#fff");
      document.documentElement.style.setProperty("--main-theme-panel", "var(--main-theme-color-11)");
      document.documentElement.style.setProperty("--main-theme-row", "transparent");
      document.documentElement.style.setProperty("--main-theme-table-body", "var(--main-theme-color-12)");
      document.documentElement.style.setProperty("--main-theme-code-area", "var(--main-theme-color-12)");
      document.documentElement.style.setProperty("--main-theme-border", "var(--main-theme-color-8)");
      document.documentElement.style.setProperty("--main-theme-border-night-mode", "var(--main-theme-color-8)");
      document.documentElement.style.setProperty("--main-theme-default", "var(--main-theme-color-11)");
      document.documentElement.style.setProperty("--main-theme-default-2", "var(--main-theme-color-11)");
      document.documentElement.style.setProperty("--main-theme-default-3", "var(--main-theme-color-11)");
      document.documentElement.style.setProperty("--main-theme-4th", "var(--main-theme-color-6)");
      document.documentElement.style.setProperty("--main-theme-5th", "var(--main-theme-color-13)");
      document.documentElement.style.setProperty("--main-theme-color-7", "#dde1e7");
    }
  }

  public static applyTheme(theme) {
    console.log("applyTheme" + theme);
    if (theme > 0) {
      if (theme == 1) {
        document.documentElement.style.setProperty("--main-theme-color", "#FF0080");
        document.documentElement.style.setProperty("--main-theme-1st", "#FF0080");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#DF007F");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#ff7eee");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#df49a6");
        document.documentElement.style.setProperty("--main-theme-2nd", "#a04cd4");
        document.documentElement.style.setProperty("--main-theme-color-5", "#fce1f9"); // Input focus
      }
      else if (theme == 2) {
        document.documentElement.style.setProperty("--main-theme-color", "#ff0041");
        document.documentElement.style.setProperty("--main-theme-1st", "#ff0041");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#e0024d");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#f5b8b5");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#df49a6");
        document.documentElement.style.setProperty("--main-theme-2nd", "#953c6b");
        document.documentElement.style.setProperty("--main-theme-color-5", "#f2d8dc"); // Input focus
      }
      else if (theme == 3) {
        document.documentElement.style.setProperty("--main-theme-color", "#FF8141");
        document.documentElement.style.setProperty("--main-theme-1st", "#FF8141");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#e37136");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#b5416a");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#ba5d5d");
        document.documentElement.style.setProperty("--main-theme-2nd", "#7F604F");
        document.documentElement.style.setProperty("--main-theme-color-5", "#ede2d3"); // Input focus
      }
      else if (theme == 4) {
        document.documentElement.style.setProperty("--main-theme-color", "#FFBF18");
        document.documentElement.style.setProperty("--main-theme-1st", "#FFBF18");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#f0ad00");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#a65656");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#856f44");
        document.documentElement.style.setProperty("--main-theme-2nd", "#A16252");
        document.documentElement.style.setProperty("--main-theme-color-5", "#e8e8cf"); // Input focus
      }
      else if (theme == 5) {
        document.documentElement.style.setProperty("--main-theme-color", "#82C168");
        document.documentElement.style.setProperty("--main-theme-1st", "#82C168");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#679953");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#b8e0bd");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#578554");
        document.documentElement.style.setProperty("--main-theme-2nd", "#007b7d");
        document.documentElement.style.setProperty("--main-theme-color-5", "#cae3ca"); // Input focus
      }
      else if (theme == 6) {
        document.documentElement.style.setProperty("--main-theme-color", "#008080");
        document.documentElement.style.setProperty("--main-theme-1st", "#008080");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#006062");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#76b08a");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#008250");
        document.documentElement.style.setProperty("--main-theme-2nd", "#6c8da2");
        document.documentElement.style.setProperty("--main-theme-color-5", "#cae3d5"); // Input focus
      }
      else if (theme == 7) {
        document.documentElement.style.setProperty("--main-theme-color", "#0060A0");
        document.documentElement.style.setProperty("--main-theme-1st", "#0060A0");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#004080");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#6dabe3");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#3d718a");
        document.documentElement.style.setProperty("--main-theme-2nd", "#7b6ed0");
        document.documentElement.style.setProperty("--main-theme-color-5", "#cacbe3"); // Input focus
      }
      else if (theme == 8) {
        document.documentElement.style.setProperty("--main-theme-color", "#82C0FF");
        document.documentElement.style.setProperty("--main-theme-1st", "#82C0FF");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#5291EF");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#d4dffc");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#3d718a");
        document.documentElement.style.setProperty("--main-theme-2nd", "#424282");
        document.documentElement.style.setProperty("--main-theme-color-5", "#c5dde0"); // Input focus
      }
      else if (theme == 9) {
        document.documentElement.style.setProperty("--main-theme-color", "#474747");
        document.documentElement.style.setProperty("--main-theme-1st", "#474747");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#615d5d");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#9a9a9a");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#636262");
        document.documentElement.style.setProperty("--main-theme-2nd", "#6f8572");
        document.documentElement.style.setProperty("--main-theme-color-5", "#ced5d6"); // Input focus
      }
      else {
        document.documentElement.style.setProperty("--main-theme-color", "#9880ff");
        document.documentElement.style.setProperty("--main-theme-1st", "#9880ff");
        document.documentElement.style.setProperty("--main-theme-1st-hover", "#7050fc");
        document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#ff7eee");
        document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#df49a6");
        document.documentElement.style.setProperty("--main-theme-2nd", "#3f5a75");
        document.documentElement.style.setProperty("--main-theme-color-5", "#e3e7fc"); // Input focus
      }
    }
    else {
      document.documentElement.style.setProperty("--main-theme-1st", "#9880ff");
      document.documentElement.style.setProperty("--main-theme-1st", "#9880ff");
      document.documentElement.style.setProperty("--main-theme-1st-hover", "#7050fc");
      document.documentElement.style.setProperty("--main-theme-nav-icon-1", "#ff7eee");
      document.documentElement.style.setProperty("--main-theme-nav-icon-2", "#df49a6");
      document.documentElement.style.setProperty("--main-theme-2nd", "#3f5a75");
      document.documentElement.style.setProperty("--main-theme-color-5", "#e3e7fc"); // Input focus
    }
  }

  public static pSBC(p, c0, c1 = null, l = null) {
    const i = parseInt, m = Math.round;
    let r, g, b, P, f, t, h, a;
    const ax = typeof (c1) == "string";
    if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !ax)) return null;
    let pSBCr = null
    if (!pSBCr) pSBCr = (d) => {
      let n = d.length;
      const x = { r: 0, g: 0, b: 0, a: 0 };
      if (n > 9) {
        [r, g, b, a] = d = d.split(","), n = d.length;
        if (n < 3 || n > 4) return null;
        x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
      } else {
        if (n == 8 || n == 6 || n < 4) return null;
        if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
        d = i(d.slice(1), 16);
        if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
        else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
      } return x
    };
    h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
    if (!f || !t) return null;
    if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
    else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
    a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
    if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
    else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
  }

  public static dynChartColors(color, numItems) {
    const bgColorArr = [];
    console.log("dynChartColors" + numItems);
    if (!numItems)
      return color;
    else {
      let stepFraction = 0.1;
      if (numItems < 10) {
        stepFraction = 0.1 * (10 - numItems)
      }
      const startStep = -(stepFraction * (numItems - 1)) / 2;

      for (let i = 0; i < numItems; i++) {
        const step = startStep + stepFraction * i;
        bgColorArr.push(Helper.pSBC(step, color));
      }
    }
    console.log
    return bgColorArr;
  }

  public static chartColors(theme, numItems) {
    const bgColorArr = [];
    bgColorArr[0] = [
      "#19242f",
      "#202d3b",
      "#263646",
      "#2c3f52",
      "#32485e",
      "#395169",
      "#3f5a75",
      "#526b83",
      "#657b91",
      "#798c9e",
      "#8c9cac",
      "#9fadba",
      "#b2bdc8",
      "#c5ced6",
      "#d9dee3",
      "#eceff1",
      "#ffffff",
    ];
    bgColorArr[1] = [
      "#401e55",
      "#50266a",
      "#602e7f",
      "#703594",
      "#803daa",
      "#9044bf",
      "#a04cd4",
      "#aa5ed8",
      "#b370dd",
      "#bd82e1",
      "#c694e5",
      "#d0a6ea",
      "#d9b7ee",
      "#e3c9f2",
      "#ecdbf6",
      "#f6edfb",
      "#ffffff",
    ];
    bgColorArr[2] = [
      "#3c182b",
      "#4b1e36",
      "#592440",
      "#682a4b",
      "#773056",
      "#863660",
      "#953c6b",
      "#a0507a",
      "#aa6389",
      "#b57797",
      "#bf8aa6",
      "#ca9eb5",
      "#d5b1c4",
      "#dfc5d3",
      "#ead8e1",
      "#f4ecf0",
      "#ffffff",
    ];
    bgColorArr[3] = [
      "#332620",
      "#403028",
      "#4c3a2f",
      "#594337",
      "#664d3f",
      "#725647",
      "#7f604f",
      "#8c7061",
      "#998072",
      "#a59084",
      "#b2a095",
      "#bfb0a7",
      "#ccbfb9",
      "#d9cfca",
      "#e5dfdc",
      "#f2efed",
      "#ffffff",
    ];
    bgColorArr[4] = [
      "#402721",
      "#513129",
      "#613b31",
      "#714539",
      "#814e42",
      "#91584a",
      "#a16252",
      "#aa7263",
      "#b48175",
      "#bd9186",
      "#c7a197",
      "#d0b1a9",
      "#d9c0ba",
      "#e3d0cb",
      "#ece0dc",
      "#f6efee",
      "#ffffff",
    ];
    bgColorArr[5] = [
      "#003132",
      "#003e3f",
      "#004a4b",
      "#005658",
      "#006264",
      "#006f71",
      "#007b7d",
      "#1a888a",
      "#339597",
      "#4da3a4",
      "#66b0b1",
      "#80bdbe",
      "#99cacb",
      "#b3d7d8",
      "#cce5e5",
      "#e6f2f2",
      "#ffffff",
    ];
    bgColorArr[6] = [
      "#2b3841",
      "#364751",
      "#415561",
      "#4c6371",
      "#567182",
      "#617f92",
      "#6c8da2",
      "#7b98ab",
      "#89a4b5",
      "#98afbe",
      "#a7bbc7",
      "#b6c6d1",
      "#c4d1da",
      "#d3dde3",
      "#e2e8ec",
      "#f0f4f6",
      "#ffffff",
    ];
    bgColorArr[7] = [
      "#312c53",
      "#3e3768",
      "#4a427d",
      "#564d92",
      "#6258a6",
      "#6f63bb",
      "#7b6ed0",
      "#887dd5",
      "#958bd9",
      "#a39ade",
      "#b0a8e3",
      "#bdb7e8",
      "#cac5ec",
      "#d7d4f1",
      "#e5e2f6",
      "#f2f1fa",
      "#ffffff",
    ];
    bgColorArr[8] = [
      "#1a1a34",
      "#212141",
      "#28284e",
      "#2e2e5b",
      "#353568",
      "#3b3b75",
      "#424282",
      "#55558f",
      "#68689b",
      "#7b7ba8",
      "#8e8eb4",
      "#a1a1c1",
      "#b3b3cd",
      "#c6c6da",
      "#d9d9e6",
      "#ececf3",
      "#ffffff",
    ];
    bgColorArr[9] = [
      "#2c352e",
      "#384339",
      "#435044",
      "#4e5d50",
      "#596a5b",
      "#647867",
      "#6f8572",
      "#7d9180",
      "#8c9d8e",
      "#9aaa9c",
      "#a9b6aa",
      "#b7c2b9",
      "#c5cec7",
      "#d4dad5",
      "#e2e7e3",
      "#f1f3f1",
      "#ffffff",
    ];
    bgColorArr[10] = [
      "#19242f",
      "#202d3b",
      "#263646",
      "#2c3f52",
      "#32485e",
      "#395169",
      "#3f5a75",
      "#526b83",
      "#657b91",
      "#798c9e",
      "#8c9cac",
      "#9fadba",
      "#b2bdc8",
      "#c5ced6",
      "#d9dee3",
      "#eceff1",
      "#ffffff",
    ];
    return bgColorArr[theme];
  }

  public static calcDateStr(date, format = 'dd/MM/yyyy') {
    if (date) {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();

      let separator = "-"
      if (format.indexOf("/") != -1)
        separator = "/";
      const dateItems = format.split(separator);
      let newDate = "";
      for (let i = 0; i < dateItems.length; i++) {
        const dateItem = dateItems[i];
        if (newDate != "")
          newDate += separator;

        if (dateItem == "dd")
          newDate += day;
        else if (dateItem == "MM")
          newDate += month;
        else if (dateItem.toLocaleLowerCase() == "yyyy" || dateItem.toLocaleLowerCase() == "y")
          newDate += year;

        //Do something
      }



      //let newDate = day + "/" + month + "/" + year;
      //if (format == 'yyyy-MM-dd')
      //  newDate = year + "-" + month + "-" + day;

      return newDate;
    }
    else
      return "01/01/1970";
  }

  public static rgba2Hex(orig) {
    const rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = (rgb && rgb[4] || "").trim(),
      hex = rgb ?
        (rgb[1] | 1 << 8).toString(16).slice(1) +
        (rgb[2] | 1 << 8).toString(16).slice(1) +
        (rgb[3] | 1 << 8).toString(16).slice(1) : orig;
    return hex;
  }

  public static isLight(color) {
    if (color.startsWith("rgba"))
      color = this.rgba2Hex(color);
    const hex = color.replace('#', '');
    const cR = parseInt(hex.substr(0, 2), 16);
    const cG = parseInt(hex.substr(2, 2), 16);
    const cB = parseInt(hex.substr(4, 2), 16);
    const brightness = ((cR * 299) + (cG * 587) + (cB * 114)) / 1000;
    return brightness > 155;
  }

  public static taxes(price) {
    let taxes = 0;
    if (price > 77.47) taxes = 2;
    return taxes;
  }
}

